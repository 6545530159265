import { Box, Heading, ListEntry, MultiSelect, Text, ToggleButton } from 'oskcomponents';
import { DetectionFeedbackStatusEnum, noop } from 'oskcore';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Asset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { FindingFilter } from '../findingUtils';

export type FiltersProps = {
    assetList: Asset[];
    defaultFilter: FindingFilter;
    fetchingAssets: boolean;
    onChange?: (updatedFilter: FindingFilter) => void;
};

export const Filters = ({ fetchingAssets, assetList, defaultFilter, onChange }: FiltersProps) => {
    const detectionListItems: ListEntry[] = [
        { label: 'Methane', value: 'Methane' },
        { label: 'Vehicle Intrusion', value: 'Vehicle' },
        { label: 'Liquid Hydrocarbon', value: 'Liquid Hydrocarbon' },
    ];

    const assetListItems: ListEntry[] = assetList.map((asset) => ({
        label: asset.name,
        value: asset.id,
    }));

    const detectionStatusItems: ListEntry[] = Object.entries(DetectionFeedbackStatusEnum).map((status) => ({
        value: status[1],
        label: status[1],
    }));

    const [filter, setFilter] = useState<FindingFilter>(defaultFilter);

    useEffect(() => {
        onChange && onChange(filter);
    }, [filter, onChange]);

    useEffect(() => {
        setFilter(defaultFilter);
    }, [defaultFilter]);

    return (
        <Box grow col>
            <Box pb={14} pr={10}>
                <Text>Show Only Alerts</Text>
                <Box grow />
                <ToggleButton
                    defaultValue={filter.alertsOnly}
                    onChange={(alertsOnly) => {
                        setFilter({
                            ...filter,
                            alertsOnly,
                        });
                    }}
                />
            </Box>
            <Box pb={14} pr={10} col>
                <Heading variant="tiny" style={{ paddingBottom: '8px' }}>
                    Assets
                </Heading>
                {fetchingAssets && <MultiSelect placeholder={'All'} items={[{ label: 'All', value: '' }]} />}
                {!fetchingAssets && (
                    <MultiSelect
                        placeholder={'All'}
                        items={assetListItems}
                        defaultValues={!fetchingAssets ? filter.assets : []}
                        onChange={(selected) => {
                            setFilter({
                                ...filter,
                                assets:
                                    selected.length === assetListItems.length ? [] : selected.map((item) => item.value),
                            });
                        }}
                    />
                )}
            </Box>
            <Box pb={14} pr={10} col>
                <Heading variant="tiny" style={{ paddingBottom: '8px' }}>
                    Detections
                </Heading>
                <MultiSelect
                    placeholder={'All'}
                    items={detectionListItems}
                    defaultValues={filter.detections}
                    onChange={(selected) => {
                        setFilter({
                            ...filter,
                            detections:
                                selected.length === detectionListItems.length ? [] : selected.map((item) => item.value),
                        });
                    }}
                />
            </Box>
            <Box pb={14} pr={10} col>
                <Heading variant="tiny" style={{ paddingBottom: '8px' }}>
                    Detection Status
                </Heading>
                <MultiSelect
                    placeholder={'All'}
                    items={detectionStatusItems}
                    defaultValues={filter.detectionStatuses}
                    onChange={(selected) => {
                        setFilter({
                            ...filter,
                            detectionStatuses:
                                selected.length === detectionStatusItems.length
                                    ? []
                                    : selected.map((item) => item.value),
                        });
                    }}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        fetchingAssets: state.monitor.app.fetchingAssets,
        assetList: Object.values(state.monitor.app.assets),
    };
};
export default connect(mapStateToProps, noop)(Filters);
