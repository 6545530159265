import React, { useEffect, useState } from 'react';
import { Box, Text } from 'oskcomponents';
import { date_format, getProgramId, time_format } from '~/utils';
import { Detection, Geometry, SigmaAPI, noop } from 'oskcore';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { AssetDetection } from '~/redux/modules/monitor/app';
import { Coordinate } from '~/atoms';

type AlertDetailsSectionProps = {
    /** The id of the selected detection or alert */
    selectedDetectionId?: string;
    /** If selectedAlertId provided, the associated object */
    selectedDetection?: AssetDetection;
    /** The assetId that is selected */
    selectedAssetId?: number;
};

const AlertDetailsSection = ({ selectedDetection }: AlertDetailsSectionProps) => {
    const [detection, setDetection] = useState<Detection | AssetDetection | undefined>(selectedDetection);
    const [longitude, latitude] = selectedDetection
        ? ((selectedDetection.coordinates as Geometry).coordinates as number[])
        : [undefined, undefined];

    // Fetch the full resolution item
    useEffect(() => {
        if (selectedDetection) {
            SigmaAPI.getDetection({
                program: getProgramId(),
                id: selectedDetection.id,
            }).then((resp) => {
                setDetection(resp.data);
            });
        }
    }, [selectedDetection]);

    return (
        <Box p={12} style={{ overflow: 'scroll', paddingTop: '0px', lineHeight: '2rem' }} col>
            <Box style={{ paddingBottom: '4px' }}>
                <Text strong style={{ paddingRight: '8px' }}>
                    Call Type:
                </Text>
                <Text>{detection?.call_type}</Text>
            </Box>
            <Box style={{ paddingBottom: '4px' }}>
                <Text strong style={{ paddingRight: '8px' }}>
                    Sub Call Type:
                </Text>
                <Text>{detection?.detection_type}</Text>
            </Box>
            <Box style={{ paddingBottom: '4px' }}>
                <Text strong style={{ paddingRight: '8px' }}>
                    Location:
                </Text>
                {longitude && latitude && <Coordinate longitude={longitude} latitude={latitude} />}
            </Box>
            <Box style={{ paddingBottom: '4px' }}>
                <Text strong style={{ paddingRight: '8px' }}>
                    Detected At:
                </Text>
                <Text>
                    {date_format(detection?.detected_at)} {time_format(detection?.detected_at)}
                </Text>
            </Box>
            <Box style={{ paddingTop: '20px' }} col>
                {detection?.artifacts &&
                    Object.values(detection?.artifacts).map((artifact, idx) => (
                        <img
                            key={`artifact-${idx}`}
                            src={artifact}
                            style={{ height: 'auto', width: '100%', cursor: 'pointer' }}
                            onClick={() => window.open(artifact)}
                        />
                    ))}
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState, props: Partial<AlertDetailsSectionProps>) => {
    const { selectedAssetId, selectedDetectionId } = props;

    const alerts = state.monitor.app.alertsByAsset[selectedAssetId ?? -1] ?? [];
    const detections = state.monitor.app.detectionsByAsset[selectedAssetId ?? -1] ?? [];
    const selectedDetection =
        alerts && detections && [...alerts, ...detections].find((detection) => detection.id === selectedDetectionId);

    return {
        fetchingAsset: state.monitor.app.fetchingAsset,
        fetchingDetections: state.monitor.app.fetchingAllAlerts,
        selectedDetection: selectedDetection,
    };
};

export { AlertDetailsSection };
export default connect(mapStateToProps, noop)(AlertDetailsSection);
