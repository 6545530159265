import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from 'oskcomponents';

export type PictureIconProps = {
    /** Picture url */
    url: string;
    /** Alt-text */
    title: string;
    /** Class name for styling */
    className?: string;
    /** This method will be called once the image successfully loads */
    onImageLoaded?: () => void;
    /** This method will be called if there's a problem with the image */
    onImageError?: () => void;
    /** The size of the icon in pixels */
    iconSize?: number;
} & Omit<BoxProps, 'ref'>;

export const PictureIcon = styled(({ onImageLoaded, onImageError, title, url, className, iconSize, ...props }) => {
    useEffect(() => {
        const img = new Image();
        img.onload = onImageLoaded;
        img.onerror = onImageError;
        img.src = url;
    }, [onImageLoaded]);

    return <Box title={title} className={className} {...props}></Box>;
})`
    & {
        padding: 18px;
        border-radius: ${(props: any) => props.iconSize ?? 20}px;
        width: ${(props: any) => props.iconSize ?? 20}px;
        height: ${(props: any) => props.iconSize ?? 20}px;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        color: ${(props: any) => props.theme.colors.fg};
        background-color: ${(props: any) => props.theme.colors.bg};
        background-image: url(${(props: any) => props.url});
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        text-decoration: none !important;
    }

    &:hover {
        background-color: ${(props: any) => props.theme.colors.accent};
    }
`;
