/**
 * This file contains all the custom hooks used to interact with the map.
 * NOTE: Any functionality which modifies map state should be implemented here.
 */
import { LatLng, Layer } from 'leaflet';
import { getBounds, OSKGeoJson } from 'oskcore';
import React, { useCallback, useContext, useEffect } from 'react';
import { EditableMapContext, EditableMapContextType } from '~/organisms/map/EditableMap';
import { clickedInside } from '~/utils';
import { LeafletDrawUtil } from '../leafletDrawUtil';

// This is an enum of event types that can be explicitly enabled/disabled through the Map Interface
export type MapEventType = 'Zoom' | 'Drag' | 'Click';

// This is effectively a closure which exists within this file scope exclusively. It
// controls which map features are enabled/disabled across the board. If any requestee wants
// to disable a feature, it must provide a distinct lock object as well as the requested
// status. All requests will be processed holistically, and a final decision will be made
// on whether to enable or disable said feature.
const featureStatusMap: Record<MapEventType, Record<any, boolean>> = { Zoom: {}, Drag: {}, Click: {} };

export class MapAPI {
    editableMap: EditableMapContextType;
    drawUtil?: LeafletDrawUtil;

    constructor(editableMap: EditableMapContextType) {
        this.editableMap = editableMap;
        this.drawUtil = editableMap.drawingUtils;
    }

    _hasMap() {
        return this.editableMap && this.editableMap.map;
    }

    fitCoordinates(geoJson: Array<OSKGeoJson>, padding?: number, animate = true) {
        if (this._hasMap() && geoJson !== undefined) {
            // Compute the extent boundary of the coordinates
            const boundary = getBounds(geoJson);

            // Add some padding
            if (boundary && boundary.length > 0 && boundary[0].length > 0) {
                const calculatedPadding = padding ?? 0.005;
                boundary[0][0] -= calculatedPadding;
                boundary[0][1] -= calculatedPadding;
                boundary[1][0] += calculatedPadding;
                boundary[1][1] += calculatedPadding;

                // Fit the map to the final boundary
                this?.editableMap?.map?.fitBounds(boundary, { animate });
            }
        }
    }

    flyTo(centroid: LatLng, zoom?: number) {
        if (this._hasMap()) {
            this?.editableMap?.map?.flyTo(centroid, zoom);
        }
    }

    panTo(centroid: LatLng) {
        if (this._hasMap()) {
            this?.editableMap?.map?.panTo(centroid);
        }
    }

    getMapBounds() {
        if (this._hasMap()) {
            return this?.editableMap?.map?.getBounds();
        }
    }

    invalidate() {
        if (this._hasMap()) {
            this?.editableMap?.map?.invalidateSize(true);
        }
    }

    fitWorld() {
        if (this._hasMap()) {
            this?.editableMap?.map?.fitWorld();
        }
    }

    startPolygon() {
        this.drawUtil && this.drawUtil.startPolgyon();
    }

    startSquare() {
        this.drawUtil && this.drawUtil.startSquare();
    }

    startMarker() {
        this.drawUtil && this.drawUtil.startPoint();
    }

    startMeasure() {
        this.drawUtil && this.drawUtil.startMeasure();
    }

    clearAll(silent = false) {
        this.drawUtil && this.drawUtil.clear(silent);
    }

    registerLeafletEvent(evt: string, fn: Function) {
        this?.editableMap?.map?.on(evt, fn);
    }

    unregisterLeafletEvent(evt: string, fn: Function) {
        this?.editableMap?.map?.off(evt, fn);
    }

    /**
     * This method is used to process requests to feature enable/disable status. Some
     * code may want to disable a feature, or enable a feature. But we can't just process
     * that on a first-come first-serve basis. Instead, we collect all the active requests
     * for enaled/disabled status and then process them holistically.
     *
     * If one component is like "hey the mouse isn't near me, we can enable zoom again"
     * but another component is like "hey the mouse is inside my div, let's disable zoom"
     * then this method will recognize that /someone/ wants zoom disabled, and so it'll remain
     * disabled.
     *
     * @param feature The map feature to consider enabling or disabling
     * @param key Effectively a lock object which is associated with the request
     * @param enabled Whether the feature should be enabled or disabled
     */
    requestUpdateToFeatureEnabled(feature: MapEventType, key: any, enabled: boolean) {
        featureStatusMap[feature][key] = enabled;

        let zoomEnabled = true;
        let dragEnabled = true;
        let clickEnabled = true;

        for (const subKey in featureStatusMap['Zoom']) {
            if (featureStatusMap['Zoom'][subKey] === false) {
                zoomEnabled = false;
            }
        }

        for (const subKey in featureStatusMap['Drag']) {
            if (featureStatusMap['Drag'][subKey] === false) {
                dragEnabled = false;
            }
        }

        for (const subKey in featureStatusMap['Click']) {
            if (featureStatusMap['Click'][subKey] === false) {
                clickEnabled = false;
            }
        }

        if (zoomEnabled) {
            this.setScrollWheelZoomEnabled(true);
        } else {
            this.setScrollWheelZoomEnabled(false);
        }

        if (dragEnabled) {
            this.setDragEnabled(true);
        } else {
            this.setDragEnabled(false);
        }

        if (clickEnabled) {
            this.setClickEnabled(true);
        } else {
            this.setClickEnabled(false);
        }
    }

    getRaw() {
        if (this._hasMap()) {
            return this.editableMap?.map;
        } else {
            return null;
        }
    }

    setDragEnabled(enabled: boolean) {
        if (this._hasMap()) {
            if (enabled) {
                this?.editableMap?.map?.dragging?.enable();
            } else {
                this?.editableMap?.map?.dragging?.disable();
            }
        }
    }

    setScrollWheelZoomEnabled(enabled: boolean) {
        if (this._hasMap()) {
            if (enabled) {
                this?.editableMap?.map?.scrollWheelZoom?.enable();
            } else {
                this?.editableMap?.map?.scrollWheelZoom?.disable();
            }
        }
    }

    setClickEnabled(enabled: boolean) {
        if (this._hasMap() && 'options' in this.editableMap.map) {
            this.editableMap.map.options.canClick = enabled;
        }
    }

    getAllPoints(): Array<L.LatLng> {
        const points: Array<L.LatLng> = [];
        this?.editableMap?.map?.editTools?.featuresLayer.eachLayer((layer: any) => {
            if (layer._latlng) {
                points.push(layer._latlng as L.LatLng);
            } else if (layer._latlngs) {
                layer?._latlngs.forEach((latLngPoints: any) => {
                    for (const point of latLngPoints) {
                        points.push(point as L.LatLng);
                    }
                });
            } else {
            }
        });
        return points;
    }

    removePoint(point: L.LatLng) {
        this?.editableMap?.map?.editTools?.featuresLayer.eachLayer((layer: any) => {
            for (const layerId in layer.editor.editLayer._layers) {
                const currentLayer = layer.editor.editLayer._layers[layerId];
                if (currentLayer.latlng === point) {
                    currentLayer.delete();
                }
            }
        });
    }

    addPoint(point: L.LatLng) {
        const layer = this?.editableMap?.map?.editTools?.featuresLayer?.getLayers()[0];
        layer.editor.setDrawnLatLngs(layer.editor.getDefaultLatLngs());
        layer.editor.push(point);
    }

    addLayer(layer: L.Layer) {
        this?.editableMap?.map?.editTools?.featuresLayer?.addLayer(layer);
    }

    removeLayer(layer: L.Layer) {
        this?.editableMap?.map?.removeLayer(layer);
    }

    toGeoJSON(): OSKGeoJson {
        if (this._hasMap() && this.drawUtil) {
            return this.drawUtil.toGeoJSON();
        } else {
            return new OSKGeoJson();
        }
    }

    eachLayer(fn: (layer: Layer) => void) {
        if (this._hasMap()) {
            this?.editableMap?.map?.eachLayer(fn);
        }
    }

    getZoom(): number {
        return this?.editableMap?.map?.getZoom();
    }

    getCenter() {
        if (this._hasMap()) {
            return this?.editableMap?.map?.getCenter();
        }
        return undefined;
    }
}

/**
 * This method will return a reference to q MapAPI which interacts with
 * the closest instance of EditableMap (as traversed by the DOM)
 */
export const useMap = (): MapAPI => {
    const editableMap = useContext(EditableMapContext);
    return new MapAPI(editableMap);
};

/**
 * This method wil disable a map feature when the mouse hovers over a particular component
 * or any children components of that ref. For this method to work, the aforementioned ref
 * must have an id attribute which is unique across the app.
 *
 * Note: This is a bit costly, so the visible parameter is intended to
 * reduce the amount of times this method is bound.
 *
 * @param ref The container with which to monitor mouse interactions against
 * @param visible Whether the container is visible or not. Use this to reduce mouse event bindings
 */
export const useDisableFeatureOnMouseOver = (
    ref: React.MutableRefObject<any>,
    feature: MapEventType,
    visible: boolean,
) => {
    const map = useMap();
    const handleMove = useCallback(
        (evt: MouseEvent) => {
            const isDragging: boolean = evt.buttons > 0;

            if (ref && ref.current) {
                // If there is no id, let's throw an error. Without this, the functionality would
                // be non-deterministic.
                if (ref.current.id === undefined || ref.current.id === null || ref.current.id === '') {
                    console.error(
                        '[useDisableFeatureOnMouseOver] container ref used does not have valid id',
                        ref.current,
                    );
                    throw '[useDisableFeatureOnMouseOver] container ref used does not have valid id';
                }

                if (!isDragging && clickedInside(evt.target as HTMLElement, ref.current)) {
                    map.requestUpdateToFeatureEnabled(feature, ref.current.id, false);
                } else {
                    map.requestUpdateToFeatureEnabled(feature, ref.current.id, true);
                }
            }
        },
        [ref, feature, map],
    );

    useEffect(() => {
        if (ref && ref.current && visible) {
            window.addEventListener('mousemove', handleMove);
        }

        return () => {
            window.removeEventListener('mousemove', handleMove);

            if (ref && ref.current) {
                // Re-enable when this hook goes away
                map.requestUpdateToFeatureEnabled(feature, ref.current.id, true);
            }
        };
    }, [ref, handleMove, visible]);
};
