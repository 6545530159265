import React, { useEffect, useState } from 'react';
import { FindingFilter } from '../findingUtils';
import { DetectionFeedbackStatusEnum, SigmaAPI, capitalize, noop } from 'oskcore';
import { getProgramId } from '~/utils';
import { Box, Heading, OSKIcon, OSKThemeType, Pagination, Spinner, Text } from 'oskcomponents';
import { FindingCard } from '~/molecules/FindingCard';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { useTheme } from 'styled-components';
import { DetectionStatusModal } from '../DetectionStatusModal';
import { AssetDetection } from '~/redux/modules/monitor/app';

const PAGE_SIZE = 25;
export type ResultsProps = {
    /** A map of assets. The key is assetId the value is assetName */
    assetNameMap: Record<number, string>;
    /** The filter object representing currently selected filters */
    filter: FindingFilter;
    /** The top-level time range start date */
    filterStartDate?: Date;
    /** The top-level time range end date */
    filterEndDate?: Date;
    /** A method to toggle upstream visibility of filter panel */
    setFilterPanelVisibility: (visible: boolean) => void;
};

export const Results = ({
    assetNameMap,
    filter,
    filterStartDate,
    filterEndDate,
    setFilterPanelVisibility,
}: ResultsProps) => {
    const theme = useTheme() as OSKThemeType;

    const [filtersVisible, setFiltersVisible] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [pageTotal, setPageTotal] = useState(0);
    const [detections, setDetections] = useState<AssetDetection[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedDetectionForModal, setSelectedDetectionForModal] = useState<AssetDetection>();

    useEffect(() => {
        setCurrentPage(0);
    }, [filter]);

    useEffect(() => {
        setFetching(true);
        requestAnimationFrame(() => {
            SigmaAPI.listDetections({
                program: getProgramId(),
                limit: PAGE_SIZE,
                offset: currentPage * PAGE_SIZE,
                detectedAfter: filterStartDate?.toISOString(),
                detectedBefore: filterEndDate?.toISOString(),
                asset: filter.assets,
                detectionType: filter.detections,
                // @ts-ignore
                detectionClass: filter.alertsOnly ? 'alert' : undefined,
                feedbackStatus: filter.detectionStatuses,
            })
                .then((resp) => {
                    setPageTotal(resp.data.count ?? 0);
                    setDetections((resp.data.results as AssetDetection[]) ?? []);
                })
                .finally(() => {
                    setFetching(false);
                });
        });
    }, [filter, currentPage, filterStartDate, filterEndDate]);

    return (
        <Box style={{ overflow: 'hidden' }} grow col center="all">
            <DetectionStatusModal
                detection={selectedDetectionForModal}
                assetName={selectedDetectionForModal ? assetNameMap[selectedDetectionForModal.asset] : ''}
                visible={selectedDetectionForModal !== undefined}
                onClose={() => setSelectedDetectionForModal(undefined)}
                onSave={(newDetection: AssetDetection) => {
                    const updatedDetections = detections.map((detection) => {
                        if (detection.id === newDetection.id) {
                            return newDetection;
                        } else {
                            return detection;
                        }
                    });

                    setDetections(updatedDetections);
                }}
            />
            <Box center="vertical" style={{ paddingTop: '5px', paddingBottom: '5px', alignSelf: 'flex-start' }}>
                <Text style={{ paddingLeft: '14px', fontWeight: 'bold' }} variant="large">
                    <span style={{ paddingRight: '4px' }}>{fetching ? 'Loading...' : pageTotal}</span>
                    {fetching ? '' : (pageTotal ?? 0) === 1 ? 'Finding' : 'Findings'}
                </Text>
                <Text color={theme.colors.accent} style={{ paddingLeft: '14px' }} underline={true}>
                    <Box
                        center="vertical"
                        style={{ maxWidth: 'fit-content' }}
                        onClick={() => {
                            setFilterPanelVisibility(!filtersVisible);
                            setFiltersVisible(!filtersVisible);
                        }}
                    >
                        {filtersVisible ? <>Hide Filters</> : <>Show Filters</>}
                        <OSKIcon pl={4} code="filter" />
                    </Box>
                </Text>
            </Box>
            {fetching ? (
                <Box grow col center="all">
                    <Spinner variant="Box" size="Large" />
                </Box>
            ) : (
                <Box style={{ flexWrap: 'wrap', overflow: 'auto' }}>
                    {detections.length === 0 && (
                        <Box pt={100}>
                            <Heading variant="small" style={{ fontWeight: 'normal' }}>
                                There are no detections for this critera.
                            </Heading>
                        </Box>
                    )}
                    {detections.map((alert, idx) => {
                        const { id, artifacts, call_type, feedback_status, feedback_text } = alert;

                        return (
                            <Box
                                key={`tile-${idx}`}
                                style={{
                                    margin: '8px',
                                    flexShrink: 1,
                                }}
                            >
                                <FindingCard
                                    id={id}
                                    isAlert={alert.detection_class === 'alert'}
                                    assetId={alert.asset}
                                    assetName={assetNameMap[alert.asset]}
                                    callType={call_type}
                                    coordinates={alert.coordinates}
                                    detectionType={alert.detection_type ? alert.detection_type : 'Unknown'}
                                    reviewStatus={feedback_status ?? DetectionFeedbackStatusEnum.Unreviewed}
                                    reviewText={feedback_text}
                                    onReviewStatusClick={() => {
                                        setSelectedDetectionForModal(alert);
                                    }}
                                    images={Object.entries(artifacts)
                                        .filter(
                                            ([type, uri]) =>
                                                uri.includes('.png') || uri.includes('.jpeg') || uri.includes('.jpg'),
                                        )
                                        .map(([type, uri]) => ({
                                            uri,
                                            subTitle: capitalize(type),
                                        }))}
                                />
                            </Box>
                        );
                    })}
                </Box>
            )}
            <Box grow />
            {detections.length > 0 && (
                <Pagination
                    onChange={(page) => {
                        setCurrentPage(page);
                    }}
                    offset={currentPage * PAGE_SIZE}
                    count={pageTotal}
                    pageSize={PAGE_SIZE}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        filterStartDate: state.monitor.app.filterStartDate,
        filterEndDate: state.monitor.app.filterEndDate,
        assetNameMap: Object.values(state.monitor.app.assets).reduce((acc, cur) => {
            acc[cur.id] = cur.name;
            return acc;
        }, {} as Record<number, string>),
    };
};

export default connect(mapStateToProps, noop)(Results);
