import { OSKIcon, OSKIconType } from 'oskcomponents';
import React from 'react';

const DetectionTypeMap: Record<string, OSKIconType> = {
    Methane: 'methane',
    'Liquid Hydrocarbon': 'hydrocarbon',
    Vehicle: 'vehicle',
};

export type DetectionTypeIconProps = {
    detectionType: string | undefined;
};

export const DetectionTypeIcon = ({ detectionType }: DetectionTypeIconProps) => {
    if (detectionType && DetectionTypeMap[detectionType]) {
        return <OSKIcon w={15} code={DetectionTypeMap[detectionType]} />;
    } else {
        return <OSKIcon w={15} code={'triangle'} />;
    }
};
