import React, { useState } from 'react';
import { unstable_useBlocker as useBlocker, useNavigate } from 'react-router-dom';
import { Box, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'oskcomponents';

type PromptWhenLeavingProps = {
    /** A method to invoke when the user selects 'cancel' from the modal. */
    onCancel?: () => void;
    /** A method to invoke when the user selects 'proceed' from the modal */
    onProceed?: () => void;
    /** A method used to determine whether or not we should block navigating away from this page. */
    shouldBlock?: () => boolean;
    /** The modal title */
    title: string;
    /** The modal body contents */
    body: React.ReactNode;
    /** The cancel button label */
    cancelLabel: string;
    /** The proceed button label */
    proceedLabel: string;
};

const PromptWhenLeaving = ({
    onCancel,
    onProceed,
    shouldBlock,
    title,
    body,
    cancelLabel,
    proceedLabel,
}: PromptWhenLeavingProps) => {
    const navigate = useNavigate();
    const [navTo, setNavTo] = useState('');
    const [showModal, setShowModal] = useState(false);

    const currentPath = `/${window.location.pathname?.split('/')[1]}`;

    const bypassBlockerAndProceed = () => {
        setShowModal(false);
        navigate(navTo, { state: { bypassBlocker: true } });
    };

    useBlocker((e) => {
        const {
            nextLocation,
            nextLocation: { state: bypassBlocker },
        } = e;

        const shouldBlockResult = shouldBlock ? shouldBlock() : true;

        if (bypassBlocker || !shouldBlockResult) return false; // Don't block

        // If we're navigating away
        if (!nextLocation.pathname.startsWith(currentPath)) {
            setNavTo(nextLocation.pathname);
            setShowModal(true);
            return true; // Block
        }

        return false; // Don't block
    });

    return (
        <Modal visible={showModal && navTo != ''}>
            <ModalHeader variant="primary">{title}</ModalHeader>
            <ModalBody>
                <Box col>{body}</Box>
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="primary"
                    label={cancelLabel}
                    onClick={() => {
                        setShowModal(false);
                        onCancel && onCancel();
                    }}
                />
                <Button
                    variant="action"
                    label={proceedLabel}
                    onClick={() => {
                        onProceed && onProceed();
                        bypassBlockerAndProceed();
                    }}
                />
            </ModalFooter>
        </Modal>
    );
};

export default PromptWhenLeaving;
