import React from 'react';
import { RootState } from '../../../redux/store';
import { noop, OSKGeoJson } from 'oskcore';
import { connect } from 'react-redux';
import { MapCentroid } from '~/atoms';
import { AoiType } from '~/redux/modules/data/search';

type OSKHomeViewMapZoomerProps = {
    /** From redux, an optionally-set region of interest on the map */
    roi?: OSKGeoJson;

    /** From redux, whether the AOI was set manually by the user, or automatically by the software */
    roiType?: AoiType;
};

const OSKHomeViewMapZoomer = ({ roi, roiType }: OSKHomeViewMapZoomerProps) => {
    if (roiType !== 'auto') return <></>;
    return <MapCentroid animated={false} area={roi} padding={0.1} />;
};

const mapStateToProps = (state: RootState) => {
    const { roi, roiType } = state.data.search;

    return {
        roi,
        roiType,
    };
};

export default connect(mapStateToProps, noop)(OSKHomeViewMapZoomer);
