import { divIcon } from 'leaflet';
import { Box, OSKThemeType } from 'oskcomponents';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker } from 'react-leaflet';
import { useTheme } from 'styled-components';
import { getLatLngObj } from 'tle.js';

export type MapSatellitePositionProps = {
    /** The TLE of a satellite to render to the map */
    tle: string;
    /** The timestamp from which to seed the position */
    timestamp: Date;
    /** Whether or not this satellite is selected */
    selected?: boolean;
    /** An optional label to render next to the marker */
    label?: string;
    /** A method to call when this satellite is clicked */
    onClick?: (e: any) => void;
};

export const MapSatellitePosition = ({ tle, timestamp, selected, label, onClick }: MapSatellitePositionProps) => {
    const position = getLatLngObj(tle, timestamp.getTime());
    const theme = useTheme() as OSKThemeType;

    const iconMarkup = renderToStaticMarkup(
        <>
            <Box
                onClick={(e) => {
                    onClick && onClick(e);
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    transform="scale(5) translate(0, -1.41421)"
                    width="36"
                    height="39"
                    viewBox="0 0 36 39"
                    fill="none"
                >
                    <g filter="url(#filter0_d_69_781)">
                        <rect
                            width="25"
                            height="25"
                            rx="12.5"
                            transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 35.3555 17.6776)"
                            fill={selected ? theme.colors.accent : '#C4C4C4'}
                        />
                        <rect
                            x="-1.41421"
                            width="23"
                            height="23"
                            rx="11.5"
                            transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 32.9413 16.6776)"
                            stroke="white"
                            strokeWidth="2"
                        />
                    </g>
                    <rect
                        xmlns="http://www.w3.org/2000/svg"
                        x="-5.96046e-08"
                        y="1.41421"
                        width="10.931"
                        height="10.931"
                        rx="3"
                        transform="matrix(-0.707107 0.707107 0.707107 0.707107 16.6777 8.89944)"
                        fill={selected ? theme.colors.accent : '#626264'}
                        stroke="white"
                        strokeWidth="2"
                    />
                    <defs>
                        <filter
                            id="filter0_d_69_781"
                            x="1.17773"
                            y="5.17761"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_69_781" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_69_781" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </Box>
            {label && (
                <Box
                    style={{
                        position: 'absolute',
                        left: '35px',
                        top: '-10px',
                        width: '85px',
                        border: `1px solid ${theme.colors.white}`,
                        backgroundColor: theme.colors.gray1a,
                        padding: '8px',
                        borderRadius: '7px',
                    }}
                >
                    {label}
                </Box>
            )}
        </>,
    );

    const eventHandlers = {
        mousedown: (e: any) => {
            onClick && onClick(e);
        },
    };

    const customMarkerIcon: any = divIcon({ className: '', html: iconMarkup });
    return <Marker eventHandlers={eventHandlers} icon={customMarkerIcon} position={position} />;
};
