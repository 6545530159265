import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { Box, OSKIcon, Text } from 'oskcomponents';
import { OrderStatusMap, selectOrderStatus } from '~/redux/modules/data/orders';
import { noop } from 'oskcore';

type ActiveOrderStatusIndicatorProps = {
    orderStatus?: string;
};

const ActiveOrderStatusIndicator = ({ orderStatus }: ActiveOrderStatusIndicatorProps) => {
    const status = orderStatus ? orderStatus : 'pending';
    const statusFormat = OrderStatusMap[status] ?? {
        color: 'black',
        icon: 'ellipsis',
        fg: 'white',
        name: 'Unknown',
    };

    if (status === 'none') {
        return <></>;
    }

    return (
        <Box center="vertical" ml={18}>
            <Box
                style={{
                    borderRadius: '50px',
                    backgroundColor: statusFormat.bg,
                    width: '16px',
                    aspectRatio: '1',
                    marginRight: '4px',
                }}
                center="all"
            >
                <OSKIcon
                    code={statusFormat.icon as any}
                    mr={4}
                    fill="white"
                    scale={80}
                    style={{ position: 'relative', left: '2px' }}
                />
            </Box>
            <Text strong>{statusFormat.name}</Text>
        </Box>
    );
};

const mapStateToProps = (state: RootState): Partial<ActiveOrderStatusIndicatorProps> => {
    return {
        orderStatus: selectOrderStatus(state.data.orders.activeOrder?.orderStatus as any),
    };
};

export default connect(mapStateToProps, noop)(ActiveOrderStatusIndicator);
