import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, TextInput, Text } from 'oskcomponents';
import { FormElement } from '~/atoms';
import * as yup from 'yup';
import { AssetDetailWithEmails } from '~/views/ProgramView/AssetPanel';
import { NotificationEmailEntry, NotificationEmailTable } from '~/organisms/tables/NotificationEmailTable';
import { date_only } from '~/utils';

export type AssetFormProps = {
    data?: AssetDetailWithEmails;
    className?: string;
    /** Readonly override */
    readonly?: boolean;
    /** True if the user is a program admin */
    isAdmin?: boolean;
};

export const AssetSchema: yup.SchemaOf<any> = yup.object().shape({
    name: yup.string().required('Asset Title is a required field'),
    startDate: yup.date().required(),
    endDate: yup.date().min(yup.ref('startDate'), "End date can't be before the start date.").required(),
});

export const AssetForm = styled(({ className, isAdmin, data, readonly }: AssetFormProps) => {
    const [txtRecipient, setTxtRecipient] = useState<string>();
    const [emailList, setEmailList] = useState<NotificationEmailEntry[]>([]);

    // Populate the emailList
    useEffect(() => {
        const emails: Record<string, NotificationEmailEntry> = {};

        for (const email of data?.report_emails ?? []) {
            emails[email] = emails[email] ?? { email };
            emails[email].can_receive_reports = true;
        }
        setEmailList(Object.values(emails));
    }, [data]);

    return (
        <Box style={{ justifyContent: 'center', width: '100%' }} grow col>
            <Box className={className} col>
                <FormElement readonly type="input" name="assetId" label="Asset ID" defaultValue={data?.id} />

                <FormElement
                    readonly={readonly}
                    type="input"
                    name="name"
                    label="Asset Title"
                    defaultValue={data?.name}
                />

                <FormElement
                    readonly={readonly}
                    type="date"
                    name="startDate"
                    label="Monitoring Start Date"
                    defaultValue={date_only(data?.monitoring_start_date)?.toISOString()}
                />

                <FormElement
                    readonly={readonly}
                    type="date"
                    name="endDate"
                    label="Monitoring End Date"
                    defaultValue={date_only(data?.monitoring_end_date)?.toISOString()}
                />

                <FormElement
                    readonly={readonly}
                    type="dropdown"
                    name="frequency"
                    label="Monitoring Frequency"
                    options={[
                        {
                            label: 'Quarterly',
                            value: 'quarterly',
                        },
                        {
                            label: 'Monthly',
                            value: 'monthly',
                        },
                        {
                            label: 'Annually',
                            value: 'annually',
                        },
                    ]}
                    defaultValue={data?.monitoring_frequency}
                />
            </Box>

            <Box style={{ paddingBottom: '14px' }} col>
                <Text style={{ marginBottom: '10px' }}>Notification Recipients</Text>
                {isAdmin && (
                    <Box style={{ marginBottom: '10px' }}>
                        <TextInput
                            data-testid="notification-recipient-email"
                            placeholder="Recipient email"
                            onChange={(evt) => {
                                setTxtRecipient(evt.target.value);
                            }}
                            value={txtRecipient}
                            name="notification_recipient"
                            style={{ width: '100%' }}
                        />
                        <Button
                            data-testid="submit-notification-recipient-email"
                            onClick={() => {
                                if (txtRecipient) {
                                    setEmailList([...emailList, { email: txtRecipient, can_receive_reports: false }]);
                                    setTxtRecipient('');
                                }
                            }}
                            label="Add"
                            style={{ marginLeft: '10px' }}
                        />
                    </Box>
                )}

                <NotificationEmailTable
                    onChange={(row) => {
                        // Compute values
                        const item = emailList.find((x) => x.email === row.email);
                        if (item) {
                            item.can_receive_reports = row.can_receive_reports;
                        }

                        setEmailList([...emailList]);
                    }}
                    onDelete={(email) => {
                        const nextList = [...emailList];
                        nextList.splice(
                            nextList.findIndex((record) => record.email === email),
                            1,
                        );
                        setEmailList(nextList);
                    }}
                    data={emailList}
                />

                <input
                    type="hidden"
                    name="report_emails"
                    value={emailList
                        .filter((x) => x.can_receive_reports)
                        .map((item) => item.email)
                        .join(',')}
                />
            </Box>
        </Box>
    );
})`
    & {
        width: 100%;
    }
`;
