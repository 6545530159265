import React from 'react';
import { Box, Typography } from 'oskcomponents';
import { LatLng } from 'leaflet';
import styled from 'styled-components';

type ContextMenuOptionProps = {
    /** React key passthrough */
    key?: string;
    /** styled-component passthrough */
    className?: string;
    /** children passthrough */
    children?: React.ReactNode | Array<React.ReactNode>;
    /** A method to be fired when the option is clicked */
    onClick?: (e: any) => void;
    /** The gps coordinate of the map where the context menu has been opened */
    latlng?: LatLng;
};

const ContextMenuOption = styled(({ children, onClick, ...props }: ContextMenuOptionProps) => {
    return (
        <Box
            {...props}
            center="all"
            onClick={(e) => {
                onClick && onClick(e);
            }}
        >
            <Typography variant="caption1" strong>
                <Box row center="all">
                    {children}
                </Box>
            </Typography>
        </Box>
    );
})`
    & {
        background-color: white;
        color: black;
        padding: 8px 12px;
        margin: 4px;
        border-radius: 5px;
    }
    &: hover {
        background-color: ${(props: any) => props.theme.colors.lightGray};
    }
`;

export { ContextMenuOption };
export type { ContextMenuOptionProps };
