import React, { useRef } from 'react';
import { NavItem, useProfileMetadata } from 'oskcore';
import { Box, OSKThemeType, Overlay, Button, useClickAway, Typography, OSKIcon, BoxProps } from 'oskcomponents';
import styled, { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { NavItemEntry, showNavItem } from '~/redux/modules/data/nav';
import { UserProfileIcon } from '~/molecules';
import { LogoutButton } from 'oskcore';
import { GlobalZIndex } from '~/constants';
import { user_fullname } from '~/utils';
import { useNavigate } from 'react-router-dom';

type SettingsButtonProps = {
    /** The class provided by styled-components */
    className?: string;
} & Omit<Omit<BoxProps, 'ref'>, 'className'>;

const SettingsButton = styled(({ className, ...props }: SettingsButtonProps) => {
    return <Box className={className} {...props} />;
})`
    & {
        padding: 20px;
        width: 100%;
    }
    &:hover {
        background-color: ${(props: any) => props.theme.colors.lightGray};
    }

    &: hover path {
        fill: ${(props) => props.theme.colors.accent};
    }
`;

export type UserProfileProps = {
    /** Whether the profile is open or not */
    show: boolean;
    /** Change state of the profile popup */
    setShow: (show: boolean) => void;
    /** Method to invoke when the profile popup has triggered a navigation event */
    onNavigate?: () => void;
};

export const UserProfile = ({ show, setShow, onNavigate }: UserProfileProps) => {
    const ref = useRef(null);
    const [profile] = useProfileMetadata();
    const theme = useTheme() as OSKThemeType;
    const navigate = useNavigate();

    useClickAway(ref, () => setShow(false), show);

    return (
        <Box ref={ref} style={{ zIndex: GlobalZIndex.HeaderPopup }}>
            <NavItem>
                <Box bg={show ? theme.colors.gray80 : ''} p={8} style={{ borderRadius: '7px' }}>
                    <UserProfileIcon onClick={() => setShow(!show)} />
                </Box>
                <Box style={{ position: 'absolute', top: '55px', right: 0 }}>
                    <Overlay
                        p={10}
                        show={show}
                        style={{
                            width: '250px',
                            height: '350px',
                            right: '0',
                            overflow: 'hidden',
                        }}
                        col
                    >
                        <Box
                            style={{
                                border: `1px solid ${theme.colors.black200}`,
                                overflow: 'hidden',
                                borderRadius: '10px',
                                backgroundColor: theme.colors.white,
                                color: theme.colors.white,
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                            }}
                            bg={theme.colors.gray2ab}
                            fg={theme.colors.black}
                            grow
                            col
                        >
                            <Box grow col center="horizontal" style={{ paddingTop: '10px' }}>
                                <Box col center="all" style={{ height: '100%' }}>
                                    <Box>
                                        <UserProfileIcon iconSize={50} />
                                    </Box>

                                    <Typography
                                        variant="body2"
                                        style={{ color: theme.colors.black, fontWeight: 'bold' }}
                                    >
                                        {user_fullname(profile)}
                                    </Typography>
                                    <Typography variant="body3" style={{ color: theme.colors.black600 }}>
                                        {profile.email}
                                    </Typography>
                                </Box>

                                <Box
                                    style={{
                                        borderTop: `1px solid ${theme.colors.black200}`,
                                        borderBottom: `1px solid ${theme.colors.black200}`,
                                        width: '100%',
                                    }}
                                    center="vertical"
                                >
                                    <SettingsButton
                                        fg={theme.colors.black}
                                        pl={20}
                                        onClick={() => {
                                            navigate('/settings/profile');
                                            onNavigate && onNavigate();
                                        }}
                                    >
                                        <OSKIcon code={'gear-outline'} pr={5} /> Settings
                                    </SettingsButton>
                                </Box>

                                <Box center={'all'} style={{ height: '50%' }}>
                                    <LogoutButton>
                                        <Button variant="action">Sign Out</Button>
                                    </LogoutButton>
                                </Box>
                            </Box>
                        </Box>
                    </Overlay>
                </Box>
            </NavItem>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        show: state.data.nav.showUserProfile,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setShow: (show: boolean) => {
            dispatch(showNavItem(show ? NavItemEntry.UserProfile : NavItemEntry.None));
        },
        onNavigate: () => {
            // Close all nav items
            dispatch(showNavItem(NavItemEntry.None));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
