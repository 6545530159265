import React from 'react';
import { Box, Text, Table, Spinner, OSKIcon } from 'oskcomponents';
import { ProgramReport } from '~/redux/modules/monitor/reports';
import { date_format } from '~/utils';

export type ProgramReportsTableProps = {
    /** Whether or not we should display the loading spinner */
    isLoading: boolean;
    /** The index of the given data to style as 'selected'  */
    selectedIndex?: number;
    /** The data to render into rows */
    data: Array<ProgramReport>;
    /** An event to call when a row is selected */
    onRowClick?: (report: Record<string, any>) => void;
};

const DateCell = ({ row: { values } }: any) => {
    const date = date_format(values.created_at);
    return date ? date : '--';
};

export const ProgramReportsTable = ({ isLoading, selectedIndex, data, onRowClick }: ProgramReportsTableProps) => {
    const columns = React.useMemo(
        () => [
            { Header: 'Reports', accessor: 'display_name' },
            { Header: 'Date', accessor: 'created_at', Cell: DateCell },
            {
                Header: 'Ready',
                accessor: 'pdf_presigned_uri',
                Cell: ({ value }: any) => {
                    if (value) {
                        return (
                            <Box h={30} center="all" grow>
                                <OSKIcon code="check-circle" />
                            </Box>
                        );
                    } else {
                        return (
                            <Box h={30} center="all">
                                <Spinner size="Small" />
                            </Box>
                        );
                    }
                },
            },
        ],
        [],
    );

    if (isLoading) {
        return (
            <Box grow center="horizontal" p={16}>
                <Spinner />
            </Box>
        );
    } else if (data.length > 0) {
        return <Table selectedIndex={selectedIndex} onRowClick={onRowClick} columns={columns} data={data} />;
    } else {
        return (
            <Box grow center="horizontal" p={16}>
                <Text>No reports found.</Text>
            </Box>
        );
    }
};
