import React from 'react';
import { OSKIcon } from 'oskcomponents';
import toast from 'react-hot-toast';
import { ContextMenuOption, ContextMenuOptionProps } from '../ContextMenuOption';

const CopyCoordinateMenuOption = ({ latlng, onClick, ...props }: ContextMenuOptionProps) => {
    if (!latlng) return <></>;
    const coordsRaw = `${latlng.lat}, ${latlng.lng}`;
    const coordsText = `${latlng.lat.toFixed(3)}, ${latlng.lng.toFixed(3)}`;

    return (
        <ContextMenuOption
            onClick={(e) => {
                navigator.clipboard.writeText(coordsRaw);
                toast.success('Copied coordinates to clipboard!');

                onClick && onClick(e);
            }}
            {...props}
        >
            <OSKIcon code="copy" style={{ marginRight: '8px' }} /> {coordsText}
        </ContextMenuOption>
    );
};

export { CopyCoordinateMenuOption };
