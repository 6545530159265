import React from 'react';
import { Box, OSKIcon, Text, Tooltip } from 'oskcomponents';
import { FindingReviewIcon } from '../FindingReviewIcon';
import { DetectionFeedbackStatusEnum } from 'oskcore';

type FindingDetectionStatusProps = {
    /** The status of the detection to display */
    status: DetectionFeedbackStatusEnum;
    /** Optional tooltip label */
    label?: string;
    /** A method that's called when the detection status is clicked */
    onClick?: () => void;
};

const FindingDetectionStatus = ({ status, label, onClick }: FindingDetectionStatusProps) => {
    return (
        <Text variant="small" pt={5}>
            <Box row center="vertical" h={32} onClick={() => onClick && onClick()}>
                <strong style={{ paddingRight: '8px' }}>
                    <FindingReviewIcon status={status} />
                </strong>
                <Tooltip label={label}>
                    <u>{status}</u>
                </Tooltip>
                <OSKIcon code="edit" style={{ paddingLeft: '8px' }} />
            </Box>
        </Text>
    );
};

export { FindingDetectionStatus };
export type { FindingDetectionStatusProps };
