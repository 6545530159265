import React from 'react';

import { connect } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import AoiTools from '../AoiTools';
import { OSKGeoJson, noop } from 'oskcore';
import { setSelectedAoi } from '~/redux/modules/tasking/app';

type TaskingAoiToolsProps = {
    /** Method that's called when the aoi is changed. */
    onAoiChanged?: (aoi: OSKGeoJson) => void;
};

const TaskingAoiTools = ({ onAoiChanged, ...props }: TaskingAoiToolsProps) => {
    return <AoiTools onAoiChanged={onAoiChanged} {...props} />;
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onAoiChanged: (aoi: OSKGeoJson) => {
            dispatch(setSelectedAoi(aoi));
        },
    };
};

export default connect(noop, mapDispatchToProps)(TaskingAoiTools);
