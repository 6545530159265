/**
 * This method will take a 3-dimensional array of numbers and flatten it into a 1 dimensional array.
 * This is mainly useful for crunching rgb colors and flatting them into a 1d array which is what
 * the GLSL shader requires.
 *
 * @param arr A 3d array of numbers
 * @returns A 1d array of numbers
 */
export function flatten3D(arr: Array<[number, number, number]>): Array<number> {
    return arr.flatMap((x) => x);
}

/** Interpolate from one number to another number at a point in some arbitrary range */
export function interpolate(from: number, to: number, min: number, current: number, max: number): number {
    const x0 = min;
    const y0 = Math.min(from, to);
    const x1 = max;
    const y1 = Math.max(from, to);
    const x = current;
    const delta = x * ((y1 - y0) / (x1 - x0));
    if (from > to) {
        return from - delta;
    } else {
        return from + delta;
    }
}
