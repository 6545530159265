import L, { LatLng, circle } from 'leaflet';
import { OSKGeoJson } from 'oskcore';
import './css/leaflet-drawing-util.css';
import { useTheme } from 'styled-components';
import { OSKThemeType } from 'oskcomponents';

const LINE_COLOR = '#799FF7';
type DrawingMode = 'point' | 'polygon' | 'square' | 'measure' | 'none';

/**
 * Key is zoom-level
 * Value is how many meters per pixel
 */
const PIXEL_SIZE_MAP: Record<number, number> = {
    0: 156543,
    1: 78272,
    2: 39136,
    3: 19568,
    4: 9784,
    5: 4892,
    6: 2446,
    7: 1223,
    8: 611.496,
    9: 305.748,
    10: 152.874,
    11: 76.437,
    12: 38.219,
    13: 19.109,
    14: 9.555,
    15: 4.777,
    16: 2.389,
    17: 1.194,
    18: 0.597,
    19: 0.299,
    20: 0.149,
    21: 0.0745,
};

/**
 * This method will return how many meters there are per pixel. Because of the mercator projection,
 * you have to give some context about the point in question so the correct scale
 * factor can be accounted for.
 *
 * @param p The point you are sampling
 * @param zoom How zoomed out the map is
 * @returns How many meters per pixel.
 */
export function getPixelSize(p: LatLng, zoom: number) {
    const latInRads = Math.PI * (p.lat / 180);
    const secant = 1 / Math.cos(latInRads);
    return PIXEL_SIZE_MAP[zoom] / Math.abs(secant);
}

// Given two points, return the 4 corners of the resulting shape
function getPointsForSquare(p1: L.LatLng, p2: L.LatLng): L.LatLng[] {
    const x = p1.lat,
        y = p1.lng,
        w = p2.lat - p1.lat,
        h = p2.lng - p1.lng;

    const points = [
        [x, y],
        [x + w, y],
        [x + w, y + h],
        [x, y + h],
    ];

    return points.map((p) => new L.LatLng(p[0], p[1]));
}
export class LeafletDrawUtil {
    /** The Leaflet Map instance to draw on */
    map: L.Map;
    /** Boolean to indicate whether we are in drawing mode or not */
    drawing = false;
    /** The points that have been drawn */
    points: L.LatLng[] = [];
    /** A single point */
    point: L.LatLng | undefined;
    /** A reusable tooltip element that is repurposed frequently in the mouse-move method */
    tooltip: L.Tooltip;
    /** The active layer which is being manipulated and ultimately added to the map */
    layer?: L.LayerGroup;
    /** The default body.style.userSelect value */
    defaultUserSelect = 'auto';
    /** MouseMove event */
    onMouseMove: (evt: L.LeafletMouseEvent) => void;
    /** Keydown event */
    onKeyDown: (evt: L.LeafletKeyboardEvent) => void;
    /** Click event */
    onClick: (evt: L.LeafletMouseEvent) => void;
    /** Point click event */
    onPointClick: (evt: L.LeafletMouseEvent) => void;
    /** Mouse down event for square mode */
    onSquareMouseDown: (evt: L.LeafletMouseEvent) => void;
    /** Mouse up event for square mode */
    onSquareMouseUp: () => void;
    /** The mode we are drawing in */
    mode: DrawingMode = 'none';

    constructor(map: L.Map) {
        this.map = map;
        this.tooltip = L.tooltip({ className: 'leaflet-drawing-tooltip', direction: 'right', opacity: 0 });

        if (map) {
            this.tooltip.setLatLng(map.getCenter());
        }

        this.onMouseMove = this.handleMouseMove.bind(this);
        this.onKeyDown = this.handleKeydown.bind(this);
        this.onClick = this.handleClickEvent.bind(this);
        this.onSquareMouseDown = this.handleSquareMouseDownEvent.bind(this);
        this.onSquareMouseUp = this.handleSquareMouseUpEvent.bind(this);
        this.onPointClick = this.handlePointClickEvent.bind(this);
    }

    /**
     * This method will create a blank layer for drawing. If a layer was previously defined, it will
     * be destroyed and removed from the map.
     */
    private createLayer() {
        if (this.layer) {
            this.layer.removeFrom(this.map);
        }

        this.layer = L.layerGroup();
        this.tooltip.setOpacity(0.0);
        this.tooltip.addTo(this.layer);
        this.layer.addTo(this.map);
        this.points = [];
        this.point = undefined;
    }

    /**
     * This method will handle mouse movement and update the tooltip accordingly.
     */
    private handleMouseMove(evt: L.LeafletMouseEvent) {
        // Calculate message and position
        if (this.mode === 'polygon' && this.tooltip && this.points.length === 0 && this.drawing === true) {
            this.tooltip.setContent('Click anywhere to place your first point').setLatLng(evt.latlng);
            this.tooltip.setOpacity(1);
        } else if (this.mode === 'square' && this.tooltip && this.points.length === 0 && this.drawing === true) {
            this.tooltip.setContent('Click and drag anywhere to draw a square').setLatLng(evt.latlng);
            this.tooltip.setOpacity(1);
        } else if (this.mode === 'measure' && this.tooltip && this.points.length === 0 && this.drawing === true) {
            this.tooltip.setContent('Click and drag to measure').setLatLng(evt.latlng);
            this.tooltip.setOpacity(1);
        } else if (
            this.mode === 'polygon' &&
            this.tooltip &&
            this.points.length > 2 &&
            this.drawing === true &&
            this.isMouseWithinClosingRange(evt)
        ) {
            this.tooltip.setContent('Click here to close the shape').setLatLng(this.points[0]);
            this.tooltip.setOpacity(1);
            document.body.style.cursor = 'pointer';
        } else if (this.drawing) {
            this.tooltip.setOpacity(0);
            document.body.style.cursor = 'crosshair';
        } else {
            this.tooltip.setOpacity(0);
        }

        // Update the second point of the square or ruler as the user drags the mouse
        if (['square', 'measure'].includes(this.mode) && this.points.length === 2) {
            this.points[1].lat = evt.latlng.lat;
            this.points[1].lng = evt.latlng.lng;
            this.redraw();
        }
    }

    /**
     * This method checks whether the mouse is near the origin point.
     */
    private isMouseWithinClosingRange(evt: L.LeafletMouseEvent) {
        if (this.points.length < 2) return false;

        const first = this.points[0];
        const mouse = evt.latlng;
        const pixelSize = getPixelSize(evt.latlng, this.map.getZoom());
        const distInGps = Math.sqrt(Math.pow(first.lat - mouse.lat, 2) + Math.pow(first.lng - mouse.lng, 2));

        // Idk what is up with 600 specifically but since this distance is normalized,
        // the math will always be consistent. So that's something.
        const dist = (distInGps * 600) / pixelSize;
        return dist < 0.15;
    }

    /**
     * Method to handle keydown event when the map is focused
     */
    private handleKeydown(evt: L.LeafletKeyboardEvent) {
        if (evt.originalEvent.key === 'Escape' && this.drawing) {
            this.clear();
        }
    }

    /**
     * Method to handle a click event triggered on the map
     */
    private handleClickEvent(evt: L.LeafletMouseEvent) {
        if (this.drawing && this.isMouseWithinClosingRange(evt)) {
            this.closeShape();
        } else if (this.drawing) {
            this.points.push(evt.latlng);
            this.redraw();
        }
    }

    /**
     * Method to handle a click event triggered on the map for the square mode
     */
    private handleSquareMouseDownEvent(evt: L.LeafletMouseEvent) {
        if (this.drawing) {
            // Disable text selection everywhere
            document.body.style.userSelect = 'none';

            // @ts-ignore because we don't need the full object, just the lat/lng bits
            this.points.push({ ...evt.latlng });
            // @ts-ignore because we don't need the full object, just the lat/lng bits
            this.points.push({ ...evt.latlng });
        }
    }

    private handleSquareMouseUpEvent() {
        this.closeShape();
    }

    private handlePointClickEvent(evt: L.LeafletMouseEvent) {
        if (this.drawing) {
            this.point = evt.latlng;
        }
        this.closeShape();
    }

    /**
     * Method to close the shape if it is in-progress.
     */
    private closeShape() {
        if (this.layer) {
            this.layer.clearLayers();
        }

        this.exitDrawing();
    }

    /**
     * Method to clear the primary layer and redraw the polygon (and circle endcaps)
     */
    private redraw() {
        if (this.layer) {
            this.layer.clearLayers();

            if (this.mode === 'square' && this.points.length === 2) {
                const points = getPointsForSquare(this.points[0], this.points[1]);
                this.layer.addLayer(L.polygon(points, { color: LINE_COLOR, weight: 4 }));
            } else if (this.mode === 'measure' && this.points.length === 2) {
                const circleOptions: L.CircleMarkerOptions = {radius: 10, fillColor: 'white', fillOpacity: 1, interactive: false, color: '#F96621'};
                this.layer.addLayer(L.polyline(this.points, {color: 'white', weight: 5, dashArray: '15px'}));
                this.layer.addLayer(L.circleMarker(this.points[0], circleOptions));
                this.layer.addLayer(L.circleMarker(this.points[1], circleOptions));

                let dist = this.map.distance(this.points[0], this.points[1]);
                let unit = 'm';
                if (dist > 1000) {
                    dist /= 1000;
                    unit = 'km';
                }

                this.tooltip.setContent(`${dist.toFixed(2)}${unit}`).setLatLng(this.points[1]);
                this.tooltip.setOpacity(1);
            } else {
                this.layer.addLayer(L.polyline(this.points, { color: LINE_COLOR, weight: 4 }));

                // Add the circles
                for (let i = 0; i < this.points.length; i++) {
                    const point = this.points[i];
                    const pixelSize = getPixelSize(point, this.map.getZoom());
                    const circle = L.circle(point, {
                        color: LINE_COLOR,
                        fill: true,
                        opacity: 1,
                        fillColor: 'white',
                        fillOpacity: 1,
                        className: 'leaflet-drawing-circle',
                        radius: pixelSize * 5, // This math checks out, trust me
                    });

                    this.layer.addLayer(circle);
                }
            }

            // Add the tooltip
            if (this.tooltip) {
                this.layer.addLayer(this.tooltip);
            }
        }
    }

    /**
     * Attach the map handlers
     */
    private attachHandlers() {
        switch(this.mode) {
            case 'point':
                this.map.addEventListener('click', this.onPointClick);
                break;
            case 'square':
            case 'measure':
                this.map.addEventListener('mousedown', this.onSquareMouseDown);
                this.map.addEventListener('mouseup', this.onSquareMouseUp);
                break;
            default:
                this.map.addEventListener('click', this.onClick);
                break;
        }
        this.map.addEventListener('keydown', this.onKeyDown);
        this.map.addEventListener('mousemove', this.onMouseMove);
    }

    /**
     * Capture any style properties that are going to be overwritten
     */
    private captureStyle() {
        this.defaultUserSelect = document.body.style.userSelect;
    }

    /**
     * Restore any style properties that may have been overwritten
     */
    private resetStyle() {
        document.body.style.userSelect = this.defaultUserSelect;
    }

    /**
     * Remove the map handlers
     */
    private removeHandlers() {
        this.map.removeEventListener('click', this.onPointClick);
        this.map.removeEventListener('click', this.onClick);
        this.map.removeEventListener('mousedown', this.onSquareMouseDown);
        this.map.removeEventListener('mouseup', this.onSquareMouseUp);
        this.map.removeEventListener('keydown', this.onKeyDown);
        this.map.removeEventListener('mousemove', this.onMouseMove);
    }

    /**
     * Exit drawing mode. If silent is specified, no commit ack will be issued.
     * If silent is false, a commit event will be dispatched to the map.
     *
     * @param silent If false, dispatch a commit event to the map.
     */
    private exitDrawing(silent = false) {
        if (!silent) {
            this.map.fireEvent('editable:drawing:commit');
        }
        this.removeHandlers();
        this.resetStyle();
        this.mode = 'none';
        document.body.style.cursor = 'default';
    }

    private initializeDrawingMode(mode: DrawingMode) {
        this.removeHandlers();
        this.drawing = true;
        this.mode = mode;
        this.createLayer();
        this.attachHandlers();
        this.captureStyle();
    }

    /** Enter polygon drawing mode. This will hikack all global user input and assume you are working on the polygon. */
    startPolgyon() {
        this.initializeDrawingMode('polygon');
        document.body.style.cursor = 'crosshair';
    }

    startSquare() {
        this.initializeDrawingMode('square');
        document.body.style.cursor = 'crosshair';
    }

    startPoint() {
        this.initializeDrawingMode('point');
        document.body.style.cursor = 'crosshair';
    }

    startMeasure() {
        this.initializeDrawingMode('measure');
        document.body.style.cursor = 'crosshair';
    }

    /** Remove all shapes from the layer */
    clear(silent = false) {
        this.drawing = false;
        if (this.layer) {
            this.layer.remove();
            this.layer = undefined;
        }

        this.points = [];
        this.exitDrawing(silent);
    }

    /** This will return a GeoJSON with any shape or shapes that have been added to the map */
    toGeoJSON(): OSKGeoJson {
        if (this.mode === 'measure') return new OSKGeoJson();
        if (this.points.length > 0) {
            const latlngs =
                this.mode === 'square' && this.points.length === 2
                    ? getPointsForSquare(this.points[0], this.points[1])
                    : this.points;
            const points = latlngs.map((point) => [point.lng, point.lat]);
            points.push([points[0][0], points[0][1]]);

            return OSKGeoJson.fromGeoJSON({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            coordinates: [points],
                            type: 'Polygon',
                        },
                    },
                ],
            });
        } else if (this.point) {
            return OSKGeoJson.fromGeoJSON({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'Point',
                            coordinates: [this.point.lng, this.point.lat],
                        },
                    },
                ],
            });
        } else {
            return new OSKGeoJson();
        }
    }
}
