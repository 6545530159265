import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';

// Global style imports for various components
import './index.css';
import './css/react-infinite-calendar.theme.css';
import 'animate.css';

import App from './App';
import { DefaultThemeProvider, OSKThemeType } from 'oskcomponents';
import { OSKApplication } from 'oskcore';
import { ToggleContext } from './toggles/toggleContext';
import { ToggleRouter } from './toggles/toggleRouter';

const sigmaTheme = {
    map: {
        panel: {
            width: 325,
            margin: 15,
        },
    },
};

// @ts-ignore
const domain = import.meta.env.VITE_AUTH0_DOMAIN ?? 'identity.canary.orbitalsk.com';
// @ts-ignore
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID ?? 'Df44u9XE7vtugwH0NqmNByZRcat3RGV2';

export type SigmaTheme = typeof sigmaTheme & OSKThemeType;

ReactDOM.render(
    <React.StrictMode>
        <OSKApplication domain={domain} clientId={clientId} redirectUri={window.location.origin} name="Sigma Data">
            <Provider store={store}>
                <DefaultThemeProvider theme={sigmaTheme}>
                    <ToggleContext.Provider value={new ToggleRouter()}>
                        <App />
                    </ToggleContext.Provider>
                </DefaultThemeProvider>
            </Provider>
        </OSKApplication>
    </React.StrictMode>,
    document.getElementById('root'),
);
