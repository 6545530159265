import React from 'react';
import { useTheme } from 'styled-components';
import { Box, BoxProps } from '../Box';
import { OSKThemeType } from '../DefaultThemeProvider';

export type ModalBodyProps = {
    /** Children elements to render in the modal body */
    children?: React.ReactNode;
} & Omit<BoxProps, 'ref'>;

/**
 * This is a ModalBody component which wraps the content area
 * of a modal with standardized padding and height requirements.
 */
const ModalBody = ({ children, style, ...props }: ModalBodyProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                padding: '15px',
                minHeight: '75px',
                backgroundColor: theme.colors.gray10,
                color: theme.colors.primary.bg,
                ...style,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};

export { ModalBody };
