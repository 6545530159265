import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';
import { noop } from 'oskcore';
import { Box, Button, Modal, ModalBody, ModalFooter, ModalHeader, OSKThemeType, Typography } from 'oskcomponents';
import { useTheme } from 'styled-components';
import { EnviHeaders } from '~/redux/modules/data/search';

type EnviHeaderModalProps = {
    /** The ENVI header data to display in a modal, in key-value pairs */
    enviHeaderDisplay?: EnviHeaders;
};

const EnviHeaderModal = ({ enviHeaderDisplay }: EnviHeaderModalProps) => {
    const theme = useTheme() as OSKThemeType;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (enviHeaderDisplay) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [enviHeaderDisplay]);

    if (!visible || !enviHeaderDisplay) return <></>;

    return (
        <Modal visible={visible}>
            <ModalHeader variant="primary">
                <Typography variant="body3">ENVI Metadata</Typography>
            </ModalHeader>
            <ModalBody>
                <Box col style={{ maxHeight: '600px', width: '600px', overflowY: 'scroll' }}>
                    {Object.entries(enviHeaderDisplay).map((v, idx) => {
                        if (!v[0]) return <></>;

                        if (Array.isArray(v[1])) {
                            return (
                                <Box
                                    key={`envi-header-${idx}`}
                                    col
                                    style={{ padding: '8px 0px', borderBottom: `1px solid ${theme.colors.black200}` }}
                                >
                                    <details open={v[1].length === 1}>
                                        <summary
                                            style={{
                                                fontSize: '0.75rem',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {v[0]} ({v[1].length})
                                        </summary>

                                        <Box col>
                                            {(v[1] as string[]).map((e, idx) => (
                                                <Typography key={`${v[0]}-idx`} variant="body3">
                                                    {e}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </details>
                                </Box>
                            );
                        }
                        return (
                            <Box
                                key={`envi-header-${idx}`}
                                col
                                style={{ padding: '8px 0px', borderBottom: `1px solid ${theme.colors.black200}` }}
                            >
                                <Typography variant="caption1" strong>
                                    {v[0]}
                                </Typography>
                                <Typography variant="body3">{v[1]}</Typography>
                            </Box>
                        );
                    })}
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="action"
                    label="Close"
                    onClick={() => {
                        setVisible(false);
                    }}
                />
            </ModalFooter>
        </Modal>
    );
};
const mapStateToProps = (state: RootState) => {
    const { enviHeaderDisplay } = state.data.search;
    return { enviHeaderDisplay };
};

export default connect(mapStateToProps, noop)(EnviHeaderModal);
