import React from 'react';
import AppList from '~/organisms/AppList';
import UserProfile from '~/organisms/UserProfile';
import ProgramSwitcher from '~/organisms/ProgramSwitcher';
import { Box, OSKThemeType } from 'oskcomponents';
import { useTheme } from 'styled-components';

export type SigmaNavItemsProps = {
    /** Any children elements to render to the right of the program switcher */
    children?: React.ReactNode;
};

export const SigmaNavItems = ({ children }: SigmaNavItemsProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <React.Fragment>
            <ProgramSwitcher />
            <Box
                style={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    height: '24px',
                    width: '20px',
                    marginRight: '10px',
                    borderRight: `1px solid ${theme.colors.gray50}`,
                }}
            />
            <Box
                style={{
                    flexGrow: 1,
                }}
            >
                {children}
                <AppList />
                <UserProfile />
            </Box>
        </React.Fragment>
    );
};
