import React from 'react';
import { OSKError } from '../../templates/OSKError';

type GenericErrorViewProps = {};
const GenericErrorView = ({}: GenericErrorViewProps) => {
    return (
        <OSKError>
            Something went wrong. We&apos;re sorry for the inconvenience. Please refresh the page and try again.
        </OSKError>
    );
};

export { GenericErrorView };
