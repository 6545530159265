import 'leaflet-editable';
import { Box, Button, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from 'oskcomponents';
import React from 'react';
import { getSimpleChildNodes } from '~/utils';
import { useMap } from '~/hooks';

export type BaseMapAttributionModalType = {
    /** Whether or not this modal is visible. */
    visible?: boolean;
    /** A method that's called when the modal is dismissed. */
    onClose?: () => void;
};

export const BaseMapAttributionModal = ({ visible = false, onClose }: BaseMapAttributionModalType) => {
    const map = useMap();

    const attributions: Array<HTMLElement> = [];
    map?.eachLayer((layer: any) => {
        if ('_attributesContainer' in layer) {
            attributions.push(layer._attributesContainer as HTMLElement);
        }
    });

    let attrHtml = '';
    attributions.forEach((attribution) => {
        attribution.childNodes.forEach((child) => {
            // Get only the HTML nodes with content, and de-dupe them.
            const nodes: Array<any> = [];
            getSimpleChildNodes(child, nodes, ['button', 'span'], 'max-width: 450px; margin-top: 8px');

            const uniqueNodes = nodes?.filter(
                (n, idx) => nodes.indexOf(nodes.find((nn) => nn.innerText === n.innerText)) === idx,
            );

            attrHtml += uniqueNodes.map((node) => node.outerHTML).join('');
        });
    });

    return (
        <Modal visible={visible}>
            <ModalHeader variant="primary">Data Attribution</ModalHeader>
            <ModalBody>
                <Box col grow>
                    <Typography strong variant="body2">
                        The current map view includes data from:
                    </Typography>
                    <Typography variant="body3">
                        <div
                            dangerouslySetInnerHTML={{ __html: attrHtml }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        />
                    </Typography>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => onClose && onClose()}>Done</Button>
            </ModalFooter>
        </Modal>
    );
};
