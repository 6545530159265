import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from '../Box';
import styled, { useTheme } from 'styled-components';
import { OSKThemeType } from '../DefaultThemeProvider';
import CSS from 'csstype';

export type ToggleButtonProps = {
    /** The default toggle state of this button */
    defaultValue: boolean;
    /** An optional method which is invoked when toggle state changes */
    onChange?: (toggled: boolean) => void;
    /** The name of the hidden form input box which is included for form submission support */
    name?: string;
    /** Additional styled-components class name */
    className?: string;
    /** Override container style for the toggle button itself */
    style?: CSS.Properties;
    /** Optional accent color. Defaults to theme.colors.accent. */
    accentColor?: string;
} & Omit<Omit<Omit<Omit<Omit<BoxProps, 'ref'>, 'style'>, 'className'>, 'defaultValue'>, 'onChange'>;

export const ToggleButton = styled(
    ({ accentColor, onChange, defaultValue, className, name, style, ...props }: ToggleButtonProps) => {
        const theme = useTheme() as OSKThemeType;
        const [toggled, setToggled] = useState(defaultValue);
        const bg = toggled ? accentColor ?? theme.colors.accent : theme.colors.black600;

        useEffect(() => {
            setToggled(defaultValue);
        }, [defaultValue]);

        return (
            <>
                <Box
                    data-testid="toggle-button"
                    className={className}
                    onClick={() => {
                        const nextValue = !toggled;
                        setToggled(nextValue);
                        onChange && onChange(nextValue);
                    }}
                    w={55}
                    h={25}
                    style={{ border: `2px solid ${bg}`, borderRadius: '23px', ...style }}
                    bg={bg}
                    {...props}
                >
                    <Box className="toggle-spacer" style={{ flexGrow: !toggled ? 0 : 1 }} />
                    <Box
                        style={{
                            width: `25px`,
                            height: `25px`,
                            backgroundColor: 'white',
                            borderRadius: '30px',
                            alignSelf: 'center',
                        }}
                    />
                </Box>
                {name && (
                    <input
                        data-testid="toggle-button-value"
                        type="hidden"
                        name={name}
                        value={toggled ? 'true' : 'false'}
                    />
                )}
            </>
        );
    },
)`
    & {
        transition: background-color 0.5s;
    }

    & .toggle-spacer {
        transition: flex-grow 0.25s;
    }
`;
