import { Box, OSKThemeType } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { OSKContentView } from '~/molecules/OSKContentView';
import OrderSummary from '~/organisms/OrderSummary';
import OrderListPanel from '~/organisms/OrderListPanel';

import { DataResourceProvider } from '~/redux';
import { getProgramId } from '~/utils';
import { RootState } from '~/redux/store';
import { connect } from 'react-redux';
import { selectHasOrders } from '~/redux/modules/data/orders';
import { noop } from 'oskcore';

type OrderDashboardViewProps = {
    /** True if we should show the summary panel or not. */
    showSummary: boolean;
};

const OrderDashboardView = ({ showSummary }: OrderDashboardViewProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <OSKContentView nogradient>
            <DataResourceProvider
                features={['tasking_orders', 'archive_orders']}
                programId={getProgramId()}
                orderPage={0}
            >
                <Box style={{ width: '100%', height: '100%' }} bg={theme.colors.white} grow>
                    <Box
                        bg={theme.colors.whiteAccentBackground}
                        style={{ borderRight: `1px solid ${theme.colors.black200}` }}
                        grow
                    >
                        <Box
                            fg={theme.colors.black}
                            style={{ width: showSummary ? '450px' : '100%', height: '100%', position: 'relative' }}
                            grow
                            col
                        >
                            <OrderListPanel />
                        </Box>
                    </Box>
                    {showSummary && (
                        <Box
                            bg={theme.colors.white}
                            p={40}
                            fg={theme.colors.black}
                            style={{ width: '100%', height: '100%' }}
                            grow
                        >
                            <OrderSummary />
                        </Box>
                    )}
                </Box>
            </DataResourceProvider>
        </OSKContentView>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        showSummary: selectHasOrders(state),
    };
};
export default connect(mapStateToProps, noop)(OrderDashboardView);
