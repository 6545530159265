import { createSelector } from '@reduxjs/toolkit';
import { Detection, MapOf, Asset as ApiAsset, AssetDetail, RasterArtifact, SigmaAPI } from 'oskcore';
import { DEFAULT_PAGINATION_LIMIT, DEFAULT_VISIBLE_PAGE_SIZE } from '~/pagination';
import { AppDispatch, RootState } from '~/redux/store';
import { getReportsWithNames, ProgramReport } from './reports';

/* Types */
export type Asset = AssetDetail | ApiAsset;
export type AssetDetection = Detection & {
    /** A unique external-facing id for this detection */
    external_id: string;
    /** A list of artifacts (files, other data) associated with this detection */
    artifacts: Array<RasterArtifact>;
};

/* Actions */

export const SET_FETCHING_ASSETS = 'SET_FETCHING_ASSETS';
export function setFetchingAssets(fetchingAssets: boolean) {
    return { type: SET_FETCHING_ASSETS, payload: { fetchingAssets } };
}

export const SET_FETCHING_ASSET = 'SET_FETCHING_ASSET';
export function setFetchingAsset(fetchingAsset: boolean) {
    return { type: SET_FETCHING_ASSET, payload: { fetchingAsset } };
}

export const ADD_ASSETS = 'ADD_ASSETS';
export function addAssets(assets: Array<Asset>) {
    return {
        type: ADD_ASSETS,
        payload: {
            assets,
        },
    };
}

export const SET_ASSETS = 'SET_ASSETS';
export function setAssets(assets: Array<Asset>) {
    return {
        type: SET_ASSETS,
        payload: {
            assets,
        },
    };
}

export const SET_ASSET_DETAIL = 'SET_ASSET_DETAIL';
export function setAssetDetail(assetDetail: Asset) {
    return {
        type: SET_ASSET_DETAIL,
        payload: {
            assetDetail,
        },
    };
}

export const SET_FETCHING_ALL_DETECTIONS = 'SET_FETCHING_ALL_DETECTIONS';
export function setFetchingAllDetections(fetchingAllDetections: boolean) {
    return { type: SET_FETCHING_ALL_DETECTIONS, payload: { fetchingAllDetections } };
}

export const ADD_DETECTIONS = 'ADD_DETECTIONS';
export function addDetections(assetId: number, detections: Array<AssetDetection>) {
    return {
        type: ADD_DETECTIONS,
        payload: {
            assetId,
            detections,
        },
    };
}

export const SET_DETECTION_PAGE = 'SET_DETECTION_PAGE';
export function setDetectionPage(assetId: number, pageNumber: number) {
    return {
        type: SET_DETECTION_PAGE,
        payload: {
            assetId,
            pageNumber,
        },
    };
}

export const SET_FETCHING_ALL_ALERTS = 'SET_FETCHING_ALL_ALERTS';
export function setFetchingAllAlerts(fetchingAllAlerts: boolean) {
    return { type: SET_FETCHING_ALL_ALERTS, payload: { fetchingAllAlerts } };
}

export const ADD_ALERTS = 'ADD_ALERTS';
export function addAlerts(assetId: number, alerts: Array<AssetDetection>) {
    return {
        type: ADD_ALERTS,
        payload: {
            assetId,
            alerts,
        },
    };
}

export const SET_ALERT_PAGE = 'SET_ALERT_PAGE';
export function setAlertPage(assetId: number, pageNumber: number) {
    return {
        type: SET_ALERT_PAGE,
        payload: {
            assetId,
            pageNumber,
        },
    };
}

export const SET_FETCH_ERROR = 'SET_FETCH_ERROR';
export function setFetchError(error: boolean) {
    return {
        type: SET_FETCH_ERROR,
        payload: {
            error,
        },
    };
}

export const SET_FILTER_DATES = 'SET_FILTER_DATES';
export function setFilterDates(start: Date | undefined, end: Date | undefined) {
    return {
        type: SET_FILTER_DATES,
        payload: {
            start,
            end,
        },
    };
}

export const SET_PANEL_TAB_IDX = 'SET_PANEL_TAB_IDX';
export function setPanelTabIdx(idx: number) {
    return {
        type: SET_PANEL_TAB_IDX,
        payload: {
            idx,
        },
    };
}

export function getAllDetectionsForAssetAsync(
    programId: number,
    assetId: number,
    detectionClasses: ('alert' | 'detection')[],
    filterStartDate?: Date,
    filterEndDate?: Date,
) {
    return async (dispatch: AppDispatch) => {
        if (detectionClasses.includes('alert')) {
            dispatch(setFetchingAllAlerts(true));
        }

        if (detectionClasses.includes('detection')) {
            dispatch(setFetchingAllDetections(true));
        }

        function handleResponse(detections: AssetDetection[]) {
            const alertResults = detections.filter((x) => x.detection_class.includes('alert'));
            const detectionResults = detections.filter((x) => x.detection_class.includes('detection'));

            if (alertResults.length > 0) {
                dispatch(addAlerts(assetId, alertResults));
            }

            if (detectionResults.length > 0) {
                dispatch(addDetections(assetId, detectionResults));
            }
        }

        const promises: Array<Promise<any>> = [];

        for (const detectionClass of detectionClasses) {
            const requestParameters = {
                program: programId,
                asset: [assetId],
                detectionClass: detectionClass,
                detectedAfter: filterStartDate?.toISOString(),
                detectedBefore: filterEndDate?.toISOString(),
                limit: DEFAULT_PAGINATION_LIMIT,
                includeFiles: detectionClasses.includes('alert') ? 'all' : 'none',
            };

            // @ts-ignore for now
            await SigmaAPI.listDetections(requestParameters)
                .then((response) => {
                    // Paginate
                    if (response.data) {
                        const total = response.data.count ?? 0;
                        const pages = total / DEFAULT_PAGINATION_LIMIT;
                        for (let pageNumber = 1; pageNumber < pages; pageNumber++) {
                            // @ts-ignore for now
                            const promise = SigmaAPI.listDetections({
                                ...requestParameters,
                                offset: pageNumber * DEFAULT_PAGINATION_LIMIT,
                            })
                                .then((resp) => {
                                    handleResponse((resp.data?.results ?? []) as AssetDetection[]);
                                })
                                .catch((ex) => {
                                    console.error(ex);
                                });

                            promises.push(promise);
                        }

                        handleResponse((response.data?.results ?? []) as AssetDetection[]);
                    }
                })
                .catch((ex) => {
                    console.error(ex);
                });
        }

        await Promise.all(promises).finally(() => {
            if (detectionClasses.includes('alert')) {
                dispatch(setFetchingAllAlerts(false));
            }

            if (detectionClasses.includes('detection')) {
                dispatch(setFetchingAllDetections(false));
            }
        });
    };
}

export function fetchAssetsAsync(programId: number) {
    return (dispatch: AppDispatch) => {
        dispatch(setFetchingAssets(true));

        SigmaAPI.listAssets({ program: programId })
            .then((response) => {
                if (response.data.results) {
                    dispatch(addAssets(response.data.results as Asset[]));
                }
            })
            .catch((er) => {
                console.log('Error fetching assets', er);
                dispatch(addAssets([]));
                dispatch(setFetchError(true));
                setTimeout(() => {
                    dispatch(setFetchError(false));
                }, 8000);
            })
            .finally(() => {
                dispatch(setFetchingAssets(false));
            });
    };
}

export function fetchAssetAsync(programId: number, assetId: number) {
    return (dispatch: AppDispatch) => {
        dispatch(setFetchingAsset(true));

        SigmaAPI.getAsset({ id: assetId, program: programId })
            .then((resp) => {
                const { data } = resp;
                if (data) {
                    dispatch(setAssetDetail(data));
                }
            })
            .finally(() => {
                dispatch(setFetchingAsset(false));
            });
    };
}

/* Reducer */
type MonitorAppReducerType = {
    fetchingAssets: boolean;
    fetchingAsset: boolean;
    fetchingAllAlerts: boolean;
    fetchingAllDetections: boolean;

    assets: MapOf<Asset>;

    panelTabIdx: number;

    alertPageByAsset: MapOf<number>;
    alertsByAsset: MapOf<Array<AssetDetection>>;

    detectionPageByAsset: MapOf<number>;
    detectionsByAsset: MapOf<Array<AssetDetection>>;

    fetchError: boolean;

    filterStartDate?: Date;
    filterEndDate?: Date;
};

const initialState: MonitorAppReducerType = {
    fetchingAsset: false,
    fetchingAssets: false,

    fetchingAllAlerts: false,
    fetchingAllDetections: false,

    assets: {},

    panelTabIdx: 0,

    alertPageByAsset: {},
    alertsByAsset: {},

    detectionPageByAsset: {},
    detectionsByAsset: {},

    fetchError: false,

    filterStartDate: undefined,
    filterEndDate: undefined,
};

export default function reducer(state = initialState, action: any): MonitorAppReducerType {
    switch (action.type) {
        case SET_FETCHING_ASSETS: {
            const { fetchingAssets } = action.payload;
            return {
                ...state,
                fetchingAssets,
            };
        }

        case SET_FETCHING_ASSET: {
            const { fetchingAsset } = action.payload;
            return {
                ...state,
                fetchingAsset,
            };
        }

        case ADD_ASSETS: {
            const { assets } = action.payload;
            const assetMap: MapOf<Asset> = {};

            for (const asset of assets) {
                assetMap[asset.id] = asset;
            }

            return {
                ...state,
                assets: {
                    ...state.assets,
                    ...assetMap,
                },
            };
        }

        case SET_ASSETS: {
            const { assets } = action.payload;
            const assetMap: MapOf<Asset> = {};

            for (const asset of assets) {
                assetMap[asset.id] = asset;
            }

            return {
                ...state,
                assets: {
                    ...assetMap,
                },
            };
        }

        case SET_ASSET_DETAIL: {
            const { assetDetail } = action.payload;
            const { assets } = state;

            return {
                ...state,
                assets: {
                    ...assets,
                    [assetDetail.id]: assetDetail,
                },
            };
        }

        case SET_FETCHING_ALL_DETECTIONS: {
            const { fetchingAllDetections } = action.payload;
            return {
                ...state,
                fetchingAllDetections,
            };
        }

        case ADD_DETECTIONS: {
            const { assetId, detections } = action.payload;
            const detectionsByAsset = Object.assign({}, state.detectionsByAsset ?? {});
            detectionsByAsset[assetId] = detectionsByAsset[assetId] ?? [];
            const oldDetectionHash: MapOf<boolean> = {};
            detectionsByAsset[assetId].forEach((detection) => {
                oldDetectionHash[detection.id] = true;
            });

            // Don't re-add detections
            const newDetections = detections.filter((detection: AssetDetection) => !oldDetectionHash[detection.id]);

            for (const detection of newDetections) {
                detectionsByAsset[assetId].push(detection);
            }

            return {
                ...state,
                detectionsByAsset,
            };
        }

        case SET_DETECTION_PAGE: {
            const { pageNumber, assetId } = action.payload;

            return {
                ...state,
                detectionPageByAsset: {
                    ...state.detectionPageByAsset,
                    [assetId]: pageNumber,
                },
            };
        }

        case SET_FETCHING_ALL_ALERTS: {
            const { fetchingAllAlerts } = action.payload;
            return {
                ...state,
                fetchingAllAlerts,
            };
        }

        case ADD_ALERTS: {
            const { assetId, alerts } = action.payload;
            const alertsByAsset = Object.assign({}, state.alertsByAsset ?? {});
            alertsByAsset[assetId] = alertsByAsset[assetId] ?? [];
            const oldAlertHash: MapOf<boolean> = {};
            alertsByAsset[assetId].forEach((alert) => {
                oldAlertHash[alert.id] = true;
            });

            // Don't re-add alerts
            const newAlerts = alerts.filter((alert: AssetDetection) => !oldAlertHash[alert.id]);

            for (const alert of newAlerts) {
                alertsByAsset[assetId].push(alert);
            }

            return {
                ...state,
                alertsByAsset,
            };
        }

        case SET_ALERT_PAGE: {
            const { pageNumber, assetId } = action.payload;
            return {
                ...state,
                alertPageByAsset: {
                    ...state.alertPageByAsset,
                    [assetId]: pageNumber,
                },
            };
        }

        case SET_FETCH_ERROR: {
            const { error } = action.payload;
            return {
                ...state,
                fetchError: error,
            };
        }

        case SET_FILTER_DATES: {
            const { start, end } = action.payload;
            return {
                ...state,
                filterStartDate: start,
                filterEndDate: end,
            };
        }

        case SET_PANEL_TAB_IDX: {
            const { idx } = action.payload;
            return {
                ...state,
                panelTabIdx: idx,
            };
        }

        default:
            return { ...state };
    }
}

function filterDetectionByDate(state: RootState, detection: Detection) {
    const hasStartFilter = state.monitor.app.filterStartDate ?? false;
    const hasEndFilter = state.monitor.app.filterEndDate ?? false;

    if (hasStartFilter || hasEndFilter) {
        const startFilteredIn =
            hasStartFilter && detection.detected_at
                ? new Date(detection.detected_at) > (state.monitor.app.filterStartDate ?? new Date())
                : true;

        const endFilteredIn =
            hasEndFilter && detection.detected_at
                ? new Date(detection.detected_at) < (state.monitor.app.filterEndDate ?? new Date())
                : true;

        return startFilteredIn && endFilteredIn;
    } else {
        return true;
    }
}

/** Selectors */
export const getDetections = (state: RootState) => state.monitor.app.detectionsByAsset;

function filterDetectionsByDateRange(
    detections: AssetDetection[],
    filterStartDate: Date | undefined,
    filterEndDate: Date | undefined,
) {
    return detections.filter((detection) => {
        if ((detection.detected_at ?? detection.created_at) && filterStartDate && filterEndDate) {
            const date = new Date(detection.detected_at ?? detection.created_at);
            return date.getTime() > filterStartDate.getTime() && date.getTime() < filterEndDate.getTime();
        } else {
            return true;
        }
    });
}

export const selectDetectionsByAsset = createSelector(
    [
        (state: RootState) => state.monitor.app.detectionsByAsset,
        (state: RootState) => state.monitor.app.filterStartDate,
        (state: RootState) => state.monitor.app.filterEndDate,
        (state: RootState, assetId: number) => assetId,
    ],
    (
        detectionsByAsset: MapOf<AssetDetection[]>,
        filterStartDate: Date | undefined,
        filterEndDate: Date | undefined,
        assetId: number,
    ): AssetDetection[] => {
        return filterDetectionsByDateRange(detectionsByAsset[assetId] ?? [], filterStartDate, filterEndDate);
    },
);

export const selectDetectionsByAssetByPage = createSelector(
    [
        (state: RootState) => state.monitor.app.detectionsByAsset,
        (state: RootState) => state.monitor.app.filterStartDate,
        (state: RootState) => state.monitor.app.filterEndDate,
        (state: RootState) => state.monitor.app.detectionPageByAsset,
        (state: RootState, assetId: number) => assetId,
    ],
    (
        detectionsByAsset: MapOf<AssetDetection[]>,
        filterStartDate: Date | undefined,
        filterEndDate: Date | undefined,
        pagesByAsset: MapOf<number>,
        assetId: number,
    ) => {
        const detections = filterDetectionsByDateRange(
            detectionsByAsset[assetId] ?? [],
            filterStartDate,
            filterEndDate,
        );
        const page = pagesByAsset[assetId] ?? 0;

        return detections.slice(page * DEFAULT_VISIBLE_PAGE_SIZE, (page + 1) * DEFAULT_VISIBLE_PAGE_SIZE);
    },
);

export const selectAlerts = createSelector(
    [
        (state: RootState) => state.monitor.app.alertsByAsset,
        (state: RootState) => state.monitor.app.filterStartDate,
        (state: RootState) => state.monitor.app.filterEndDate,
    ],
    (alertsByAsset: MapOf<AssetDetection[]>, filterStartDate: Date | undefined, filterEndDate: Date | undefined) => {
        return filterDetectionsByDateRange(
            Object.keys(alertsByAsset).flatMap((assetId) => alertsByAsset[assetId]),
            filterStartDate,
            filterEndDate,
        );
    },
);

export const selectAlertsByAsset = createSelector(
    [
        (state: RootState) => state.monitor.app.alertsByAsset,
        (state: RootState) => state.monitor.app.filterStartDate,
        (state: RootState) => state.monitor.app.filterEndDate,
        (state: RootState, assetId: number) => assetId,
    ],
    (
        alertsByAsset: MapOf<AssetDetection[]>,
        filterStartDate: Date | undefined,
        filterEndDate: Date | undefined,
        assetId: number,
    ) => {
        return filterDetectionsByDateRange(alertsByAsset[assetId] ?? [], filterStartDate, filterEndDate);
    },
);

export function filterReportsByAsset(state: RootState, assetId: number): Array<ProgramReport> {
    const reports = getReportsWithNames(state) ?? [];
    return (reports as Array<ProgramReport>).filter((x) => x.assets.includes(assetId));
}

/**
 * Return all detections and alerts aggregated into one list
 * filtered by the current tile filter and for the current
 * asset.
 */
export const selectAllDetectionsByAsset = createSelector(
    [
        (state: RootState) => state.monitor.app.detectionsByAsset,
        (state: RootState) => state.monitor.app.alertsByAsset,
        (state: RootState) => state.monitor.app.filterStartDate,
        (state: RootState) => state.monitor.app.filterEndDate,
        (state: RootState, assetId: number) => assetId,
    ],
    (
        detectionsByAsset: MapOf<AssetDetection[]>,
        alertsByAsset: MapOf<AssetDetection[]>,
        filterStartDate: Date | undefined,
        filterEndDate: Date | undefined,
        assetId: number,
    ): AssetDetection[] => {
        return [
            ...filterDetectionsByDateRange(detectionsByAsset[assetId] ?? [], filterStartDate, filterEndDate),
            ...filterDetectionsByDateRange(alertsByAsset[assetId] ?? [], filterStartDate, filterEndDate),
        ];
    },
);
