import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from 'oskcomponents';
import { AbbreviationText } from '../AbbreviationText';

export type AbbreviationIconProps = {
    /** Additional CSS class to add to the icon */
    className?: string;
    /** The label from which to extract an abbreviation from. The ProfileIcon
     * will automatically determine the best-fit abbreviation to use. */
    label: string;
    /** The method to invoke when the icon is pressed. */
    onClick?: () => void;
    /** How big the icon should be */
    iconSize?: number;
} & Omit<BoxProps, 'ref'>;

export const AbbreviationIcon = styled(
    ({ className, label, onClick, iconSize = 20, ...props }: AbbreviationIconProps) => {
        return (
            <Box className={className} onClick={onClick} {...props}>
                <AbbreviationText label={label} />
            </Box>
        );
    },
)`
    & {
        padding: 18px;
        border-radius: ${(props: any) => props.iconSize ?? 20}px;
        width: ${(props: any) => props.iconSize ?? 20}px;
        height: ${(props: any) => props.iconSize ?? 20}px;
        justify-content: center;
        align-items: center;
        font-size: ${(props: any) => (props.iconSize > 20 ? '2rem' : '1rem')};
        color: ${(props: any) => props.theme.colors.primary.invertedBg};
        background-color: ${(props: any) => props.theme.colors.primary.invertedFg};
        cursor: pointer;
        text-decoration: none !important;
    }

    &:hover {
        background-color: ${(props: any) => props.theme.colors.accent};
    }
`;
