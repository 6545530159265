import { LatLng, LatLngBounds, LatLngExpression } from 'leaflet';
import _ from 'lodash';
import { OSKThemeType } from 'oskcomponents';
import { LeafletFeature, OSKGeoJson, useRemountOnChange } from 'oskcore';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Polygon, Polyline, useMap as useLeafletMap } from 'react-leaflet';
import { useTheme } from 'styled-components';

import { useMap } from '~/hooks';
import { checkLatLngLineCollision } from '~/utils';

export type FootprintProps = {
    /** Override the default color of the footprint. */
    color?: string;
    /** If true, the color will be different */
    selected?: boolean;
    /** If true, the color will be different */
    highlighted?: boolean;
    /** A shape describing the footprint to render */
    shape: OSKGeoJson | LeafletFeature;
    /** A method which will be invokved when the footprint is clicked */
    onClick?: () => void;
    /** A method which will be invoked when the mouse hovers over this footprint */
    onMouseOver?: () => void;
    /** A method which will be invoked when the mouse leaves the footprint area */
    onMouseOut?: () => void;
    /** Whether to render this footprint or not. */
    visible?: boolean;
};

/**
 * Footprint is a geometric shape drawn on the map. It must be nested within
 * a leaflet map element.
 */
const Footprint = ({
    color,
    onClick,
    onMouseOver,
    onMouseOut,
    shape,
    selected = false,
    highlighted = false,
    visible = true,
    ...props
}: FootprintProps) => {
    const ref = useRef(null);
    const inBounds = useRef(false);
    const [doRender] = useRemountOnChange([highlighted, selected, color]);
    const theme = useTheme() as OSKThemeType;

    const map = useMap();
    const basemap = useLeafletMap();

    // Extract the coordinates from the geometry
    useEffect(() => {
        if (ref.current !== null && ref.current !== undefined) {
            (ref.current as any).on('click', onClick);
            (ref.current as any).on('mouseover', onMouseOver);
            (ref.current as any).on('mouseout', onMouseOut);
        }

        return () => {
            if (ref && ref.current !== null) {
                (ref.current as any).off('click', onClick);
                (ref.current as any).off('mouseover', onMouseOver);
                (ref.current as any).off('mouseout', onMouseOut);
            }
        };
    }, [ref, ref.current]);

    const isInBounds = useCallback(
        (point: LatLngExpression) => {
            const isInBounds = (map.getMapBounds() as LatLngBounds).contains(point);
            return isInBounds;
        },
        [map],
    );

    basemap.on('move', function (e) {
        calculateOutOfBounds();
    });

    const calculateOutOfBounds = _.throttle(() => {
        const points = [...shapes.polygon, ...shapes.polyline];
        const mapBounds = map.getMapBounds() as LatLngBounds;

        if (!mapBounds) return;

        let anyInBounds = false;
        let touchingMapEdge = false;

        for (const latlngs of points) {
            for (let i = 1; i < latlngs.length; i++) {
                const p1 = latlngs[i - 1];
                const p2 = latlngs[i];

                const l1 = checkLatLngLineCollision([p1, p2], [mapBounds.getNorthWest(), mapBounds.getNorthEast()]);
                const l2 = checkLatLngLineCollision([p1, p2], [mapBounds.getNorthEast(), mapBounds.getSouthEast()]);
                const l3 = checkLatLngLineCollision([p1, p2], [mapBounds.getSouthEast(), mapBounds.getSouthWest()]);
                const l4 = checkLatLngLineCollision([p1, p2], [mapBounds.getSouthWest(), mapBounds.getNorthWest()]);

                if (l1 || l2 || l3 || l4) {
                    touchingMapEdge = true;
                }
            }

            for (const point of latlngs) {
                if (isInBounds(point)) anyInBounds = true;
            }
        }

        inBounds.current = anyInBounds || touchingMapEdge;
    }, 250);

    const shapes = useMemo(() => {
        const shapes = {
            polygon: [] as LatLng[][],
            polyline: [] as LatLng[][],
        };

        const featureList = [];
        if ('toLeafletFeatureList' in shape) {
            for (const feature of shape.toLeafletFeatureList()) {
                featureList.push(feature);
            }
        } else {
            featureList.push(shape);
        }

        for (const feature of featureList) {
            if (feature.type === 'MultiLineString' || feature.type === 'LineString') {
                shapes.polyline.push(feature.coordinates);
            } else {
                shapes.polygon.push(feature.coordinates);
            }
        }

        return shapes;
    }, [shape]);
    const shapeProps = {
        'data-testid': 'footprint',
        onClick: onClick,
        ...props,
        color: color ?? (selected ? theme.colors.accent : highlighted ? theme.colors.white : theme.colors.cyan1a),
    };

    // Check if there are multiple features
    if (!doRender || !visible || !shape || !inBounds.current) {
        return <React.Fragment />;
    } else {
        return (
            <>
                {shapes.polygon.length > 0 && <Polygon ref={ref} {...shapeProps} positions={shapes.polygon} />}
                {shapes.polyline.length > 0 && <Polyline ref={ref} {...shapeProps} positions={shapes.polyline} />}
            </>
        );
    }
};

export { Footprint };
