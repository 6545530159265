import React, { useImperativeHandle, useState } from 'react';
import { useTheme } from 'styled-components';
import { Box } from '../Box';
import { Button } from '../Button';
import { GlobalZIndex } from '../constants';
import { OSKThemeType } from '../DefaultThemeProvider';
import { OSKIcon } from '../OSKIcon';
import CSS from 'csstype';
import { useRefState } from '../hooks';

export type SidePanelProps = {
    /** Children components to render inside the SidePanel */
    children?: React.ReactNode | Array<React.ReactNode>;
    /** Whether to show the SidePanel or not */
    visible?: boolean;
    /** Whether or not the panel can be collapsed */
    collapsible?: boolean;
    /** Whether or not the panel can be expanded */
    expandable?: boolean;
    /** Style passthrough */
    style?: CSS.Properties;
    /** An optional width override */
    width?: number;
    /** Used by other panels as a reference for opening this one. */
    panelId?: string;
    /** Method to call prior to the panel closing animation for each panel being closed.
     * (Note: This is called for each sub-panel just before it closes.)
     */
    onClose?: () => Promise<any>;
    /** Width of the collapse tab button */
    collapseButtonWidth?: number;
    /** How fast (in milliseconds) the slide-out animation is */
    expandAnimationTime?: number;
    /** A flag to enable `overflow: hidden;` on the panel */
    hideOverflow?: boolean;
};

export type SidePanelHandle = {
    /** Returns the expanded status of the panel */
    getIsOpen: () => number;
    /** Triggers the opening sequence */
    open: () => Promise<any>;
    /** Triggers the closing sequence. Also closes any sub-panels. */
    close: () => Promise<any>;
    /** Triggers the opening or closing sequence depending on the expanded status. */
    toggle: () => Promise<any>;
    /** Opens a sub panel underneath this one. */
    openSub: (ref: React.RefObject<SidePanelHandle>) => void;
    /** Opens a sub panel underneath this one or close an existing one. */
    toggleSub: (ref: React.RefObject<SidePanelHandle>) => void;
    /** Sets the level of this panel. */
    setLevel: (level: number) => void;
    /** Sets the offset of this panel. */
    setOffset: (offset: number) => void;
};

const DEFAULT_SIDEPANEL_WIDTH = 350;

/**
 *The SidePanel component is composed with
 * <SidePanelBody /> <SidePanelFooter /> and <SidePanelHeader>. Doing so will create
 * a standardized popup which can be used for any blocking user action.
 */
const SidePanel = React.forwardRef<SidePanelHandle, SidePanelProps>(
    (
        {
            children,
            visible = true,
            width = 350,
            collapsible = true,
            expandable = true,
            style,
            panelId,
            onClose,
            collapseButtonWidth = 33,
            expandAnimationTime = 400,
            hideOverflow = true,
        }: SidePanelProps,
        ref,
    ) => {
        const [expanded, setExpanded] = useState<boolean>(visible);
        const [animating, setAnimating] = useState<boolean>(false);
        const [panelOffset, setPanelOffset] = useState<number>(0);
        const theme = useTheme() as OSKThemeType;
        const canShowExpandButton = (expandable && !expanded) || (collapsible && expanded);
        const [getSubPanelRef, setSubPanelRef] = useRefState<React.RefObject<SidePanelHandle>>(null);

        const handleOpen = () => {
            setExpanded(true);
            return new Promise((resolve) => setTimeout(resolve, expandAnimationTime));
        };

        const handleClose = async () => {
            await getSubPanelRef().current?.close();
            await (onClose && onClose());
            setExpanded(false);
            return new Promise((resolve) => setTimeout(resolve, expandAnimationTime));
        };

        const handleAnimatingValue = () => {
            setAnimating(true);
            setTimeout(() => {
                setAnimating(false);
            }, expandAnimationTime);
        };

        const openSubPanel = async (ref: React.RefObject<SidePanelHandle>) => {
            // Set our subpanel ref if it's missing or new.
            if (getSubPanelRef().current !== ref.current || !getSubPanelRef().current) {
                // If we're opening a new subpanel, first close the last one.
                await getSubPanelRef().current?.close();
                // getSubPanelRef() = ref;
                setSubPanelRef(ref);
            }

            ref.current?.setOffset(panelOffset + width);
            await ref.current?.open();
        };

        // Typescript thinks this is invalid because we're combining an imperative handle
        // and regular forwarded ref, so this is a rare valid ts-ignore.
        // @ts-ignore
        useImperativeHandle(ref, () => {
            return {
                id: panelId, // Expected for HTMLDivRef
                getIsOpen() {
                    return expanded;
                },
                async open() {
                    handleAnimatingValue();

                    await handleOpen();
                },
                async close() {
                    handleAnimatingValue();

                    await getSubPanelRef().current?.close();
                    await handleClose();
                },
                async toggle() {
                    if (expanded) {
                        await handleClose();
                    } else {
                        await handleOpen();
                    }
                },
                setOffset(offset: number) {
                    setPanelOffset(offset);
                },
                async openSub(ref: React.RefObject<SidePanelHandle>) {
                    await openSubPanel(ref);
                },
                async toggleSub(ref: React.RefObject<SidePanelHandle>) {
                    if (getSubPanelRef().current?.getIsOpen()) {
                        await getSubPanelRef().current?.close();
                    } else {
                        await openSubPanel(ref);
                    }
                },
            };
        });

        return (
            <Box style={{ position: 'relative' }}>
                <Box
                    ref={ref as React.Ref<HTMLDivElement>}
                    id={panelId}
                    style={{
                        position: 'absolute',
                        left: `${panelOffset}px`,
                        height: '100%',
                        marginRight: '-33px',
                        width: `${width + collapseButtonWidth}px`,
                        overflow: expanded && !animating ? 'visible' : 'hidden',
                    }}
                >
                    <Box
                        style={{
                            position: 'relative',
                            height: '100%',
                            transition: `left ${expandAnimationTime}ms`,
                            left: expanded ? '0px' : `-${width}px`,
                            width: 'fit-content',
                        }}
                    >
                        <Box
                            style={{
                                width: `${width}px`,
                                backgroundColor: theme.colors.white0a,
                                color: theme.colors.black,
                                position: 'relative',
                                zIndex: GlobalZIndex.Overlay,
                                height: '100%',
                                borderRight: `1px solid ${theme.colors.black600}`,
                                ...style,
                            }}
                            col
                        >
                            <Box p={16} style={{ overflow: hideOverflow ? 'hidden' : 'inherit' }} col grow>
                                {children}
                            </Box>
                        </Box>

                        {canShowExpandButton && (
                            <Box
                                style={{
                                    float: 'right',
                                    zIndex: 100000,
                                    width: `${width}px`,
                                    pointerEvents: 'none',
                                }}
                            >
                                <Button
                                    style={{
                                        borderRadius: '0px 0px 10px 0px',
                                        borderLeft: `1px solid ${theme.colors.lightGray}`,
                                        height: '50px',
                                        padding: '12px',
                                        opacity: '.95',
                                        pointerEvents: 'all',
                                    }}
                                    onClick={async () => {
                                        if (expanded) {
                                            await handleClose();
                                        } else {
                                            await handleOpen();
                                        }
                                    }}
                                    inverted
                                >
                                    <OSKIcon code={expanded ? 'arrow-left' : 'arrow-right'} w={8} />
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        );
    },
);

export { SidePanel, DEFAULT_SIDEPANEL_WIDTH };
