import React from 'react';
import { Box, Button, OSKIcon, Text, Tooltip } from 'oskcomponents';
import { RootState } from '~/redux/store';
import { noop } from 'oskcore';
import { connect } from 'react-redux';
import { getVisibleItems } from '~/redux/modules/data/cart';

type ArchiveSearchCheckoutButtonProps = {
    /** A method that's called when the checkout button is clicked. */
    onClick?: () => void;
    /** From redux, the number of items in the cart. */
    visibleCartItems?: number;
};

const ArchiveSearchCheckoutButton = ({ onClick, visibleCartItems }: ArchiveSearchCheckoutButtonProps) => {
    return (
        <Tooltip
            position="top"
            label={visibleCartItems === 0 ? 'Please make a selection before checking out.' : ''}
            style={{ width: '100%' }}
        >
            <Button
                variant="action"
                style={{ width: '100%', height: '40px' }}
                onClick={() => {
                    onClick && onClick();
                }}
                disabled={visibleCartItems === 0}
            >
                <Box row center="all">
                    <OSKIcon code="shopping-cart" style={{ paddingRight: '8px' }} />
                    <Text strong>Checkout</Text>
                </Box>
            </Button>
        </Tooltip>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        visibleCartItems: getVisibleItems(state).length,
    };
};

export default connect(mapStateToProps, noop)(ArchiveSearchCheckoutButton);
