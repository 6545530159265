import { Box, OSKIcon, OSKThemeType } from 'oskcomponents';
import { OSKLogoSolid } from 'oskcomponents/src/assets/OSKLogo';
import { ActiveApp, useCurrentApp } from 'oskcore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

export const ClickableLogo = () => {
    const navigate = useNavigate();
    const theme = useTheme() as OSKThemeType;
    const currentApp = useCurrentApp();
    const redirectMap: Record<ActiveApp, () => void> = {
        monitor: () => navigate('/monitor'),
        tasking: () => navigate('/tasking'),
        data: () => navigate('/map'),
        error: () => navigate('/'),
        sigma: () => navigate('/'),
        tools: () => navigate('/tools/tasking'),
    };

    return (
        <Box
            pt={15}
            pb={15}
            onClick={() => {
                redirectMap[currentApp]();
            }}
        >
            <OSKIcon svg={OSKLogoSolid} fill={theme.colors.primary.fg} height={30} width={50} />
        </Box>
    );
};
