import { OSKIcon, OSKIconType, OSKThemeType } from 'oskcomponents';
import { DetectionFeedbackStatusEnum } from 'oskcore';
import React from 'react';
import { useTheme } from 'styled-components';

export type FindingReviewIconProps = {
    /** The status to use for selecting the icon and color */
    status: DetectionFeedbackStatusEnum;
};

type FindingReviewStatusInfo = {
    /** Icon code */
    code: OSKIconType;
    /** Icon fill color */
    color: string;
};

export const FindingReviewIcon = ({ status }: FindingReviewIconProps) => {
    const theme = useTheme() as OSKThemeType;

    const FindingReviewMap: Record<DetectionFeedbackStatusEnum, FindingReviewStatusInfo> = {
        [DetectionFeedbackStatusEnum.Unreviewed]: { code: 'info', color: theme.colors.white },
        [DetectionFeedbackStatusEnum.Unresolved]: { code: 'check-circle', color: theme.colors.yellow50 },
        [DetectionFeedbackStatusEnum.ResolvedAccepted]: { code: 'check-circle', color: theme.colors.green500 },
        [DetectionFeedbackStatusEnum.ResolvedRejected]: { code: 'check-circle', color: theme.colors.red },
    };

    const info: FindingReviewStatusInfo = status
        ? FindingReviewMap[status]
        : {
              code: 'triangle',
              color: 'white',
          };

    if (info) {
        return <OSKIcon w={15} code={info.code} fill={info.color} />;
    } else {
        console.error(`${status} is not a valid Review Status`);
        return <OSKIcon w={15} code="info" fill="white" />;
    }
};
