/** A global list to keep track of large or negative zIndex values 
    Inherited values from packages/OSKComponents/src/constants.tsx:
    GradientBar: -1,
    ShadowImage: -1,
    Tooltip: 100,
    Overlay: 999,
    ViewPanelResizeTarget: 1000,
    DatePicker: 9001,
    ShadeShadow: 99999998,
    ShadeContent: 99999999,
 */
const GlobalZIndex = {
    SearchResultPanel: 400,
    Timeline: 400,
    MapSection: 401,
    SearchPanel: 403,
    OrderComplete: 999,
    LeafletEditingTools: 1000,
    Cart: 1001,
    Downloads: 1001,
    CartWizardPopup: 200000,
    ActionBar: 10000000,
    HeaderPopup: 10000001,
};

const DEFAULT_MAX_OFF_NADIR_ANGLE = 15; // 15 degrees is the maximum angle we currently support

type ArtifactDisplayInfo = {
    /** The field to access for the value */
    field: string;
    /** The text to display in the table header */
    label: string;
    /** The friendly name to display for a tooltip */
    name: string;
};

const ARTIFACT_COLUMNS: Array<ArtifactDisplayInfo> = [
    { field: 'oil_map', label: 'OIL', name: 'Oil' },
    { field: 'methane_map', label: 'CH4', name: 'Methane' },
    { field: 'envi', label: 'ENVI', name: 'ENVI' },
    { field: 'darksub', label: 'D-SUB', name: 'Darksub' },
];

export { GlobalZIndex, DEFAULT_MAX_OFF_NADIR_ANGLE, ARTIFACT_COLUMNS };
export type { ArtifactDisplayInfo };
