import React from 'react';
import { Box } from 'oskcomponents';
import { AppContext } from '../../templates/OSKAppProvider';

type LoggedInViewProps = {
    children?: React.ReactNode;
};

const LoggedInView = ({ children }: LoggedInViewProps) => {
    return <nav style={{ display: 'flex', alignItems: 'center', height: '48px' }}>{children}</nav>;
};

const DefaultView = () => (
    <nav style={{ display: 'flex', height: '48px' }}>
        <Box p={15}>&nbsp;</Box>
    </nav>
);

const OSKNav = React.forwardRef<HTMLDivElement, any>(({}, ref) => {
    return (
        <AppContext.Consumer>
            {({ isLoggedIn }) => (
                <Box style={{ alignContent: 'center' }} grow>
                    <Box grow />
                    {isLoggedIn && (
                        <LoggedInView>
                            <Box ref={ref} />
                        </LoggedInView>
                    )}
                    {!isLoggedIn && <DefaultView />}
                </Box>
            )}
        </AppContext.Consumer>
    );
});

export { OSKNav };
