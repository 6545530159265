import { debounce } from 'lodash';
import { Box, OSKIcon, Pagination, Spinner, TextInput, Typography, useRefState } from 'oskcomponents';
import {
    InternalCapture,
    InternalCaptureTask,
    PaginatedInternalCaptureList,
    PipelineAPI,
    RasterArtifact,
} from 'oskcore';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ToolsTaskSearchTable, { ToolsTaskSearchTableExpandMode } from '~/organisms/tables/ToolsTaskSearchTable';
import CapturesLightbox from './CapturesLightbox';
import { OSKContentView } from '~/molecules/OSKContentView';
import { AxiosResponse } from 'axios';
import { SHORT_VISIBLE_PAGE_SIZE } from '~/pagination';
import { useToggles } from '~/hooks/useToggles';
import { ensureUUIDFormat } from '~/utils';

type InternalRasterCapture = InternalCapture & { rasters: RasterArtifact[] };
type CaptureMap = { [id: string]: InternalRasterCapture[] };
type TaskingCollectionData = {
    /** The total number of captures available to return from the endpoint */
    total: number;
    /** The captures returned for the currently-visible page */
    tasks: InternalCaptureTask[];
};

const ToolsToolsTaskSearchView = () => {
    const navigate = useNavigate();
    const toggles = useToggles();
    const is_admin = toggles.router.isFeatureSet('osk_admin');

    const searchParams = new URLSearchParams(window.location.search);
    const taskId = searchParams.get('taskId') || searchParams.get('taskid');
    let defaultGridMode: ToolsTaskSearchTableExpandMode | undefined = searchParams.get(
        'mode',
    ) as ToolsTaskSearchTableExpandMode;
    if (!['list', 'grid'].includes(defaultGridMode)) {
        defaultGridMode = undefined;
    }

    const [taskPage, setTaskPage] = useState<TaskingCollectionData>({} as TaskingCollectionData);
    const [getTaskCaptureMap, setTaskCaptureMap] = useRefState<CaptureMap>({});
    const [taskPageNumber, setTaskPageNumber] = useState<number>(0);
    const [getListGridToggle, setListGridToggle] = useRefState<ToolsTaskSearchTableExpandMode>(
        defaultGridMode ?? 'list',
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [taskQuery, setTaskQuery] = useState<string>(taskId?.trim() ?? '');
    const [focusedCapture, setFocusedCapture] = useState<InternalRasterCapture | undefined>();
    const [getLoadCaptureQueue, setLoadCaptureQueue] = useRefState<
        Promise<AxiosResponse<PaginatedInternalCaptureList, any>>[]
    >([]);

    useEffect(() => {
        setIsLoading(true);

        PipelineAPI.internalListCaptureTasks({
            limit: SHORT_VISIBLE_PAGE_SIZE,
            offset: taskPageNumber * SHORT_VISIBLE_PAGE_SIZE,
            taskId: [taskQuery],
        })
            .then((response) => {
                const { count, results } = response.data;

                const pageData: TaskingCollectionData = {
                    total: count ?? 0,
                    tasks: results ?? [],
                };

                if (count) {
                    setTaskPage(pageData);
                } else {
                    setTaskPage({} as TaskingCollectionData);
                }

                if (taskQuery) {
                    MakeTaskCapturesRequest([taskQuery], true);
                } else {
                    // If we're just loading the task list, we're done now.
                    setIsLoading(false);
                }
            })
            .catch(() => setIsLoading(false));
    }, [taskQuery, taskPageNumber]);

    useEffect(() => {
        updateTaskQuery(taskQuery);
    }, []);

    const updateTaskQuery = debounce((e: string) => {
        setTaskQuery(e.trim().toLowerCase());

        updateDeeplinkUrl({ task: e.trim() });
    }, 500);

    const updateDeeplinkUrl = ({
        task = taskQuery,
        mode = getListGridToggle(),
    }: { task?: string; mode?: ToolsTaskSearchTableExpandMode } = {}) => {
        // Keep the URL updated with the current query, for deeplinking
        let url = '/tools/tasking';
        const params = [];

        if (task) {
            params.push(`taskid=${task}`);
        }

        if (mode === 'grid') {
            params.push('mode=grid');
        }

        if (params.length > 0) {
            url += '?' + params.join('&');
        }

        navigate(url, { replace: true });
    };

    const ChainProcessTaskCaptures = (
        promise: Promise<AxiosResponse<PaginatedInternalCaptureList, any>>,
    ): Promise<void> => {
        return new Promise<void>((resolve) => {
            ProcessTaskCapture(promise).then(() => {
                if (getLoadCaptureQueue().length > 0) {
                    // Done processing capture, remove ourselves from the queue
                    const filteredCaptureQueue = getLoadCaptureQueue().filter((p) => p !== promise);
                    setLoadCaptureQueue(filteredCaptureQueue);

                    // Run the next one in the queue if there are still any
                    if (filteredCaptureQueue.length > 0) {
                        ChainProcessTaskCaptures(getLoadCaptureQueue()[0]);
                    }
                } else {
                    resolve();
                }
            });
        });
    };

    /** Generates a request to the internalListCaptures endpoint and
        places it in a queue if necessary.*/
    const MakeTaskCapturesRequest = (tasks: string[], replace = false): Promise<void> => {
        return new Promise<void>((resolve) => {
            tasks.forEach((taskId) => {
                const newCapturePromise = PipelineAPI.internalListCaptures({
                    taskId: [taskId],
                    limit: 10000,
                });

                // If we're replacing everything then don't chain
                if (replace) {
                    ProcessTaskCapture(newCapturePromise, true).then(resolve);
                }

                // Otherwise, if there are no other requests pending,
                // start processing the chain
                else if (getLoadCaptureQueue().length === 0) {
                    ChainProcessTaskCaptures(newCapturePromise).then(() => {
                        resolve();
                    });
                }

                setLoadCaptureQueue([...getLoadCaptureQueue(), newCapturePromise]);
            });
        });
    };

    /** Processes the internalListCapture promise and triggers the next in the queue if applicable. */
    const ProcessTaskCapture = (
        capturePromise: Promise<AxiosResponse<PaginatedInternalCaptureList, any>>,
        replace = false,
    ): Promise<any> => {
        return new Promise<void>((resolveTaskCaptures) => {
            const captureMap: CaptureMap = {};
            capturePromise.then((response) => {
                const captures: InternalRasterCapture[] | undefined = response.data.results as InternalRasterCapture[];

                // Place captures in buckets by task_id
                captures?.forEach((capture, idx) => {
                    // First-time bucket setup for unencountered task_id
                    const { task_id } = capture;
                    if (!(task_id in captureMap)) {
                        captureMap[task_id] = [];
                    }

                    const existingCaptureIdx = captureMap[task_id].findIndex((c) => c.file_id === capture.file_id);
                    const existingCapture = captureMap[task_id][existingCaptureIdx];

                    // De-dupe captures, keeping the most recently-acquired copy
                    if (existingCapture) {
                        if (new Date(existingCapture.processed_date) < new Date(capture.processed_date)) {
                            captureMap[task_id][existingCaptureIdx] = capture;
                        }
                    } else {
                        // If no dupe, just put it in the task_id bucket
                        captureMap[task_id].push(capture);
                    }

                    // After we've de-duped the captures, retrieve the rasters
                    if (idx === captures.length - 1) {
                        let finishedCaptures = 0;
                        captures.forEach((capture) => {
                            PipelineAPI.internalListRasters({ capture: capture.id })
                                .then((response) => {
                                    if (response.data?.results) {
                                        (capture as InternalRasterCapture).rasters = response.data.results;
                                    }
                                })
                                .finally(() => {
                                    finishedCaptures++;

                                    // If we're the last capture, we're done loading.
                                    if (finishedCaptures === captures.length) {
                                        if (replace) {
                                            setTaskCaptureMap(captureMap);
                                        } else {
                                            setTaskCaptureMap({ ...captureMap, ...getTaskCaptureMap() });
                                        }

                                        setIsLoading(false);

                                        // Wait 10ms to avoid race condition between resolving
                                        // and setting task capture map.
                                        setInterval(() => resolveTaskCaptures(), 10);
                                    }
                                });
                        });
                    }
                });

                // If no captures were returned, finish loading and set an empty object.
                if (captures.length === 0) {
                    setIsLoading(false);

                    resolveTaskCaptures();
                }
            });
        });
    };

    const getData = useCallback(
        () =>
            taskPage?.tasks?.map((task) => ({
                ...task,
                captures: getTaskCaptureMap()[task.task_id] ?? undefined,
            })),
        [taskPage, getTaskCaptureMap()],
    );

    // Only admins can access the required internal apis,
    // let them know they need this permission if they don't have it
    if (!is_admin) {
        return (
            <OSKContentView>
                <FullscreenTaskContainer>
                    <Box style={{ width: '100%', height: '100%' }} center="all" col>
                        <OSKIcon code="generic-error" height={300} mb={-30} />
                        <Typography variant="heading2">Insufficient Permission</Typography>
                        <Typography
                            variant="body2"
                            style={{ marginTop: '8px', width: '400px', textAlign: 'center', lineHeight: '1.5' }}
                        >
                            Your account needs to be flagged as &apos;staff&apos; in order to access this utility. Post
                            in
                            <a
                                href="https://orbital-sidekick.slack.com/archives/C05R7GYJUUW"
                                target="_blank"
                                rel="noreferrer"
                            >
                                &nbsp;#sigma-feedback&nbsp;
                            </a>
                            on the OSK Slack to request access.
                        </Typography>
                    </Box>
                </FullscreenTaskContainer>
            </OSKContentView>
        );
    }

    return (
        <OSKContentView>
            {focusedCapture && (
                <Box
                    style={{
                        position: 'absolute',
                        width: 'calc(100% - 73px)',
                        left: '73px',
                        height: 'calc(100% - 60px)',
                        top: '60px',
                    }}
                >
                    <CapturesLightbox
                        capture={focusedCapture}
                        onClose={() => {
                            setFocusedCapture(undefined);
                        }}
                    />
                </Box>
            )}
            <FullscreenTaskContainer>
                <Box style={{ width: '100%', justifyContent: 'space-between' }} center="vertical">
                    <TextInput
                        name="id-query"
                        variant="contrast"
                        placeholder="Search Task ID"
                        value={taskQuery}
                        icon={<OSKIcon code="search" />}
                        style={{ margin: '12px 0px', width: '500px', height: '30px', resize: 'none' }}
                        onChange={(e) => {
                            // @ts-ignore
                            if ('inputType' in e.nativeEvent && e.nativeEvent.inputType === 'insertFromPaste') {
                                updateTaskQuery(ensureUUIDFormat(e.target.value));
                            } else {
                                updateTaskQuery(e.target.value);
                            }
                        }}
                    />
                </Box>
                {isLoading ? (
                    <Box center="all" style={{ width: '100%', height: '200px' }}>
                        <Spinner variant="Box" size="Large" />
                    </Box>
                ) : (
                    <Box col>
                        <ToolsTaskSearchTable
                            data={getData()}
                            expandMode={getListGridToggle()}
                            onRowExpand={(row, idx, expanded) => {
                                if (expanded) {
                                    const taskid = getData()[idx].task_id;
                                    MakeTaskCapturesRequest([taskid]);
                                }
                            }}
                            onModeChange={(mode) => {
                                setListGridToggle(mode);
                                updateDeeplinkUrl({ mode });
                            }}
                            onImageSelect={(capture) => {
                                setFocusedCapture(capture);
                            }}
                        />
                    </Box>
                )}
                {getData() && getData().length > 1 && (
                    <Pagination
                        inverted
                        offset={taskPageNumber * SHORT_VISIBLE_PAGE_SIZE}
                        pageSize={SHORT_VISIBLE_PAGE_SIZE}
                        count={taskPage.total}
                        onChange={(pageNumber) => {
                            setTaskPageNumber(pageNumber);
                        }}
                    />
                )}
            </FullscreenTaskContainer>
        </OSKContentView>
    );
};

const FullscreenTaskContainer = ({ children }: any) => {
    return (
        <Box style={{ width: '100%', height: '100%' }}>
            <Box
                p={32}
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    overflowY: 'scroll',
                }}
                col
                grow
            >
                <Typography variant="heading2">Task Explorer</Typography>
                {children}
            </Box>
        </Box>
    );
};

export default ToolsToolsTaskSearchView;
export type { InternalRasterCapture };
