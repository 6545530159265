import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Button, Form, PageScaffold } from 'oskcomponents';
import { AssetForm, AssetSchema } from '~/organisms/forms/AssetForm';
import { AssetDetail, SigmaAPI } from 'oskcore';
import { decrementBusyCount, incrementBusyCount } from '~/redux/modules/data/app';
import { PortalContentView } from '~/atoms';
import { useEnsureUrlParameters } from '~/hooks';
import { getCachedProgramProfile, getProgramId } from '~/utils';

export type AssetDetailWithEmails = AssetDetail;
export type AssetPanelProps = {
    data?: AssetDetailWithEmails;
    className?: string;
};
export type AssetStatus = 'loading' | 'none' | 'success' | 'error';

export const AssetPanel = styled(({ data, className }: AssetPanelProps) => {
    const dispatch = useDispatch();
    useEnsureUrlParameters('/program/:programId/');

    const programId = getProgramId();
    const [status, setStatus] = useState<AssetStatus>('none');

    const handleSubmit = useCallback(
        (props: any) => {
            const assetId: number = data?.id as number;
            setStatus('loading');
            dispatch(incrementBusyCount());
            SigmaAPI.updateAsset({
                id: assetId,
                program: programId,
                assetRequest: {
                    alert_emails: props.alert_emails ? props.alert_emails.split(',') : [],
                    report_emails: props.report_emails ? props.report_emails.split(',') : [],
                },
            }).finally(() => {
                setStatus('none');
                dispatch(decrementBusyCount());
            });
        },
        [data, programId, dispatch],
    );

    const programProfile = getCachedProgramProfile();
    const isAdmin =
        (programProfile && programProfile.roles && programProfile.roles.includes('Program Administrator')) ?? false;

    return (
        <PortalContentView>
            <PageScaffold title={<span>{data?.name} Details</span>}>
                <Form
                    className={className}
                    schema={AssetSchema}
                    onSubmit={(formData) => {
                        handleSubmit(formData);
                    }}
                >
                    <Box grow col pb={25}>
                        <AssetForm isAdmin={isAdmin} data={data} readonly={false} />
                        <Box className="submit-container">
                            <Button
                                disabled={status === 'loading' || !isAdmin}
                                className="button"
                                variant="neutral"
                                label="Update"
                                style={{
                                    alignSelf: 'flex-end',
                                }}
                                inverted
                                submit
                            />
                        </Box>
                    </Box>
                </Form>
            </PageScaffold>
        </PortalContentView>
    );
})`
    & .submit-container {
        justify-content: flex-end;
    }

    & .form-container {
        width: 500px;
        padding-bottom: 150px;
    }
`;
