import React from 'react';
import PromptWhenLeaving from '../PromptWhenLeaving';
import { connect, useDispatch } from 'react-redux';
import { noop } from 'oskcore';
import { CartWizardFlow, emptyCart, setWizardFlow } from '~/redux/modules/data/cart';
import { RootState } from '~/redux/store';

type PromptWhenLeavingTaskingViewProps = {
    /** The current state of the checkout wizard */
    wizardFlow: CartWizardFlow;
};

const PromptWhenLeavingTaskingView = ({ wizardFlow }: PromptWhenLeavingTaskingViewProps) => {
    const dispatch = useDispatch();
    return (
        <PromptWhenLeaving
            shouldBlock={() => wizardFlow !== 'none'}
            onProceed={() => {
                dispatch(emptyCart()); // Empty the cart
                dispatch(setWizardFlow('none')); // Make sure the wizard is closed
            }}
            title="Exit wizard?"
            body="The checkout wizard will be closed. Do you want to proceed?"
            cancelLabel="Cancel"
            proceedLabel="Close and Proceed"
        />
    );
};

function mapStateToProps(state: RootState) {
    return {
        wizardFlow: state.data.cart.wizardFlow,
    };
}

export default connect(mapStateToProps, noop)(PromptWhenLeavingTaskingView);
