import React from 'react';
import { Box, BoxProps } from '../Box';
import styled from 'styled-components';
import { range } from 'lodash';
import { OSKIcon } from '../OSKIcon';

export type PaginationProps = {
    /** How many buttons to render on screen. Defaults to 7. */
    windowSize?: number;
    /** How many items are present per page */
    pageSize?: number;
    /*** The amount of items we've already fetched */
    offset: number;
    /** The total number of items */
    count?: number;
    /** The css class to apply to the component */
    className?: string;
    /** The method to invoke when a page is selected */
    onChange?: (pageNumber: number) => void;
    /** Whether or not to invert the color scheme */
    inverted?: boolean;
} & Omit<Omit<Omit<BoxProps, 'onChange'>, 'ref'>, 'inverted'>;

export const Pagination = styled(
    ({ pageSize = 100, windowSize = 7, onChange, className, offset, count, inverted, ...props }: PaginationProps) => {
        // How many numbers to show on either side of "current page". (more than 2 looks weird).
        const seekRange = windowSize - 4;

        const totalPages = Math.ceil((count ?? 0) / pageSize);
        const currentPage = Math.floor((offset ?? 0) / pageSize);

        // The lowest number page (0-based) that we want to show
        const earlierPage = Math.max(currentPage - seekRange, 0);
        // The highest number page (0-based) that we want tos how
        const latterPage = Math.min(currentPage + seekRange + 2, totalPages);
        // At the upper bound, this lists how many numbers would be hidden
        // by the seekRange.
        const overflow = Math.max(currentPage + seekRange - totalPages, 0);
        // True if we should show the ellipse. False if the last page is already part of the range.
        const showLastPage = latterPage !== totalPages;
        // An array of numbers between the lower bound and the upper bound.
        const pages = range(Math.max(earlierPage - overflow, 0), latterPage, 1);

        function handleChange(page: number) {
            if (page >= 0 && page <= totalPages - 1) {
                onChange && onChange(page);
            }
        }

        // Given a button within the window, and current state,
        // compute what (page + 1) the button should be pointing to
        function computeButtonId(buttonIndex: number) {
            const showFirstEllipse = buttonIndex === 1 && earlierPage !== 0;
            const showSecondEllipse = buttonIndex === windowSize - 2 && showLastPage;

            if (buttonIndex === 0) {
                return 1; // First button is always present
            } else if (showFirstEllipse || showSecondEllipse) {
                return -buttonIndex; // 2nd button and 2nd-from-last will sometimes be ellipses
            } else if (buttonIndex === windowSize - 1 && showLastPage) {
                return totalPages; // Last button will show if there are ellipses
            } else {
                return pages[buttonIndex] + 1; // Show whatever the pages array says to show for any other button
            }
        }

        return (
            <Box data-testid={`pagination-component`} className={className} {...props}>
                <button data-testid="pagination-arrow-left" onClick={handleChange.bind(this, currentPage - 1)}>
                    <OSKIcon code="arrow-left" />
                </button>

                {/* Math.min() here will limit the amount of pages to either full window size or however many pages there are (if it's less than window size). */}
                {range(0, Math.min(windowSize, totalPages)).map((buttonId) => {
                    const computedId = computeButtonId(buttonId);
                    const text = isNaN(computedId) ? '   ' : computedId < 0 ? '...' : computedId.toString();

                    return (
                        <button
                            data-current={computedId === currentPage + 1}
                            data-testid={`pagination-item-${text}`}
                            key={`pagination-page-${buttonId}`}
                            onClick={handleChange.bind(this, computedId - 1)}
                            style={{ cursor: text === '...' ? 'default' : 'pointer' }}
                            className="page-item"
                        >
                            {text}
                        </button>
                    );
                })}
                <button data-testid="pagination-arrow-right" onClick={handleChange.bind(this, currentPage + 1)}>
                    <OSKIcon code="arrow-right" />
                </button>
            </Box>
        );
    },
)`
    & {
        border-radius: 15px;
        background-color: ${(props: any) =>
            props.inverted ? props.theme.colors.primary.fg : props.theme.colors.primary.bg};
        color: ${(props: any) => (props.inverted ? props.theme.colors.primary.bg : props.theme.colors.primary.fg)};
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0px;
    }

    button {
        background: none;
        outline: none;
        border: none;
        color: ${(props: any) => (props.inverted ? props.theme.colors.primary.bg : props.theme.colors.primary.fg)};
        font-size: 2rem;
        text-align: middle;
        border-radius: 5px;
        padding: 3px 10px;
        margin: 0px 4px;
        cursor: pointer;
        min-width: 40px;
    }

    & .page-item[data-current='true'] {
        background-color: ${(props: any) => props.theme.colors.accent};
    }

    .page-item {
        font-size: 1rem;
    }
`;
