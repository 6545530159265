import { Box } from 'oskcomponents';
import React from 'react';
import { ClickableLogo } from '~/atoms';
import { GlobalZIndex } from '~/constants';

export const FloatingLogo = () => {
    return (
        <Box style={{ position: 'absolute', zIndex: GlobalZIndex.HeaderPopup, top: 0, left: '12px' }}>
            <ClickableLogo />
        </Box>
    );
};
