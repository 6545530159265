import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';
import { omit } from 'lodash';
import { GlobalZIndex } from '../constants';
import CSS from 'csstype';

type TooltipProps = {
    position?: 'top' | 'bottom' | 'left' | 'right';
    children: React.ReactElement | string;
    label?: JSX.Element | string;
    disabled?: boolean;
    style?: CSS.Properties;
} & BoxProps;

const CoreTooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
    ({ className, position, children, label, disabled = false, style, ...props }: TooltipProps, ref) => {
        const childRef = useRef<any>(null);
        const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
        const [targetSize, setTargetSize] = useState({
            w: 0,
            h: 0,
            x: 0,
            y: 0,
        });
        const [visible, setVisible] = useState<boolean>(false);
        const mouseOverTarget = useCallback(() => {
            setIsMouseOver(true);

            if (label != undefined && label != '' && !disabled) {
                setVisible(true);
            }
        }, [label, disabled]);
        const mouseLeaveTarget = () => {
            setVisible(false);
            setIsMouseOver(false);
        };
        const calculateOffsets = () => {
            if (childRef.current) {
                const totalScroll = getTotalScrollForElement(childRef.current);

                setTargetSize({
                    w: childRef.current.offsetWidth,
                    h: childRef.current.offsetHeight,
                    x: childRef.current.offsetLeft - totalScroll.left,
                    y: childRef.current.offsetTop - totalScroll.top,
                });
            }
        };

        const getTotalScrollForElement = (element: HTMLElement) => {
            let { scrollLeft, scrollTop } = element;

            if (element.parentElement) {
                const parentScroll = getTotalScrollForElement(element.parentElement);

                scrollLeft += parentScroll.left;
                scrollTop += parentScroll.top;
            }

            return { left: scrollLeft, top: scrollTop };
        };

        useEffect(() => {
            if (!label) {
                setVisible(false);
            } else if (isMouseOver) {
                setVisible(true);
            }
        }, [label]);

        useEffect(() => {
            window.addEventListener('resize', calculateOffsets);
            window.addEventListener('scroll', calculateOffsets, true);

            return () => {
                window.removeEventListener('resize', calculateOffsets);
                window.removeEventListener('scroll', calculateOffsets, true);
            };
        }, []);

        useEffect(() => {
            calculateOffsets();

            if (childRef.current) {
                childRef.current.addEventListener('mouseenter', mouseOverTarget);
                childRef.current.addEventListener('mouseleave', mouseLeaveTarget);

                return () => {
                    childRef.current?.removeEventListener('mouseenter', mouseOverTarget);
                    childRef.current?.removeEventListener('mouseleave', mouseLeaveTarget);
                };
            }
        }, [childRef, mouseOverTarget]);

        return (
            <React.Fragment>
                <Box ref={childRef} style={style}>
                    {children}
                </Box>
                {label && (
                    <Box
                        ref={ref}
                        {...omit(props, ['ref'])}
                        className={className}
                        style={{
                            left: ((): string => {
                                switch (position) {
                                    case 'top':
                                    case 'bottom':
                                        return `${targetSize.x + targetSize.w / 2}px`;
                                    case 'left':
                                        return `${targetSize.x}px`;
                                    case 'right':
                                        return `${targetSize.x + targetSize.w}px`;
                                    default:
                                        return '';
                                }
                            })(),
                            top: ((): string => {
                                switch (position) {
                                    case 'top':
                                        return `${targetSize.y}px`;
                                    case 'bottom':
                                        return `${targetSize.y + targetSize.h}px`;
                                    case 'left':
                                    case 'right':
                                        return `${targetSize.y + targetSize.h / 2}px`;
                                    default:
                                        return '';
                                }
                            })(),
                            transform: ((): string => {
                                switch (position) {
                                    case 'top':
                                        return `translate(-50%, calc(-100% - 12px))`;
                                    case 'bottom':
                                        return `translate(-50%, 12px)`;
                                    case 'left':
                                        return `translate(calc(-100% - 14px), -50%)`;
                                    case 'right':
                                        return `translate(12px, -50%)`;
                                    default:
                                        return '';
                                }
                            })(),
                        }}
                    >
                        <div
                            className={`tooltip-container ${
                                visible && !disabled ? 'tooltip-visible' : 'tooltip-hidden'
                            }`}
                        >
                            <div className="tooltip-box">{label}</div>
                            <div className="tooltip-arrow" />
                        </div>
                    </Box>
                )}
            </React.Fragment>
        );
    },
);

const Tooltip = styled(CoreTooltip)`
    display: block;
    position: absolute;

    ${(props: any) => {
        switch (props.position) {
            case 'top':
            case 'bottom':
                return 'max-width: 250px; min-width: 100px;';
            case 'left':
            case 'right':
                return 'max-width: 400px; min-width: 300px;';
        }
    }}
    opacity: 1;
    pointer-events: none;
    z-index: ${() => GlobalZIndex.Tooltip};

    .tooltip-container {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.25s ease-in-out;
        position: relative;
        width: fit-content;
    }

    .tooltip-hidden {
        opacity: 0;
    }
    .tooltip-visible {
        opacity: 1;
    }

    .tooltip-box {
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        padding: 14px;
        border-radius: 9px;
        color: white;
        background-color: black;

        min-width: 25px;
        width: fit-content;
        height: fit-content;
        position: relative;
    }

    .tooltip-arrow {
        position: absolute;
        float: left;
        width: 20px;
        height: 20px;
        ${(props: any) => {
            switch (props.position) {
                case 'top':
                    return 'bottom: -10px;';
                case 'bottom':
                    return 'top: -10px;';
                case 'left':
                    return 'right: -10px;';
                case 'right':
                    return 'left: -10px;';
            }
        }}

        background-color: black;
        transform: rotate(45deg);
        margin-left: auto;
    }
`;

Tooltip.defaultProps = {
    position: 'top',
};

export { Tooltip };
