import React from 'react';
import { useTheme } from 'styled-components';
import { Box, OSKThemeType } from 'oskcomponents';
import { GlobalZIndex } from 'oskcomponents/src/constants';

export type NavItemProps = {
    /** The children elements to render inside the NavItem */
    children: React.ReactNode;
};

export const NavItem = ({ children }: NavItemProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                marginLeft: `${theme.navItemPadding}px`,
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                width: '45px',
                height: '100%',
                verticalAlign: 'center',
                marginRight: `${theme.navItemPadding}px`,
                zIndex: GlobalZIndex.Overlay,
            }}
        >
            {children}
        </Box>
    );
};
