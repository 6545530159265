//@ts-nocheck

import React, { useEffect, useState } from 'react';
import { getGroundTracks } from 'tle.js';
import { Polyline } from 'react-leaflet';
import { LatLng } from 'leaflet';

const orbitalPeriodColors = ['#90909090', '#ff000090', '#90909090'];

export type MapSatelliteTrackProps = {
    /** A full two-line element set */
    tle: string;
    /** The epoch time at which to begin propagation */
    startTime: number;
    /** The interpolation steps */
    stepsMs?: number;
    /** How many orbital periods to render. (Maximum of 3). */
    orbitalPeriods: 1 | 2 | 3;
    /** An optional override for the active orbit color */
    activeOrbitColor?: Color;
    /** An optional override for the inactive orbit color */
    inactiveOrbitColor?: Color;
    /** An optional dash pattern for the polyline*/
    dashPattern?: string;
};

/**
 * A component which returns an SVG of a satellite track
 * given a bounding box of gps coordinates
 */
export const MapSatelliteTrack = ({
    tle,
    startTime,
    stepsMs = 1000,
    orbitalPeriods,
    activeOrbitColor,
    inactiveOrbitColor,
    dashPattern,
}: MapSatelliteTrackProps) => {
    const [tracks, setTracks] = useState<Array<Array<LatLng>>>([[], [], []]);
    const orbitColors = orbitalPeriodColors;
    if (activeOrbitColor) orbitColors[1] = activeOrbitColor;
    if (inactiveOrbitColor) {
        orbitColors[0] = inactiveOrbitColor;
        orbitColors[1] = inactiveOrbitColor;
    }

    useEffect(() => {
        if (tle) {
            getGroundTracks({
                tle,
                startTimeMS: startTime,
                stepMS: stepsMs,
                isLngLatFormat: true,
            }).then((groundTracks) => {
                const resultTracks = [];
                for (let period = 0; period < 3; period++) {
                    resultTracks.push(
                        groundTracks[period].map((coord) => {
                            return new LatLng(coord[1], coord[0]);
                        }),
                    );
                }
                setTracks(resultTracks);
            });
        } else {
            console.error(`No TLE given for map satellite track.`);
        }
    }, [orbitalPeriods, startTime, stepsMs, tle, setTracks]);

    if (!tle) {
        return <></>;
    }

    return (
        <React.Fragment>
            {/* If the Orbital period is 1 we want to show "current" orbital period which is actually the middle track. */}
            {orbitalPeriods === 1 ? (
                <Polyline color={orbitColors[1]} weight={2} positions={tracks[1]} dashArray={dashPattern} />
            ) : (
                [0, 0, 0].map((_, idx) => {
                    if (idx < orbitalPeriods) {
                        return (
                            <Polyline
                                {...orbitTrackStyle}
                                color={orbitColors[idx]}
                                weight={3}
                                key={`orbital_period_${idx}`}
                                positions={tracks[idx]}
                                dashArray={dashPattern}
                            />
                        );
                    } else {
                        return <React.Fragment key={`orbital_period_${idx}`} />;
                    }
                })
            )}
        </React.Fragment>
    );
};
