import { Box, Button, Heading, PageScaffold, Text, TextInput } from 'oskcomponents';
import { clearDeveloperOverrides, getBaseApi, setDeveloperOverrides } from 'oskcore';
import React, { useState } from 'react';

export const OSKDeveloperTools = () => {
    const [apiOverride, setApiOverride] = useState(getBaseApi());

    return (
        <PageScaffold
            title={
                <Box center="vertical">
                    <Heading>OSK Developer Tools</Heading>
                </Box>
            }
        >
            <Box center="vertical" style={{ textAlign: 'right', justifyContent: 'flex-end', paddingBottom: '12px' }}>
                <Text>
                    Customize the API endpoint. Do not include a trailing whack (it will be automatically removed if you
                    do).
                </Text>
            </Box>
            <Box center="vertical">
                <Text style={{ flexShrink: 1, textAlign: 'right', paddingRight: '16px' }}>API</Text>
                <TextInput
                    value={apiOverride}
                    name="apiLocation"
                    style={{ width: '100%' }}
                    onChange={(evt) => {
                        setApiOverride(evt.target.value);
                    }}
                />
            </Box>
            <Box style={{ paddingTop: '20px', justifyContent: 'flex-end' }}>
                <Button
                    variant="action"
                    label="Clear Overrides"
                    onClick={() => {
                        clearDeveloperOverrides();
                        window.location.reload();
                    }}
                />
                <Button
                    variant="contrast"
                    inverted
                    label="Save"
                    style={{ marginLeft: '12px' }}
                    onClick={() => {
                        setDeveloperOverrides({
                            apiPath: apiOverride.endsWith('/')
                                ? apiOverride.substring(0, apiOverride.length - 1)
                                : apiOverride,
                        });
                        window.location.reload();
                    }}
                />
            </Box>
        </PageScaffold>
    );
};

export default OSKDeveloperTools;
