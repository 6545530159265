import React from 'react';
import { useProfileMetadata } from '../../hooks';
import { OSKError } from '../../templates/OSKError';
import { Box, Spinner, Typography } from 'oskcomponents';

type LoginViewProps = {};
const LoginView = ({}: LoginViewProps) => {
    const [user] = useProfileMetadata();

    return (
        <OSKError style={{ padding: '40px 60px' }}>
            <Box style={{ marginTop: '40px' }} col grow center="all">
                <Typography variant="heading4" style={{ marginBottom: '40px' }}>
                    {user ? `Welcome, ${user.first_name}!` : 'Loading...'}
                </Typography>
                <Spinner size="Medium" variant="Box" />
            </Box>
        </OSKError>
    );
};

export { LoginView };
