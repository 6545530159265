import React from 'react';
import { OrderStatus } from 'oskcore';
import { Box, Typography } from 'oskcomponents';
import { OrderStatusMap, selectOrderStatus } from '~/redux/modules/data/orders';

type ActivityProgressChipProps = {
    /** The order status to display a chip for */
    status?: OrderStatus;
};

const ActivityProgressChip = ({ status }: ActivityProgressChipProps) => {
    if (!status) {
        return <></>;
    }

    const { bg, fg, name } = OrderStatusMap[selectOrderStatus(status)];

    return (
        <Box
            style={{
                backgroundColor: bg,
                color: fg,
                width: 'fit-content',
                padding: '4px 10px',
                borderRadius: '4px',
            }}
        >
            <Typography variant="caption2">{name}</Typography>
        </Box>
    );
};

export { ActivityProgressChip };
export type { ActivityProgressChipProps };
