import React from 'react';
import { ActiveApp } from 'oskcore/src/hooks';
import { useParams } from 'react-router-dom';
import { OSKError } from 'oskcore/src/templates/OSKError';
import { AppData } from 'oskcore/src/atoms/CurrentApp';
import { Box } from 'oskcomponents';

type InvalidApplicationErrorViewProps = {};
const InvalidApplicationErrorView = ({}: InvalidApplicationErrorViewProps) => {
    const { targetAppName } = useParams() as any;

    return (
        <OSKError
            actions={[
                {
                    label: 'Contact Support',
                    action: () => {
                        window.location.assign('mailto:support@orbitalsidekick.com');
                    },
                },
            ]}
        >
            <Box m={24}>
                Your account does not have permission to access {AppData[targetAppName as ActiveApp].name}.
            </Box>
        </OSKError>
    );
};

export { InvalidApplicationErrorView };
