import { Box, Typography } from 'oskcomponents';
import { NavComponent, OSKView } from 'oskcore';
import React from 'react';
import ActiveModeSwitcher from '~/organisms/ActiveModeSwitcher';
import AppShimmerBar from '~/organisms/AppShimmerBar';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';

const ToolsDefaultView = () => {
    return (
        <OSKView>
            <AppShimmerBar />
            <Box style={{ flexGrow: 1 }}>
                <ActiveModeSwitcher />
                <NavComponent>
                    <SigmaNavItems />
                </NavComponent>
                <Box grow center="all" style={{ backgroundColor: 'white', color: 'black' }}>
                    <Box col>
                        <Typography variant="heading1">Welcome to SIGMA: Tools</Typography>
                    </Box>
                </Box>
            </Box>
        </OSKView>
    );
};

export default ToolsDefaultView;
