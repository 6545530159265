import {
    Box,
    Button,
    CarouselImage,
    LightboxCarousel,
    NonEmptyArray,
    OSKIcon,
    OSKThemeType,
    Text,
} from 'oskcomponents';
import { DetectionFeedbackStatusEnum, Geometry, GeometryCollection } from 'oskcore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { coord_format, date_format, getProgramId } from '~/utils';
import NoThumbnailEmptyState from '~/../public/images/no_thumbnail_empty_state.png';
import { AlertChip } from './AlertChip';
import { LeakChip } from './LeakChip';
import { DetectionTypeIcon } from './DetectionTypeIcon';
import { FindingDetectionStatus } from '../FindingCard/FindingDetectionStatus';

type FindingCardProps = {
    /** The ID of the finding */
    id: string;
    /** The ID of the associated asset for the finding */
    assetId: string | number;
    /** The finding's call type */
    callType: string;
    /** The finding's detection type */
    detectionType?: string;
    /** The name of the associated asset for the finding */
    assetName?: string;
    /** When the finding was initially detected */
    detectedAt?: Date;
    /** Is this finding an alert? */
    isAlert: boolean;
    /** A collection of associated images for the finding. */
    images: CarouselImage[];
    /** The coordinates of the finding. */
    coordinates: Geometry | GeometryCollection;
    /** The state of the finding's review. */
    reviewStatus: DetectionFeedbackStatusEnum;
    /** An optional note that can accompany the finding's review. */
    reviewText?: string;
    /** A method that's called when the review status label is clicked. */
    onReviewStatusClick?: () => void;
};

const FindingCard = ({
    id,
    assetId,
    images,
    detectedAt,
    assetName,
    callType,
    isAlert,
    detectionType,
    coordinates,
    reviewStatus,
    reviewText,
    onReviewStatusClick,
}: FindingCardProps) => {
    const theme = useTheme() as OSKThemeType;
    const navigate = useNavigate();

    const coords = (coordinates as Geometry).coordinates;
    const coordText = `${coords[1]}, ${coords[0]}`;
    const visualCoordText = coord_format(coords);
    const [coordMessage, setCoordMessage] = useState(visualCoordText);

    const copyCoords = () => {
        navigator.clipboard.writeText(coordText);
        setCoordMessage('Copied');
        setTimeout(() => {
            setCoordMessage(visualCoordText);
        }, 3000);
    };

    return (
        <Box
            w={250}
            style={{
                backgroundColor: theme.colors.primary.bg,
                marginBottom: '20px',
                borderRadius: '9px',
                borderTopRightRadius: '10px',
                borderTopLeftRadius: '10px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
            col
        >
            <Box p={10} mb={4} style={{ justifyContent: 'center' }} col>
                {detectedAt && (
                    <Text color={theme.colors.primary.accent} variant="small">
                        {date_format(detectedAt)}
                    </Text>
                )}
                <Text pt={5} strong>
                    {id}
                </Text>
                <Text variant="small" color={theme.colors.neutral.bg} pt={5}>
                    {assetName}
                </Text>
            </Box>

            {images.length > 0 ? (
                <LightboxCarousel images={images as NonEmptyArray<CarouselImage>} width={250} height={150} />
            ) : (
                <Box
                    w={250}
                    h={150}
                    fs={'1.15rem'}
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTopRightRadius: '10px',
                        borderTopLeftRadius: '10px',
                    }}
                    bg={theme.colors.disabled.bg}
                    fg={theme.colors.disabled.fg}
                    col
                >
                    <img
                        alt="missing thumbnails"
                        src={NoThumbnailEmptyState}
                        width={100}
                        style={{ marginBottom: '10px' }}
                    />
                    <span>Missing Thumbnails</span>
                </Box>
            )}
            <Box>
                <AlertChip type={isAlert ? 'alert' : 'detection'} />
                {callType === 'Leak Indicator' && <LeakChip />}
            </Box>
            <Box pl={15} pr={15} pt={4} pb={4} col>
                <FindingDetectionStatus
                    status={reviewStatus}
                    label={reviewText}
                    onClick={() => onReviewStatusClick && onReviewStatusClick()}
                />
                <Box row center="vertical" onClick={copyCoords}>
                    <Text variant="small" pt={5}>
                        <Box row center="vertical">
                            <strong style={{ paddingRight: '8px' }}>
                                <OSKIcon code="gps" />
                            </strong>
                            <u>{coordMessage}</u>
                            <OSKIcon code="copy" style={{ paddingLeft: '8px' }} />
                        </Box>
                    </Text>
                </Box>
                <Text variant="small" pt={5}>
                    <Box row center="vertical" h={32}>
                        <strong style={{ paddingRight: '8px' }}>
                            <DetectionTypeIcon detectionType={detectionType} />
                        </strong>
                        {detectionType}
                    </Box>
                </Text>
            </Box>
            <Box grow />
            <Box center="all" pb={15} mb={4}>
                <Button
                    p={0}
                    style={{ marginTop: '14px', width: '100%', marginRight: '15px', marginLeft: '15px' }}
                    hollow
                    variant="primary"
                    onClick={() => {
                        navigate(`/program/${getProgramId()}/monitor/${assetId}/map/alert/${id}`);
                    }}
                >
                    <Box style={{ justifyContent: 'center', alignItems: 'center' }} p={10}>
                        <OSKIcon
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/program/${getProgramId()}/monitor/${assetId}/map/alert/${id}`);
                            }}
                            code="map"
                            pr={10}
                        />
                        View on Map
                    </Box>
                </Button>
            </Box>
        </Box>
    );
};

export { FindingCard };
