import { MapOf, noop, AssetDetail } from 'oskcore';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEnsureUrlParameters } from '~/hooks';
import { OSKContentView } from '~/molecules/OSKContentView';
import ValidAssetDetailMapView from '~/organisms/Monitor/AssetDetailMap/ValidAssetDetailMapView';
import { MonitorResourceProvider } from '~/redux';
import { setPanelTabIdx } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';

type MonitorMapViewProps = {
    assets?: MapOf<AssetDetail>;
    /** Which tab is currently active. This affects whether alerts or detections are visible. */
    panelTabIdx: number;
};

const MonitorMapView = ({ assets, panelTabIdx }: MonitorMapViewProps) => {
    const { assetId, alertId, detectionId } = useParams();
    const dispatch = useDispatch();

    const id = alertId ?? detectionId;

    useEffect(() => {
        if (id && panelTabIdx !== 0) {
            dispatch(setPanelTabIdx(0));
        }
    }, [alertId, detectionId, dispatch, panelTabIdx]);

    useEnsureUrlParameters('/program/:programId/monitor');

    return (
        <MonitorResourceProvider
            programId={getProgramId()}
            assetId={assetId}
            features={['assets', 'asset', 'alerts', 'detections']}
        >
            <OSKContentView>
                {assets && assetId && assets[assetId] && (
                    <ValidAssetDetailMapView selectedAssetId={parseInt(`${assetId ?? -1}`)} selectedDetectionId={id} />
                )}
            </OSKContentView>
        </MonitorResourceProvider>
    );
};

export { MonitorMapView };
const mapStateToProps = (state: RootState) => {
    return {
        assets: state.monitor.app.assets as MapOf<AssetDetail>,
        panelTabIdx: state.monitor.app.panelTabIdx,
    };
};
export default connect(mapStateToProps, noop)(MonitorMapView);
