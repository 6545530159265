/** Represents a map of events for a given event name.
 * Used to keep track of event methods that have subscribed to the event name.*/
type gdux_ListenerMap = {
    [id: string]: gdux_Function[];
};

/** Represents a method with generic argument to be called as a subscriber. */
type gdux_Function = (e: any) => void;

/** An event that's triggered when a fileid should be highlighted. */
const GDUX_HIGHLIGHT_ADD = 'ON_HIGHLIGHT_ADD';
/** An event that's triggered when a fileid should be unhighlighted. */
const GDUX_HIGHLIGHT_REMOVE = 'ON_HIGHLIGHT_REMOVE';
/** An event that's triggered when a fileid should be selected. */
const GDUX_SELECT_ADD = 'ON_SELECT_ADD';
/** An event that's triggered when a fileid should be unselected. */
const GDUX_SELECT_REMOVE = 'ON_SELECT_REMOVE';

/** A global router that's used to keep track of subscribers for different events. */
class gdux_Router {
    private eventListeners: gdux_ListenerMap;

    constructor() {
        this.eventListeners = {} as gdux_ListenerMap;
    }

    /** Subscribe function `f` to `eventName`.
     * Usage:
     * `const f = (e: any) => { ... };
     *  gdux.subscribe(eventName, f);`
     *
     * Now e will be triggered when `gdux.trigger(eventName)` is called. */
    subscribe(eventName: string, f: gdux_Function) {
        if (!this.eventListeners[eventName]) {
            this.eventListeners[eventName] = [f];
            return;
        }
        this.eventListeners[eventName].push(f);
    }

    /** Unsubscribe function `f` from `eventName` so it's no longer triggered. */
    unsubscribe(eventName: string, f: gdux_Function) {
        if (eventName in this.eventListeners) {
            this.eventListeners[eventName] = this.eventListeners[eventName].filter((e) => e !== f);
        }
    }

    /** Trigger all functions subscribed to `eventName`, and pass them the given `context` data. */
    trigger(eventName: string, context: any) {
        const listeners = this.eventListeners[eventName];
        listeners && Array.isArray(listeners) && listeners.forEach((listener) => listener.call(null, context));
    }
}

const gdux = new gdux_Router();

export default gdux;
export { GDUX_HIGHLIGHT_ADD, GDUX_HIGHLIGHT_REMOVE, GDUX_SELECT_ADD, GDUX_SELECT_REMOVE };
export type { gdux_ListenerMap, gdux_Function };
