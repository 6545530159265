import React from 'react';
import ProgramFindingsGrid from '~/organisms/ProgramFindingsGrid';
import { OSKContentView } from '~/molecules/OSKContentView';
import { useEnsureUrlParameters } from '~/hooks';
import { connect } from 'react-redux';
import { noop } from 'oskcore';
import { MonitorResourceProvider } from '~/redux';
import { getProgramId } from '~/utils';

const ProgramFindingsView = () => {
    useEnsureUrlParameters('/program/:programId/monitor/findings');

    return (
        <MonitorResourceProvider programId={getProgramId()} features={['assets']}>
            <OSKContentView>
                <ProgramFindingsGrid />
            </OSKContentView>
        </MonitorResourceProvider>
    );
};

export default connect(noop, noop)(ProgramFindingsView);
