import { createSelector } from '@reduxjs/toolkit';
import { reduce, some, filter, values } from 'lodash';
import { Capture, DownloadIntent, DownloadStatus } from 'oskcore';
import { AppDispatch, RootState } from '../../store';

export type CartWizardFlow = 'none' | 'search' | 'tasking';

export type CartGroupIcon = 'all' | 'some' | 'none';
export type EnqueueFileEvent = {
    /** The associated DownloadIntent to store in redux */
    intent: DownloadIntent;
    /** The associated Capture item to store in redux  */
    capture: Capture;
};

export type DownloadCollectGroup = Record<string, Array<DownloadIntent | undefined>>;

const ENQUEUE_FILE = 'ENQUEUE_FILE';
export function enqueueFile(request: DownloadIntent, item: Capture) {
    return {
        type: ENQUEUE_FILE,
        payload: {
            request,
            item,
        },
    };
}

const ENQUEUE_FILES = 'ENQUEUE_FILES';
export function enqueueFiles(events: EnqueueFileEvent[]) {
    return {
        type: ENQUEUE_FILES,
        payload: events,
    };
}

const DEQUEUE_FILE = 'DEQUEUE_FILE';
export function dequeueFile(fileId: string) {
    return {
        type: DEQUEUE_FILE,
        payload: {
            fileId,
        },
    };
}

const DEQUEUE_FILES = 'DEQUEUE_FILES';
export function dequeueFiles(fileIds: string[]) {
    return {
        type: DEQUEUE_FILES,
        payload: {
            fileIds,
        },
    };
}

const TOGGLE_FILE_INTENT = 'TOGGLE_FILE_INTENT';
export function toggleFileIntent(fileId: string) {
    return {
        type: TOGGLE_FILE_INTENT,
        payload: {
            fileId,
        },
    };
}

const SET_FILE_INTENT = 'SET_FILE_INTENT';
export function setFileIntent(fileId: string, skip: boolean) {
    return {
        type: SET_FILE_INTENT,
        payload: {
            fileId,
            skip,
        },
    };
}

const SET_WIZARD_FLOW = 'SET_WIZARD_FLOW';
export function setWizardFlow(wizardFlow: CartWizardFlow) {
    return {
        type: SET_WIZARD_FLOW,
        payload: {
            wizardFlow,
        },
    };
}

const PRUNE_CART_ITEMS = 'PRUNE_CART_ITEMS';
export function pruneCartItems() {
    return {
        type: PRUNE_CART_ITEMS,
        payload: {},
    };
}

const SET_IS_DOWNLOADING = 'IS_DOWNLOADING';
export function setIsDownloading(isDownloading: boolean) {
    return {
        type: SET_IS_DOWNLOADING,
        payload: {
            isDownloading,
        },
    };
}

const EMPTY_CART = 'EMPTY_CART';
export function emptyCart() {
    return {
        type: EMPTY_CART,
        payload: {},
    };
}

export function enqueueFilesById(fileIds: string[]) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        const payload: EnqueueFileEvent[] = [];

        for (const fileId of fileIds) {
            const taskId = state.data.search.fileIdToCollectMap?.[fileId];

            if (taskId) {
                const capture = state.data.search.resultMap[fileId];
                if (capture) {
                    payload.push({
                        intent: {
                            taskId,
                            fileId,
                            skip: false,
                        },
                        capture,
                    });
                }
            }
        }

        dispatch(enqueueFiles(payload));
    };
}

/* Reducer */
export type DownloadQueueState = {
    /** An object of DownloadIntents. Key is fileId, value is intent */
    enqueued: Record<string, DownloadIntent | undefined>;
    /** An object containing PipelineResult items. Key is fileId, value is result obj */
    enqueuedItemMap: Record<string, Capture | undefined>;
    recentlyEnqueuedCollect: string | null;
    requestId?: string;
    status: DownloadStatus;
    wizardFlow: CartWizardFlow;
};

const initialState: DownloadQueueState = {
    enqueued: {},
    enqueuedItemMap: {},
    recentlyEnqueuedCollect: null,
    requestId: undefined,
    status: DownloadStatus.Idle,
    wizardFlow: 'none',
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case TOGGLE_FILE_INTENT: {
            const { fileId } = action.payload;
            const nextState = Object.assign({}, state);

            // Find out which intents are associated with the collect
            const downloadIntents = values(state.enqueued).filter(
                (intent) => intent !== undefined && intent?.fileId === fileId,
            );
            const shouldSkip = !some(downloadIntents, (intent) => intent?.skip);

            // Update the intents
            downloadIntents.forEach((intent) => {
                if (intent !== undefined && nextState.enqueued[intent.fileId] !== undefined) {
                    (nextState.enqueued[intent.fileId] as any).skip = shouldSkip;
                } else if (intent !== undefined && nextState.enqueued[intent.fileId] === undefined) {
                    nextState.enqueued[intent.fileId] = intent;
                }
            });

            return nextState;
        }

        case SET_FILE_INTENT: {
            const { fileId, skip } = action.payload;
            const nextState = Object.assign({}, state);

            // Find out which intents are associated with the collect
            values(nextState.enqueued)
                .filter((intent) => intent !== undefined && intent.fileId === fileId)
                .forEach((intent) => {
                    if (intent) {
                        intent.skip = skip;
                    }
                });

            return nextState;
        }

        case ENQUEUE_FILE: {
            const { request, item } = action.payload;

            return {
                ...state,
                recentlyEnqueuedCollect: request.taskId,
                enqueued: {
                    ...state.enqueued,
                    [`${request.fileId}`]: request,
                },
                // NOTE: We are copying the file data here. There is an important reason for this:
                // when a new search happens, the ResultItem map gets cleared. As such, we would
                // no longer be able to render metadata about this thing if a new search
                // overwrites the underlying datasource. We want selected items to persist
                // between searches. So copying it here is the best way to ensure the information
                // survives between search instances.
                enqueuedItemMap: {
                    ...state.enqueuedItemMap,
                    [`${request.fileId}`]: item,
                },
            };
        }

        case ENQUEUE_FILES: {
            const events = action.payload as EnqueueFileEvent[];
            const nextState = { ...state };

            for (const event of events) {
                const { fileId } = event.intent;
                nextState.enqueued[fileId] = { ...event.intent };
                nextState.enqueuedItemMap[fileId] = { ...event.capture };
            }

            return {
                ...nextState,
            };
        }

        case DEQUEUE_FILE: {
            const payload = action.payload as DownloadIntent;

            // Check if we still have anything enqeued which is part of the original taskId
            const collectStillRelevant = some(
                values(state.enqueued),
                (intent) => intent?.fileId !== payload.fileId && intent?.taskId === state.recentlyEnqueuedCollect,
            );

            return {
                ...state,
                // If we are still interacting with the same collect, retain the value. Otherwise reset it.
                recentlyEnqueuedCollect: collectStillRelevant ? state.recentlyEnqueuedCollect : null,
                enqueued: {
                    ...state.enqueued,
                    [`${payload.fileId}`]: undefined,
                },
                enqueuedItemMap: {
                    ...state.enqueuedItemMap,
                    [`${payload.fileId}`]: undefined,
                },
            };
        }

        case DEQUEUE_FILES: {
            const { fileIds } = action.payload as { fileIds: string[] };
            const nextState = { ...state };

            for (const fileId of fileIds) {
                nextState.enqueued[fileId] = undefined;
                nextState.enqueuedItemMap[fileId] = undefined;
            }

            return {
                ...nextState,
            };
        }

        case SET_WIZARD_FLOW: {
            const { wizardFlow } = action.payload;
            return {
                ...state,
                wizardFlow,
            };
        }

        case PRUNE_CART_ITEMS: {
            const nextState = { ...state };
            for (const file in nextState.enqueued) {
                const intent = nextState.enqueued[file];
                if (intent !== undefined && intent.skip === true) {
                    delete nextState.enqueued[file];
                    delete nextState.enqueuedItemMap[file];
                }
            }

            return nextState;
        }

        case EMPTY_CART: {
            return {
                ...state,
                enqueued: {},
            };
        }

        default:
            return { ...state };
    }
}

/* Selectors */
export function getItemsByCollect(state: RootState): DownloadCollectGroup {
    return reduce(
        getAllItems(state),
        (acc, item) => {
            if (item !== undefined) {
                acc[item.taskId] = acc[item.taskId] || [];
                acc[item.taskId].push(item);
            }
            return acc;
        },
        {} as DownloadCollectGroup,
    );
}

export function getCollectIcon(state: RootState, taskId: string, onlyVisible = false): CartGroupIcon {
    const fileIds: string[] = state.data.search.collectToFileIdList[taskId];
    let skipCount = 0;
    let total = 0;

    for (const fileId of fileIds) {
        if (!onlyVisible && state.data.cart.enqueued[fileId] === undefined) {
            skipCount++;
        } else if (state.data.cart.enqueued[fileId] === undefined) {
            continue;
        } else if (state.data.cart.enqueued[fileId].skip === true) {
            skipCount++;
        }

        total++;
    }

    if (skipCount === 0) {
        return 'all';
    } else if (skipCount !== total) {
        return 'some';
    } else {
        return 'none';
    }
}

export function computeCollectIcons(state: RootState): Record<string, CartGroupIcon> {
    const intents = Object.values(state.data.cart.enqueued).filter((x) => x !== undefined) as DownloadIntent[];
    const collects = intents.map((intent) => intent.taskId);
    const result: Record<string, CartGroupIcon> = {};
    for (const collect of collects) {
        if (result[collect] === undefined) {
            result[collect] = getCollectIcon(state, collect);
        }
    }
    return result;
}

export function getAllItems(state: RootState): Array<DownloadIntent> {
    return filter(values(state.data.cart.enqueued), (enqueued) => enqueued !== undefined);
}

export function getVisibleItems(state: RootState): Array<DownloadIntent | undefined> {
    return filter(values(state.data.cart.enqueued), (enqueued) => enqueued !== undefined && enqueued.skip !== true);
}

export function isItemSelected(state: RootState, fileId: string | undefined): boolean {
    return (
        fileId !== undefined &&
        state.data.cart.enqueued[fileId] !== undefined &&
        state.data.cart.enqueued[fileId]?.skip !== true
    );
}

export function getSelectionCount(state: RootState) {
    return filter(values(state.data.cart.enqueued), (enqueued) => enqueued && enqueued.skip !== true).length;
}

/***
 * Get the respective Capture information for all enqueued cart items
 * and group them in a map where the key is taskId and the value
 * is an array of Captures for that collect.
 */
export const getFullItemsByCollect = createSelector(
    (state: RootState) => state.data.cart.enqueuedItemMap,
    (enqueued: Record<string, Capture | undefined>) => {
        const items = Object.values(enqueued);

        return items
            .filter((item) => item !== undefined)
            .reduce((groupedFiles: Record<string, Capture[]>, file: Capture | undefined) => {
                if (file) {
                    const { task_id } = file;
                    if (groupedFiles.hasOwnProperty(task_id)) {
                        groupedFiles[task_id] = groupedFiles[task_id].concat(file);
                    } else {
                        groupedFiles[task_id] = [file];
                    }
                }
                return groupedFiles;
            }, {});
    },
);
