import { Box, Button, Heading, Spinner, Table, TableColumn, Text } from 'oskcomponents';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { OrderStatusMap, SigmaOrder, selectOrderStatus } from '~/redux/modules/data/orders';
import { date_format } from '~/utils';

type TaskingOrderTableProps = {
    /** The data that will go in the table. */
    data?: SigmaOrder[];
    /** Method to invoke when a row is clicked */
    onRowClick?: (asset: Record<string, any>) => void;
    /** The row to highlight (if applicable) */
    selectedIndex?: number;
    /** Whether to show the spinner or not */
    loading?: boolean;
    /** Children nodes to render underneath the table */
    children?: React.ReactNode | JSX.Element | Array<React.ReactNode | JSX.Element>;
};

// @ts-ignore
const StyledTable = styled(Table)`
    tbody tr {
        background-color: transparent !important;
        border-bottom: 1px solid ${(props) => props.theme.colors.black200} !important;
    }
`;

const TaskingOrderTable = ({ data, onRowClick, selectedIndex, children, loading }: TaskingOrderTableProps) => {
    const navigate = useNavigate();

    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            { Header: 'Order Id', accessor: 'id', disableSortBy: true },
            {
                Header: 'Order Date',
                accessor: 'orderTimeStamp',
                disableSortBy: true,
                Cell: ({ value }: any) => <Text>{date_format(value)}</Text>,
            },
            {
                Header: 'Collect Start',
                accessor: 'startCollectDateTime',
                disableSortBy: true,
                Cell: ({ value }: any) => <Text>{date_format(value)}</Text>,
            },
            {
                Header: 'Collect End',
                accessor: 'endCollectDateTime',
                disableSortBy: true,
                Cell: ({ value }: any) => <Text>{date_format(value)}</Text>,
            },
            {
                Header: 'Status',
                accessor: 'orderStatus',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    const orderStatus = selectOrderStatus(value);
                    const details = OrderStatusMap[orderStatus] ?? {
                        color: 'black',
                        icon: '',
                        fg: 'white',
                        name: 'Unknown',
                    };

                    return (
                        <Box
                            style={{
                                backgroundColor: details.bg,
                                color: details.fg,
                                padding: '4px 8px',
                                borderRadius: '5px',
                                width: 'fit-content',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <Text strong variant="small">
                                {details.name}
                            </Text>
                        </Box>
                    );
                },
            },
        ],
        [],
    );

    if (!loading) {
        if (!data) {
            return <></>;
        }

        if (data.length === 0) {
            return (
                <Box col center="all">
                    <Heading>No tasks yet.</Heading>
                    <Button
                        m={12}
                        variant="action"
                        onClick={() => {
                            navigate('/tasking');
                        }}
                    >
                        Place Order
                    </Button>
                </Box>
            );
        }
    }

    return (
        <Box col style={{ height: '100%' }}>
            <Box grow style={{ overflow: 'auto', flex: '1 0px' }} col>
                <StyledTable
                    inverted
                    selectedIndex={selectedIndex}
                    onRowClick={onRowClick}
                    columns={columns}
                    data={loading ? [] : data}
                    style={{ height: loading ? '0px' : 'fit-content' }}
                />
                {loading && (
                    <Box grow center="all">
                        <Spinner variant="Box" size="Large" />
                    </Box>
                )}
            </Box>
            {children}
        </Box>
    );
};

export default TaskingOrderTable;
