import { Box } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ClickableLogo, ModeButton } from '~/atoms';
import { GlobalZIndex } from '~/constants';
import { useCurrentApp } from 'oskcore/src/hooks';
import { getProgramId } from '~/utils';
import { useToggles } from '~/hooks/useToggles';

type ActiveMode =
    | 'search'
    | 'tasking'
    | 'tasking-dashboard'
    | 'orders'
    | 'checkout'
    | 'monitor'
    | 'reports'
    | 'findings'
    | 'satellite-track';

type ActiveModeSwitcherProps = {
    /** CSS Class for styling the container */
    className?: string;
};

const ActiveModeSwitcher = styled(({ className }: ActiveModeSwitcherProps) => {
    const { pathname } = useLocation();
    const activeApp = useCurrentApp();
    const programId = useParams()?.programId ?? getProgramId();
    const toggles = useToggles();

    let activeMode: ActiveMode = 'search';

    // Use this more advanced form of rule-based matching so we can
    // prioritize more easily. For example:
    //
    // startsWith('/monitor/') would match /monitor/reports
    // But those are two separate paths and the latter should
    // overrule the former.
    if (pathname.startsWith('/tasking') || pathname === '/tools/tasking') {
        activeMode = 'tasking';
    } else if (pathname === '/tasking/dashboard') {
        activeMode = 'tasking-dashboard';
    } else if (pathname.endsWith('/track')) {
        activeMode = 'satellite-track';
    } else if (pathname.includes('/monitor/reports')) {
        activeMode = 'reports';
    } else if (pathname.includes('/monitor/findings')) {
        activeMode = 'findings';
    } else if (pathname.includes('/monitor')) {
        activeMode = 'monitor';
    } else if (pathname.includes('/checkout')) {
        activeMode = 'checkout';
    } else if (pathname.startsWith('/orders')) {
        activeMode = 'orders';
    } else if (pathname === '/' || pathname.includes('/map')) {
        activeMode = 'search';
    }

    return (
        <>
            <Box
                col
                style={{ zIndex: GlobalZIndex.ActionBar + 100999999 }}
                className={className}
                data-testid="ActiveModeSwitcher"
            >
                <ClickableLogo />
                {(() => {
                    switch (activeApp) {
                        case 'data':
                            return (
                                <>
                                    <ModeButton
                                        data-mode="search"
                                        icon={'search'}
                                        location={`/map`}
                                        active={activeMode === 'search'}
                                        tooltip="Search our archive of imagery"
                                        label="Search"
                                    />
                                    {toggles.showTasking() && (
                                        <ModeButton
                                            data-mode="tasking"
                                            icon={'ghost'}
                                            location="/tasking"
                                            active={activeMode === 'tasking'}
                                            scale={100}
                                            tooltip="View satellite opportunities over any area"
                                            label="Opportunity Viewer"
                                        />
                                    )}
                                    <ModeButton
                                        data-mode="orders"
                                        icon={'table'}
                                        location={`/orders`}
                                        active={activeMode === 'orders'}
                                        tooltip="View the status of past and current orders"
                                        label="Orders"
                                    />
                                    <ModeButton
                                        data-mode="satellite"
                                        icon="satellite"
                                        location="/data/track"
                                        active={activeMode === 'satellite-track'}
                                        tooltip="Track the GHOSt constellation"
                                        label="GHOSt Tracker"
                                    />
                                </>
                            );
                        case 'monitor':
                            return (
                                <>
                                    <ModeButton
                                        data-mode="monitor"
                                        icon={'quilt'}
                                        location={`/program/${programId}/monitor`}
                                        active={activeMode === 'monitor'}
                                        tooltip="View the summary of your assets and accompanying findings"
                                        label="Summary"
                                    />
                                    <ModeButton
                                        data-mode="findings"
                                        icon={'alert'}
                                        location={`/program/${programId}/monitor/findings`}
                                        active={activeMode === 'findings'}
                                        tooltip="View all findings across your assets"
                                        label="Findings"
                                    />
                                    <ModeButton
                                        data-mode="reports"
                                        icon={'report'}
                                        location={`/program/${programId}/monitor/reports`}
                                        active={activeMode === 'reports'}
                                        tooltip="View the generated reports for your assets"
                                        label="Reports"
                                    />
                                    <ModeButton
                                        data-mode="satellite"
                                        icon="satellite"
                                        location="/monitor/track"
                                        active={activeMode === 'satellite-track'}
                                        tooltip="Track the GHOSt constellation"
                                        label="GHOSt Tracker"
                                    />
                                </>
                            );
                        case 'tools':
                            return (
                                <>
                                    <ModeButton
                                        data-mode="tasking"
                                        icon="search"
                                        location={'/tools/tasking'}
                                        active={activeMode === 'tasking'}
                                        tooltip="Task Explorer"
                                        label="Task Explorer"
                                    />
                                    <ModeButton
                                        data-mode="satellite"
                                        icon="satellite"
                                        location="/tools/track"
                                        active={activeMode === 'satellite-track'}
                                        tooltip="Track the GHOSt constellation"
                                        label="GHOSt Tracker"
                                    />
                                </>
                            );
                        default:
                            return <></>;
                    }
                })()}
            </Box>
            {/* Do not remove. This adds necessary padding since the actual button bar is floating */}
            <Box w={73} style={{ minWidth: '73px' }} h={'100%'}>
                &nbsp;
            </Box>
        </>
    );
})`
    & {
        box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.25);
        align-items: center;
        min-width: 73px;
        width: 73px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${(props: any) => props.theme.colors.primary.bg};
    }
`;

export { ActiveModeSwitcher };
export default connect(noop, noop)(ActiveModeSwitcher);
