import { Box, OSKIcon, OSKThemeType, Table, TableColumn, Text } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';

export type NotificationEmailEntry = {
    email: string;
    can_receive_reports: boolean;
};

export type NotificationEmailTableProps = {
    /** The alert to populate the table with */
    data: Array<NotificationEmailEntry>;
    /** The method to invoke when trash icon is clicked */
    onDelete?: (value: string) => void;
    /** The method to invoke when the checkboxes are toggled */
    onChange?: (value: NotificationEmailEntry) => void;
};

const NotificationEmailTable = ({ data, onChange, onDelete, ...props }: NotificationEmailTableProps) => {
    const theme = useTheme() as OSKThemeType;

    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            {
                Header: 'Email',
                accessor: 'email',
            },

            {
                Header: 'Reports',
                accessor: 'report_emails',
                Cell: ({ row }: any) => {
                    const original = { ...row.original };

                    return (
                        <input
                            type="checkbox"
                            checked={original.can_receive_reports}
                            onChange={(evt) => {
                                original.can_receive_reports = evt.target.checked;
                                onChange && onChange({ ...original });
                            }}
                        />
                    );
                },
                width: 85,
            },
            {
                Header: '',
                accessor: 'actions',
                Cell: ({ row }: any) => {
                    return (
                        <Box style={{ justifyContent: 'flex-end' }} grow>
                            <OSKIcon
                                data-testid="delete-notification-recipient"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    const { values } = row;
                                    const { email } = values;

                                    if (onDelete) {
                                        onDelete(email);
                                    }
                                }}
                                code="trash"
                            />
                        </Box>
                    );
                },
            },
        ],
        [onChange, onDelete],
    );

    if (data.length < 1) {
        return (
            <Box
                grow
                center="all"
                style={{
                    alignSelf: 'center',
                    border: `1px solid ${theme.colors.primary.border}`,
                    width: '100%',
                    minHeight: '150px',
                }}
            >
                <Text>No recipients configured</Text>
            </Box>
        );
    }

    return <Table {...props} columns={columns} data={data} />;
};

export { NotificationEmailTable };
