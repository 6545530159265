import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, OSKThemeType } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';

export type CancelOrderModalProps = {
    /** Method to invoke when the abort button is pressed */
    onAbort: () => void;
} & ModalProps;

export const CancelOrderModal = ({ onAbort, ...props }: CancelOrderModalProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Modal w={462} {...props}>
            <ModalHeader variant="primary" style={{ backgroundColor: theme.colors.black900 }}>
                Cancel Order
            </ModalHeader>
            <ModalBody style={{ backgroundColor: theme.colors.black900, color: theme.colors.white }}>
                Are you sure you want to cancel your order? You will be taken back to the map and your cart will be
                cleared.
            </ModalBody>
            <ModalFooter style={{ backgroundColor: theme.colors.black900, borderTop: 'none' }}>
                <Button
                    onClick={onAbort}
                    label="Continue Ordering"
                    style={{ border: `1px solid ${theme.colors.lightGray}`, fontSize: '1rem' }}
                />
                <Button onClick={noop} variant="action" style={{ fontSize: '1rem' }} label="Yes, Cancel" />
            </ModalFooter>
        </Modal>
    );
};

export default connect(noop, noop)(CancelOrderModal);
