import { Box, Text, Typography } from 'oskcomponents';
import { OrderStatus, noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { ActivityProgressChip } from '~/atoms/ActivityProgressChip';
import { OrderStatusMessageMap, selectOrderStatusCode } from '~/redux/modules/data/orders';
import { RootState } from '~/redux/store';

export type ActiveOrderTitleProps = {
    /** orderId of the active order */
    orderId?: string;
    /** Name of the active order */
    orderName?: string;
    /** Status of the active order */
    orderStatus?: OrderStatus;
    /** If true, the order is loading */
    orderLoading: boolean;
};

export const ActiveOrderTitle = ({ orderId, orderName, orderStatus, orderLoading }: ActiveOrderTitleProps) => {
    const statusLabelColor = OrderStatusMessageMap[selectOrderStatusCode(orderStatus)].color;

    return (
        <Box pb={20}>
            {orderLoading && <Text style={{ fontSize: '2rem' }}>&nbsp;</Text>}
            {!orderLoading && (
                <Box col>
                    <Box>
                        <Typography variant="heading4">
                            {orderName && orderName.length > 0 ? orderName : orderId}
                        </Typography>
                        <Box style={{ paddingLeft: '20px' }}>
                            <ActivityProgressChip status={orderStatus ?? undefined} />
                        </Box>
                    </Box>

                    {orderStatus?.reqLineStatusText && (
                        <Box
                            style={{
                                color: statusLabelColor,
                                marginTop: '8px',
                            }}
                        >
                            <Typography variant="caption2">{orderStatus.reqLineStatusText}</Typography>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        orderId: state.data.orders.activeOrder?.id,
        orderName: state.data.orders.activeOrder?.name,
        orderStatus: state.data.orders.activeOrder?.orderStatus ?? undefined,
        orderLoading: state.data.orders.loadingSingleOrder,
    };
};
export default connect(mapStateToProps, noop)(ActiveOrderTitle);
