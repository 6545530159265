import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { fround, MapOf, noop, OSKGeoJson, stringOrElse } from 'oskcore';
import { connect } from 'react-redux';
import { Asset, AssetDetection, selectAllDetectionsByAsset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { useMap } from '~/hooks';
import L, { LatLng } from 'leaflet';
import 'leaflet.markercluster';
import './MarkerCluster.css';
import './MarkerCluster.Default.css';

import AlertConstruction from './icons/alert_construction.png';
import AlertDigging from './icons/alert_digging.png';
import AlertExposedPiping from './icons/alert_exposed_piping.png';
import AlertHydrocarbon from './icons/alert_hydrocarbon.png';
import AlertMethane from './icons/alert_methane.png';
import AlertVehicle from './icons/alert_vehicle.png';
import AlertUnknown from './icons/basic_alert.png';
import AlertUnknownRounded from './icons/basic_alert_rounded.png';
import AlertConstructionRounded from './icons/alert_construction_rounded.png';
import AlertDiggingRounded from './icons/alert_digging_rounded.png';
import AlertExposedPipingRounded from './icons/alert_exposed_piping_rounded.png';
import AlertHydrocarbonRounded from './icons/alert_hydrocarbon_rounded.png';
import AlertMethaneRounded from './icons/alert_methane_rounded.png';
import AlertVehicleRounded from './icons/alert_vehicle_rounded.png';
import DetectionConstruction from './icons/detection_construction.png';
import DetectionDigging from './icons/detection_digging.png';
import DetectionExposedPiping from './icons/detection_exposed_piping.png';
import DetectionHydrocarbon from './icons/detection_hydrocarbon.png';
import DetectionMethane from './icons/detection_methane.png';
import DetectionVehicle from './icons/detection_vehicle.png';
import DetectionConstructionRounded from './icons/detection_construction_rounded.png';
import DetectionDiggingRounded from './icons/detection_digging_rounded.png';
import DetectionExposedPipingRounded from './icons/detection_exposed_piping_rounded.png';
import DetectionHydrocarbonRounded from './icons/detection_hydrocarbon_rounded.png';
import DetectionMethaneRounded from './icons/detection_methane_rounded.png';
import DetectionVehicleRounded from './icons/detection_vehicle_rounded.png';
import DetectionUnknown from './icons/basic_detection.png';
import DetectionUnknownRounded from './icons/basic_detection_rounded.png';

import { useEffect } from 'react';
import { FaCopy } from 'react-icons/fa';

/// The icons for each detection_class/detection_type tuple
const IconCodeMap: Record<string, any> = {
    alert: {
        Construction: AlertConstruction,
        Digging: AlertDigging,
        Plume: AlertMethane,
        'Soil Contamination': AlertHydrocarbon,
        Vehicle: AlertVehicle,
        'Exposed Piping': AlertExposedPiping,
        'Hydrocarbon Leak': AlertHydrocarbon,
        Methane: AlertMethane,
        'Liquid Hydrocarbon': AlertHydrocarbon,
    },
    detection: {
        Construction: DetectionConstruction,
        Digging: DetectionDigging,
        Plume: DetectionMethane,
        'Soil Contamination': DetectionHydrocarbon,
        Vehicle: DetectionVehicle,
        'Exposed Piping': DetectionExposedPiping,
        'Hydrocarbon Leak': DetectionHydrocarbon,
        Methane: DetectionMethane,
        'Liquid Hydrocarbon': DetectionHydrocarbon,
    },
    hidden: {},
};

/// The rounded icons for each detection_class/detection_type tuple
const RoundedIconCodeMap: Record<string, any> = {
    alert: {
        Construction: AlertConstructionRounded,
        Digging: AlertDiggingRounded,
        Plume: AlertMethaneRounded,
        'Soil Contamination': AlertHydrocarbonRounded,
        Vehicle: AlertVehicleRounded,
        'Exposed Piping': AlertExposedPipingRounded,
        'Hydrocarbon Leak': AlertHydrocarbonRounded,
        Methane: AlertMethaneRounded,
        'Liquid Hydrocarbon': AlertHydrocarbonRounded,
    },
    detection: {
        Construction: DetectionConstructionRounded,
        Digging: DetectionDiggingRounded,
        Plume: DetectionMethaneRounded,
        'Soil Contamination': DetectionHydrocarbonRounded,
        Vehicle: DetectionVehicleRounded,
        'Exposed Piping': DetectionExposedPipingRounded,
        'Hydrocarbon Leak': DetectionHydrocarbonRounded,
        Methane: DetectionMethaneRounded,
        'Liquid Hydrocarbon': DetectionHydrocarbonRounded,
    },
    hidden: {},
};

/// Given a detection, return the icon uri.
function getIcon(detection: AssetDetection, rounded = false) {
    if (rounded) {
        const candidate = RoundedIconCodeMap[detection.detection_class][detection.detection_type];
        if (candidate) {
            return candidate;
        } else if (detection.detection_class === 'alert') {
            return AlertUnknownRounded;
        } else {
            return DetectionUnknownRounded;
        }
    } else {
        const candidate = IconCodeMap[detection.detection_class][detection.detection_type];
        if (candidate) {
            return candidate;
        } else if (detection.detection_class === 'alert') {
            return AlertUnknown;
        } else {
            return DetectionUnknown;
        }
    }
}

export type DetectionMarkerProps = {
    /// The currently active assetId
    selectedAssetId: number;
    /// The deep-linked detectionId if applicable
    selectedDetectionId?: string;
    /// A map of all assets loaded for this program
    assets: MapOf<Asset>;
    /// The list of detections and alerts to render to the map
    detections: AssetDetection[];
};

/// This method will take a mouse event and find if any
/// parent element has copyable data associated with it. If it does
/// that data will be formatted and copied to the clipboard.
const handleCopy = (event: Event) => {
    const possibilities: HTMLDivElement[] = [event.target as HTMLDivElement];

    // This while loop will make it so if you click a nested item
    // (like the copy icon) it'll bubble up the parent list looking for
    // a valid copy target.
    while (possibilities.length > 0) {
        const target = possibilities.pop();
        if (target) {
            const lat = target.dataset['lat'];
            const lng = target.dataset['lng'];

            // check for presence of elements
            if (lat && lng) {
                event.preventDefault();
                event.stopPropagation();
                const originalText = target.innerHTML;
                const text = `${lat}, ${lng}`;
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        target.innerHTML = 'Copied';
                        setTimeout(() => {
                            target.innerHTML = originalText;
                        }, 3000);
                    })
                    .catch(() => {
                        console.error('missing clipboard permissions');
                    });
            } else {
                possibilities.push(target.parentElement as HTMLDivElement);
            }
        }
    }
};

/// This method takes a detection and some metadata and will return the popup
/// information to render on the map (for when the icon is clicked). This must
/// be pure html.
function generatePopup(detection: AssetDetection, assetName: string, centroid: LatLng) {
    return renderToStaticMarkup(
        <div style={{ width: '220px', height: '80px', lineHeight: '1.8rem' }}>
            <div style={{ fontSize: '20px', fontWeight: 700 }}>{stringOrElse(detection.detection_type, 'Unknown')}</div>
            <div style={{ fontSize: '14px' }}>{assetName}</div>
            <div
                data-copyable={true}
                data-lat={centroid.lat}
                data-lng={centroid.lng}
                style={{ fontSize: '14px', textDecoration: 'underline' }}
            >
                {`${fround(centroid.lat, 3)}, ${fround(centroid.lng, 3)}`}
                <span style={{ paddingLeft: '8px' }}>
                    <FaCopy />
                </span>
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '260px',
                    height: '110px',
                    zIndex: -1,
                    filter: 'blur(35px)',
                    backgroundColor: 'rgba(97,97,97,1)',
                }}
            ></div>
        </div>,
    );
}

export const DetectionMarkers = ({ assets, detections, selectedDetectionId }: DetectionMarkerProps) => {
    const map = useMap();

    useEffect(() => {
        const rawMap = map.getRaw();

        // This is the cluster for detections
        const detectionMarkers = L.markerClusterGroup({
            chunkedLoading: true,
            iconCreateFunction: (cluster) => {
                const childCount = cluster.getChildCount();
                return new L.DivIcon({
                    html: '<div><span>' + childCount + '</span></div>',
                    className: 'detection-marker-cluster marker-cluster ',
                    iconSize: new L.Point(40, 40),
                });
            },
        });

        // This is the cluster for alerts
        const alertMarkers = L.markerClusterGroup({
            chunkedLoading: true,
            iconCreateFunction: (cluster) => {
                const childCount = cluster.getChildCount();

                return new L.DivIcon({
                    html: '<div><span>' + childCount + '</span></div>',
                    className: 'alert-marker-cluster marker-cluster ',
                    iconSize: new L.Point(40, 40),
                });
            },
        });

        for (const detection of detections) {
            // Skip the selected detection since this is handled elsewhere.
            if (detection.id === selectedDetectionId) {
                continue;
            }

            const markerCoordinates = OSKGeoJson.fromAPIGeometry(detection.coordinates);
            const markerCentroid = markerCoordinates.getCentroid();
            const marker = L.marker(markerCentroid, {
                icon: L.icon({
                    iconUrl: getIcon(detection),
                    iconSize: [32, 32],
                }),
            });

            // Bind the full popup
            const detectionAsset = (assets ?? {})[detection.asset.toString()] ?? {};
            marker.bindPopup(generatePopup(detection, detectionAsset.name, markerCentroid));
            marker.on('popupopen', () => {
                // Enable copy coordinate behavior
                const copyables = document.querySelectorAll('[data-copyable=true]');
                copyables.forEach((copyable) => {
                    copyable.addEventListener('click', handleCopy);
                });
            });

            // Associate the detection with the correct layer based on detection_class
            if (detection.detection_class === 'alert') {
                alertMarkers.addLayer(marker);
            } else {
                detectionMarkers.addLayer(marker);
            }
        }

        /// Add the detections
        rawMap.addLayer(detectionMarkers);
        rawMap.addLayer(alertMarkers);

        // Handle deep-linked detection
        let selectedMarker: L.Marker<any> | undefined = undefined;
        if (selectedDetectionId) {
            const selectedDetection = detections.find((x) => x.id === selectedDetectionId);
            if (selectedDetection) {
                const centroid = OSKGeoJson.fromAPIGeometry(selectedDetection.coordinates).getCentroid();
                selectedMarker = L.marker(centroid, {
                    icon: L.icon({
                        iconUrl: getIcon(selectedDetection, true),
                        iconSize: [53, 64],
                    }),
                });

                selectedMarker.setZIndexOffset(100);
                rawMap.addLayer(selectedMarker);

                // Position the map to the marker after a few milliseconds.
                setTimeout(() => {
                    map.flyTo(centroid, 13);
                }, 500);
            }
        }

        // When the map is unloaded or this component re-renders for whatever reason
        // we need to clean up all the layers we created.
        return () => {
            rawMap.removeLayer(detectionMarkers);
            rawMap.removeLayer(alertMarkers);
            if (selectedMarker) {
                rawMap.removeLayer(selectedMarker);
            }
        };
        // ESLINT LIES
        // eslint-disable-next-line
    }, [detections, selectedDetectionId, assets]);

    // No need to actually render anything in react.
    return null;
};

const mapStateToProps = (state: RootState, ownProps: Partial<DetectionMarkerProps>) => {
    return {
        assets: state.monitor.app.assets,
        detections: selectAllDetectionsByAsset(state, ownProps.selectedAssetId ?? -1),
    };
};
export default connect(mapStateToProps, noop)(DetectionMarkers);
