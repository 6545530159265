import React from 'react';
import { Pagination } from 'oskcomponents';
import { connect } from 'react-redux';
import { DEFAULT_VISIBLE_PAGE_SIZE } from '~/pagination';
import { selectAlertsByAsset, setAlertPage } from '~/redux/modules/monitor/app';
import { AppDispatch, RootState } from '~/redux/store';

export type AlertPaginatorProps = {
    assetId: number;
    totalDetections: number;
    currentPage: number;
    changePage: (pageNumber: number) => void;
};

export const AlertPaginator = ({ changePage, totalDetections, currentPage }: AlertPaginatorProps) => {
    return (
        <Pagination onChange={changePage} count={totalDetections} offset={currentPage * DEFAULT_VISIBLE_PAGE_SIZE} />
    );
};

const mapStateToProps = (state: RootState, ownProps: Partial<AlertPaginatorProps>) => {
    const { assetId } = ownProps;

    return {
        totalDetections: selectAlertsByAsset(state, assetId ?? -1)?.length ?? 0,
        currentPage: state.monitor.app.alertPageByAsset[assetId ?? -1] ?? 0,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: Partial<AlertPaginatorProps>) => {
    const { assetId } = ownProps;

    return {
        changePage: (pageNumber: number) => {
            dispatch(setAlertPage(assetId ?? -1, pageNumber));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertPaginator);
