import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { OSKError } from '../../templates/OSKError';

type ErrorViewProps = {};
const ErrorView = ({}: ErrorViewProps) => {
    const { loginWithRedirect } = useAuth0();
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        loginWithRedirect({
            audience: 'https://coreapi.orbitalsk.com',
            scope: 'profile',
            max_age: 0,
        });
    };

    return (
        <OSKError actions={[{ label: 'Retry Login', action: handleLogout }]}>
            Oh no! It seems you don&apos;t have permission to access this page. Please log in again.
        </OSKError>
    );
};

export { ErrorView };
