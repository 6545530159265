import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from '../Box';
import CSS from 'csstype';
import { useTheme } from 'styled-components';
import { OSKThemeType } from '../DefaultThemeProvider';
import { OSKIcon } from '../OSKIcon';
import { NonEmptyArray } from '../utils';

export type DotProps = {
    /** If true, the dot will be styled differently. */
    active?: boolean;
} & Omit<Omit<BoxProps, 'style'>, 'ref'>;

/**
 * A circular dot
 */
export const Dot = ({ active, ...props }: DotProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                width: '10px',
                height: '10px',
                margin: '4px',
                borderRadius: '30px',
                backgroundColor: active ? theme.colors.accent : theme.colors.gray40,
                border: active ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.gray40}`,
            }}
            {...props}
        />
    );
};

export type CarouselImage = {
    /** Uri to an image resource */
    uri: string;
    /** Alt-text for the image (please use for accessibility). */
    alt?: string;
    /** Vanity text for headers where applicable */
    fileName?: string;
    /** Vanity text to add to the file name, if present */
    subTitle?: string;
};

export type CarouselProps = {
    /** THe index to show as the primary image */
    defaultIndex?: number;
    /** A list of images to render in the carousel */
    images: NonEmptyArray<CarouselImage>;
    /** The width of the carousel image */
    width: number | string;
    /** The height of the carousel */
    height: number | string;
    /** How to fill the space of the carousel (defaults to 'cover') */
    objectFit?: 'cover' | 'contain';
    /** Additional styles to applky to the image element */
    style?: CSS.Properties;
    /** If present, this adds a scale icon in the upper right and will invoke the method on-click */
    onExpanded?: () => void;
    /** The method to invoke when the image changes */
    onChange?: (image: CarouselImage, index: number) => void;
} & Omit<Omit<BoxProps, 'ref'>, 'onChange'>;

export const Carousel = ({
    images,
    objectFit,
    onChange,
    defaultIndex,
    width,
    height,
    onExpanded,
    style,
    ...props
}: CarouselProps) => {
    const [index, setIndex] = useState(defaultIndex ?? 0);
    const [showDots, setShowDots] = useState(false);

    const image = images[index];
    const decr = () => {
        if (index - 1 < 0) {
            setIndex(images.length - 1);
        } else {
            setIndex(index - 1);
        }
    };

    const incr = () => {
        if (index + 1 > images.length - 1) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    };

    useEffect(() => {
        onChange && onChange(images[index], index);
    }, [index]);

    useEffect(() => {
        setIndex(defaultIndex ?? 0);
    }, [defaultIndex]);

    return (
        <Box
            onMouseEnter={() => setShowDots(true)}
            onMouseLeave={() => setShowDots(false)}
            w={width}
            h={height}
            style={{ position: 'relative' }}
            col
        >
            <img
                style={{ objectFit: objectFit ?? 'cover', ...style }}
                alt={image.alt}
                src={image.uri}
                width={width}
                height={height}
            />
            <Box
                w={width}
                h={height}
                style={{
                    transition: 'opacity 300ms',
                    opacity: showDots ? 1.0 : 0.0,
                    position: 'absolute',
                    paddingBottom: '15px',
                    paddingTop: '5px',
                    justifyContent: 'space-around',
                    alignItems: 'flex-end',
                    background: 'linear-gradient(0deg, rgba(0,0,0,.35) 0%,  rgba(0,0,0,0) 80%)',
                    bottom: 0,
                }}
            >
                {onExpanded && (
                    <OSKIcon
                        onClick={onExpanded}
                        style={{ cursor: 'pointer', position: 'absolute', top: '20px', right: '20px' }}
                        code="scale"
                    />
                )}
                {images.length > 1 && (
                    <React.Fragment>
                        <OSKIcon onClick={decr} width={28} height={28} code={'arrow-left-with-border'} />
                        <Box>
                            {images.map((image, idx) => (
                                <Dot active={idx === index} onClick={() => setIndex(idx)} key={`${image}_${idx}`} />
                            ))}
                        </Box>
                        <OSKIcon onClick={incr} width={28} height={28} code={'arrow-right-with-border'} />
                    </React.Fragment>
                )}
            </Box>
        </Box>
    );
};
