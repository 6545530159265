import { Box, OSKIcon, OSKThemeType, Typography } from 'oskcomponents';
import { Program } from 'oskcore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

export type SettingsButtonProps = {
    /** The class which is provided by styled-components */
    className?: string;
    /** The currently active program */
    activeProgram: Program;
};

export const SettingsButton = styled(({ activeProgram, className }: SettingsButtonProps) => {
    const navigate = useNavigate();
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            style={{
                marginTop: '30px',
                borderTop: `2px solid ${theme.colors.black200}`,
            }}
        >
            <Box
                className={className}
                onClick={() => {
                    navigate(`/program/${activeProgram.id}`);
                }}
                center="vertical"
            >
                <Box
                    style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }}
                    className="icon"
                >
                    <OSKIcon fill={theme.colors.black} code="gear-outline" />
                </Box>
                <Typography variant="body2" color="black" style={{ cursor: 'pointer' }}>
                    Current Program Settings
                </Typography>
            </Box>
        </Box>
    );
})`
    & {
        cursor: pointer;
        padding: 20px 15px;
        width: 100%;
    }

    &:hover {
        background-color: ${(props) => props.theme.colors.lightGray} !important;
    }

    &:hover .icon {
        fill: ${(props) => props.theme.colors.accent} !important;
    }

    &:hover > *,
    &:hover svg path {
        fill: ${(props) => props.theme.colors.accent} !important;
    }
`;
