import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { Box, Heading, OSKIcon, Text } from 'oskcomponents';
import { selectOrderFailed } from '~/redux/modules/data/orders';
import { noop } from 'oskcore';

type OrderFailCardProps = {
    /** From redux, the status of the active order */
    isFailed: boolean;
    /** From redux, whether or not the order is loading */
    isLoading: boolean;
};

const OrderFailCard = ({ isFailed, isLoading }: OrderFailCardProps) => {
    if (!isFailed || isLoading) {
        return <></>;
    }

    return (
        <Box center="all" col>
            <OSKIcon code="order-error" w={400} m={-40} />
            <Heading variant="large" m={6}>
                Unable to Complete Order
            </Heading>
            <Text variant="large" w={300} style={{ textAlign: 'center', marginBottom: '40px' }}>
                Your order cannot be completed at this time. Please try again.
            </Text>
        </Box>
    );
};

const mapStateToProps = (state: RootState): OrderFailCardProps => {
    return {
        isLoading: state.data.orders.loadingSingleOrder,
        isFailed: selectOrderFailed(state.data.orders.activeOrder),
    };
};

export default connect(mapStateToProps, noop)(OrderFailCard);
