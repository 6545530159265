import React from 'react';
import { useTheme } from 'styled-components';
import { Box } from '../Box';
import { OSKThemeType } from '../DefaultThemeProvider';
import CSS from 'csstype';

export type ModalFooterProps = {
    /** Children elements to render in the footer */
    children?: React.ReactNode;
    /** Styles to override */
    style?: CSS.Properties;
};

/**
 * This represents a modal footer element. For consistency, compose with
 * ModalBody and ModalHeader and Modal to create a full popup dialog.
 */
const ModalFooter = ({ children, style }: ModalFooterProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                borderTop: `2px solid ${theme.colors.gray25}`,
                borderRadius: ' 0px 0px 5px 5px',
                backgroundColor: theme.colors.gray10,
                padding: '15px',
                justifyContent: 'flex-end',
                ...style,
            }}
        >
            {React.Children.map(children, (child) => (
                <Box
                    style={{
                        paddingLeft: '10px',
                    }}
                >
                    {child}
                </Box>
            ))}
        </Box>
    );
};

export { ModalFooter };
