import { createSelector } from '@reduxjs/toolkit';
import { Capture, Order, OrderStatus, OrderStatusReqLineItemStatusEnum, SigmaAPI } from 'oskcore';
import { SHORT_VISIBLE_PAGE_SIZE } from '~/pagination';
import { AppDispatch, RootState } from '~/redux/store';

export type OrderType = 'tasking' | 'archive';

export type OrderStatusMapEntry = {
    name: string;
    bg: string;
    fg: string;
    icon: string;
};

export const OrderStatusMap: Record<string, OrderStatusMapEntry> = {
    manual: { name: 'Manual Verification', bg: '#FBC414', icon: 'ellipsis', fg: '#282828' },
    mod_received: { name: 'Mod Received', bg: '#EFEEEB', icon: 'ellipsis', fg: '#282828' },
    mod_accepted: { name: 'Mod Accepted', bg: '#EFEEEB', icon: 'ellipsis', fg: '#282828' },
    mod_rejected: { name: 'Mod Rejected', bg: '#FFC6C4', icon: 'times-circle', fg: '#282828' },
    order_received: { name: 'Order Received', bg: '#EFEEEB', icon: 'ellipsis', fg: '#282828' },
    order_accepted: { name: 'Order Accepted', bg: '#EFEEEB', icon: 'ellipsis', fg: '#282828' },
    order_rejected: { name: 'Order Rejected', bg: '#FFC6C4', icon: 'times-circle', fg: '#282828' },
    order_active: { name: 'Order Active', bg: '#A0D1FF', icon: 'ellipsis', fg: '#282828' },
    order_expired: { name: 'Order Expired', bg: '#FFC6C4', icon: 'times-circle', fg: '#282828' },
    order_scheduled: { name: 'Order Scheduled', bg: '#A0D1FF', icon: 'ellipsis', fg: '#282828' },
    order_tasked: { name: 'Order Tasked', bg: '#A0D1FF', icon: 'ellipsis', fg: '#282828' },
    order_processing: { name: 'Order Processing', bg: '#A0D1FF', icon: 'ellipsis', fg: '#282828' },
    order_delivered: { name: 'Order Delivered', bg: '#ABFBB3', icon: 'check', fg: '#282828' },
    order_canceled: { name: 'Order Canceled', bg: '#FFC6C4', icon: 'times-circle', fg: '#282828' },
    order_failed: { name: 'Order Failed', bg: '#FFC6C4', icon: 'times-circle', fg: '#282828' },
};

export type OrderStatusMessageMapEntry = {
    /** If present, the color to use when displaying reqLineStatusCode and reqLineStatusText.
     * If absent, an indication that these values should not be displayed. */
    color?: string;
    error?: boolean;
};

export const OrderStatusMessageMap: Record<string, OrderStatusMessageMapEntry> = {
    AA: { error: true, color: '#f00' }, // CIOrder fails Feasibility
    AB: { error: true, color: '#f00' }, // CIOrder Fails Business Rules
    AC: { error: true, color: '#f00' }, // Invalid AOI requested on CIOrder
    AD: { error: true, color: '#f00' }, // Invalid inventoryID requested on CIOrder
    AE: { error: true, color: '#f00' }, // Invalid Delivery Information requested on CIOrder
    AH0: { error: false, color: '#000' }, // Line item on CDP's constellation schedule
    AH1: { error: false, color: '#000' }, // Line item uplinked to CDP's constellation; Level 1 Satisfaction
    PS: { error: false, color: '#000' }, // Production Started
    AI: {}, // All increments for the line item have delivered.
    AJ: { error: true, color: '#f00' }, // Invalid Line Item for an Order Mod
    AK: { error: false, color: '#000' }, // CIOrderMod request is waiting for manual review
    S1: { error: false, color: '#000' }, // Weather forecast is not favorable for collection over requested area of interest.
    S2: { error: true, color: '#f00' }, // Cannot schedule collection requirement due to competing collection requirements
    P1: { error: true, color: '#f00' }, // Failure in downlink stage within CDP Collection System
    P3: { error: false, color: '#000' }, // Collected Imaged cloud cover does not meet collection requirement
    P2: { error: true, color: '#f00' }, // Failure in backhaul or ingest stage within CDP Production System
    TE: { error: true, color: '#f00' }, // Technical Anomaly
    FF: { error: true, color: '#f00' }, // The Tasking Feasibility request does not meet business rules
    null: {},
};

/**
 * This represents a Sigma Order in its base form.
 */
export type SigmaOrder = {
    bucket: string;
    data_level: string;
    delivery_type: string;
} & Order;

/**
 * This represents a Sigma Order that has been enhanced
 * with additional metadata. Such as the full capture
 * information for the items inside.
 */
export type SigmaOrderDetails = {
    captures?: Capture[];
} & SigmaOrder;

/* Actions */
const RESET_ORDER_DATA = 'RESET_ORDER_DATA';
export function resetOrderData() {
    return {
        type: RESET_ORDER_DATA,
        payload: {},
    };
}

const COMPUTE_ORDER_READY_STATE = 'COMPUTE_ORDER_READY_STATE';
export function computeOrderReadyState() {
    return {
        type: COMPUTE_ORDER_READY_STATE,
        payload: {},
    };
}

const SET_LOADING_ORDERS = 'SET_LOADING_ORDERS';
export function setLoadingOrders(type: OrderType, isLoading: boolean) {
    return {
        type: SET_LOADING_ORDERS,
        payload: {
            type,
            isLoading,
        },
    };
}

const SET_LOADING_SINGLE_ORDER = 'SET_LOADING_SINGLE_ORDER';
export function setLoadingSingleOrder(isLoading: boolean) {
    return {
        type: SET_LOADING_SINGLE_ORDER,
        payload: {
            isLoading,
        },
    };
}

const SET_TASKING_ORDERS = 'SET_TASKING_ORDERS';
export function setTaskingOrders(pageNumber: number, orders: SigmaOrder[]) {
    return {
        type: SET_TASKING_ORDERS,
        payload: {
            pageNumber,
            orders,
        },
    };
}

const SET_ARCHIVE_ORDERS = 'SET_ARCHIVE_ORDERS';
export function setArchiveOrders(pageNumber: number, orders: SigmaOrder[]) {
    return {
        type: SET_ARCHIVE_ORDERS,
        payload: {
            pageNumber,
            orders,
        },
    };
}

const SET_ORDER_PAGE = 'SET_ORDER_PAGE';
export function setOrderPage(type: OrderType, pageNumber: number) {
    return {
        type: SET_ORDER_PAGE,
        payload: {
            type,
            pageNumber,
        },
    };
}

const SET_ORDER_TOTALS = 'SET_ORDER_TOTALS';
export function setOrderTotals(type: OrderType, total: number) {
    return {
        type: SET_ORDER_TOTALS,
        payload: {
            type,
            total,
        },
    };
}

const SET_ACTIVE_ORDER = 'SET_ACTIVE_ORDER';
export function setActiveOrder(order: SigmaOrderDetails) {
    return {
        type: SET_ACTIVE_ORDER,
        payload: {
            order,
        },
    };
}

const SET_ACTIVE_ORDER_ID = 'SET_ACTIVE_ORDER_ID';
export function setActiveOrderId(orderId: string) {
    return {
        type: SET_ACTIVE_ORDER_ID,
        payload: {
            orderId,
        },
    };
}

const SET_ACTIVE_ORDER_TYPE = 'SET_ACTIVE_ORDER_TYPE';
export function setActiveOrderType(orderType: string) {
    return {
        type: SET_ACTIVE_ORDER_TYPE,
        payload: {
            orderType,
        },
    };
}

const SET_ORDER_NETWORK_ERROR = 'SET_ORDER_NETWORK_ERROR';
export function setOrderNetworkError(isError: boolean) {
    return {
        type: SET_ORDER_NETWORK_ERROR,
        payload: {
            isError,
        },
    };
}

const SET_ORDERS_USER_FILTER = 'SET_ORDERS_USER_FILTER';
export function setOrdersUserFilter(user?: number) {
    return {
        type: SET_ORDERS_USER_FILTER,
        payload: {
            user,
        },
    };
}

export function fetchOrderAsync(programId: number, orderId: string) {
    return (dispatch: AppDispatch) => {
        dispatch(setLoadingSingleOrder(true));

        SigmaAPI.getOrder({
            id: orderId,
            program: programId,
        })
            .then(async (resp) => {
                if (resp.data.inventoryId) {
                    SigmaAPI.listCaptures({
                        orderId,
                        program: programId,
                        limit: 1000,
                    })
                        .then((captureResp) => {
                            dispatch(
                                setActiveOrder({
                                    ...resp.data,
                                    captures: captureResp.data.results,
                                } as SigmaOrderDetails),
                            );
                        })
                        .catch((e) => {
                            console.error(e);
                            dispatch(setOrderNetworkError(true));
                        })
                        .finally(() => {
                            dispatch(setLoadingSingleOrder(false));
                            dispatch(computeOrderReadyState());
                        });
                } else {
                    dispatch(
                        setActiveOrder({
                            ...resp.data,
                        } as SigmaOrderDetails),
                    );

                    dispatch(setLoadingSingleOrder(false));
                    dispatch(computeOrderReadyState());
                }
            })
            .catch(() => {
                dispatch(setLoadingSingleOrder(false));
                dispatch(computeOrderReadyState());
            });
    };
}

export function fetchOrdersAsync(type: OrderType, programId: number, page: number) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setLoadingOrders(type, true));
        SigmaAPI.listOrders({
            program: programId,
            limit: SHORT_VISIBLE_PAGE_SIZE,
            offset: page * SHORT_VISIBLE_PAGE_SIZE,
            orderType: type,
            ordering: '-order_timestamp',
            requestedBy: getState().data.orders.ordersUserFilter,
        })
            .then((resp) => {
                if (resp.data.results && resp.data.count) {
                    if (type === 'tasking') {
                        dispatch(
                            setTaskingOrders(
                                page,
                                resp.data.results.map((data) => {
                                    return {
                                        ...data,
                                    } as SigmaOrderDetails;
                                }),
                            ),
                        );

                        dispatch(setOrderTotals('tasking', resp.data.count));
                    } else if (type === 'archive') {
                        dispatch(
                            setArchiveOrders(
                                page,
                                resp.data.results.map((data) => {
                                    return {
                                        ...data,
                                    } as SigmaOrderDetails;
                                }),
                            ),
                        );

                        dispatch(setOrderTotals('archive', resp.data.count));
                    }
                } else {
                }
            })
            .finally(() => {
                dispatch(setLoadingOrders(type, false));
                dispatch(computeOrderReadyState());
            });
    };
}

/* Reducer */
type OrderStateType = {
    pageInitialized: boolean;
    loadingSingleOrder: boolean;
    totalTaskingOrders: number;
    loadingTaskingOrders: boolean;
    taskingOrderPage: number;
    taskingOrdersByPage: Record<number, SigmaOrder[]>;
    totalArchiveOrders: number;
    loadingArchiveOrders: boolean;
    archiveOrderPage: number;
    archiveOrdersByPage: Record<number, SigmaOrder[]>;
    activeOrder?: SigmaOrderDetails;
    activeOrderId?: string;
    activeOrderType?: string;
    isOrderNetworkError?: boolean;
    ordersUserFilter?: number;
};

const initialState: OrderStateType = {
    pageInitialized: false,
    loadingSingleOrder: false,
    totalTaskingOrders: 0,
    loadingTaskingOrders: false,
    taskingOrderPage: 0,
    taskingOrdersByPage: {},
    totalArchiveOrders: 0,
    loadingArchiveOrders: false,
    archiveOrderPage: 0,
    archiveOrdersByPage: {},
    activeOrder: undefined,
    activeOrderId: undefined,
    activeOrderType: undefined,
    isOrderNetworkError: false,
    ordersUserFilter: undefined,
};

export default function reducer(state = initialState, action: any): OrderStateType {
    switch (action.type) {
        case RESET_ORDER_DATA: {
            return initialState;
        }

        case SET_TASKING_ORDERS: {
            const { pageNumber, orders } = action.payload;
            return {
                ...state,
                taskingOrdersByPage: {
                    ...state.taskingOrdersByPage,
                    [pageNumber]: orders,
                },
            };
        }

        case SET_ARCHIVE_ORDERS: {
            const { pageNumber, orders } = action.payload;
            return {
                ...state,
                archiveOrdersByPage: {
                    ...state.archiveOrdersByPage,
                    [pageNumber]: orders,
                },
            };
        }

        case SET_ORDER_PAGE: {
            const { type, pageNumber } = action.payload;
            if (type === 'archive') {
                return {
                    ...state,
                    archiveOrderPage: pageNumber,
                };
            } else if (type === 'tasking') {
                return {
                    ...state,
                    taskingOrderPage: pageNumber,
                };
            }
        }

        case SET_ORDER_TOTALS: {
            const { type, total } = action.payload;
            if (type === 'archive') {
                return {
                    ...state,
                    totalArchiveOrders: total,
                };
            } else if (type === 'tasking') {
                return {
                    ...state,
                    totalTaskingOrders: total,
                };
            }
        }

        case SET_ACTIVE_ORDER_ID: {
            const { orderId } = action.payload;
            return {
                ...state,
                activeOrderId: orderId,
            };
        }

        case SET_ACTIVE_ORDER_TYPE: {
            const { orderType } = action.payload;
            return {
                ...state,
                activeOrderType: orderType,
            };
        }

        case SET_ACTIVE_ORDER: {
            const { order } = action.payload;
            return {
                ...state,
                activeOrder: order,
            };
        }

        case SET_LOADING_ORDERS: {
            const { type, isLoading } = action.payload;
            if (type === 'tasking') {
                return {
                    ...state,
                    loadingTaskingOrders: isLoading,
                };
            } else if (type === 'archive') {
                return {
                    ...state,
                    loadingArchiveOrders: isLoading,
                };
            }
        }

        case SET_LOADING_SINGLE_ORDER: {
            const { isLoading } = action.payload;
            return {
                ...state,
                loadingSingleOrder: isLoading,
            };
        }

        case COMPUTE_ORDER_READY_STATE: {
            return {
                ...state,
                pageInitialized:
                    state.pageInitialized ||
                    (!state.loadingArchiveOrders && !state.loadingSingleOrder && !state.loadingTaskingOrders),
            };
        }

        case SET_ORDER_NETWORK_ERROR: {
            const { isError } = action.payload;
            return {
                ...state,
                isOrderNetworkError: isError,
            };
        }

        case SET_ORDERS_USER_FILTER: {
            const { user } = action.payload;

            return {
                ...state,
                ordersUserFilter: user,
            };
        }

        default:
            return { ...state };
    }
}

export const selectTaskingOrdersByPage = createSelector(
    [(state: RootState) => state.data.orders.taskingOrdersByPage, (state: RootState, pageNumber: number) => pageNumber],
    (orderPages, pageNumber): SigmaOrder[] => {
        return orderPages[pageNumber] ?? [];
    },
);

export const selectArchiveOrdersByPage = createSelector(
    [(state: RootState) => state.data.orders.archiveOrdersByPage, (state: RootState, pageNumber: number) => pageNumber],
    (orderPages, pageNumber): SigmaOrder[] => {
        return orderPages[pageNumber] ?? [];
    },
);

export const selectOrderDashboardReady = createSelector(
    [(state: RootState) => state.data.orders.pageInitialized],
    (pageInitialized) => {
        return pageInitialized;
    },
);

export const selectHasOrders = createSelector(
    [(state: RootState) => state.data.orders.archiveOrdersByPage],
    (orders: Record<any, any>): boolean => {
        return Object.values(orders).flatMap((x) => x).length > 0;
    },
);

export function selectOrderStatusCode(orderStatus?: OrderStatus | null): string {
    if (!orderStatus) return 'null';

    return orderStatus.reqLineStatusCode ?? 'null';
}

export function selectOrderStatus(orderStatus?: OrderStatus): string {
    if (!orderStatus) return 'unknown';

    if (['TE', 'AE'].includes(orderStatus.reqLineStatusCode ?? '')) {
        return 'order_failed';
    } else {
        return orderStatus.reqLineItemStatus;
    }
}

export function selectOrderFailed(order?: Order) {
    if (!order) return false;

    const status = order.orderStatus?.reqLineItemStatus;
    const statusCode = order.orderStatus?.reqLineStatusCode;

    // For mysterious reasons, this means the order is failed.
    if (['TE', 'AE'].includes(statusCode ?? '')) return true;
    // If there is no status, what does that even mean?
    if (!status) return false;
    // If the status is one of a known failure mode, return true
    return [
        OrderStatusReqLineItemStatusEnum.OrderCanceled,
        OrderStatusReqLineItemStatusEnum.OrderRejected,
        OrderStatusReqLineItemStatusEnum.OrderExpired,
        OrderStatusReqLineItemStatusEnum.ModRejected,
    ].includes(status);
}
