import { MapOf } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { Asset, fetchAssetAsync, fetchAssetsAsync, getAllDetectionsForAssetAsync } from '../modules/monitor/app';
import { fetchProgramReportsAsync } from '../modules/monitor/reports';
import { AppDispatch, RootState } from '../store';

const latch: Record<string, boolean | undefined> = {};
/**
 * This method will take a cache key. If the key has never been used before,
 * it will populate the cache and then fetch the data
 * by invoking the second parameter.
 */
function fetchDataOnce(key: string, doFetch: () => void) {
    if (latch[key] === undefined) {
        latch[key] = true;
        doFetch();
    }
}

/** The feature you would like to request from the backend */
export type MonitorResourceProviderFeatures = 'asset' | 'assets' | 'alerts' | 'detections' | 'reports';
export type MonitorResourceProviderProps = {
    /** The children component to render. This should be your main content. */
    children: React.ReactNode;
    /** The assets currently loaded by the system. */
    assets: MapOf<Asset>;
    /** Optional programId, necessary for some features */
    programId?: number;
    /** Optional assetId, necessary for some features */
    assetId?: string;
    /** The list of features you are requested from the backend */
    features: MonitorResourceProviderFeatures[];
    /** Optional date filter from redux */
    filterStartDate?: Date;
    /** Optional date filter from redux */
    filterEndDate?: Date;
    /** Dispatch method */
    dispatch: AppDispatch;
};

/**
 * MonitorResourceProvider is a component which you can wrap any view or content with, specify some
 * optional parameters, and a list of features. It will take care of efficiently fetching
 * the necessary data (if it hasn't already been fetched). This is useful in case you want
 * access to information. It uses a layer of caching, so the data will only be fetched once.
 *
 * Example usage:
 * ```
 * <MonitorResourceProvider programId={6} features={['assets']}>
 *  <AssetView />
 * </MonitorResourceProvider>
 * ```
 */
class MonitorResourceProvider extends React.PureComponent<MonitorResourceProviderProps> {
    render() {
        const { assets, children, dispatch, assetId, programId, features, filterStartDate, filterEndDate } = this
            .props as MonitorResourceProviderProps;

        features.includes('asset') &&
            programId &&
            assetId &&
            fetchDataOnce(`fetch_asset_${assetId}`, () => dispatch<any>(fetchAssetAsync(programId, parseInt(assetId))));

        features.includes('assets') &&
            programId &&
            fetchDataOnce(`fetch_assets_for_program_${programId}`, () => dispatch<any>(fetchAssetsAsync(programId)));

        features.includes('detections') &&
            programId &&
            assetId &&
            fetchDataOnce(`fetch_detections_for_map_${programId}_${assetId}_${filterStartDate}_${filterEndDate}`, () =>
                dispatch<any>(
                    getAllDetectionsForAssetAsync(
                        programId,
                        parseInt(assetId),
                        ['detection'],
                        filterStartDate,
                        filterEndDate,
                    ),
                ),
            );

        features.includes('alerts') &&
            programId &&
            fetchDataOnce(
                `fetch_alerts_for_programs_${programId}_for_assets_${
                    (Object.keys(assets ?? {}) ?? []).length
                }_for_range_${filterStartDate}_${filterEndDate}`,
                () => {
                    Object.keys(assets).forEach((assetId) => {
                        dispatch<any>(
                            getAllDetectionsForAssetAsync(
                                programId,
                                parseInt(assetId),
                                ['alert'],
                                filterStartDate,
                                filterEndDate,
                            ),
                        );
                    });
                },
            );

        features.includes('reports') &&
            programId &&
            fetchDataOnce(`fetch_reports_for_program_${programId}`, () =>
                dispatch<any>(fetchProgramReportsAsync(programId)),
            );

        return children;
    }
}

const mapStateToProps = (state: RootState) => {
    const { assets, filterStartDate, filterEndDate } = state.monitor.app;

    return {
        filterStartDate,
        filterEndDate,
        assets,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        dispatch,
    };
};

const ConnectedProvider = connect(mapStateToProps, mapDispatchToProps)(MonitorResourceProvider);
export { ConnectedProvider as MonitorResourceProvider };
