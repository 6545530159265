import React from 'react';
import { useTheme } from 'styled-components';
import {
    Box,
    DatePicker,
    DatePickerProps,
    ListEntry,
    MultiPickerProps,
    MultiSelect,
    OSKThemeType,
    Select,
    SelectProps,
    Text,
    TextInput,
    TextInputProps,
} from 'oskcomponents';
import { isValid } from 'date-fns';

export type FormElementProps = {
    label: string;
    name: string;
    type: 'input' | 'dropdown' | 'multiselect' | 'date' | 'email' | 'number';
    options?: Array<ListEntry>;
    readonly?: boolean;
    defaultValue?: string | number | Date | boolean | null | undefined;
    defaultValues?: Array<string | number> | undefined;
};

export type FormElementPropsUnion =
    | (FormElementProps & Omit<DatePickerProps, 'defaultValue'>)
    | (FormElementProps & Omit<SelectProps, 'defaultValue'>)
    | (FormElementProps & Omit<MultiPickerProps, 'defaultValue'>)
    | (FormElementProps & Omit<TextInputProps, 'defaultValue'>);

export const FormElement = ({
    label,
    name,
    type,
    options,
    readonly,
    defaultValue,
    defaultValues,
    ...props
}: FormElementPropsUnion) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box col style={{ paddingBottom: '14px', paddingTop: '14px', pointerEvents: 'none' }}>
            <Text variant="medium" style={{ marginBottom: '10px', width: 'fit-content' }}>
                {label}
            </Text>
            <Box col style={{ pointerEvents: 'auto' }}>
                {['input', 'email', 'number'].includes(type) && (
                    <TextInput
                        disabled={readonly}
                        name={name}
                        type={type === 'input' ? 'text' : type}
                        value={defaultValue ?? undefined}
                        {...(props as Omit<Partial<TextInputProps>, 'ref'>)}
                    />
                )}
                {['date'].includes(type) && (
                    <DatePicker
                        name={name}
                        disabled={readonly}
                        defaultValue={
                            defaultValue && isValid(new Date(defaultValue.toString()))
                                ? new Date(defaultValue.toString())
                                : null
                        }
                        variant={'primary'}
                        {...(props as Partial<DatePickerProps>)}
                    />
                )}
                {['dropdown'].includes(type) && (
                    <Select
                        variant={readonly ? 'disabled' : 'primary'}
                        disabled={readonly}
                        defaultValue={defaultValue ?? undefined}
                        name={name}
                        items={options ?? []}
                        {...(props as Partial<SelectProps>)}
                    />
                )}
                {['multiselect'].includes(type) && (
                    <MultiSelect
                        readonly={readonly}
                        name={name}
                        defaultValues={defaultValues ?? undefined}
                        variant={readonly ? 'disabled' : 'primary'}
                        items={options ?? []}
                        {...(props as Partial<MultiPickerProps>)}
                    />
                )}
            </Box>
        </Box>
    );
};
