import React, { useState } from 'react';
import { Box, OSKIcon, Text, TextElementProps, VariantTypes } from 'oskcomponents';
import { coord_format } from '~/utils';

const RESOLUTION = 3;

export type CoordinateProps = {
    longitude: number;
    latitude: number;
    variant?: VariantTypes;
} & Omit<TextElementProps, 'variant'>;

export const Coordinate = ({ longitude, latitude, variant = 'medium', ...props }: CoordinateProps) => {
    const serialized = `${latitude}, ${longitude}`;
    const visualCoordinateMessage = coord_format([longitude, latitude]);
    const [text, setText] = useState(visualCoordinateMessage);

    if (latitude && longitude) {
        const doCopy = () => {
            try {
                navigator.clipboard.writeText(serialized);
                setText('Copied');
            } catch (ex) {
                /** Not everyone will have permission to copy */
                setText('Failed');
            } finally {
                setTimeout(setText.bind(this, visualCoordinateMessage), 3000);
            }
        };

        return (
            <Box row center="vertical" id="copy-button" onClick={doCopy}>
                <Text variant={variant} style={{ textDecoration: 'underline' }} {...props}>
                    {text}
                </Text>
                <OSKIcon code="copy" style={{ cursor: 'pointer', paddingLeft: '8px' }} />
            </Box>
        );
    } else {
        return null;
    }
};
