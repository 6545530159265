import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { DataResourceProvider } from '~/redux';
import { getProgramId } from '~/utils';
import { RootState } from '~/redux/store';
import PanelContents from './PanelContents';

export type OrderSummaryProps = {
    /** From redux, the currently active order id */
    orderId?: string;
};

export const OrderSummary = ({ orderId }: OrderSummaryProps) => {
    const programId = getProgramId();

    return (
        <DataResourceProvider features={['order']} orderId={orderId} programId={programId}>
            <PanelContents />
        </DataResourceProvider>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        orderId: state.data.orders.activeOrderId,
    };
};
export default connect(mapStateToProps, noop)(OrderSummary);
