import React, { useRef } from 'react';
import { Header, Content, ContentProps, OSKThemeType, Box } from 'oskcomponents';
import { OSKNav } from '../../organisms/OSKNav';
import CSS from 'csstype';
import { CurrentApp } from '../../atoms/CurrentApp';
import { hasDeveloperOverrides } from '../../utils';
import { useTheme } from 'styled-components';

export type OSKViewProps = {
    children?: React.ReactNode;
    /** The style to pass into the <Header /> component */
    headerStyle?: CSS.Properties;
    nopadding?: boolean;
    /** If true, there won't be a header bar at all */
    noheader?: boolean;
    /** Optional flag to hide the gradient */
    nogradient?: boolean;
} & Omit<ContentProps, 'ref'>;

export type OSKHeaderContextProps = {
    ref?: React.MutableRefObject<any>;
};

export const OSKHeaderContext = React.createContext<OSKHeaderContextProps>({
    ref: undefined,
});

export const OSKView = ({ children, headerStyle, noheader, ...props }: OSKViewProps) => {
    const ref = useRef(null);
    const theme = useTheme() as OSKThemeType;

    const computedStyle = { ...headerStyle };
    if (hasDeveloperOverrides()) {
        computedStyle.backgroundColor = theme.colors.orange950;
    }

    return (
        <OSKHeaderContext.Provider value={{ ref: ref }}>
            {(noheader === false || noheader === null || noheader === undefined) && (
                <Header style={{ ...computedStyle }}>
                    <Box pl={73}>
                        <CurrentApp />
                    </Box>
                    <OSKNav ref={ref} />
                </Header>
            )}
            <Content {...props}>{children}</Content>
        </OSKHeaderContext.Provider>
    );
};
