import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Form, PageScaffold, TextInput, Typography } from 'oskcomponents';
import { ProgramUserTable } from '~/organisms/tables/ProgramUserTable';
import { InviteUserDialog } from '~/molecules/InviteUserDialog';
import * as yup from 'yup';
import { Role, AdminAPI, ProgramUser, Program } from 'oskcore';
import { RemoveUserDialog } from '~/molecules';
import { PortalContentView, RolePicker } from '~/atoms';
import { Heading } from 'oskcomponents/src/Typography';

export type UserPanelProps = {
    data?: Program;
};

export const UserPanel = ({ data }: UserPanelProps) => {
    const programId = data?.id;
    const [showInviteUser, setShowInviteUser] = useState(false);
    const [showRemoveUser, setShowRemoveUser] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [inviteEmail, setInviteEmail] = useState('');
    const [email, setEmail] = useState('');
    const [userIdToremove, setUserIdToRemove] = useState(0);
    const [users, setUsers] = useState<Array<ProgramUser>>([]);
    const [roles, setRoles] = useState<Role[]>([]);

    const [inviteRoles, setInviteRoles] = useState<string[]>([]);
    const [inviteErrors, setInviteErrors] = useState<string[]>([]);

    const refreshData = useCallback(() => {
        if (programId) {
            setFetching(true);
            AdminAPI.listProgramUsers({ program: programId })
                .then((res) => {
                    AdminAPI.listProgramRoles({ program: programId })
                        .then((roleResult) => {
                            setUsers(res.data.results ?? []);
                            setRoles(roleResult.data.results ?? []);
                        })
                        .finally(() => {
                            setFetching(false);
                        });
                })
                .catch((ex) => {
                    setFetching(false);
                });
        }
    }, [programId]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const handleRoleChange = (user: number, prevRoles: Array<string>, finalRoles: Array<Role>) => {
        AdminAPI.updateProgramUser({
            id: user,
            program: programId ?? -1,
            programUserRequest: {
                roles: finalRoles.map((role) => role.name),
            },
        });
    };

    const beginUserRemove = useCallback(
        (user: number) => {
            setUserIdToRemove(user);
            setEmail(users.find((userEntry) => userEntry.id === user)?.email ?? '');
            setShowRemoveUser(true);
        },
        [setUserIdToRemove, users, setShowRemoveUser],
    );

    const handleUserRemove = useCallback(() => {
        // Hide the dialog
        setShowRemoveUser(false);
        AdminAPI.removeUserFromProgram({ id: userIdToremove, program: programId ?? -1 }).then(() => {
            // Artificially remove the user from the list
            const userList = [...users].filter((item) => item.id !== userIdToremove);
            setUsers(userList);
        });
    }, [setShowRemoveUser, userIdToremove, users, programId, setUsers]);

    const onBeginInvite = useCallback(
        (data: any) => {
            setInviteErrors([]);

            const emails = (data.email as string).replace(' ', '').split(',');
            const errors: string[] = [];
            if (inviteRoles.length === 0) {
                errors.push('At least one role must be selected.');
            }
            emails.forEach((email) => {
                if (!yup.string().email().isValidSync(email)) {
                    errors.push(`${email} is not a valid email address.`);
                }
            });

            if (errors.length > 0) {
                setInviteErrors(errors);
            } else {
                setShowInviteUser(true);
                setEmail(data.email);
            }
        },
        [setShowInviteUser, setEmail, inviteRoles],
    );

    const doInviteUser = useCallback(async () => {
        setShowInviteUser(false);

        const emails = email.replace(' ', '').split(',');

        for (const i in emails) {
            const cleanEmail: string = emails[i].trim();

            // Find the member role
            await AdminAPI.addUserToProgram({
                program: programId ?? -1,
                addUserToProgramRequest: {
                    email: cleanEmail,
                    roles: inviteRoles,
                },
            });
        }

        setInviteEmail('');
        setInviteRoles([]);
        refreshData();
    }, [email, programId, setInviteEmail, refreshData, inviteRoles]);

    return (
        <PortalContentView
            crumbs={[{ title: data?.name ?? 'Program', url: `/program/${data?.id}` }, { title: 'Users' }]}
        >
            <PageScaffold>
                <Form
                    onSubmit={onBeginInvite}
                    style={{
                        paddingBottom: '20px',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        grow
                    >
                        <Box col style={{ flexGrow: '1' }}>
                            <Typography variant="caption1" style={{ marginBottom: '4px' }}>
                                Email(s)
                            </Typography>
                            <TextInput
                                value={inviteEmail}
                                onChange={(evt) => {
                                    setInviteEmail(evt.target.value);
                                }}
                                placeholder="user@company.com, user2@company.com"
                                name="email"
                            />
                        </Box>
                        <Box style={{ alignSelf: 'flex-end' }}>
                            <Box col style={{ marginLeft: '12px' }}>
                                <Typography variant="caption1" style={{ marginBottom: '4px' }}>
                                    Role(s) in Program
                                </Typography>
                                <RolePicker
                                    onChange={(newSelected) => {
                                        if (newSelected.length > 0) {
                                            setInviteRoles(newSelected.map((role) => role.label) as string[]);
                                        } else {
                                            setInviteRoles([]);
                                        }
                                    }}
                                    variant="primary"
                                    name="role"
                                    roles={roles ?? []}
                                    selected={inviteRoles ?? []}
                                    style={{ width: '280px' }}
                                />
                            </Box>
                            <Button
                                submit
                                label="Invite"
                                variant="neutral"
                                style={{
                                    width: '62px',
                                    height: '36px',
                                    marginLeft: '12px',
                                    padding: 0,
                                    alignSelf: 'flex-end',
                                }}
                                inverted
                            />
                        </Box>
                    </Box>
                    {inviteErrors.length > 0 && (
                        <Typography variant="caption1" color="red">
                            {inviteErrors.map((error) => (
                                <>
                                    {error}
                                    <br />
                                </>
                            ))}
                        </Typography>
                    )}
                </Form>

                <Box style={{ paddingBottom: '10px', marginBottom: '20px', borderBottom: '1px solid #515151' }}>
                    <Heading>Users</Heading>
                </Box>
                <ProgramUserTable
                    onRoleChange={handleRoleChange}
                    onUserRemoved={beginUserRemove}
                    roles={roles}
                    readonly={false}
                    loading={fetching}
                    data={users}
                />
                <RemoveUserDialog
                    email={email}
                    onSubmit={handleUserRemove}
                    onCancel={() => setShowRemoveUser(false)}
                    visible={showRemoveUser}
                />
                <InviteUserDialog
                    email={email}
                    roles={inviteRoles}
                    onSubmit={doInviteUser}
                    onCancel={() => setShowInviteUser(false)}
                    visible={showInviteUser}
                />
            </PageScaffold>
        </PortalContentView>
    );
};
