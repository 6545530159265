import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, OSKIcon, OSKThemeType, Text } from '..';
import { Toolean } from '../constants';

export type SelectItemProps = {
    /** The method to invoke when this element is clicked. */
    onClick?: (evt: any) => void;
    /** If true, the element will be rendered in "selected" mode with a checkmark next to it */
    selected: Toolean;
    /** The text or ReactNode to render inside this ListItem */
    text: string | ReactNode;
};

export const SelectItem = styled(({ onClick, selected, text }: SelectItemProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box onClick={onClick} p={10} style={{ width: '100%', alignItems: 'center' }} grow>
            {(() => {
                switch (selected) {
                    case true:
                        return <OSKIcon code="check-square" fill={theme.colors.primary.accent} />;
                    case false:
                        return <OSKIcon code="reg-square" fill={theme.colors.primary.fg} />;
                    case 'some':
                        return <OSKIcon code="dash-square" fill={theme.colors.primary.accent} />;
                }
            })()}
            <Text style={{ paddingLeft: '10px', userSelect: 'none' }} variant="medium">
                {text}
            </Text>
        </Box>
    );
})``;
