import { range } from 'lodash';
import { Box, BoxProps, OSKThemeType, Text } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';

const MinimalDivider = () => {
    const theme = useTheme() as OSKThemeType;
    return <Box w={100} h={2} bg={theme.colors.accent}></Box>;
};

export type MinimalStepProps = {
    /** True if this is the current step */
    current?: boolean;
    /** True if this step has been completed */
    completed?: boolean;
    /** Optional label to render under the step */
    label?: string;
};

const MinimalStep = ({ current, completed, label }: MinimalStepProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box col center="horizontal">
            <Box
                m={4}
                w={16}
                h={16}
                bg={current || completed ? theme.colors.accent : 'transparent'}
                style={{ border: `2px solid ${theme.colors.accent}`, borderRadius: '30px' }}
            />
            <Box style={{ position: 'absolute', maxWidth: '125px' }}>
                <Text
                    style={{
                        position: 'relative',
                        textAlign: 'center',
                        lineHeight: '1.25rem',
                        top: '24px',
                    }}
                    variant="tiny"
                    strong={current}
                >
                    {label}
                </Text>
            </Box>
        </Box>
    );
};

export type MinimalWizardStepListProps = {
    /** How many dots to render in the step list */
    stepCount: number;
    /** Which position we are currently on (starts at 1) */
    activeStep: number;
    /** Optional labels to render under each step in the list. */
    labels?: string[];
} & Omit<BoxProps, 'ref'>;

export const MinimalWizardStepList = ({ stepCount, activeStep, labels, ...props }: MinimalWizardStepListProps) => {
    return (
        <Box center="vertical" {...props}>
            <MinimalDivider />
            {range(0, stepCount).map((idx) => {
                const adjustedIdx = idx + 1;
                return (
                    <React.Fragment key={`step_${idx}`}>
                        <MinimalStep
                            current={adjustedIdx === activeStep}
                            completed={adjustedIdx < activeStep}
                            label={labels && idx < labels.length ? labels[idx] : undefined}
                        />
                        <MinimalDivider />
                    </React.Fragment>
                );
            })}
        </Box>
    );
};
