import { Box, OSKIcon, OSKThemeType, Spinner, Typography } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { utc_date_format } from '~/utils';
import { InternalRasterCapture } from '..';
import { ARTIFACT_COLUMNS } from '~/constants';

type CapturesListViewProps = {
    /** Children passthrough */
    children?: React.ReactNode | React.ReactNode[];
    /** The captures to render */
    captures?: InternalRasterCapture[];
};

const CapturesListView = ({ children, captures, ...props }: CapturesListViewProps) => {
    const theme = useTheme() as OSKThemeType;

    if (!captures) {
        return (
            <Box p={14} grow center="horizontal" {...props}>
                <Spinner variant="Box" size="Medium" />
            </Box>
        );
    } else if (captures.length === 0) {
        return (
            <Box
                style={{
                    padding: '14px',
                    borderRadius: '7px',
                    border: `1px solid ${theme.colors.black200}`,
                }}
                grow
                col
                center="horizontal"
                {...props}
            >
                <Typography variant="heading2">Error retrieving captures.</Typography>
            </Box>
        );
    }

    return (
        <Box
            style={{
                padding: '14px',
                borderRadius: '7px',
                border: `1px solid ${theme.colors.black200}`,
            }}
            grow
            col
            {...props}
        >
            {children}
            <Box>
                <Typography variant="heading2">Captures</Typography>
            </Box>
            <Box
                style={{
                    backgroundColor: theme.colors.lightGray,
                    color: theme.colors.black600,
                    margin: '8px -14px 4px -14px',
                    paddingLeft: '12px',
                    height: '50px',
                }}
                center="vertical"
            >
                <Box w={210}>
                    {ARTIFACT_COLUMNS.map((col) => (
                        <Box key={`artifact-${col.label}`} w={50} center="horizontal">
                            <Typography title={col.name} variant="heading5">
                                {col.label}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box w={200}>
                    <Typography variant="heading5">Acquired</Typography>
                </Box>
                <Box w={200}>
                    <Typography variant="heading5">Processed</Typography>
                </Box>
                <Box w={300}>
                    <Typography variant="heading5">File ID</Typography>
                </Box>
            </Box>
            {captures.map((capture: InternalRasterCapture, idx) => (
                <React.Fragment key={`${capture.id}-${idx}`}>
                    <Box
                        style={{
                            width: '100%',
                            height: '40px',
                        }}
                        center="vertical"
                    >
                        <Box
                            style={{
                                width: '210px',
                            }}
                        >
                            {ARTIFACT_COLUMNS.map((col) => {
                                const raster =
                                    capture.rasters &&
                                    capture.rasters.find((raster) => raster.artifact_type === col.field);
                                if (raster) {
                                    return (
                                        <Box key={`${col.field}-${capture.id}`} w={50} center="horizontal">
                                            <OSKIcon
                                                title={col.name}
                                                code="check-circle"
                                                m={2}
                                                fill={theme.colors.accent}
                                                scale={175}
                                            />
                                        </Box>
                                    );
                                } else {
                                    return (
                                        <Box key={`${col.field}-${capture.id}`} w={50} center="horizontal">
                                            <OSKIcon
                                                title={col.name}
                                                code="times-circle"
                                                m={2}
                                                fill={theme.colors.black600}
                                                scale={175}
                                            />
                                        </Box>
                                    );
                                }
                            })}
                        </Box>
                        <Box w={200}>
                            <Typography variant="body3">{utc_date_format(capture.acquisition_time)}</Typography>
                        </Box>
                        <Box w={200}>
                            <Typography variant="body3">{utc_date_format(capture.processed_date)}</Typography>
                        </Box>
                        <Box w={300}>
                            <Typography variant="body3">{capture.file_id}</Typography>
                        </Box>
                    </Box>
                    {idx < captures.length - 1 && (
                        <Box
                            key={`capture-spacer-${idx}`}
                            style={{ backgroundColor: theme.colors.black200, width: '100%', height: '1px' }}
                        />
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default CapturesListView;
