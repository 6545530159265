import { Box, OSKThemeType, Typography } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';

export const LeakChip = () => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            w={80}
            p={4}
            mt={14}
            ml={14}
            center="all"
            style={{
                backgroundColor: theme.colors.primary.bg,
                border: `1px solid ${theme.colors.primary.border}`,
                borderRadius: '3px',
            }}
        >
            <Typography variant="caption2">Leak</Typography>
        </Box>
    );
};
