import { Box, OSKIcon, Tooltip, Typography } from 'oskcomponents';
import React from 'react';
import styled from 'styled-components';
import { OrderStatusMessageMap, SigmaOrder, selectOrderStatusCode } from '~/redux/modules/data/orders';
import { ActivityProgressChip } from '~/atoms/ActivityProgressChip';
import { format } from 'date-fns';

type OrderCardProps = {
    /** The order that this card represents */
    order: SigmaOrder;
    /** Pass-through for styled-components */
    className?: string;
    /** Whether or not this card is selected */
    selected?: boolean;
    /** A method that's called when the card is clicked */
    onClick?: () => void;
};

const OrderCard = styled(({ order, className, selected, onClick }: OrderCardProps) => {
    const { orderStatus } = order;
    const isErrorStatus = OrderStatusMessageMap[selectOrderStatusCode(orderStatus)].error;

    return (
        <Box grow className={`${selected ? 'selected' : ''} ${className}`} center="vertical" onClick={onClick}>
            <Box style={{ padding: '0px 18px' }}>
                <OSKIcon code="image-circle" />
            </Box>
            <Box col className="order-details">
                <Box center="vertical">
                    <ActivityProgressChip status={order.orderStatus ?? undefined} />
                    {isErrorStatus && (
                        <Tooltip label={orderStatus?.reqLineStatusText ?? ''}>
                            <OSKIcon code="alert-circle" height={22} fill="red" />
                        </Tooltip>
                    )}
                </Box>
                <Typography variant="heading5" className="order-name">
                    {order.name ? order.name : order.id}
                </Typography>
                <Typography variant="body3" className="order-subtitle">
                    {order.customerPoc} | {format(new Date(order.orderTimeStamp), 'h:mm a')}
                </Typography>
            </Box>
        </Box>
    );
})`
    & {
        border-radius: 4px;
        padding: 22px 0px;
        margin: 12px;
        box-shadow: 0px 3px 9px 0px #00000040;
        user-select: none;
        background-color: ${(props: any) => props.theme.colors.white};
        color: ${(props: any) => props.theme.colors.black900};
    }

    &.selected {
        background-color: ${(props: any) => props.theme.colors.blue1};
        color: white !important;
        .order-subtitle {
            color: white;
        }
    }

    .order-details {
        height: 73px;
        justify-content: space-between;
    }

    .order-subtitle {
        color: ${(props: any) => props.theme.colors.black600};
    }
`;

export { OrderCard };
export type { OrderCardProps };
