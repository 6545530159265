import React from 'react';
import { Box, Typography } from 'oskcomponents';
import { useAuth0 } from '@auth0/auth0-react';
import { OSKError } from '../../templates/OSKError';

type VerifyEmailViewProps = {};
const VerifyEmailView = ({}: VerifyEmailViewProps) => {
    const { loginWithRedirect } = useAuth0();
    const handleLogin = () => {
        localStorage.clear();
        sessionStorage.clear();
        loginWithRedirect({
            audience: 'https://coreapi.orbitalsk.com',
            scope: 'profile',
            max_age: 0,
        });
    };

    return (
        <OSKError actions={[{ label: 'Login', action: handleLogin }]}>
            <Box m={12} center="all" col>
                <Typography variant="heading4" m={12}>
                    Welcome to Sigma!
                </Typography>
                <Typography variant="body3" style={{ textAlign: 'center' }}>
                    To proceed, please verify your email. Once verified, click here to:
                </Typography>
            </Box>
        </OSKError>
    );
};

export { VerifyEmailView };
