import { Box, OSKIcon, OSKThemeType } from 'oskcomponents';
import { NavItem, noop } from 'oskcore';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { GlobalZIndex } from '~/constants';
import { getVisibleItems, setWizardFlow } from '~/redux/modules/data/cart';
import { RootState } from '~/redux/store';

export type CartButtonProps = {
    /** If > 0, this value will be shown as a badge on top of the cart button */
    visibleCartItems: number;
};

export const CartButton = ({ visibleCartItems }: CartButtonProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();

    return (
        <Box style={{ position: 'relative' }} pt={16} pb={16}>
            <NavItem>
                <OSKIcon
                    code="shopping-cart"
                    onClick={
                        visibleCartItems > 0
                            ? () => {
                                  dispatch(setWizardFlow('search'));
                              }
                            : undefined
                    }
                    fill={visibleCartItems > 0 ? theme.colors.white : theme.colors.gray3a}
                    hoverFill={visibleCartItems > 0 ? theme.colors.accent : 'theme'}
                    style={{
                        zIndex: GlobalZIndex.ActionBar,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    scale={150}
                />
                {visibleCartItems > 0 && (
                    <Box
                        style={{
                            borderRadius: '30px',
                            minWidth: '20px',
                            minHeight: '20px',
                            maxWidth: '20px',
                            maxHeight: '20px',
                            fontSize: '0.8rem',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: theme.colors.red,
                            color: 'white',
                            padding: '4px',
                            zIndex: GlobalZIndex.ActionBar + 1,
                            position: 'absolute',
                            pointerEvents: 'none',
                            top: '5px',
                            left: '30px',
                        }}
                    >
                        {visibleCartItems > 0 ? visibleCartItems : ''}
                    </Box>
                )}
            </NavItem>
        </Box>
    );
};

function mapStateToProps(state: RootState) {
    return {
        visibleCartItems: getVisibleItems(state).length,
    };
}

export default connect(mapStateToProps, noop)(CartButton);
