import React from 'react';
import { OSKView } from '../OSKView';
import { Box, Button, OSKIcon, OSKIconType, OneOrMore, Typography } from 'oskcomponents';
import SpaceImage from '../../assets/images/spaaace.jpg';
import { useWaitForImage } from '../../hooks';
import { OSKLogoSolid } from 'oskcomponents/src/assets/OSKLogo';
import CSS from 'csstype';

type OSKErrorProps = {
    icon?: OSKIconType;
    children?: OneOrMore<React.ReactNode>;
    actions?: Array<OSKErrorAction>;
    style?: CSS.Properties;
};

type OSKErrorAction = {
    label: string;
    action: () => void;
};

const OSKError = ({ children, icon, actions, style }: OSKErrorProps) => {
    const [render] = useWaitForImage(SpaceImage);

    return (
        <OSKView noheader nogradient grow>
            {render && (
                <Box
                    grow
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        backgroundImage: `url('${SpaceImage}')`,
                        backgroundSize: 'cover',
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: '#111',
                            color: '#ccc',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            paddingTop: '20px',
                            padding: '20px',
                            borderRadius: '14px',
                            minWidth: '250px',
                            maxWidth: '400px',
                            filter: 'drop-shadow(0 0 0.55rem black)',
                            ...style,
                        }}
                        col
                    >
                        <Box center="all" col grow style={{ padding: '20px 0px', justifyContent: 'space-around' }}>
                            {icon ? <OSKIcon code={icon} /> : <OSKIcon fill="white" svg={OSKLogoSolid} />}
                            {typeof children === 'string' ? (
                                <Typography
                                    variant="body2"
                                    style={{
                                        textAlign: 'center',
                                        margin: '48px 0px',
                                    }}
                                >
                                    {children}
                                </Typography>
                            ) : (
                                <Box center="all" col grow>
                                    {children}
                                </Box>
                            )}
                            {actions && actions?.length > 0 && (
                                <Box>
                                    {actions?.map((action) => (
                                        <Button key={action.label} onClick={action.action}>
                                            {action.label}
                                        </Button>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </OSKView>
    );
};

export { OSKError };
export type { OSKErrorProps, OSKErrorAction };
