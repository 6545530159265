import { PublicDataProduct, Sensor, SigmaAPI } from 'oskcore';
import { AppDispatch } from '../store';
import {} from './data/app';
import { includePlatform } from './data/search';

/* Actions */
export const SET_SENSORS = 'SET_SENSORS';
export function setSensors(sensors: Array<Sensor>) {
    return {
        type: SET_SENSORS,
        payload: {
            sensors,
        },
    };
}

export const SET_DATA_PRODUCTS = 'SET_DATA_PRODUCTS';
export function setDataProducts(dataProducts: Array<PublicDataProduct>) {
    return {
        type: SET_DATA_PRODUCTS,
        payload: {
            dataProducts,
        },
    };
}

export const SET_ACCESSIBLE_SENSORS = 'SET_ACCESSIBLE_SENSORS';
export function setAccessibleSensors(sensorDataAccess: Array<number>) {
    return {
        type: SET_ACCESSIBLE_SENSORS,
        payload: {
            sensorDataAccess,
        },
    };
}

export const SET_SUBSCRIBED_PRODUCTS = 'SET_SUBSCRIBED_PRODUCTS';
export function setSubscribedProducts(subscribedProducts: Array<string>) {
    return {
        type: SET_SUBSCRIBED_PRODUCTS,
        payload: {
            subscribedProducts,
        },
    };
}

export const SET_SENSOR_TLES = 'SET_SENSOR_TLES';
export function setSensorTLEs(sensorTLEs: Record<number, string>) {
    return {
        type: SET_SENSOR_TLES,
        payload: {
            sensorTLEs,
        },
    };
}

export const SET_APP_INITIALIZING = 'SET_APP_INITIALIZING';
export function setAppInitializing(initializing: boolean) {
    return {
        type: SET_APP_INITIALIZING,
        payload: {
            initializing,
        },
    };
}

/* Async methods */

/** doPopulateAppAsync()
 *
 * This method will load all the initial data needed to render
 * the UI. This includes things like sensors and other
 * such immutable data.
 */
export function doPopulateAppAsync() {
    return (dispatch: AppDispatch) => {
        dispatch(setAppInitializing(true));

        // The following set of promises are intended to block the UI.
        // The UI will not render until all data in this section has been
        // fetched.
        SigmaAPI.listSensors({ limit: 10000 })
            .then(
                (response) =>
                    new Promise((resolve) => {
                        const { data } = response;
                        const { results } = data;
                        if (results) {
                            dispatch(setSensors(results));
                            // Select all sensors by default.
                            results.forEach((sensor) => dispatch(includePlatform(sensor.osk_id)));
                            // Get the TLE for each sensor and cache it in a map
                            const clearSensors = results.filter(
                                (sensor) =>
                                    sensor.tasking_eligible_start !== null || sensor.tasking_eligible_end !== null,
                            );
                            const tles: Record<number, string> = {};
                            const tlePromises = clearSensors.map((sensor) => SigmaAPI.getTle({ oskId: sensor.osk_id }));

                            Promise.allSettled(tlePromises)
                                .then((results) => {
                                    results.forEach((result) => {
                                        if (result.status === 'fulfilled') {
                                            const {
                                                // @ts-ignore
                                                value: {
                                                    data: { raw_tle, sensor },
                                                },
                                            } = result;
                                            tles[sensor] = raw_tle;
                                        }
                                    });
                                })
                                .then(() => dispatch(setSensorTLEs(tles)))
                                .finally(() => resolve(response));
                        } else {
                            resolve(response);
                        }
                    }),
            )
            .finally(() => {
                // Load the DataProducts
                SigmaAPI.publicListDataProducts({ limit: 1000 })
                    .then((dataProductResp) => {
                        const dataProducts = dataProductResp.data.results;
                        if (dataProducts) {
                            dispatch(setDataProducts(dataProducts));
                        }
                    })
                    .finally(() => {
                        dispatch(setAppInitializing(false));
                    });
            });

        // The following set of promises are intended to be executed
        // in the background and do not block the UI. Useful for teriary
        // supporting data.
    };
}

/* Reducer */
type OSKAppReducerType = {
    initializing: boolean;
    subscribedProducts: string[];
    accessibleSensors: Array<number>;
    sensors: Array<Sensor>;
    dataProducts: Array<PublicDataProduct>;
    sensorTLEs: Record<number, string>;
};
const initialState: OSKAppReducerType = {
    initializing: true,
    subscribedProducts: [],
    sensors: [],
    dataProducts: [],
    accessibleSensors: [],
    sensorTLEs: {},
};

export default function reducer(state = initialState, action: any): OSKAppReducerType {
    switch (action.type) {
        case SET_SENSORS: {
            const { sensors } = action.payload;
            return {
                ...state,
                sensors,
            };
        }

        case SET_SENSOR_TLES: {
            const { sensorTLEs } = action.payload;

            return {
                ...state,
                sensorTLEs,
            };
        }

        case SET_DATA_PRODUCTS: {
            const { dataProducts } = action.payload;
            return {
                ...state,
                dataProducts,
            };
        }

        case SET_APP_INITIALIZING: {
            const { initializing } = action.payload;
            return {
                ...state,
                initializing,
            };
        }

        case SET_ACCESSIBLE_SENSORS: {
            const { sensorDataAccess } = action.payload;
            return {
                ...state,
                accessibleSensors: sensorDataAccess,
            };
        }

        case SET_SUBSCRIBED_PRODUCTS: {
            const { subscribedProducts } = action.payload;
            return {
                ...state,
                subscribedProducts,
            };
        }

        default:
            return { ...state };
    }
}
