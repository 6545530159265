import { Box } from 'oskcomponents';
import { OSKView } from 'oskcore';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { GlobalZIndex } from '~/constants';

type OSKToastViewProps = {
    /** Optional flag to hide the gradient */
    nogradient?: boolean;
    /** Child node passthrough */
    children?: Array<React.ReactNode> | React.ReactNode;
};

const OSKToastView = ({ children, nogradient = false, ...props }: OSKToastViewProps) => {
    return (
        <OSKView constrained nopadding nogradient={nogradient}>
            <Box style={{ flexGrow: 1, height: '100%' }}>{children}</Box>
            <Toaster
                position="bottom-center"
                containerStyle={{ bottom: '50px', zIndex: GlobalZIndex.CartWizardPopup + 1 }}
            />
        </OSKView>
    );
};

export { OSKToastView };
export type { OSKToastViewProps };
