import React from 'react';
import styled from 'styled-components';
import CSS, { Property } from 'csstype';

export type Alignment = 'horizontal' | 'vertical' | 'all';

export type BoxProps = {
    /** Custom style */
    style?: CSS.Properties;
    /** Amount of padding to add */
    p?: number;
    /** Padding top */
    pt?: number;
    /** Padding bottom */
    pb?: number;
    /** Padding left */
    pl?: number;
    /** Padding right */
    pr?: number;
    /** Amount of margin to add */
    m?: number;
    /** Margin top */
    mt?: number;
    /** Margin bottom */
    mb?: number;
    /** Margin left */
    ml?: number;
    /** Margin right */
    mr?: number;
    /** Maximum width */
    w?: number | string;
    /** Height of this box in pixels */
    h?: number | string;
    /** Whether to flex-grow or not */
    grow?: boolean;
    /** Font size */
    fs?: string;
    /** Font color */
    fg?: string;
    /** Background color */
    bg?: string;
    /** If true, flexbox will be pivoted to row */
    row?: boolean;
    /** If true, flexbox will be pivoted to column */
    col?: boolean;
    /** Flag for centering content on one or more axes. */
    center?: Alignment;
    /** Cursor style override */
    cursor?: Property.Cursor;
} & JSX.IntrinsicElements['div'];

const CoreBox = React.forwardRef<HTMLDivElement, BoxProps>(
    ({ center, col, grow, bg, h, p, pt, pb, row, m, w, ...props }: BoxProps, ref) => {
        return <div ref={ref} {...props} />;
    },
);

const Box = styled(CoreBox)`
    display: flex;
    color: ${(props: any) => props.fg ?? ''};
    flex-grow: ${(props: any) => (props.grow ? 1 : 'unset')};
    flex-direction: ${(props: any) => (props.row === true ? 'row' : props.col === true ? 'column' : 'row')};
    font-family: ${(props: any) => props.theme.font};
    font-size: ${(props: any) => props.fs ?? ''};
    width: ${(props: any) => (props.w && !isNaN(props.w) ? props.w + 'px' : props.w ?? '')};
    height: ${(props: any) => (props.h && !isNaN(props.h) ? props.h + 'px' : props.h ?? 'unset')};
    padding: ${(props: any) => (props.p ? props.p + 'px' : '')};
    padding-top: ${(props: any) => (props.pt ? props.pt + 'px' : '')};
    padding-bottom: ${(props: any) => (props.pb ? props.pb + 'px' : '')};
    padding-left: ${(props: any) => (props.pl ? props.pl + 'px' : '')};
    padding-right: ${(props: any) => (props.pr ? props.pr + 'px' : '')};
    margin: ${(props: any) => (props.m ? props.m + 'px' : '')};
    margin-top: ${(props: any) => (props.mt ? props.mt + 'px' : '')};
    margin-bottom: ${(props: any) => (props.mb ? props.mb + 'px' : '')};
    margin-left: ${(props: any) => (props.ml ? props.ml + 'px' : '')};
    margin-right: ${(props: any) => (props.mr ? props.mr + 'px' : '')};
    background-color: ${(props: any) => (props.bg ? props.bg : '')};
    cursor: ${(props: any) => (props.cursor ? props.cursor : props.onClick ? 'pointer' : 'inherit')};
    ${(props: any) => {
        const hCenter = 'justify-content: center;';
        const vCenter = 'align-items: center;';
        const direction = props.row === true ? 'row' : props.col === true ? 'column' : 'row';

        switch (props.center) {
            case 'horizontal':
                return direction === 'row' ? hCenter : vCenter;
            case 'vertical':
                return direction === 'row' ? vCenter : hCenter;
            case 'all':
                return `${hCenter} ${vCenter}`;
        }
    }}
`;

export { Box };
