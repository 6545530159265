import { OSKThemeType, Select, Typography } from 'oskcomponents';
import { PublicDataProduct, noop } from 'oskcore';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { RootState } from '~/redux/store';

export type ProductPickerProps = {
    /** Data products currently supproted by the API */
    dataProducts: PublicDataProduct[];
    /** The default value to select */
    defaultValue?: string | undefined;
    /** Method to invoke when a selection happens */
    onChange: (product: string) => void;
};

export const ProductPicker = ({ dataProducts, defaultValue, onChange }: ProductPickerProps) => {
    const theme = useTheme() as OSKThemeType;

    // Create a mapping of productLevel to helpText
    const productLevelMapping: Record<string, string> = dataProducts.reduce((acc, cur) => {
        acc[cur.product_level] = cur.product_level_help_text;
        return acc;
    }, {} as Record<string, string>);

    // Validate the default option
    let isValid = false;
    for (const option of dataProducts) {
        if (defaultValue && defaultValue === option.product_level) {
            isValid = true;
        }
    }

    if (!isValid) {
        defaultValue = undefined;
    }

    const productLevelOptions = Object.entries(productLevelMapping).map(([label, helpText]) => ({
        label: (
            <div>
                <b>{label}</b> -
                <Typography variant="body4" as="span" color={theme.colors.gray80} style={{ paddingLeft: '4px' }}>
                    {helpText}
                </Typography>
            </div>
        ),
        value: label,
    }));

    const [selectedProduct, setSelectedProduct] = useState(defaultValue ?? productLevelOptions[0].value);

    useEffect(() => {
        onChange(selectedProduct);
    }, [selectedProduct, onChange]);

    return (
        <Select
            name="product_level"
            variant="primary"
            inverted
            maxHeight={'250px'}
            items={productLevelOptions}
            defaultValue={selectedProduct}
            onSelect={(option) => {
                option && setSelectedProduct(option.value);
            }}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        dataProducts: state.osk.dataProducts,
    };
};

export default connect(mapStateToProps, noop)(ProductPicker);
