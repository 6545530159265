import React, { useEffect, useState } from 'react';
import { Box, Button, OSKIcon, Typography } from 'oskcomponents';
import { connect, useDispatch } from 'react-redux';
import { emptyCart } from '~/redux/modules/data/cart';
import { GlobalZIndex } from '~/constants';
import { AoiType, doSearchAsync, setIsSearchDirty, setRoi, setSearchFilterDates } from '~/redux/modules/data/search';

import { AppDispatch, RootState } from '../../../redux/store';
import { throttle } from 'lodash';
import { useMap, useWaitForMap } from '~/hooks';
import { OSKGeoJson } from 'oskcore';
import { LatLngBounds } from 'leaflet';

type MapSearchButtonProps = {
    /** From Redux, the current state of a search. */
    isSearching?: boolean;
    /** Whether or not a new search needs to be performed to update the visual state. */
    isSearchDirty?: boolean;
    /** Aggregate all search options from store and query API */
    doSearch?: () => void;
    /** The currently selected roi on the map */
    selectedRoi?: OSKGeoJson;
    /** The method with which the roi was set. (Automatically by us, or manually by the user.) */
    selectedRoiType?: AoiType;
};

const MapSearchButton = ({
    isSearching,
    isSearchDirty,
    doSearch,
    selectedRoi,
    selectedRoiType,
}: MapSearchButtonProps) => {
    const map = useMap();
    const dispatch = useDispatch();
    const [shouldMapEventsSetSearchDirty, setShouldMapEventsSetSearchDirty] = useState(true);
    const hasSelectedRoi = selectedRoi && selectedRoi.features.length > 0;
    const isPointAoi = hasSelectedRoi && selectedRoi.features[0].type === 'Point';

    const setSearchDirty = throttle((e) => {
        // Set the dirty flag if the map was moved AND the current ROI
        // wasn't set by the user.
        if (
            !isSearchDirty &&
            shouldMapEventsSetSearchDirty &&
            (selectedRoiType === 'viewport' || selectedRoiType === 'auto')
        ) {
            dispatch(setIsSearchDirty(true));
        }
    }, 1750);

    useWaitForMap(() => {
        map.registerLeafletEvent('move', setSearchDirty);
        map.registerLeafletEvent('zoom', setSearchDirty);

        return () => {
            map.unregisterLeafletEvent('move', setSearchDirty);
            map.unregisterLeafletEvent('zoom', setSearchDirty);
        };
    });

    useEffect(() => {
        if (!isSearching) {
            /* Search is done, prevent the dirty flag from being set
             by map events for the next 2s */
            setShouldMapEventsSetSearchDirty(false);
            setTimeout(() => setShouldMapEventsSetSearchDirty(true), 2000);
        }

        return () => {
            /* This is necessary because sometimes the map causes
            the component to reset after search, and we don't want
            to default shouldMapEventsSetSearchDirty if that happens. */
            setShouldMapEventsSetSearchDirty(false);
        };
    }, [isSearching]);

    let searchButtonLabel = 'Search the Viewport';
    if (isSearching) searchButtonLabel = 'Searching...';
    else if (isPointAoi) searchButtonLabel = 'Search this Point';
    else if (hasSelectedRoi && selectedRoiType !== 'viewport') searchButtonLabel = 'Search this Area';

    return (
        <Box
            style={{
                position: 'absolute',
                zIndex: GlobalZIndex.Timeline,
                width: '100%',
                height: '100%',
                display: isSearchDirty ? 'inherit' : 'none',
            }}
            center="horizontal"
        >
            <Box style={{ position: 'absolute', top: '100px', pointerEvents: 'auto' }}>
                <Button
                    variant="action"
                    style={{
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                    onClick={() => {
                        // If AOI isn't set, or the last one was set from the viewport bounds,
                        // create a new one from the viewport bounds
                        if (!hasSelectedRoi || selectedRoiType === 'viewport') {
                            const mapBounds: LatLngBounds = map.getMapBounds();
                            const newRoi = OSKGeoJson.fromLatLngBounds(mapBounds.pad(-0.05));
                            dispatch(setRoi(newRoi, undefined, 'viewport'));
                        }

                        doSearch && doSearch();
                    }}
                    disabled={isSearching}
                >
                    <Box center="vertical">
                        <OSKIcon code="search" scale={150} style={{ marginRight: '4px' }} />
                        <Typography variant="body2" strong p={7}>
                            {searchButtonLabel}
                        </Typography>
                    </Box>
                </Button>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isSearching: state.data.search.isSearching,
        isSearchDirty: state.data.search.isSearchDirty,
        selectedRoi: state.data.search.roi,
        selectedRoiType: state.data.search.roiType,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        doSearch: () => {
            // Clear relevant Redux values before searching
            dispatch(emptyCart());
            dispatch(setSearchFilterDates(undefined, undefined));

            dispatch<any>(doSearchAsync());
        },
    };
};

export type { MapSearchButtonProps };
export default connect(mapStateToProps, mapDispatchToProps)(MapSearchButton);
