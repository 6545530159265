import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Heading, OSKIcon, Spinner } from 'oskcomponents';

export type CollapsableGroupProps = {
    /** Whether to start with the container expanded */
    defaultExpanded?: boolean;
    /** A title to render at the top */
    title?: string | React.ReactNode;
    /** If true, the caret will be on the right side */
    flipCaret?: boolean;
    /** If true, the caret will be hidden */
    hideCaret?: boolean;
    /** Method to invoke when expanded status changes */
    onExpandChange?: (isExpanded: boolean) => void;
    /** A method that's called before expansion is finalized.
     * Returns a promise that resolves when the operation is complete. */
    beforeExpand?: () => Promise<void>;
    /** Children to render inside the container */
    children?: React.ReactNode;
} & Omit<Omit<BoxProps, 'ref'>, 'title'>;

export const CollapsableGroup = ({
    defaultExpanded,
    onExpandChange,
    beforeExpand,
    title,
    children,
    flipCaret,
    hideCaret,
    ...props
}: CollapsableGroupProps) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);
    const [isExpandFinished, setIsExpandFinished] = useState<boolean>(false);

    useEffect(() => {
        onExpandChange && onExpandChange(isExpanded);

        /* If a `beforeExpand` method is provided,
        wait for that to resolve before setting isExpandFinished */
        if (isExpanded && beforeExpand) {
            setIsExpandFinished(false);
            beforeExpand().finally(() => {
                setIsExpandFinished(true);
            });
        } else {
            setIsExpandFinished(true);
        }
    }, [onExpandChange, isExpanded]);

    return (
        <Box col {...props}>
            <Box
                onClick={() => {
                    setIsExpandFinished(false);
                    setIsExpanded(!isExpanded);
                }}
            >
                <Box
                    style={{
                        justifyContent: 'space-between',
                        flexDirection: flipCaret ? 'row-reverse' : 'row',
                        paddingRight: '8px',
                        flex: '1 1 auto',
                        minWidth: 0,
                    }}
                    center="vertical"
                >
                    {React.isValidElement(title) ? (
                        title
                    ) : (
                        <Box grow>
                            <Heading>{title}</Heading>
                        </Box>
                    )}
                    {!hideCaret && (
                        <Box
                            style={{
                                transform: `rotate(${isExpanded ? '90deg' : '0deg'})`,
                                padding: '8px',
                                minWidth: 0,
                            }}
                            center="all"
                        >
                            <OSKIcon code="arrow-forward" />
                        </Box>
                    )}
                </Box>
            </Box>

            <Box style={{ visibility: isExpanded ? 'visible' : 'hidden', display: isExpanded ? 'flex' : 'none' }} col>
                {isExpandFinished ? (
                    <Box col>{children}</Box>
                ) : (
                    <Box center="all" style={{ padding: '12px' }}>
                        <Spinner size="Medium" variant="Box" />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
