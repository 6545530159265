// NOTE: Disabling the following rule because it's enforced incorrectly.
// The property splat includes a key prop, but tsx doesn't see it.
/* eslint-disable react/jsx-key */

import React, { useEffect, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import styled from 'styled-components';
import { Box } from '../Box';
import { OSKIcon } from '../OSKIcon';
import CSS from 'csstype';
import { ColorVariants } from '../DefaultThemeProvider';
import { Typography } from '../Typography';

export type TableColumn = {
    Header: any;
    accessor: string;
    width?: number | string;
    cell?: any;
};

export type TableProps = {
    /** The definitions for the table columns to display */
    columns: Array<TableColumn>;
    /** Passthrough styled-component class name */
    className?: string;
    /** The data to map to the column definitions */
    data: Array<Record<string, any>>;
    /** If provided, the index that should be highlighted. */
    selectedIndex?: number;
    /** A method that's called when the row is clicked */
    onRowClick?: (row: Record<string, any>, index?: number) => void;
    /** Passthrough style */
    style: CSS.Properties;
    /** Whether the theme colors should be inverted */
    inverted?: boolean;
    /** Whether we should alternate row colors or use the same for each. */
    singleRowColor?: boolean;
    /** An optional method to render an expandable tray based on the full row data. */
    expandRow?: (row: any) => React.ReactNode;
    /** A method that's called when a row is expanded */
    onRowExpand?: (row: any, idx: number, expanded: boolean) => void;
    /** An optional array of rows to expand by default */
    defaultExpandRows?: number[];
    /** The theme variant to use for styling */
    variant?: ColorVariants;
    /** An optional flag to show lines between rows.*/
    showLines?: boolean;
    [key: string]: any;
};

export const Table = styled(
    ({
        columns,
        style,
        className,
        data,
        selectedIndex,
        defaultExpandRows,
        showLines,
        singleRowColor,
        onRowClick,
        expandRow,
        onRowExpand,
        ...props
    }: TableProps) => {
        const [rowExpandMap, setRowExpandMap] = useState<any>({});

        const tableUse = useTable({ columns, data, ...props }, useSortBy);
        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableUse;

        useEffect(() => {
            const newMap = { ...rowExpandMap };
            defaultExpandRows?.forEach((idx) => {
                newMap[idx] = true;
            });
            setRowExpandMap(newMap);
        }, [defaultExpandRows]);

        const toggleRowExpand = (idx: number) => {
            const newMap = { ...rowExpandMap };
            let newValue = true; // False by default, first expand

            if (idx in newMap) {
                newValue = !newMap[idx];
            }

            onRowExpand && onRowExpand(rows[idx], idx, newValue);

            newMap[idx] = newValue;
            setRowExpandMap(newMap);
        };

        // Create a hashmap of the columns indexed by accessor
        // so we can quickly lookup specific values that were
        // set by the user when they configured the header.
        //
        // If we didn't have this, we would have to rely on
        // the react-table interpretation of those default
        // values which sometimes overrides them based on
        // its own internal logic.
        const columnMap =
            columns.reduce((acc, item) => {
                acc[item.accessor] = item;
                return acc;
            }, {} as Record<string, any>) ?? {};

        return (
            <Box style={style} className={className}>
                <table className="table" {...getTableProps()} {...props}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {/* @ts-ignore */}
                                {expandRow && <th width="50px" />}
                                {headerGroup.headers.map((column: any) => {
                                    return (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            style={{
                                                width: columnMap[column.id].width,
                                                cursor: column.disableSortBy ? 'default' : 'pointer',
                                            }}
                                        >
                                            <Box row>
                                                <Box style={{ paddingRight: '4px' }}>{column.render('Header')}</Box>

                                                {column.isSorted ? (
                                                    <React.Fragment>
                                                        {column.isSortedDesc ? (
                                                            <OSKIcon code="arrow-down" />
                                                        ) : (
                                                            <OSKIcon code="arrow-up" />
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <Box w={16} />
                                                )}
                                            </Box>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, idx) => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={`row-${idx}-frag`}>
                                    <tr
                                        onClick={() => onRowClick && onRowClick(row.original, idx)}
                                        {...row.getRowProps()}
                                        className={(() => {
                                            const classes = [row.getRowProps()?.className];
                                            if (selectedIndex === idx) {
                                                classes.push('selected-row');
                                            }
                                            if (expandRow && rowExpandMap[idx]) {
                                                classes.push('expanded-child');
                                            }
                                            return classes.join(' ');
                                        })()}
                                    >
                                        {expandRow && (
                                            <td key={`row-expand-button-${idx}`}>
                                                <Box
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                        borderRadius: '2px',
                                                        fontSize: '1.25em',
                                                        fontWeight: 'bold',
                                                        userSelect: 'none',
                                                    }}
                                                    onClick={() => {
                                                        toggleRowExpand(idx);
                                                    }}
                                                    center="all"
                                                >
                                                    <Typography variant="body2" strong style={{ marginTop: '-2px' }}>
                                                        {rowExpandMap[idx] ? '-' : '+'}
                                                    </Typography>
                                                </Box>
                                            </td>
                                        )}
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                        })}
                                    </tr>
                                    {expandRow && rowExpandMap[idx] && (
                                        <tr key={`row-${idx}-expand-region`} className="expand">
                                            {/* Preserve the odd-even color scheme with a dummy component*/}
                                            <td style={{ display: 'none' }} />
                                            <td colSpan={row.cells.length + 1}>{expandRow(row)}</td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </Box>
        );
    },
)`
    &,
    thead,
    tbody,
    th,
    tr,
    td {
        font-family: '${(props: any) => props.theme.font}';
        text-align: left;
        font-size: 0.875rem;
        position: relative;
        background-color: ${(props: any) => props.theme.colors[props.variant ?? 'primary'].transparent};
    }

    & {
        border-radius: 9px;
        width: 100%;
    }

    & .table {
        width: 100%;
        border-collapse: collapse;
    }

    th {
        top: 0;
        font-size: 0.875rem;
        font-weight: 700;
    }

    thead {
        border-radius: 9px;
        color: ${(props: any) => props.theme.colors[props.variant ?? 'primary'].fg};
        background-color: transparent;
    }

    thead::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${(props: any) => props.theme.colors[props.variant ?? 'primary'].bg};
        border-radius: 9px 9px 0px 0px;
    }

    tbody tr:nth-child(even) {
        td {
            background-color: ${(props: any) =>
                props.inverted
                    ? props.singleRowColor
                        ? props.theme.colors[props.variant ?? 'primary'].lightGrey
                        : props.theme.colors[props.variant ?? 'primary'].fg
                    : props.singleRowColor
                    ? props.theme.colors[props.variant ?? 'primary'].subtleAccent
                    : props.theme.colors[props.variant ?? 'primary'].bg};
        }
    }

    tbody tr:nth-child(odd) {
        td {
            background-color: ${(props: any) =>
                props.inverted
                    ? props.theme.colors[props.variant ?? 'primary'].lightGrey
                    : props.theme.colors[props.variant ?? 'primary'].subtleAccent};
        }
    }

    tbody {
        tr:last-child {
            background-color: transparent;

            td:first-child {
                border-bottom-left-radius: 9px;
            }

            td: last-child {
                border-bottom-right-radius: 9px;
            }
        }
    }

    td,
    th {
        padding: 10px;
    }

    tbody tr:not(.expand):hover,
    tbody .selected-row {
        background-color: ${(props: any) => props.theme.colors[props.variant ?? 'primary'].highlight} !important;
        cursor: ${(props: any) => (props.onRowClick ? 'pointer' : 'normal')} !important;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid
            ${(props: any) => (props.showLines ? props.theme.colors[props.variant ?? 'primary'].bg : 'transparent')};
    }

    .expanded-child {
        border-bottom: none !important;
    }
`;
