import { Box, Typography } from 'oskcomponents';
import { OSKError } from 'oskcore/src/templates/OSKError';
import React from 'react';

const NotFoundView = () => {
    return (
        <OSKError>
            <Box center="all" col>
                <Typography variant="heading4" m={24}>
                    Page Not Found
                </Typography>
                <Typography variant="body3" style={{ textAlign: 'center' }}>
                    We couldn&apos;t find the page you&apos;re trying to access. Please try again.
                </Typography>
            </Box>
        </OSKError>
    );
};

export default NotFoundView;
