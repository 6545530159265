import { noop } from 'oskcore';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEnsureUrlParameters } from '~/hooks';
import { OSKContentView } from '~/molecules/OSKContentView';
import AssetDashboardView from '~/organisms/Monitor/AssetDashboardView';
import { MonitorResourceProvider } from '~/redux';
import { Asset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { getProgramId, setLastAppAccessed } from '~/utils';

export type MonitorDashboardViewProps = {
    assets: Asset[];
};

const MonitorDashboardView = ({ assets }: MonitorDashboardViewProps) => {
    const navigate = useNavigate();
    const { assetId, programId } = useParams();

    useEnsureUrlParameters('/program/:programId/monitor');
    setLastAppAccessed('monitor');

    useEffect(() => {
        // Redirect to the first asset in the list when the assets are populated on load if there is
        // no selectedAssetId by default.
        if (!assetId && assets && assets.length > 0 && !location.href.includes('/grid') && programId) {
            const firstAssetId = assets[0].id;
            navigate(`/program/${programId}/monitor/${firstAssetId}/grid`);
        }
    }, [assets, assetId, programId, navigate]);

    return (
        <MonitorResourceProvider
            programId={getProgramId()}
            assetId={assetId}
            features={['assets', 'alerts', 'detections']}
        >
            <OSKContentView>
                <AssetDashboardView selectedAssetId={assetId} />
            </OSKContentView>
        </MonitorResourceProvider>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        assets: Object.values(state.monitor.app.assets),
    };
};

export { MonitorDashboardView };
export default connect(mapStateToProps, noop)(MonitorDashboardView);
