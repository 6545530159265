import { Capture } from 'oskcore';
import { TimelineBucket } from '.';
import { utcToZonedTime } from 'date-fns-tz';
import { date_format_longer } from '~/utils';

/** Given a list of Captures, create a list of TimelineBuckets that represent the groups they fall into. */
export const bucketByDateNoGaps = (captures: Capture[]): TimelineBucket[] => {
    const b: any[] = new Array(50).fill(0).map(() => ({ value: 0, date: new Date() }));
    if (!captures || captures.length === 0) return b;

    // First bucket by taskid
    const taskBuckets: {
        [id: string]: Capture[];
    } = {};
    captures?.forEach((capture: Capture) => {
        if (!(capture.task_id in taskBuckets)) {
            taskBuckets[capture.task_id] = [];
        }
        taskBuckets[capture.task_id].push(capture);
    });

    // Then bucket by date, using the oldest date for all captures with a given taskid
    let minDate: Date = new Date((captures as Capture[])[0].acquisition_time);
    let maxDate: Date = new Date((captures as Capture[])[0].acquisition_time);

    const dayBuckets: {
        [id: string]: Capture[];
    } = {};
    captures?.forEach((capture: Capture) => {
        const oldestDate: Date = taskBuckets[capture.task_id]
            .map((capture) => new Date(capture.acquisition_time))
            .reduce((oldest, acquisition_time) => (oldest < acquisition_time ? oldest : acquisition_time));

        const key = date_format_longer(oldestDate);
        if (oldestDate < minDate) minDate = oldestDate;
        if (oldestDate > maxDate) maxDate = oldestDate;
        if (!(key in dayBuckets)) {
            dayBuckets[key] = [];
        }

        dayBuckets[key].push(capture);
    });

    let minBucketSize = Number.MAX_SAFE_INTEGER;
    let maxBucketSize = 0;
    Object.values(dayBuckets).forEach((c: Capture[]) => {
        if (c.length < minBucketSize) minBucketSize = c.length;
        if (c.length > maxBucketSize) maxBucketSize = c.length;
    });

    const returnBuckets = Object.entries(dayBuckets).map((value) => {
        const bucketDate = new Date(value[0]);
        const c: Capture[] = value[1];
        const data: TimelineBucket = {
            value: (c.length - minBucketSize) / (maxBucketSize - minBucketSize),
            date: utcToZonedTime(bucketDate, 'UTC'),
        };
        return data;
    });

    return returnBuckets.sort((a, b) => (a.date > b.date ? 1 : -1));
};

/** Generate a CSS clip-path value for a given list of buckets. */
export const histogramGetClipPath = (buckets: TimelineBucket[], minValue = 0, maxValue = 1) =>
    `polygon(0 100%,${buckets.map((bucket, idx) => {
        const space = 100 / (buckets.length - 1);
        return `${idx * space}% ${Math.max(Math.min(100 - bucket.value * 100, minValue), maxValue)}%`;
    })}, 101% 100%)`;

/** Generate a CSS clip-path value for a given list of buckets in a selected sub-range. */
export const histogramGetSelectedClipPath = (
    buckets: TimelineBucket[],
    startIndex: number,
    endIndex: number,
    minValue = 0,
    maxValue = 1,
) =>
    `polygon(0 100%,${buckets
        .filter((bucket, idx) => idx >= startIndex && idx <= endIndex)
        .map((bucket, idx) => {
            const space = 100 / (buckets.length - 1);
            const returns: string[] = [];
            const i = idx + startIndex;

            if (i === startIndex) {
                returns.push(`${i * space - 0.01}% 100%`);
            }

            returns.push(`${i * space}% ${Math.max(Math.min(100 - bucket.value * 100, minValue), maxValue)}%`);

            if (i === endIndex) {
                returns.push(`${i * space + 0.01}% 100%`);
            }

            return returns;
        })}, 101% 100%)`;
