import { Box, Divider, OSKIcon, OSKThemeType, Typography } from 'oskcomponents';
import React, { useEffect } from 'react';

import { noop, useProfileMetadata } from 'oskcore';
import { connect, useDispatch } from 'react-redux';
import CatalogOrders from './CatalogOrders';
import {
    fetchOrdersAsync,
    resetOrderData,
    selectArchiveOrdersByPage,
    setActiveOrderId,
    setOrderNetworkError,
    setOrdersUserFilter,
    SigmaOrder,
} from '~/redux/modules/data/orders';
import { RootState } from '~/redux/store';
import ArchiveOrderPaginator from './CatalogOrders/paginator';
import { clearDataResourceProvider } from '~/redux';
import { useTheme } from 'styled-components';
import { getProgramId } from '~/utils';

export type OrderListPanelProps = {
    /** The first archive order in redux, used for setting the default selection */
    firstArchiveOrder?: SigmaOrder;
    /** Whether or not archive orders are loading */
    isLoading?: boolean;
    /** The current 'requested by' user filter for orders  */
    ordersUserFilter?: number;
};

const OrderListPanel = ({ firstArchiveOrder, isLoading, ordersUserFilter }: OrderListPanelProps) => {
    const dispatch = useDispatch();
    const [profile] = useProfileMetadata();
    const theme = useTheme() as OSKThemeType;

    useEffect(() => {
        // Select the first order
        if (firstArchiveOrder && !isLoading) {
            dispatch(setActiveOrderId(firstArchiveOrder.id));
        }
    }, [dispatch, firstArchiveOrder, isLoading]);

    return (
        <Box col style={{ height: '100%' }} p={14}>
            <Typography variant="heading2" style={{ padding: '0px 14px', marginBottom: '8px' }}>
                Order Dashboard
            </Typography>

            <Box center="vertical">
                <Box
                    center="all"
                    style={{
                        justifyContent: 'space-between',
                        padding: '8px',
                        width: '95px',
                        marginLeft: '8px',
                        backgroundColor: ordersUserFilter ? theme.colors.blue1 : theme.colors.transparent,
                        color: ordersUserFilter ? theme.colors.white : theme.colors.black900,
                        borderRadius: '5px',
                    }}
                    onClick={() => {
                        const programId = getProgramId();
                        dispatch(resetOrderData());

                        if (ordersUserFilter) {
                            dispatch<any>(fetchOrdersAsync('archive', programId, 0));
                            dispatch(setOrdersUserFilter());
                        } else {
                            clearDataResourceProvider();
                            dispatch(setOrdersUserFilter(profile.id));
                        }
                    }}
                >
                    <OSKIcon
                        code="user"
                        fill={ordersUserFilter ? theme.colors.white : theme.colors.black900}
                        style={{ paddingRight: '4px' }}
                    />
                    <Typography variant="body3">My Tasks</Typography>
                </Box>

                <Box
                    center="all"
                    style={{
                        justifyContent: 'space-between',
                        padding: '8px',
                        width: '90px',
                        marginLeft: '8px',
                    }}
                    onClick={() => {
                        const programId = getProgramId();

                        // Refresh orders
                        dispatch(setOrderNetworkError(false));
                        dispatch(resetOrderData());
                        dispatch<any>(fetchOrdersAsync('archive', programId, 0));
                    }}
                >
                    <OSKIcon fill={theme.colors.black900} code="refresh" />
                    <Typography variant="body3">Refresh</Typography>
                </Box>
            </Box>

            <Divider vm={14} />

            <CatalogOrders />

            {firstArchiveOrder && (
                <ArchiveOrderPaginator
                    inverted
                    style={{
                        borderRadius: '0px',
                        margin: '0px -14px -14px -14px',
                        padding: '14px',
                        boxShadow: '-6px -2px 7px -1px #09080833',
                    }}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    const firstArchiveOrder = (selectArchiveOrdersByPage(state, 0) ?? [])?.[0];
    const { loadingArchiveOrders, ordersUserFilter } = state.data.orders;

    return {
        firstArchiveOrder,
        isLoading: loadingArchiveOrders,
        ordersUserFilter: ordersUserFilter,
    };
};

export default connect(mapStateToProps, noop)(OrderListPanel);
