import React, { useEffect, useRef } from 'react';
import { Box } from '../Box';
import styled, { useTheme } from 'styled-components';
import { useRefState } from '../hooks';
import { clamp } from '../utils';
import { ColorVariants, OSKThemeType } from '../DefaultThemeProvider';
import { Typography } from '../Typography';

type SliderProps = {
    /** The lowest value the user can select */
    minValue: number;
    /** The highest value the user can select */
    maxValue: number;
    /** The optional starting value. If not provided, minValue is used */
    defaultValue?: number;
    /** The precision of the value captured, in # of decimal places i.e. '2' for .00 */
    decimals?: number;
    /** The theme variant of this button */
    variant?: ColorVariants;
    /** An optional override for the label on the lefthand side of the control. */
    minValueLabel?: JSX.Element;
    /** An optional override for the label on the righthand side of the control. */
    maxValueLabel?: JSX.Element;
    /** The name of the form field this element is associated with */
    name?: string;
    /** The method that's called when the value changes */
    onChange?: (newValue: number, oldValue: number) => void;
    /** Overrides the default value display with custom formatting */
    getLabel?: (value: number) => string;
    /** Passthrough for styled-component */
    className?: string;
};

const SLIDER_HANDLE_RADIUS = 15;

const SliderBase = ({
    minValue = 0,
    maxValue = 100,
    defaultValue = 0,
    decimals = 0,
    minValueLabel,
    maxValueLabel,
    name,
    onChange,
    getLabel,
    className,
    variant = 'action',
    ...props
}: SliderProps) => {
    const theme = useTheme() as OSKThemeType;

    // Refs
    const containerRef = useRef<any>(null);
    const dragRef = useRef<any>(null);

    // Clamp a given value between our min and max
    const rangeClamp = (value: number) => clamp(value, minValue, maxValue);

    // Hooks / ref values
    const [getValue, setValue] = useRefState<number>(defaultValue >= 0 ? rangeClamp(defaultValue) : minValue);
    const dragStartPosition = useRef([0, 0]);
    const dragStartValue = useRef(getValue());
    const [getPixelValue, setPixelValue] = useRefState<number>(0);
    const [getIsDragging, setIsDragging] = useRefState<boolean>(false);

    // Used for updating the page once
    const [, updateState] = React.useState({});
    const update = React.useCallback(() => updateState({}), []);

    // Calculated constants
    const barWidth = containerRef.current ? containerRef.current.offsetWidth : -1;
    const perc = (getValue() - minValue) / (maxValue - minValue);

    // Update the pixel value scale whenever the width changes
    useEffect(() => {
        setPixelValue((maxValue - minValue) / barWidth);
    }, [barWidth, containerRef, maxValue, minValue]);

    // Force one refresh to populate the refs
    useEffect(() => {
        update();
    }, []);

    // If the default value changes externally, update our value
    useEffect(() => {
        setValue(rangeClamp(defaultValue));
    }, [defaultValue]);

    function handleMouseMoveStart(evt: MouseEvent) {
        setIsDragging(true);

        dragStartPosition.current = [evt.clientX, evt.clientY];
        dragStartValue.current = getValue();
    }

    function handleMouseMoveEnd() {
        setIsDragging(false);
        dragStartValue.current = getValue();
    }

    function handleMouseMove(evt: MouseEvent) {
        if (getIsDragging()) {
            const delta = {
                x: dragStartPosition.current[0] - evt.clientX,
                y: dragStartPosition.current[1] - evt.clientY,
            };
            const offset = delta.x * getPixelValue();
            const oldValue = getValue();
            const newValue = parseFloat((dragStartValue.current - offset).toFixed(decimals));
            const clamped = rangeClamp(newValue);

            setValue(clamped);
            onChange && onChange(clamped, oldValue);
        }
    }

    function onResize() {
        update();
    }

    useEffect(() => {
        window.addEventListener('resize', onResize);

        if (dragRef.current) {
            dragRef.current.addEventListener('mousedown', handleMouseMoveStart);
            window.addEventListener('mouseup', handleMouseMoveEnd);
            window.addEventListener('mousemove', handleMouseMove);
        }

        return () => {
            window.removeEventListener('resize', onResize);

            if (dragRef.current) {
                dragRef.current.removeEventListener('mousedown', handleMouseMoveStart);
                window.removeEventListener('mouseup', handleMouseMoveEnd);
                window.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, []);

    return (
        <>
            <Box className={className} {...props} col>
                <Box className="slider-line" ref={containerRef} />
                <Box
                    className="slider-fill"
                    style={{
                        width: `calc(${perc * barWidth}px)`,
                    }}
                />
                <Box
                    draggable={false}
                    className="slider-point-container"
                    center="all"
                    ref={dragRef}
                    style={{
                        left: `${perc * barWidth}px`,
                        top: '0px',
                    }}
                >
                    <Box
                        draggable={false}
                        className="slider-point"
                        onDragStart={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                            return false;
                        }}
                    />
                </Box>
                <Box
                    className="slider-labels"
                    style={{ justifyContent: 'space-between', color: theme.colors.black500 }}
                >
                    <Typography variant="caption3" className="min-max-label">
                        {minValueLabel ? minValueLabel : getLabel ? getLabel(minValue) : minValue}
                    </Typography>
                    <Typography variant="heading5" data-testid="slider-value" color={theme.colors[variant].invertedFg}>
                        {getLabel ? getLabel(getValue()) : getValue()}
                    </Typography>
                    <Typography variant="caption3" className="min-max-label">
                        {maxValueLabel ? maxValueLabel : getLabel ? getLabel(maxValue) : maxValue}
                    </Typography>
                </Box>
            </Box>
            {name && <input type="hidden" name={name} value={getValue()} />}
        </>
    );
};

const Slider = styled(SliderBase)`
    & {
        width: 100%;
        position: relative;
        height: 15px;
        padding: 12px ${SLIDER_HANDLE_RADIUS}px;
        user-drag: none;

        div {
            user-drag: none;
        }
    }

    &:hover {
        .slider-labels {
            .min-max-label {
                opacity: 1;
            }
        }
    }

    .slider-line {
        min-width: 50px;
        width: 100%;
        min-height: 5px;
        background-color: ${(props: any) => props.theme.colors.black200};
        border-radius: 10px;
        pointer-events: none;
    }

    .slider-fill {
        height: 5px;
        background-color: ${(props: any) =>
            props.disabled
                ? props.theme.colors.disabled.bg
                : props.bg ?? props.inverted
                ? props.theme.colors[props.variant].invertedBg
                : props.theme.colors[props.variant].bg};
        border-radius: 10px;
        position: absolute;
        pointer-events: none;
    }

    .slider-point-container {
        width: ${SLIDER_HANDLE_RADIUS * 2}px;
        height: ${SLIDER_HANDLE_RADIUS * 2}px;
        position: absolute;
        left: calc(100px - 15px);
        cursor: pointer;
        scale: 100%;
        pointer-events: all;
    }

    .slider-point {
        height: ${SLIDER_HANDLE_RADIUS}px;
        width: ${SLIDER_HANDLE_RADIUS}px;
        border-radius: 25px;
        background-color: ${(props: any) =>
            props.disabled
                ? props.theme.colors.disabled.bg
                : props.bg ?? props.inverted
                ? props.theme.colors[props.variant].invertedBg
                : props.theme.colors[props.variant].bg};
        transition: all 0.5s;
        pointer-events: all;
    }

    .slider-point-container:hover {
        .slider-point {
            filter: drop-shadow(0px 0px 2px #000000cc);
            scale: 150%;
        }
    }

    .slider-labels {
        position: relative;
        min-width: 100%;
        bottom: -4px;
        user-select: none;
    }

    .min-max-label {
        opacity: 0;
        transition: opacity 0.5s;
    }
`;

Slider.defaultProps = {
    variant: 'action',
};

export { Slider };
