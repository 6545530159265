import React, { useCallback, useState } from 'react';
import { Box, OSKIcon } from 'oskcomponents';
import { InternalRasterCapture } from '..';
import { GlobalZIndex } from '~/constants';
import { ImgTiff } from '~/atoms';

type CapturesLightboxProps = {
    /** The capture to render the preview_artifact for */
    capture: InternalRasterCapture;
    /** The method to call when the lightbox should close */
    onClose?: () => void;
};

const CapturesLightbox = ({ capture, onClose }: CapturesLightboxProps) => {
    const [imgHeight, setImgHeight] = useState<number>();

    const measuredRef = useCallback((node) => {
        if (node !== null) {
            setImgHeight(node.offsetHeight);
        }
    }, []);

    return (
        <Box
            ref={measuredRef}
            style={{
                width: '100%',
                height: '100%',
                zIndex: GlobalZIndex.ActionBar,
                backgroundColor: 'rgba(0,0,0,.75)',
                backdropFilter: 'blur(4px)',
            }}
            center="all"
            onClick={() => {
                onClose && onClose();
            }}
        >
            <Box
                style={{
                    width: '100%',
                    height: '100%',
                    zIndex: GlobalZIndex.ActionBar,
                }}
                center="all"
            >
                <Box
                    style={{ position: 'absolute', top: '25px', right: '25px' }}
                    onClick={() => {
                        onClose && onClose();
                    }}
                >
                    <OSKIcon code="times" fill="white" scale={200} />
                </Box>
                <ImgTiff
                    src={capture.preview_artifact ?? ''}
                    width={1024}
                    height={1024}
                    style={{
                        borderRadius: ' 0px',
                        height: `${imgHeight}px`,
                        cursor: 'default',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                />
            </Box>
        </Box>
    );
};

export default CapturesLightbox;
