import CSS from 'csstype';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';
import { TabItem } from './TabItem';

export type TabViewItem = {
    /** Label for Tab option */
    label: string;
    /** Component For the Tab option */
    component: React.ReactNode;
};

export type TabProps = {
    /** ClassName generated by styled-components */
    className?: string;
    /** Default active tab */
    defaultTab?: number;
    /** Children components to render inside the modal */
    tabItems: Array<TabViewItem>;
    /** custom styling for Tab Item */
    tabStyle?: object;
    /** Custom styling for the Tab Item Container */
    tabContainerStyle?: CSS.Properties;
    /** show scrollbars for content */
    contentStyle?: object;
    /** Method that's called when the selected tab changes */
    onTabChange?: (index: number) => void;
} & Omit<BoxProps, 'ref'>;

const TabView = styled(
    ({
        className,
        defaultTab,
        style,
        tabContainerStyle,
        tabStyle,
        contentStyle,
        tabItems,
        onTabChange,
        ...props
    }: TabProps) => {
        const [activeIndex, setActiveIndex] = useState<number>(defaultTab ?? 0);
        const [viewed, setViewed] = useState<Record<number, boolean>>({ [defaultTab ?? 0]: true });

        const hiddenStyle: CSS.Properties = {
            width: 0,
            height: 0,
            visibility: 'hidden',
            display: 'none',
        };

        function doChangeTab(tabIndex: number) {
            setActiveIndex(tabIndex);
            onTabChange && onTabChange(tabIndex);
            setViewed({ ...viewed, [tabIndex]: true });
        }

        useEffect(() => {
            doChangeTab(defaultTab ?? 0);
        }, [defaultTab]);

        return (
            <Box grow col style={{}}>
                <Box data-testid="Tab-Wrapper" className={className} style={tabContainerStyle} {...props}>
                    {tabItems.map((item, idx) => {
                        const { label } = item;
                        return (
                            <TabItem
                                key={label}
                                label={label}
                                onClick={() => {
                                    doChangeTab(idx);
                                }}
                                active={activeIndex === idx}
                                tabStyle={tabStyle}
                            />
                        );
                    })}
                </Box>

                {tabItems.map((item, idx) => {
                    const styleOverride = idx === activeIndex ? { ...contentStyle } : { ...hiddenStyle };
                    return (
                        <Box className="contents" key={item.label} grow col style={{ ...styleOverride }}>
                            {viewed[idx] && item.component}
                        </Box>
                    );
                })}
            </Box>
        );
    },
)`
    & {
        height: 44px;
        flex-shrink: 1;
        background: ${(props: any) => props.theme.colors.primary.bg};
    }

    & .contents {
        display: block !important;
        height: 0 !important;
        overflow: scroll !important;
    }
`;

export { TabView };
