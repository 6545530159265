import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Box, Button } from 'oskcomponents';
import { BaseAdminDashboard } from '../BaseAdminDashboard';
import { ProgramForm, ProgramSchema } from '~/organisms/forms/ProgramForm';
import { AdminAPI, mapToInt, ProgramRequest } from 'oskcore';
import { incrementBusyCount, decrementBusyCount } from '~/redux/modules/data/app';
import { useDispatch } from 'react-redux';
import { useTemplateHistory } from '~/hooks';
import { NoPermission } from '~/atoms';

export type ProgramCreateDashboardProps = {
    className?: string;
};

export const ProgramCreateDashboard = styled(({ className }: ProgramCreateDashboardProps) => {
    const [creating, setCreating] = useState(false);
    const [allowed, setAllowed] = useState(true);

    const dispatch = useDispatch();
    const history = useTemplateHistory();

    const handleSubmit = (props: any) => {
        setCreating(true);
        dispatch(incrementBusyCount());

        const request: ProgramRequest = {
            ...(props as ProgramRequest),
        };

        // Convert multi-select fields into actual arrays.
        if (props.sensor_data_access) {
            request.sensor_data_access = mapToInt((props.sensor_data_access ?? '').split(','));
        }

        if (props.product_level_access) {
            request.product_level_access = (props.product_level_access ?? '').split(',');
        }

        if (props.subscribed_products) {
            request.subscribed_products = (props.subscribed_products ?? '').split(',');
        }

        AdminAPI.internalCreateProgram({ programRequest: request })
            .then((resp) => {
                if (resp.status === 403) {
                    setAllowed(false);
                } else {
                    history.push('/admin/view_programs');
                }
            })
            .catch((err) => {
                const { response } = err;
                if (response && response.status === 403) {
                    setAllowed(false);
                } else {
                    console.error(err);
                }
            })
            .finally(() => {
                setCreating(false);
                dispatch(decrementBusyCount());
            });
    };

    return (
        <BaseAdminDashboard title={<span>New Program</span>}>
            <Box className={className} grow>
                {!allowed && <NoPermission text="You do not have the permissions to create new programs." />}
                {allowed && (
                    <Form schema={ProgramSchema} onSubmit={handleSubmit}>
                        <Box className="form-container" grow col>
                            <ProgramForm readonly={false} />
                            <Box className="submit-container">
                                <Button
                                    disabled={creating}
                                    variant="neutral"
                                    style={{
                                        alignSelf: 'flex-end',
                                    }}
                                    inverted
                                    submit
                                />
                            </Box>
                        </Box>
                    </Form>
                )}
            </Box>
        </BaseAdminDashboard>
    );
})`
    form {
        width: 100%;
    }

    & {
        width: 100%;
        align-self: center;
        justify-content: center;
    }

    & .form-container {
        padding-bottom: 40px;
    }

    & .submit-container {
        justify-content: flex-end;
    }
`;
