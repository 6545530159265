import { Box, OSKThemeType, OSKIcon, RangeCalendar, Text, useClickAway } from 'oskcomponents';
import { GlobalZIndex } from 'oskcomponents/src/constants';
import { noop } from 'oskcore';
import React, { useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { setFilterDates } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { date_format_long } from '~/utils';

type RangeType = 'All Time' | 'Today' | 'Yesterday' | 'Last Week' | 'Last Month' | 'Last 3 Months' | 'Last Year';

const rangePresets: Record<RangeType, number> = {
    'All Time': 1,
    Today: 0,
    Yesterday: -1,
    'Last Week': -7,
    'Last Month': -31,
    'Last 3 Months': -(31 * 3),
    'Last Year': -365,
};

type QuickFilterOptionProps = {
    value: RangeType;
    className?: string;
    onClick: () => void;
};

const QuickFilterOption = styled(({ value, className, onClick, ...props }: QuickFilterOptionProps) => (
    <Box className={className} key={value} onClick={onClick} p={4} {...props}>
        {value}
    </Box>
))`
    :hover {
        font-weight: bold;
    }
`;

export type DateRangePickerProps = {
    /** The currently-selected default start date from redux */
    defaultStartDate?: Date;
    /** The currently-selected default end date pulled from redux */
    defaultEndDate?: Date;
};

export const DateRangePicker = ({ defaultStartDate, defaultEndDate }: DateRangePickerProps) => {
    let defaultRangeDisplay = 'All Time';
    if (defaultStartDate && defaultEndDate) {
        if (defaultStartDate?.toDateString() === defaultEndDate?.toDateString()) {
            defaultRangeDisplay = date_format_long(defaultStartDate);
        } else {
            defaultRangeDisplay = `${defaultStartDate ? date_format_long(defaultStartDate) : '--'} - ${
                defaultEndDate ? date_format_long(defaultEndDate) : '--'
            }`;
        }
    }

    const [startDate, setStartDate] = useState<Date | undefined>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date | undefined>(defaultEndDate);
    const [pickerVisible, setPickerVisible] = useState<boolean>(false);
    const [rerender, setRerender] = useState(0);
    const [rangeDisplay, setRangeDisplay] = useState<string>(defaultRangeDisplay);
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();

    const ref = useRef<HTMLDivElement>(null);

    useClickAway(
        ref,
        () => {
            setPickerVisible(false);
        },
        pickerVisible,
    );

    return (
        <Box ref={ref} style={{ zIndex: GlobalZIndex.DatePicker }}>
            <Box
                w={275}
                h={34}
                bg={theme.colors.primary.transBg}
                center="all"
                p={8}
                m={16}
                style={{ borderRadius: '5px', position: 'relative' }}
                onClick={() => setPickerVisible(!pickerVisible)}
            >
                <OSKIcon code="calendar" style={{ position: 'absolute', left: '16px' }} />
                <Text>{rangeDisplay}</Text>
                <Box
                    style={{ position: 'absolute', right: '16px' }}
                    center="all"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setStartDate(undefined);
                        setEndDate(undefined);
                        setRangeDisplay('All Time');
                        dispatch(setFilterDates(undefined, undefined));
                    }}
                    w={24}
                    h={24}
                >
                    <Text strong>x</Text>
                </Box>
            </Box>

            {pickerVisible && (
                <Box
                    row
                    style={{
                        position: 'absolute',
                        top: '43px',
                        right: '0px',
                        paddingLeft: '24px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                    bg={theme.colors.primary.bg}
                    center="all"
                >
                    <Box
                        col
                        center="vertical"
                        style={{
                            padding: '24px 8px 24px 0px',
                            borderRight: `1px solid ${theme.colors.primary.border}`,
                        }}
                        w={150}
                    >
                        <Text strong style={{ paddingBottom: '4px' }}>
                            Quick Filter
                        </Text>
                        {Object.keys(rangePresets).map((key) => (
                            <QuickFilterOption
                                key={`range-${key}`}
                                value={key as RangeType}
                                onClick={() => {
                                    setRangeDisplay(key);
                                    // Positive range value indicates 'no filter'
                                    if (rangePresets[key as RangeType] > 0) {
                                        setStartDate(undefined);
                                        setEndDate(undefined);
                                        dispatch(setFilterDates(undefined, undefined));
                                    } else {
                                        // TODO: Add nicer handling for months / quarters.
                                        const today = new Date();
                                        const rangeStart = new Date();
                                        // range.value here will be negative, so we use addition
                                        rangeStart.setDate(today.getDate() + rangePresets[key as RangeType]);

                                        setStartDate(rangeStart);
                                        setEndDate(today);
                                        dispatch(setFilterDates(rangeStart, today));
                                    }
                                    setRerender(new Date().getTime());
                                }}
                            />
                        ))}
                    </Box>
                    <Box style={{ alignItems: 'flex-start' }}>
                        <RangeCalendar
                            doubleWide
                            key={`range-calendar-${rerender}`} /* This is a trick to make it re-render only when the quick filters are selected, because that's the only time this state variable (rerender) will change */
                            style={{ margin: '18px' }}
                            defaultStartDate={startDate}
                            defaultEndDate={endDate}
                            onRangeChange={(newStart, newEnd) => {
                                if (newStart?.toDateString() !== newEnd?.toDateString()) {
                                    setRangeDisplay(
                                        `${newStart ? date_format_long(newStart) : '--'} - ${
                                            newEnd ? date_format_long(newEnd) : '--'
                                        }`,
                                    );
                                } else {
                                    setRangeDisplay(date_format_long(newStart));
                                }
                                setStartDate(newStart);
                                setEndDate(newEnd);
                                dispatch(setFilterDates(newStart, newEnd));
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        defaultStartDate: state.monitor.app.filterStartDate,
        defaultEndDate: state.monitor.app.filterEndDate,
    };
};

export default connect(mapStateToProps, noop)(DateRangePicker);
