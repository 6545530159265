import { Box, Button, IconSwitch, OSKIcon, OSKThemeType, Spinner, Table, TableColumn, Typography } from 'oskcomponents';
import React from 'react';
import CapturesListView from '~/views/ToolsTaskSearchView/CapturesListView';
import CapturesGridView from '~/views/ToolsTaskSearchView/CapturesGridView';
import { useTheme } from 'styled-components';
import { Sensor, noop } from 'oskcore';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { InternalRasterCapture } from '~/views/ToolsTaskSearchView';
import { date_format_long } from '~/utils';

type ToolsTaskSearchTableExpandMode = 'list' | 'grid';

type ToolsTaskSearchTableProps = {
    /** The tasking data to display in the table */
    data?: any;
    /** Whether or not to display captures in the expand tray as a list or grid */
    expandMode?: ToolsTaskSearchTableExpandMode;
    /** A list of sensor metadata from redux */
    sensors?: Sensor[];
    /** A method that's called when the expand mode changes. */
    onModeChange?: (mode: ToolsTaskSearchTableExpandMode) => void;
    /** A method that's called when a row is expanded */
    onRowExpand?: (row: any, idx: number, expanded: boolean) => void;
    /** A method that's called when an image in a grid-view card is selected */
    onImageSelect?: (capture: InternalRasterCapture) => void;
};

const ToolsTaskSearchTable = ({
    data,
    expandMode,
    sensors,
    onModeChange,
    onRowExpand,
    onImageSelect,
}: ToolsTaskSearchTableProps) => {
    const theme = useTheme() as OSKThemeType;

    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            {
                Header: 'Task ID',
                accessor: 'task_id',
                disableSortBy: true,
            },
            {
                Header: 'Sensor',
                accessor: 'sensor',
                Cell: ({ row }: any) => {
                    const { sensor: sensorId, captures } = row.original;
                    return (
                        sensors?.find(
                            (sensor) => sensor.osk_id === (sensorId ?? (captures.length > 0 && captures[0].sensor)),
                        )?.osk_sensor_name ?? <Spinner size="Tiny" />
                    );
                },
                disableSortBy: true,
            },
            {
                Header: 'Min. Acquired Date',
                accessor: 'start_acquisition_time',
                Cell: ({ row }: any) => {
                    const { start_acquisition_time } = row.values;
                    return date_format_long(start_acquisition_time);
                },
                disableSortBy: true,
            },
            {
                Header: 'Max. Acquired Date',
                accessor: 'end_acquisition_time',
                Cell: ({ row }: any) => {
                    const { end_acquisition_time } = row.values;
                    return date_format_long(end_acquisition_time);
                },
                disableSortBy: true,
            },
            {
                Header: '',
                accessor: 'order',
                Cell: ({ row }: any) => (
                    <Box style={{ justifyContent: 'flex-end' }}>
                        <Button
                            variant="action"
                            onClick={() => {
                                window.open(`/map/@/?taskid=${row.values.task_id}`);
                            }}
                        >
                            Order
                        </Button>
                    </Box>
                ),
                disableSortBy: true,
            },
        ],
        [],
    );

    const ListGridSwitch = () => (
        <IconSwitch
            options={[
                {
                    value: 'list',
                    icon: 'list',
                },
                {
                    value: 'grid',
                    icon: 'grid',
                },
            ]}
            selectedValue={expandMode}
            onSelect={(value: any) => {
                onModeChange && onModeChange(value);
            }}
            variant="contrast"
        />
    );

    if (!data) {
        return (
            <Box grow center="horizontal">
                <Box col center="horizontal" w={500} h={240} style={{ textAlign: 'center' }}>
                    <OSKIcon code="no-captures" width={300} style={{ marginBottom: '-35px' }} />
                    <Typography variant="heading3">No Results to Display</Typography>
                    <Typography variant="body3" m={12}>
                        There are currently no results to display. This could mean that the task is still in progress.
                        You can try searching for a different Task ID, or checking that the given Task ID is correct.
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Table
            columns={columns}
            data={data}
            singleRowColor
            showLines
            defaultExpandRows={data.length === 1 ? [0] : []}
            variant="neutral"
            style={{ border: `2px solid ${theme.colors.lightGray}`, color: 'black' }}
            onRowExpand={onRowExpand}
            expandRow={(row: any) => {
                const { captures } = row.original;
                switch (expandMode) {
                    case 'list':
                    default:
                        return (
                            <CapturesListView captures={captures}>
                                <Box grow style={{ position: 'absolute', right: '50px' }}>
                                    <ListGridSwitch />
                                </Box>
                            </CapturesListView>
                        );
                    case 'grid':
                        return (
                            <CapturesGridView captures={captures} onImageClick={onImageSelect}>
                                <Box grow style={{ position: 'absolute', right: '50px' }}>
                                    <ListGridSwitch />
                                </Box>
                            </CapturesGridView>
                        );
                }
            }}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    const { sensors } = state.osk;

    return {
        sensors,
    };
};

export default connect(mapStateToProps, noop)(ToolsTaskSearchTable);

export type { ToolsTaskSearchTableProps, ToolsTaskSearchTableExpandMode };
