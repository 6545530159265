import * as toGeoJson from '@tmcw/togeojson';
import {
    Feature,
    FeatureCollection,
    GeoJsonProperties,
    LineString,
    MultiLineString,
    MultiPoint,
    Point,
    Polygon,
} from 'geojson';
import { LatLng, LatLngBounds } from 'leaflet';
import { getBounds } from '..';
import { Geometry, GeometryCollection } from '../api/generated';
import geoJsonArea from '@mapbox/geojson-area';

export type LeafletFeature = {
    type: 'Polygon' | 'Point' | 'MultiPoint' | 'LineString' | 'MultiLineString';
    coordinates: LatLng[];
};

/**
 * Assert whether something is true or not. If it ends up being a falsey assertion,
 * the error message is logged to the console. This, in turn, will be picked up
 * by sentry and other log-reporting middleware we have.
 */
function assert(truthy: boolean, errorMessage: string) {
    if (!truthy) {
        console.error(errorMessage);
    }
}

function coordinatesToLatLng(position: number[] | number[][] | number[][][]): LatLng | LatLng[] {
    if (position === undefined || position === null) {
        return [];
    }

    if (position.length == 0) {
        return [];
    }

    if (Array.isArray(position) && Array.isArray(position[0]) && Array.isArray(position[0][0])) {
        return coordinatesToLatLng(position[0]);
    }

    if (Array.isArray(position[0])) {
        const coordinates = position as number[][];
        return coordinates.map((pos: number[]) => {
            return new LatLng(pos[1], pos[0]);
        });
    } else if (Array.isArray(position)) {
        const coordinates = position as number[];
        return new LatLng(coordinates[1], coordinates[0]);
    } else {
        return [];
    }
}

/**
 * A single lat/lng coordinate
 */
export type OSKCoordinate = LatLng;

/**
 * An array of lat/lng coordinates
 */
export type OSKCoordinates = Array<OSKCoordinate>;

/**
 * A geoJSON feature
 */
export type OSKFeature = (Point | MultiPoint | LineString | MultiLineString | Polygon) & {
    name?: string;
};

/**
 * A wrapper class which encapsulates any representation of a geoJSON object.
 * This class contains helper methods which can convert between the API geometries
 * and the leaflet geometries.
 *
 * This class should be the only geometry object that is passed around anywhere
 * in the application.
 */
export class OSKGeoJson {
    /** An array of geoJSON features */
    features: Array<OSKFeature> = [];
    _source: any = {};

    constructor() {}

    /**
     * This method converts a real, compliant geoJSON object into an OSKGeoJson object
     *
     * @param geoJson A real geoJSON object
     */
    static fromGeoJSON(geoJson: FeatureCollection) {
        if (geoJson === undefined || geoJson === null) return new OSKGeoJson();
        if (geoJson.features === undefined || geoJson.features.length === 0) return new OSKGeoJson();

        const result = new OSKGeoJson();
        result._source = geoJson;

        for (const feature of geoJson.features) {
            const oskFeature = this.parseFeatureFromGeometry(feature.geometry as Geometry);
            if (oskFeature) {
                oskFeature.name = feature?.properties?.name;
                result.features.push(oskFeature);
            }
        }

        return result;
    }

    /**
     * This will parse a KML file into an OSKGeoJson instance.
     *
     * @param kmlFileContents A string representation of a KML XML file
     * @returns An OSKGeoJson instance
     */
    static fromKML(kmlFileContents: string) {
        if (kmlFileContents === undefined || kmlFileContents === null) return new OSKGeoJson();

        const kml_raw = new DOMParser().parseFromString(kmlFileContents, 'text/xml');
        const converted = toGeoJson.kml(kml_raw);
        const converted_folders = toGeoJson.kmlWithFolders(kml_raw);
        const hasFolders =
            converted_folders.children &&
            converted_folders.children.length > 0 &&
            converted_folders.children[0].type === 'folder';

        // If the KML has folders, parse it ourselves.
        if (hasFolders) {
            const rootNode = converted_folders;

            const featureCollection: FeatureCollection = {
                type: 'FeatureCollection',
                features: this._kmlParseFolder(rootNode),
            } as FeatureCollection;

            return OSKGeoJson.fromGeoJSON(featureCollection);
        }

        return OSKGeoJson.fromGeoJSON(converted);
    }

    static _kmlParseFolder(folder: any): Feature<GeoJSON.Geometry | null, GeoJsonProperties>[] {
        let geometries: Feature<GeoJSON.Geometry | null, GeoJsonProperties>[] = [];

        folder.children.forEach((node: any, idx: number) => {
            switch (node.type.toLowerCase()) {
                case 'folder':
                    geometries = [...geometries, ...this._kmlParseFolder(node)];
                    break;
                case 'feature':
                    const { name: featureName } = node.properties;
                    const nodeName = featureName ??
                        ('meta' in folder
                            ? (folder.children.length > 1 ? `${folder.meta.name ?? '.'} ${node.id ?? idx + 1}` : folder.meta.name)
                            : `${node.id ?? idx + 1}`);
                            
                    const newGeometry: Feature<GeoJSON.Geometry | null, GeoJsonProperties> = {
                        type: node.geometry.type,
                        geometry: node.geometry,
                        properties: {
                            name: nodeName
                        },
                    };

                    geometries = [...geometries, newGeometry];
                    break;
            }
        });

        return geometries;
    }

    /**
     * This method will convert a geometry object which comes from the API and normalize it
     * into a standard OSKGeoJson object. This is the most common gis type that is returned from
     * the API.
     *
     * @param shape A geometry object defined by the API clients.
     * @returns An OSKGeoJson object
     */
    static fromAPIGeometry(shape: GeometryCollection | Geometry | null | undefined): OSKGeoJson {
        if (shape === undefined || shape === null) {
            return new OSKGeoJson();
        }

        const result = new OSKGeoJson();
        const geometryList = [];

        if ((shape as GeometryCollection).geometries) {
            for (const geometry of (shape as GeometryCollection).geometries) {
                geometryList.push(geometry);
            }
        } else {
            geometryList.push(shape as Geometry);
        }

        for (const geometry of geometryList) {
            const feature = geometry as OSKFeature;
            if (feature) {
                result.features.push(feature);
            }
        }

        result._source = shape;
        return result;
    }

    /**
     * Convert an API geometry into a valid geosjon feature.
     *
     * @param geometry A Geometry from the API
     * @returns An OSKFeature (valid geojson)
     */
    static parseFeatureFromGeometry(geometry: Geometry | null | undefined): OSKFeature | null {
        if (geometry === null || geometry === undefined) {
            return null;
        }

        return geometry as OSKFeature;
    }

    /**
     * Convert a single geojson feature into a valid OSKGeoJson instance.
     *
     * @param feature A single geojson feature
     * @returns A valid OSKGeoJson instance
     */
    static fromFeature(feature: OSKFeature): OSKGeoJson {
        const result = new OSKGeoJson();
        result.features = [feature];
        result._source = feature;
        return result;
    }

    /**
     * Convert a single point into a valid OSKGeoJson instance.
     *
     * @param coords A leaflet LatLng point
     * @returns A valid OSKGeoJson instance
     */
    static fromCoordinate(coords: LatLng): OSKGeoJson {
        const feature = { type: 'Point', name: '', coordinates: [coords.lng, coords.lat] } as OSKFeature;
        return this.fromFeature(feature);
    }
    /**
     * Convert a LatLngBounds object (i.e. from map.getBounds()) into a valid OSKGeoJson instance.
     *
     * @param bounds A leaflet LatLngBounds point
     * @returns A valid OSKGeoJson instance
     */
    static fromLatLngBounds(bounds: LatLngBounds): OSKGeoJson {
        const points = [
            bounds.getNorthWest(),
            bounds.getNorthEast(),
            bounds.getSouthEast(),
            bounds.getSouthWest(),
            bounds.getNorthWest(), // Starting point has to be repeated to 'close' the shape
        ];
        const feature = {
            type: 'Polygon',
            name: '',
            coordinates: [points.map((point) => [point.lng, point.lat])],
        } as OSKFeature;
        return this.fromFeature(feature);
    }

    /**
     * Convert an OSKGeoJson object into an API-compliant geometry.
     *
     * @returns An API-compliant geometry
     */
    toAPIGeometry(): any /* it bugs me in my mind it should be OSKFeature | undefined*/ {
        if (this.features && this.features.length > 0) {
            switch (this.features[0].type) {
                case 'Point': {
                    return {
                        type: 'MultiPoint',
                        // The coordinates are Lng, Lat per RFC7946: https://datatracker.ietf.org/doc/html/rfc7946#appendix-A.1
                        coordinates: this.toLeafletCoordinates().map((coordinate) => [coordinate.lng, coordinate.lat]),
                    };
                }
                default: {
                    return {
                        type: 'Polygon',
                        /* NOTE: This is an array of arrays of arrays because the first array is a shape, then
                           we start building out the coordinates for that shape. A bit of a tricky nuance
                           but that's what django likes.
                           
                           The coordinates are Lng, Lat per RFC7946: https://datatracker.ietf.org/doc/html/rfc7946#appendix-A.1
                        */
                        coordinates: [
                            this.toLeafletCoordinates().map((coordinate) => [coordinate.lng, coordinate.lat]),
                        ],
                    };
                }
            }
        } else {
            return undefined;
        }
    }

    /**
     * This converts the OSKGeoJson object into an array of coordinates which are compatible
     * with most leaflet objects. Use this method to render footprints, fit boundaries, create shapes,
     * and much more.
     *
     * @returns A LatLng[]
     */
    toLeafletCoordinates(): LatLng[] {
        assert(this.features.length < 2, 'Too many features to convert into a leaflet coordinate list');
        if (this.features.length > 0) {
            const latLng = coordinatesToLatLng(this.features[0].coordinates);
            if (Array.isArray(latLng)) {
                return latLng;
            } else {
                return [latLng as LatLng];
            }
        } else {
            return [];
        }
    }

    /**
     * Convert a valid OSKGeoJson object into a list of features. This method should be preferred
     * when rendering things to leaflet because it is multi-feature aware.
     */
    toLeafletFeatureList(): LeafletFeature[] {
        const finalFeatureList = [] as OSKFeature[];
        for (const feature of this.features) {
            // If it's a MultiLineString parse it into multiple features
            // per the geojson spec, this is valid and it makes
            // leaflet happier.
            if (feature.type === 'MultiLineString') {
                for (const lineStringCoordinates of feature.coordinates) {
                    finalFeatureList.push({
                        type: 'LineString',
                        coordinates: lineStringCoordinates,
                    });
                }
            } else {
                finalFeatureList.push(feature);
            }
        }

        return finalFeatureList.map((feature) => {
            const latLng = coordinatesToLatLng(feature.coordinates);
            const coordinates = Array.isArray(latLng) ? latLng : [latLng as LatLng];
            return {
                type: feature.type,
                coordinates,
            };
        });
    }

    /**
     * Convert a valid OSKGeoJson instance into raw geojson.
     *
     * @returns Raw geojson
     */
    toGeoJSON(): FeatureCollection {
        return {
            type: 'FeatureCollection',
            features: this.features.map((feature: OSKFeature) => {
                return {
                    type: 'Feature',
                    properties: {},
                    geometry: feature,
                };
            }),
        };
    }

    /**
     * This is a convenience method to return a normalized array of coordinates in
     * the base OSKCoordinates class type.
     *
     * @returns A LatLng[]
     */
    toCoordinates(): OSKCoordinates {
        return this.toLeafletCoordinates();
    }

    /**
     * Compare two instances of OSKGeoJson to see if they are the same.
     *
     * @param other Another instance of OSKGeoJson
     * @returns True if both instances are functionally the exact same geojson representations.
     */
    sameAs(other: OSKGeoJson) {
        return JSON.stringify(this) === JSON.stringify(other);
    }

    /**
     * Evaluate whether this OSKGeoJson instance has no features, or all features are empty.
     *
     * @returns True if there are no features, or if the features present are empty of points.
     */
    isEmpty(): boolean {
        // If it has no features, or it only has empty features, then it's effectively empty.
        return (
            this.features.length == 0 ||
            this.features.filter((x) => x && x.coordinates && x.coordinates.length > 0).length === 0
        );
    }

    /**
     * The center point of the boundaries of this OSKGeoJson instance. If it is a complex shape, it will get
     * the min/max x/y points and then compute the center of the rectangle.
     *
     * @returns A centroid which represents the center point of the union of all features contained within this OSKGeoJson instance.
     */
    getCentroid(): LatLng {
        const bounds = getBounds([this]);
        if (bounds && bounds.length > 0 && bounds[0].length > 0) {
            return new LatLng(
                bounds[0][0] + (bounds[1][0] - bounds[0][0]) / 2,
                bounds[0][1] + (bounds[1][1] - bounds[0][1]) / 2,
            );
        } else {
            console.error('Failed to get centroid', { bounds });
            return new LatLng(0, 0);
        }
    }

    /**
     * Calculate the surface area of the bounding box in meters.
     */
    getApproximateArea(): number {
        return geoJsonArea.geometry(this.toGeoJSON().features[0].geometry) * 1e-6;
    }
}
