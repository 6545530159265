import { OSKGeoJson } from 'oskcore';
import React, { useCallback } from 'react';
import { useMap } from '~/hooks';

const LEAFLET_COMMIT_EVENT = 'editable:drawing:commit';
export type MapModes = 'Polygon' | 'Point' | 'Square' | 'Clear' | 'Measure' | 'None';
export type MapModeProps = {
    /** The drawing mode which the map should be actively using */
    mode: MapModes;
    /** The method to invoke when we are about to start an edit operation */
    onBeforeEdit?: () => void;
    /** The method to invoke when a new polygon is selected */
    onSave?: (shape: OSKGeoJson) => void;
};

/**
 * This component is used to coerce the map into a specific edit mode. Simply set the mode you want
 * and the map will change states into that edit mode. Once finished, be sure to reset mode
 * to "None" and the map will save state and return to normal.
 *
 * @param mode The mode you wish the map to enter
 * @param onSave The method to invoke when the map gets a new shape defined
 */
export const MapMode = ({ mode, onBeforeEdit, onSave }: MapModeProps) => {
    const map = useMap();
    const cancelMapEdit = useCallback(() => {
        map.unregisterLeafletEvent(LEAFLET_COMMIT_EVENT, saveShape);
    }, [map]);

    const saveShape = useCallback(() => {
        const geoJSON = map.toGeoJSON();
        cancelMapEdit();
        onSave && onSave(geoJSON);
    }, [map, cancelMapEdit, onSave]);

    const prepareMapForEdit = useCallback(() => {
        map.clearAll(true);
        map.registerLeafletEvent(LEAFLET_COMMIT_EVENT, saveShape);
        onBeforeEdit && onBeforeEdit();
    }, [saveShape, map, onBeforeEdit]);

    switch (mode) {
        case 'Point': {
            prepareMapForEdit();
            map.startMarker();
            break;
        }
        case 'Square': {
            prepareMapForEdit();
            map.startSquare();
            break;
        }
        case 'Polygon': {
            prepareMapForEdit();
            map.startPolygon();
            break;
        }
        case 'Clear': {
            cancelMapEdit();
            map.clearAll();
            onSave && onSave(new OSKGeoJson());
            break;
        }
        case 'Measure': {
            prepareMapForEdit();
            map.startMeasure();
            break;
        }
        default:
        case 'None': {
            break;
        }
    }
    return <React.Fragment />;
};
