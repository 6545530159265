import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Modal,
    ModalBody,
    OSKIcon,
    OSKThemeType,
    Select,
    Spinner,
    TextInput,
    Typography,
} from 'oskcomponents';
import { useTheme } from 'styled-components';
import {
    DetectionFeedbackStatusEnum,
    PatchedDetectionRequest,
    PatchedDetectionRequestFeedbackStatusEnum,
    SigmaAPI,
} from 'oskcore';
import { getProgramId } from '~/utils';
import { AssetDetection } from '~/redux/modules/monitor/app';

type DetectionStatusModalProps = {
    /** The object for the Finding we're editing. */
    detection?: AssetDetection;
    /** The name of the associated asset for the Finding */
    assetName: string;
    /** Whether or not to show the modal. */
    visible: boolean;
    /** A method to call when the modal requests to be closed. */
    onClose: () => void;
    /** A method to call when the modal successfully saves a detection */
    onSave: (detection: AssetDetection) => void;
};

const DetectionStatusModal = ({ detection, assetName, visible, onClose, onSave }: DetectionStatusModalProps) => {
    const NOTE_CHAR_LIMIT = 200;

    const theme = useTheme() as OSKThemeType;
    const programId = getProgramId();

    const [note, setNote] = useState<string>(detection?.feedback_text ?? '');
    const [showError, setShowError] = useState<boolean>(false);
    const [saveError, setSaveError] = useState('');
    const [status, setStatus] = useState<DetectionFeedbackStatusEnum | undefined>(detection?.feedback_status);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        setNote(detection?.feedback_text ?? '');
        setStatus(detection?.feedback_status);
    }, [detection]);

    return (
        <Modal visible={visible} w={500}>
            <ModalBody style={{ borderRadius: '16px' }}>
                <Box p={30} col grow style={{ position: 'relative' }}>
                    <Button
                        variant="clear"
                        style={{ position: 'absolute', right: 0, top: '15px' }}
                        onClick={() => onClose && onClose()}
                    >
                        <OSKIcon code="times" width={14} height={14} fill={theme.colors.black} />
                    </Button>
                    <Typography variant="heading1">{detection?.id}</Typography>
                    <Typography variant="subtitle2">{assetName}</Typography>
                    <Box col style={{ paddingTop: '8px' }} w={300}>
                        <Typography variant="heading3" style={{ padding: '8px 0px' }}>
                            Status
                        </Typography>
                        <Select
                            name=""
                            defaultValue={status}
                            items={Object.entries(DetectionFeedbackStatusEnum).map((status) => ({
                                value: status[1],
                                label: status[1],
                            }))}
                            onSelect={(e) => setStatus(e?.value ?? DetectionFeedbackStatusEnum.Unreviewed)}
                        />
                    </Box>
                    <Box col style={{ paddingTop: '8px' }}>
                        <Box row style={{ position: 'relative' }}>
                            <Typography variant="heading3" style={{ padding: '8px 0px' }}>
                                Notes
                            </Typography>
                            <Typography
                                variant="overline2"
                                style={{
                                    verticalAlign: 'bottom',
                                    position: 'absolute',
                                    height: 'fit-content',
                                    bottom: '4px',
                                    right: '0px',
                                }}
                                color={showError ? theme.colors.red : theme.colors.bg}
                            >
                                {note.length}/{NOTE_CHAR_LIMIT}
                            </Typography>
                        </Box>
                        <TextInput
                            name=""
                            multiline
                            style={{ width: '100%', height: '200px' }}
                            onChange={(e) => {
                                setNote(e.target.value);
                                setShowError(e.target.value.length > NOTE_CHAR_LIMIT);
                            }}
                            value={note}
                        />
                    </Box>
                    {showError && (
                        <Box style={{ position: 'relative' }}>
                            <Box center="vertical" style={{ paddingTop: '8px', position: 'absolute' }}>
                                <OSKIcon
                                    code="info-circle"
                                    fill={theme.colors.primary.bg}
                                    width={18}
                                    height={18}
                                    style={{ paddingRight: '4px' }}
                                />
                                <Typography variant="overline2" color={theme.colors.red}>
                                    Limit notes to {NOTE_CHAR_LIMIT} characters.
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Box style={{ paddingTop: '24px', alignItems: 'flex-end' }} col>
                        {saveError && (
                            <Typography
                                variant="caption2"
                                style={{
                                    height: 'fit-content',
                                    paddingBottom: '4px',
                                }}
                                color={theme.colors.red}
                            >
                                An error occurred while saving: &apos;{saveError}&apos;
                            </Typography>
                        )}
                        <Box grow>
                            <Button variant="neutral" onClick={() => onClose && onClose()}>
                                <strong>Close</strong>
                            </Button>
                            <Button
                                variant="primary"
                                style={{ marginLeft: '14px' }}
                                disabled={showError || isSaving}
                                onClick={() => {
                                    if (detection) {
                                        const patch: PatchedDetectionRequest = {
                                            feedback_status:
                                                status as unknown as PatchedDetectionRequestFeedbackStatusEnum,
                                            feedback_text: note,
                                        };

                                        setIsSaving(true);

                                        SigmaAPI.partialUpdateDetection({
                                            id: detection?.id,
                                            program: programId,
                                            patchedDetectionRequest: patch,
                                        })
                                            .then((result) => {
                                                onSave && onSave(result.data as AssetDetection);
                                                onClose && onClose();
                                            })
                                            .catch((err) => setSaveError(err))
                                            .finally(() => {
                                                setIsSaving(false);
                                            });
                                    }
                                }}
                            >
                                {isSaving ? (
                                    <Spinner size="Tiny" style={{ height: '8px', margin: '0px 6px' }} />
                                ) : (
                                    <strong>Save</strong>
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ModalBody>
        </Modal>
    );
};

export { DetectionStatusModal };
