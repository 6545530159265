import { Box, OSKThemeType, Typography } from 'oskcomponents';
import { titleize } from 'oskcore';
import React from 'react';
import { useTheme } from 'styled-components';

export type ChipType = 'alert' | 'detection';
export type AlertChipProps = {
    type: ChipType;
};

export const AlertChip = ({ type }: AlertChipProps) => {
    const theme = useTheme() as OSKThemeType;
    const bg = type === 'alert' ? theme.colors.red : theme.colors.accent2;
    return (
        <Box w={80} p={4} mt={14} ml={14} center="all" style={{ backgroundColor: bg, borderRadius: '3px' }}>
            <Typography variant="caption2">{titleize(type)}</Typography>
        </Box>
    );
};
