import React from 'react';
import { Box, Button, OSKThemeType, Typography } from 'oskcomponents';
import { useTheme } from 'styled-components';

type DropdownFilterPanelProps = {
    /** Title of the pop-out panel */
    title: string;
    /** Subtitle of the pop-out panel */
    description: string;
    /** Any child elements we need in the pop-out panel */
    children: React.ReactNode | Array<React.ReactNode>;
    /** A method that's called when the "Reset Filter" button is clicked */
    onResetFilter?: () => void;
};

const DropdownFilterPanel = ({ title, description, children, onResetFilter, ...props }: DropdownFilterPanelProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            style={{
                backgroundColor: 'white',
                border: `1px solid ${theme.colors.black200}`,
                color: 'black',
                padding: '18px',
                borderRadius: '7px',
                marginTop: '2px',
                userSelect: 'none',
            }}
            {...props}
            col
        >
            <Typography variant="heading4">{title}</Typography>
            <Typography variant="body3" style={{ padding: '8px 0px', color: theme.colors.black600 }}>
                {description}
            </Typography>
            {children}
            <Button
                variant="contrast"
                style={{ alignSelf: 'center', marginTop: '4px', marginBottom: '-12px' }}
                onClick={() => onResetFilter && onResetFilter()}
            >
                Reset Filter
            </Button>
        </Box>
    );
};

export { DropdownFilterPanel };
export type { DropdownFilterPanelProps };
