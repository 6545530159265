import {
    Box,
    DatePicker,
    NullableDate,
    OSKIcon,
    OSKThemeType,
    SidePanel,
    Text,
    Divider,
    Button,
    Tooltip,
    Select,
} from 'oskcomponents';
import { OSKGeoJson } from 'oskcore';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { SidePanelHeader } from '~/atoms';
import { useDisableFeatureOnMouseOver, useMap } from '~/hooks';
import { setWizardFlow } from '~/redux/modules/data/cart';
import {
    EclipsedMode,
    setTaskingSearchEclipsedMode,
    setTaskingSearchEndDate,
    setTaskingSearchStartDate,
} from '~/redux/modules/tasking/app';
import { RootState } from '~/redux/store';
import GeocodingSearch from '../GeocodingSearch';
import { useNavigate } from 'react-router-dom';
import { LatLng } from 'leaflet';
import * as L from 'leaflet';

type TaskingSidePanelProps = {
    /** From redux, the selected AOI if any. */
    selectedAoi?: OSKGeoJson;
    /** The start date of the tasking request */
    taskingSearchStartDate: NullableDate;
    /** The end date of the tasking request */
    taskingSearchEndDate: NullableDate;
    /** The "eclipsed" mode for the tasking request */
    taskingSearchEclipsedMode: EclipsedMode;
};

export const TaskingSidePanel = ({
    selectedAoi,
    taskingSearchStartDate,
    taskingSearchEndDate,
    taskingSearchEclipsedMode,
}: TaskingSidePanelProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const map = useMap();

    const [searchStartDate, setSearchStartDate] = useState<NullableDate>(taskingSearchStartDate);
    const [searchEndDate, setSearchEndDate] = useState<NullableDate>(taskingSearchEndDate);
    const [validationError, setValidationError] = useState<string>('');

    useEffect(() => {
        setSearchStartDate(taskingSearchStartDate);
        setSearchEndDate(taskingSearchEndDate);
    }, [taskingSearchStartDate, taskingSearchEndDate]);

    const panelRef = useRef<HTMLDivElement>(null);

    useDisableFeatureOnMouseOver(panelRef, 'Zoom', true);
    useDisableFeatureOnMouseOver(panelRef, 'Drag', true);

    const resetPanel = () => {
        setSearchStartDate(null);
        setSearchEndDate(null);
    };

    useEffect(() => {
        const errors: string[] = [];

        if (!searchStartDate && !searchEndDate) {
            errors.push('A start and end date are required.');
        } else if (!searchStartDate) {
            errors.push('A start date is required.');
        } else if (!searchEndDate) {
            errors.push('An end date is required.');
        }

        if (!selectedAoi) {
            errors.push('A search area is required.');
        }

        setValidationError(errors.join(' '));
    }, [searchEndDate, searchStartDate, selectedAoi]);

    return (
        <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <SidePanel hideOverflow={false}>
                <Box ref={panelRef} id="tasking-panel" col grow>
                    <GeocodingSearch
                        onSelected={(location, bounds) => {
                            const url = `/tasking/@${location.lat},${location.lng}`;
                            navigate(url, { replace: true });

                            if (bounds) {
                                // If we have a boundary, let's fit the whole thing
                                const latLngBounds = L.latLngBounds(bounds.southwest, bounds.northeast);
                                map.fitCoordinates([OSKGeoJson.fromLatLngBounds(latLngBounds)], 0.1);
                            } else {
                                // Otherwise, if we just have a point that's ok too.
                                const coord = { lat: location.lat, lng: location.lng } as LatLng;
                                map.fitCoordinates([OSKGeoJson.fromCoordinate(coord)], 0.1);
                            }
                        }}
                    />
                    <SidePanelHeader>
                        <Text variant="large" strong>
                            Opportunity Viewer
                        </Text>
                    </SidePanelHeader>
                    <Text strong variant="large">
                        Time of Day
                    </Text>
                    <Text variant="small" color={theme.colors.black500} style={{ padding: '4px 0px' }}>
                        Select whether to search for day or night opportunities.
                    </Text>
                    <Box
                        style={{
                            justifyContent: 'space-between',
                            paddingTop: '20px',
                            width: '100%',
                        }}
                    >
                        <Select
                            name="time-of-day"
                            variant="primary"
                            inverted
                            items={['All', 'Day', 'Night'].map((option) => ({
                                label: option,
                                value: option.toLowerCase(),
                            }))}
                            defaultValue={taskingSearchEclipsedMode}
                            onSelect={(option) => dispatch(setTaskingSearchEclipsedMode(option?.value as EclipsedMode))}
                            style={{ width: '280px' }}
                        />
                    </Box>
                    <Divider style={{ margin: '20px 0px 20px 0px' }} />
                    <Text strong variant="large">
                        Date
                    </Text>
                    <Text variant="small" color={theme.colors.black500} style={{ padding: '4px 0px' }}>
                        Select a date range to search within.
                    </Text>
                    <Box
                        style={{
                            justifyContent: 'space-between',
                            marginLeft: '-4px',
                            paddingTop: '20px',
                        }}
                    >
                        <DatePicker
                            variant="primary"
                            inverted
                            name="start-date"
                            defaultValue={searchStartDate}
                            style={{ marginRight: '2px' }}
                            onChange={(newDate: NullableDate) => {
                                dispatch(setTaskingSearchStartDate(newDate));
                                setSearchStartDate(newDate);
                            }}
                        />
                        <DatePicker
                            variant="primary"
                            inverted
                            name="end-date"
                            defaultValue={searchEndDate}
                            onChange={(newDate: NullableDate) => {
                                dispatch(setTaskingSearchEndDate(newDate));
                                setSearchEndDate(newDate);
                            }}
                        />
                    </Box>
                    <Divider style={{ margin: '40px 0px 20px 0px' }} />
                    <Box center="horizontal" style={{ width: '100%' }}>
                        <Text
                            onClick={() => {
                                resetPanel();
                            }}
                        >
                            Reset
                        </Text>
                    </Box>

                    <Box
                        h={80}
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'white',
                            width: '100%',
                            padding: '4px 8px',
                            boxShadow: '0px -1px 17px rgba(0, 0, 0, 0.19)',
                        }}
                        center="all"
                    >
                        <Tooltip position="top" label={validationError} style={{ width: '100%' }}>
                            <Button
                                variant="action"
                                style={{ width: '100%', height: '40px' }}
                                onClick={() => {
                                    dispatch(setWizardFlow('tasking'));
                                }}
                                disabled={!selectedAoi || !searchStartDate || !searchEndDate || selectedAoi.isEmpty()}
                            >
                                <Box row center="all">
                                    <OSKIcon code="shopping-cart" style={{ paddingRight: '8px' }} />
                                    <Text strong>View Opportunities</Text>
                                </Box>
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
            </SidePanel>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    const { taskingSearchStartDate, taskingSearchEndDate, taskingSearchEclipsedMode } = state.tasking.app;
    const { roi: selectedAoi } = state.data.search;

    return {
        selectedAoi,
        taskingSearchStartDate,
        taskingSearchEndDate,
        taskingSearchEclipsedMode,
    };
};

export default connect(mapStateToProps)(TaskingSidePanel);
