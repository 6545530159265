import React from 'react';

export type AbbreviationTextProps = {
    /** The label to abbreviate */
    label: string;
};

export const AbbreviationText = ({ label }: AbbreviationTextProps) => {
    // Extract, at most, 2 letters from the label
    let abbreviation = '';
    const words = label.split(' ');
    if (words.length > 1 && words[0].length > 0) {
        abbreviation = words[0][0].toUpperCase() + words[words.length - 1][0].toUpperCase();
    } else if (label.length > 0) {
        abbreviation = label[0].toUpperCase();
    }

    return <>{abbreviation}</>;
};
