import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Text, TextElementProps } from '../Typography';

type ToggleIconProps = {
    icon: React.FunctionComponent;
    toggledIcon: React.FunctionComponent;
    onClick?: () => void;
} & Omit<TextElementProps, 'variant'>;

export const ToggleIcon = styled(({ icon, toggledIcon, onClick, ...props }: ToggleIconProps) => {
    const [toggled, setToggled] = useState(false);
    const handleToggle = useCallback(() => {
        setToggled(!toggled);
        onClick && onClick();
    }, [toggled, onClick]);

    return (
        <Text data-testid="toggle-icon" onClick={handleToggle} {...props}>
            {React.createElement(toggled ? icon : toggledIcon)}
        </Text>
    );
})`
    cursor: pointer;
`;
