import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, OSKThemeType, Text } from 'oskcomponents';
import CSS from 'csstype';
import { OSKIcon, OSKIconType } from 'oskcomponents/src/OSKIcon';

export type SidebarNavItemType = {
    /** Title of the nav item; used for URL as well as display */
    title: string;
    /** If sub items are present, this item is treated as a parent element */
    subItems?: Array<SidebarNavItemType>;
    /** The optional icon to display next to the item name */
    iconCode?: OSKIconType;
    /** Whether or not to render this item (default true) */
    visible?: boolean;
    [key: string]: any;
};

export type SidebarNavItemProps = {
    /** Whether this item is active or not */
    active?: boolean;
    /** Additional className to be injected into the style */
    className?: string;
    /** The method to invoke when the item is clicked */
    onClick?: (item: SidebarNavItemType) => void;
    /** The title to render for this option */
    item: SidebarNavItemType;
    /** How far to indent the inside text */
    indent?: number;
};

/**
 * A SidebarNavItem is a specific item in a list which represents a view to be loaded. The intended
 * use case is a settings page. You might have a few different settings, each one tied to
 * a differed view to be rendered.
 */
export const SidebarNavItem = styled(({ active, className, onClick, indent, item }: SidebarNavItemProps) => {
    const theme = useTheme() as OSKThemeType;
    const boxStyle: CSS.Properties = {
        color: theme.colors.primary.fg,
        cursor: 'pointer',
        width: '160px',
    };
    const fontStyle: CSS.Properties = {
        color: theme.colors.primary.fg,
    };

    if (active) {
        boxStyle['backgroundColor'] = theme.colors.primary.accent;

        fontStyle['fontWeight'] = 'bold';
        fontStyle['color'] = theme.colors.primary.invertedFg;
    }

    return (
        <Box
            className={className}
            data-testid={`nav-item-${item.title}`}
            onClick={onClick?.bind(this, item)}
            style={boxStyle}
        >
            <Text
                variant="medium"
                style={{
                    paddingLeft: `${indent}px`,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...fontStyle,
                }}
            >
                {item.iconCode && (
                    <OSKIcon code={item.iconCode} fill={fontStyle.color} style={{ paddingRight: '8px' }} />
                )}
                {item.title}
            </Text>
        </Box>
    );
})`
    & {
        border-radius: 10px;
        height: 32px;
        width: 137px;
    }

    &:hover {
        color: ${(props: any) => props.theme.colors.bg};
        background-color: ${(props: any) => props.theme.colors.neutralBg};
    }
`;
