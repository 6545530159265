import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';
import { GradientBar } from '../GradientBar';
import { MeasuredLayout } from '../MeasuredLayout';

type ContentProps = {
    children?: React.ReactNode;
    /** Optional flag to hide the gradient. */
    nogradient?: boolean;
    /** Optional flag to hide padding. */
    nopadding?: boolean;
    /** Optional flag to cosntrain the view to the viewport vertically */
    constrained?: boolean;
    /** Class name for styled components */
    className?: string;
} & Omit<BoxProps, 'ref'>;

const CoreContent = ({ children, className, constrained, nopadding, nogradient = false, ...props }: ContentProps) => {
    if (constrained) {
        // This means the viewport will be the only thing
        // visible on the screen. Any content overflowing
        // will have to take care to add its own overflow
        // setting.
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
    }

    return (
        <Box className={className} {...props}>
            {(nogradient === false || nogradient === null || nogradient === undefined) && (
                <GradientBar style={{ top: '75px' }} />
            )}
            {constrained ? (
                <MeasuredLayout
                    grow
                    style={{
                        height: '100%',
                        flexDirection: 'column',
                        display: 'flex',
                        zIndex: 0,
                    }}
                    dimension="vertical"
                >
                    {children}
                </MeasuredLayout>
            ) : (
                children
            )}
        </Box>
    );
};

const Content = styled(CoreContent)`
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    padding: ${(props: any) => (props.nopadding ? 'default' : props.theme.edgeContentPadding)};
`;

Content.defaultProps = {
    nopadding: false,
};

export { Content };
export type { ContentProps };
