import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggles } from '~/hooks/useToggles';
import { getLastAppAccessed } from '~/utils';

export const RedirectToBestAppView = () => {
    const navigate = useNavigate();
    const toggles = useToggles();

    useEffect(() => {
        // First see if they had accessed a particular app recently and try to go there if permissions allow
        // otherwise, go to the next best app they have permissions for.
        const lastApp = getLastAppAccessed();

        if (lastApp === 'monitor' && toggles.router.isFeatureSet('user_can_access_monitor')) {
            navigate('/monitor', { replace: true });
        } else if (
            (lastApp === 'data' && toggles.router.isFeatureSet('user_can_access_data')) ||
            (lastApp === 'data' && toggles.router.isFeatureSet('user_can_access_tasking'))
        ) {
            navigate('/map', { replace: true });
        } else if (toggles.router.isFeatureSet('user_can_access_monitor')) {
            navigate(`/monitor`, { replace: true });
        } else if (
            toggles.router.isFeatureSet('user_can_access_data') ||
            toggles.router.isFeatureSet('user_can_access_tasking')
        ) {
            navigate('/map', { replace: true });
        } else if (toggles.router.isFeatureSet('osk_employee')) {
            navigate('/tools/tasking');
        } else {
            navigate('/error/noaccess/sigma');
        }
    }, [navigate]);

    return <React.Fragment />;
};
