import { Box } from 'oskcomponents';
import { Program } from 'oskcore';
import React from 'react';
import styled from 'styled-components';
import { getLastAppAccessed, getProgramId, setProgramIdCache } from '~/utils';
import { ProgramIcon } from '../ProgramIcon';

export type ProgramRowItemProps = {
    /** The program to render details about */
    program: Program;
    /** The class provided by styled-components */
    className?: string;
};

export const ProgramRowItem = styled(({ program, className }: ProgramRowItemProps) => {
    function switchProgram() {
        // Check if they prefer a different app
        const currentApp = getLastAppAccessed();
        const nextApp = getLastAppAccessed(program.id);

        // Update local storage
        setProgramIdCache(program.id);

        if (currentApp !== nextApp) {
            // If the preferred app is not what we're currently using, go to the root and let god sort out which app to load
            window.location.assign('/');
        } else if (window.location.pathname.includes('/program')) {
            // If the current url has program information, update it so they'll persist pages
            const findText = `/program/${getProgramId()}`;
            window.location.assign(location.pathname.replace(findText, `/program/${program.id}`));
        } else {
            // Otherwise just reload whatever page we're on
            window.location.reload();
        }
    }

    return (
        <Box
            style={{ color: 'black', padding: '10px 20px' }}
            className={className}
            center="vertical"
            onClick={switchProgram}
        >
            <ProgramIcon label={program.name} /> {program.name}
        </Box>
    );
})`
    & {
        cursor: pointer;
    }

    &:hover {
        background-color: ${(props) => props.theme.colors.lightGray} !important;
    }
    &:hover .icon {
        background-color: ${(props) => props.theme.colors.accent} !important;
    }
`;
