import { LatLng } from 'leaflet';
import { Geometry, GeometryCollection } from 'oskcore';
import { interpolate } from 'spectra-gl/src/lib/math';

/**
 * Find two points of a tilted rectangle. The lower left corner and the lower right corner.
 *
 * @param max_fov A rectangle describing the maximum fov for a given sensor
 * @returns The lower left corner and the lower right corner.
 */
function extractCorners(max_fov: Geometry | GeometryCollection) {
    // TODO: This method might not work in all orientations. Would love to find a better way to do this.

    if ('coordinates' in max_fov) {
        const coords: Array<any> = max_fov.coordinates[0][0];
        let first_point = coords[0];

        // Find the lower (or upper) left corner
        for (const point of coords) {
            if (first_point[0] > point[0]) {
                first_point = point;
            }
        }

        // Now find another point along the X-axis
        const scored_points = coords
            .filter((point) => point !== first_point)
            .map((coord) => [Math.sqrt(Math.pow(coord[0] - first_point[0], 2)), coord]);
        const furthest = scored_points.sort((a, b) => b[0] - a[0]);

        // This would be /A/ corner but not /THE/ corner that we want
        const corner = furthest[0][1];

        // Find the other corner by identifying points close to the same X-axis
        const corners = coords
            .filter((point) => point !== corner && point !== first_point)
            .map((coord) => [Math.sqrt(Math.pow(coord[0] - corner[0], 2)), coord]);
        corners.sort((a, b) => a[0] - b[0]);

        // There it is
        const second_point = corners[0][1];
        return [first_point, second_point];
    }

    throw 'Unsupported max_fov';
}

/**
 * Calculate roll-angle for a point, given slew parameters and a max_fov.
 *
 * @param min_slew The minimum slew angle of the sensor
 * @param max_slew The maxaimum slew angle of the sensor
 * @param max_fov The maximum field-of-view represented as a rectangle, of the sensor
 * @param point A point to calculate roll angle for
 * @returns The roll angle needed to hit the point
 */
export function calculateRollAngle(
    min_slew: number,
    max_slew: number,
    max_fov: Geometry | GeometryCollection,
    point: LatLng,
): number | null {
    // The following logic is calculating the intersection point between the "point" variable
    // and a line from the lower-left corner of the footprint to the lower right corner.
    // The magnitude of this line is then used as the interpolation parameter
    // to determine the roll angle.

    // https://forum.unity.com/threads/how-do-i-find-the-closest-point-on-a-line.340058/#post-2198950

    const [p1, p2] = extractCorners(max_fov);
    const p = [point.lng, point.lat];
    let dir = [p2[0] - p1[0], p2[1] - p1[1]];
    const mag = Math.sqrt(Math.pow(dir[0], 2) + Math.pow(dir[1], 2));
    dir = [dir[0] / mag, dir[1] / mag];
    const vector = [p[0] - p1[0], p[1] - p1[1]];
    const dist_along_line = vector[0] * dir[0] + vector[1] * dir[1];
    const roll_angle = interpolate(min_slew, max_slew, 0, dist_along_line, mag);

    if (Math.abs(roll_angle) > max_slew + 1) {
        return null;
    }

    return Math.sign(roll_angle) * Math.min(Math.abs(roll_angle), max_slew);
}
