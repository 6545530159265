import React, { useRef, useState } from 'react';
import { Box, OSKIcon, OSKIconType, OSKThemeType, useClickAway } from 'oskcomponents';
import styled, { useTheme } from 'styled-components';

type FilterPanelButtonProps = {
    /** The icon code to use on the left hand side of the button */
    icon: OSKIconType;
    /** The content of the button, placed to the right of the icon */
    children: React.ReactNode | Array<React.ReactNode>;
    /** The panel to show / position when the button is clicked. */
    panel: React.ReactNode;
    /** Pass-through classname from styled-components */
    className?: string;
    /** The condition under which the clear function is shown */
    showClear?: boolean;
    /** The function that's called when the clear button is clicked */
    clearFilter?: () => void;
};

const FilterPanelButtonBase = ({
    icon,
    children,
    panel,
    className,
    showClear,
    clearFilter,
    ...props
}: FilterPanelButtonProps) => {
    const theme = useTheme() as OSKThemeType;
    const buttonRef = useRef<HTMLButtonElement & HTMLInputElement>(null);
    const panelRef = useRef(null);

    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [highlightBorder, setHighlightBorder] = useState<boolean>(false);

    useClickAway(
        panelRef,
        () => {
            setShowPanel(false);
        },
        showPanel,
    );

    const borderColor = showPanel || highlightBorder ? theme.colors.accent : theme.colors.black200;

    return (
        <Box ref={panelRef} className={className}>
            <Box className="filter-panel-container">
                <Box
                    className="filter-panel-button"
                    style={{
                        borderColor: borderColor,
                    }}
                    cursor="pointer"
                    ref={buttonRef}
                    onClick={() => setShowPanel(!showPanel)}
                    {...props}
                >
                    <Box row center="all" cursor="pointer">
                        <OSKIcon code={icon} style={{ marginRight: '16px' }} scale={120} />
                        {children}
                    </Box>
                </Box>

                <Box
                    className="filter-panel-function"
                    style={{
                        borderColor: borderColor,
                    }}
                    onClick={() => {
                        if (showClear) {
                            clearFilter && clearFilter();
                            setHighlightBorder(true);
                            setInterval(() => setHighlightBorder(false), 500);
                        } else {
                            setShowPanel(!showPanel);
                        }
                    }}
                    center="all"
                >
                    {showClear ? (
                        <OSKIcon code="times" style={{ margin: '0px 8px' }} />
                    ) : showPanel ? (
                        <OSKIcon code="arrow-up" style={{ margin: '0px 8px' }} />
                    ) : (
                        <OSKIcon code="arrow-down" style={{ margin: '0px 8px' }} />
                    )}
                </Box>
            </Box>

            {showPanel && (
                <Box
                    style={{
                        position: 'absolute',
                        top: `${(buttonRef.current?.offsetTop ?? 0) + (buttonRef.current?.offsetHeight ?? 0)}px`,
                        left: `${buttonRef.current?.offsetLeft}px`,
                    }}
                >
                    {panel}
                </Box>
            )}
        </Box>
    );
};

const FilterPanelButton = styled(FilterPanelButtonBase)`
    .filter-panel-button {
        border: 1px solid;
        border-radius: 7px 0px 0px 7px;
        border-right: none;
        margin-left: 8px;
        padding: 0px 8px 0px 18px;
        color: black;
        cursor: pointer !important;
        transition: all 0.25s;
        user-select: none;
    }

    .filter-panel-function {
        border: 1px solid;
        border-radius: 0px 7px 7px 0px;
        border-left: none;
        color: black;
        cursor: pointer !important;
        transition: all 0.25s;
    }

    .filter-panel-function:hover {
        background-color: ${(props: any) => props.theme.colors.gray50} !important;
    }

    .filter-panel-container:hover {
        .filter-panel-button {
            background-color: ${(props: any) => props.theme.colors.gray25};
        }
        .filter-panel-function {
            background-color: ${(props: any) => props.theme.colors.gray25};
        }
    }
`;

export { FilterPanelButton };
export type { FilterPanelButtonProps };
