/**
    This component is designed to host the checkout page in an overlay format
    which sits atop the map. The checkout page is a wizard which includes a few
    steps based around confirming the imagery you've selected and the delivery
    details of where we'll send the information upon completion.
*/

import { Box, Heading, OSKIcon, OSKThemeType, Spinner } from 'oskcomponents';
import { noop } from 'oskcore';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { GlobalZIndex } from '~/constants';
import { useMap } from '~/hooks';
import { CartWizardFlow, emptyCart, setWizardFlow } from '~/redux/modules/data/cart';
import { RootState } from '~/redux/store';
import CheckoutSummary from '../Checkout/CheckoutSummary';
import FinalizeArchiveCheckout from '../Checkout/FinalizeArchiveCheckout';
import { SearchOrderComplete } from '../Checkout/SearchOrderComplete';
import CoveragePreview from '../Checkout/CoveragePreview';
import { MinimalWizardStepList } from '~/atoms';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { clearDataResourceProvider } from '~/redux';

export type CartWizardPopupProps = {
    /** The flow to use for this wizard popup. */
    wizardFlow: CartWizardFlow;
    /** Whether or not there is a search in progress. */
    isSearching?: boolean;
};

export const CartWizardPopup = ({ wizardFlow, isSearching }: CartWizardPopupProps) => {
    const visible = wizardFlow !== 'none';
    const map = useMap();
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stepIndex, setStepIndex] = useState<number>(0);

    const goPreviousStep = () => setStepIndex(stepIndex - 1 > 0 ? stepIndex - 1 : 0);
    const goNextStep = () => setStepIndex(stepIndex + 1);
    const resetStep = () => setStepIndex(0);
    const closeWizard = () => {
        dispatch(setWizardFlow('none'));
        resetStep();
    };
    const clearCart = () => {
        dispatch(emptyCart());
        resetStep();
    };

    const WizardSteps: { [key in CartWizardFlow]: JSX.Element[] } = {
        none: [<></>],
        search: [
            <CheckoutSummary
                key="search-checkout-summary"
                name="Summary"
                onWizardNext={() => {
                    goNextStep();
                }}
                onWizardClose={() => {
                    closeWizard();
                }}
            />,
            <FinalizeArchiveCheckout
                key="search-finalize-checkout"
                name="Finalize"
                onWizardNext={() => {
                    goNextStep();
                }}
                onWizardBack={() => {
                    goPreviousStep();
                }}
            />,
            <SearchOrderComplete
                key="search-order-complete"
                name="Complete"
                onWizardClose={() => {
                    // Clear the cache so we refetch everything
                    closeWizard();
                    clearDataResourceProvider();
                    clearCart();
                    setTimeout(() => {
                        navigate('/orders');
                    }, 0);
                }}
            />,
        ],
        tasking: [
            <CoveragePreview
                key="tasking-coverage-preview"
                name="Coverage Summary"
                onWizardNext={() => {
                    goNextStep();
                }}
                onWizardClose={() => {
                    closeWizard();
                }}
            />,
            // <FinalizeTaskingCheckout
            //     key="tasking-finalize-checkout"
            //     name="Finalize"
            //     onWizardNext={() => {
            //         goNextStep();
            //     }}
            //     onWizardBack={() => {
            //         goPreviousStep();
            //     }}
            // />,
            // <TaskingOrderComplete
            //     key="tasking-order-complete"
            //     name="Complete"
            //     onWizardClose={() => {
            //         // Clear the cache so we refetch everything
            //         closeWizard();
            //         clearDataResourceProvider();
            //         clearCart();
            //         setTimeout(() => {
            //             navigate('/orders');
            //         }, 0);
            //     }}
            // />,
        ],
    };

    useEffect(() => {
        if (visible && wizardFlow !== 'tasking') {
            map.requestUpdateToFeatureEnabled('Drag', 'checkout-wizard-popup', false);
            map.requestUpdateToFeatureEnabled('Zoom', 'checkout-wizard-popup', false);
        } else {
            map.requestUpdateToFeatureEnabled('Drag', 'checkout-wizard-popup', true);
            map.requestUpdateToFeatureEnabled('Zoom', 'checkout-wizard-popup', true);
        }
    }, [wizardFlow, map]);

    const GetCurrentWizardStep = useCallback(() => WizardSteps[wizardFlow][stepIndex], [wizardFlow, stepIndex]);

    return (
        <Box>
            {visible && (
                <>
                    <Box
                        bg="rgba(0,0,0,.65)"
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            zIndex: GlobalZIndex.CartWizardPopup,
                            width: '100%',
                            height: '100%',
                        }}
                    />
                    <Box
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            zIndex: GlobalZIndex.CartWizardPopup,
                            margin: '20px',
                            width: 'calc(100% - 40px)',
                            height: 'calc(100% - 40px)',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            style={{ backgroundColor: theme.colors.white1, width: '100%', height: '100%' }}
                            bg={theme.colors.white}
                            grow
                            col
                        >
                            <Box fg={theme.colors.black} p={14}>
                                <Heading>{wizardFlow === 'tasking' ? 'Opportunity Viewer' : 'Checkout'}</Heading>
                                <Box grow />
                                <OSKIcon code="close" onClick={closeWizard} />
                            </Box>

                            <Box
                                bg={theme.colors.lightGray}
                                p={20}
                                center="horizontal"
                                style={{
                                    border: `1px solid ${theme.colors.black300}`,
                                    borderStyle: 'solid none solid none',
                                }}
                                fg={theme.colors.black}
                                col
                            >
                                <MinimalWizardStepList
                                    stepCount={WizardSteps[wizardFlow].length}
                                    activeStep={stepIndex + 1}
                                    pb={10}
                                    labels={WizardSteps[wizardFlow].map((step) => {
                                        return step.props.name;
                                    })}
                                />
                            </Box>
                            {isSearching && (
                                <Box center="all" grow>
                                    <Spinner size="Large" variant="Box" />
                                </Box>
                            )}
                            {!isSearching && GetCurrentWizardStep()}
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    const { wizardFlow } = state.data.cart;
    const { isSearching } = state.data.search;
    return {
        wizardFlow,
        isSearching,
    };
};
export default connect(mapStateToProps, noop)(CartWizardPopup);
