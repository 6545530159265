import React from 'react';
import { useRef } from 'react';

/** Simulate the useState hook using a ref, for instances where the state
 * is cached and we need a component to re-render when the value changes. */
export function useRefState<T>(value?: any): [() => T, (v: T) => void] {
    const ref = useRef<T>(value ?? ({} as T));
    const [, updateState] = React.useState({});
    const update = React.useCallback(() => updateState({}), []);

    const setValue = (newValue: T) => {
        ref.current = newValue;
        update();
    };

    return [() => ref.current, setValue];
}
