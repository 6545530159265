import React from 'react';
import { Box, OSKThemeType, Typography } from 'oskcomponents';
import { useTheme } from 'styled-components';

type ArtifactAvailabilityChipProps = {
    /** The name of the artifact to display */
    text?: string;
    /** Whether the artifact is available or not, used to set the background color */
    available?: boolean;
};

const ArtifactAvailabilityChip = ({ text, available }: ArtifactAvailabilityChipProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            style={{
                color: 'white',
                backgroundColor: available ? theme.colors.accent : theme.colors.black600,
                width: 'fit-content',
                padding: '4px 10px',
                marginRight: '7px',
                borderRadius: '4px',
            }}
        >
            <Typography variant="caption2">{text}</Typography>
        </Box>
    );
};

export { ArtifactAvailabilityChip };
export type { ArtifactAvailabilityChipProps };
