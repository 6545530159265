import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { OSKGeoJson, noop } from 'oskcore';
import { Marker, Polygon } from 'react-leaflet';

type ActiveAoiProps = {
    /** The currently selected roi from redux */
    currentRoi?: OSKGeoJson;
};

const ActiveAoi = ({ currentRoi }: ActiveAoiProps) => {
    return (
        <React.Fragment>
            {currentRoi && currentRoi.features.length > 0 && currentRoi.features[0].type === 'Polygon' && (
                <Polygon
                    pane="tilePane"
                    positions={currentRoi.toLeafletCoordinates()}
                    fillOpacity={0.1}
                    interactive={false}
                />
            )}

            {currentRoi && currentRoi && currentRoi.features.length > 0 && currentRoi.features[0].type === 'Point' && (
                <Marker
                    position={currentRoi.toLeafletCoordinates()[0]}
                    title={`${currentRoi.features[0].coordinates[1].toFixed(
                        3,
                    )}, ${currentRoi.features[0].coordinates[0].toFixed(3)}`}
                />
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state: RootState) => {
    const { roi } = state.data.search;

    return {
        currentRoi: roi,
    };
};

export type { ActiveAoiProps };
export default connect(mapStateToProps, noop)(React.memo(ActiveAoi));
