/** A global list to keep track of large or negative zIndex values */
const GlobalZIndex = {
    GradientBar: -1,
    ShadowImage: -1,
    Tooltip: 100,
    ViewPanelResizeTarget: 1000,
    HeaderLogo: 1001,
    Overlay: 9002,
    DatePicker: 9001,
    ShadeShadow: 99999998,
    ShadeContent: 99999999,
};

type Toolean = true | false | 'some';

export { GlobalZIndex };
export type { Toolean };
