import React, { useEffect, useState } from 'react';
import { MapSatellitePosition } from '../MapSatellitePosition';

export type MapSatellitePositionRealtimeProps = {
    /** The TLE of the satellite we wish to track on the map */
    tle: string;
    /** Whether or not this satellite is selected */
    selected?: boolean;
    /** An optional label to render next to the marker */
    label?: string;
    /** A method to call when this satellite marker is clicked */
    onClick?: () => void;
};

export const MapSatellitePositionRealtime = ({ tle, selected, label, onClick }: MapSatellitePositionRealtimeProps) => {
    const [active, setActive] = useState(0);
    useEffect(() => {
        const timerId = setInterval(() => {
            setActive(active + 1);
        }, 100);

        return () => {
            clearInterval(timerId);
        };
    }, [active, setActive]);

    return (
        <MapSatellitePosition tle={tle} label={label} timestamp={new Date()} selected={selected} onClick={onClick} />
    );
};
