import React from 'react';
import { Box } from 'oskcomponents';
import { AbbreviationText } from '~/atoms';

export type ProgramIconProps = {
    /** A label to describe the program in question */
    label: string;
};

export const ProgramIcon = ({ label }: ProgramIconProps) => {
    return (
        <Box
            className="icon"
            style={{
                width: '20px',
                height: '20px',
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '30px',
                padding: '15px',
                marginRight: '10px',
            }}
            center="all"
        >
            <AbbreviationText label={label} />
        </Box>
    );
};
