import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_OSK_POSITION, encodeOSKPosition, OSKPosition } from 'oskcore';
import { useSessionStorage } from '~/hooks';
import { HomeViewMode } from '../HomeView';

/// This view has one purpose. If it is rendered, it will redirect to a specific map
/// view with the location that was most recently saved in SessionStorage or, alternatively,
/// the default osk location.
///
/// It will take this default position and replace it on the navigation stack,
/// effectively acting as a temporary route updater.
export type RedirectToHomeViewProps = {
    /** The mode we are redirecting for. Route will be different for tasking map vs. search map */
    mode: HomeViewMode;
};
export const RedirectToHomeView = ({ mode }: RedirectToHomeViewProps) => {
    const navigate = useNavigate();
    const [getSessionStorage] = useSessionStorage();
    const cachedPosition = getSessionStorage<OSKPosition>('map');
    const defaultPosition = cachedPosition
        ? encodeOSKPosition(cachedPosition)
        : encodeOSKPosition(DEFAULT_OSK_POSITION);

    useEffect(() => {
        switch (mode) {
            case 'search': {
                navigate(`/map/${defaultPosition}`, { replace: true });
                break;
            }

            case 'tasking': {
                navigate(`/tasking/${defaultPosition}`, { replace: true });
                break;
            }
        }
    }, [navigate, mode, defaultPosition]);
    return <React.Fragment />;
};
