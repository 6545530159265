import { OSKGeoJson } from 'oskcore';
import React, { useEffect } from 'react';
import { useMap } from '~/hooks';

export type MapCentroidProps = {
    /** One or more OSKGeoJson to fit on the map */
    area?: OSKGeoJson | OSKGeoJson[];
    /** How much padding to buffer the area (a good value is 0.01). It's in GPS units */
    padding?: number;
    /** If true, animate the zoom action */
    animated?: boolean;
};

export const MapCentroid = ({ animated, area, padding }: MapCentroidProps) => {
    const map = useMap();
    const singleAreaOrEmpty = area ? [area] : [];
    const areas = (Array.isArray(area) ? area : (singleAreaOrEmpty as OSKGeoJson[])).filter((x) => !x?.isEmpty()) ?? [];

    useEffect(() => {
        if (areas && areas.length > 0) {
            map.fitCoordinates(areas, padding, animated);
        }
    }, [area, animated, map.editableMap]);

    return <React.Fragment />;
};
