import React, { useCallback, useState } from 'react';
import { Box, BoxProps } from '../Box';
import { sameDay, MONTHS, getLastDayOfMonth, Datelike, parseDatelike } from './utils';
import { MonthPicker } from './components/MonthPicker';
import { YearPicker } from './components/YearPicker';
import styled from 'styled-components';
import { OSKIcon } from '../OSKIcon';

const DAY_SIZE = 34;
const MAX_WIDTH = (DAY_SIZE + 6) * 7;
type CalendarMode = 'calendar' | 'month' | 'year';

/**
 * Compute the CalendarDateType based on various inputs. This will decide how to specify
 * which day in particular is highlighted, transient, or default.
 */
function computeType(beginningOfMonth?: Date, targetDate?: Date, startDate?: Date, endDate?: Date): CalendarDayType {
    if (targetDate && beginningOfMonth && targetDate.getMonth() !== beginningOfMonth.getMonth()) {
        return 'future';
    } else if (
        targetDate &&
        ((startDate && sameDay(startDate, targetDate)) || (endDate && sameDay(endDate, targetDate)))
    ) {
        return 'selected';
    } else if (startDate === undefined || endDate === undefined) {
        return 'default';
    } else if (targetDate && targetDate.getTime() > startDate.getTime() && targetDate.getTime() < endDate.getTime()) {
        return 'transient';
    } else {
        return 'default';
    }
}

/**
 * The stylistic type of calendar day to render.
 */
type CalendarDayType = 'selected' | 'transient' | 'future' | 'default';
type CalendarDayProps = {
    /** The className which is used by styled components */
    className?: string;
    /** Which day number to render in the component */
    day: number;
    /** What stylistic type to apply to this component */
    type: CalendarDayType;
} & Omit<BoxProps, 'ref'>;

const CalendarDay = styled(({ className, day, ...props }: CalendarDayProps) => {
    return (
        <Box className={className} {...props}>
            {day}
        </Box>
    );
})`
    background-color: ${(props: any) =>
        props.type === 'selected'
            ? props.theme.colors.accent
            : props.type === 'transient'
            ? props.theme.colors.accentTrans
            : props.type === 'future'
            ? props.theme.colors.black
            : props.theme.colors.bluegray300};
    color: ${(props: any) => (props.type === 'transient' ? props.theme.colors.accent : props.theme.colors.white)};
    border-radius: 7px;
    width: ${DAY_SIZE}px;
    height: ${DAY_SIZE}px;
    justify-content: center;
    align-items: center;
`;

export type CalendarProps = {
    /**
     * The origin day. This date will get highlighted in the UI
     * and is defaulted to "today".
     */
    origin?: Datelike;
    /** If specified, this will be the start of a given date range. */
    startDate?: Datelike;
    /** If specified, this will be the end of the range. Everything inbetween start and end will have the transient style. */
    endDate?: Datelike;
    /** The minimum year you can pick in the year picker carousel */
    minYear?: number;
    /** The maximum year you can pick in the year picker carousel */
    maxYear?: number;
    /** The className used by styled-components. */
    className?: string;
    /** A method which is invoked when a specific day is clicked. */
    onDayClick?: (day: Date) => void;
    /** A method which is invoked when the mouse enters the specific day area */
    onDayEnter?: (day: Date) => void;
    /** A method which is invoked when the mouse leaves a specific day area */
    onDayLeave?: (day: Date) => void;
} & Omit<BoxProps, 'ref'>;

export const Calendar = styled(
    ({
        className,
        minYear,
        maxYear,
        onDayClick,
        onDayEnter,
        onDayLeave,
        startDate,
        endDate,
        origin = new Date(),
        ...props
    }: CalendarProps) => {
        // Compute prefix, postfix, and range.
        const [mode, setMode] = useState<CalendarMode>('calendar');
        const [beginningOfMonth, setBeginningOfMonth] = useState(parseDatelike(origin) ?? new Date());
        beginningOfMonth.setDate(1);

        // How many empty boxes to render first
        const totalDays = getLastDayOfMonth(beginningOfMonth).getDate();
        const prefixBoxes = beginningOfMonth.getDay() - 1;
        const postfixBoxes = 6 - getLastDayOfMonth(beginningOfMonth).getDay();

        const dayElements: Array<Date | null> = [];
        for (let row = 0; row < totalDays + prefixBoxes + postfixBoxes + 1; row++) {
            if (row <= prefixBoxes) {
                dayElements.push(null);
            } else {
                // Validate this date is really part of the month in question.
                const dayNumber = row - prefixBoxes;
                const candidate = new Date(beginningOfMonth.getTime());
                candidate.setDate(dayNumber);
                dayElements.push(candidate);
            }
        }

        /** This method will advance the month forward or backward which re-renders the calendar accordingly. */
        const advanceMonth = useCallback(
            (direction: 'forward' | 'backward') => {
                const nextMonth = new Date(beginningOfMonth.getTime());
                switch (direction) {
                    case 'forward': {
                        nextMonth.setMonth(beginningOfMonth.getMonth() + 1);
                        break;
                    }
                    default: {
                        nextMonth.setMonth(beginningOfMonth.getMonth() - 1);
                        break;
                    }
                }
                setBeginningOfMonth(nextMonth);
            },
            [beginningOfMonth, setBeginningOfMonth],
        );

        return (
            <Box className={className} {...props}>
                <Box className="month">
                    <Box grow>
                        <button onClick={() => setMode('month')}>{MONTHS[beginningOfMonth.getMonth()]}</button>
                        <button onClick={() => setMode('year')}>{beginningOfMonth.getFullYear()}</button>
                    </Box>
                    <button
                        onClick={() => {
                            setBeginningOfMonth(new Date());
                            setMode('calendar');
                        }}
                        className="today"
                    >
                        Today
                    </button>
                    <Box>
                        <button
                            className="arrow"
                            data-testid="arrow-left"
                            onClick={advanceMonth.bind(this, 'backward')}
                        >
                            <OSKIcon style={{ cursor: 'pointer' }} code="arrow-left" />
                        </button>
                        <button
                            className="arrow"
                            data-testid="arrow-right"
                            onClick={advanceMonth.bind(this, 'forward')}
                        >
                            <OSKIcon style={{ cursor: 'pointer' }} code="arrow-right" />
                        </button>
                    </Box>
                </Box>
                {(function () {
                    if (mode === 'calendar') {
                        return (
                            <React.Fragment>
                                <Box className="headers">
                                    <Box>Su</Box>
                                    <Box>Mo</Box>
                                    <Box>Tu</Box>
                                    <Box>We</Box>
                                    <Box>Th</Box>
                                    <Box>Fr</Box>
                                    <Box>Sa</Box>
                                </Box>
                                <Box className="days">
                                    {dayElements.map((day, idx) =>
                                        day ? (
                                            <CalendarDay
                                                onClick={() => {
                                                    onDayClick && onDayClick(day);
                                                }}
                                                onMouseEnter={() => {
                                                    onDayEnter && onDayEnter(day);
                                                }}
                                                onMouseLeave={() => {
                                                    onDayLeave && onDayLeave(day);
                                                }}
                                                type={computeType(
                                                    beginningOfMonth,
                                                    day,
                                                    parseDatelike(startDate),
                                                    parseDatelike(endDate),
                                                )}
                                                key={`day-${day}`}
                                                day={day.getDate()}
                                            />
                                        ) : (
                                            <Box key={`day-future-${idx}`} w={DAY_SIZE} h={DAY_SIZE} />
                                        ),
                                    )}
                                </Box>
                            </React.Fragment>
                        );
                    } else if (mode === 'month') {
                        return (
                            <MonthPicker
                                onMonthSelect={(month) => {
                                    const nextMonth = new Date(beginningOfMonth.getTime());
                                    nextMonth.setMonth(month);
                                    setBeginningOfMonth(nextMonth);
                                    setMode('calendar');
                                }}
                            />
                        );
                    } else if (mode === 'year') {
                        return (
                            <YearPicker
                                selected={beginningOfMonth}
                                minYear={minYear}
                                maxYear={maxYear}
                                onYearSelected={(year) => {
                                    const nextMonth = new Date(beginningOfMonth.getTime());
                                    nextMonth.setFullYear(year);
                                    setBeginningOfMonth(nextMonth);
                                    setMode('calendar');
                                }}
                            />
                        );
                    }
                })()}
            </Box>
        );
    },
)`
    & {
        flex-direction: column;
        width: ${MAX_WIDTH}px;
        min-height: 300px;
        font-size: 0.75rem;
        color: ${(props: any) => props.theme.colors.white} !important;
    }

    button {
        font-size: 1.35rem;
        color: ${(props: any) => props.theme.colors.white};
        outline: none;
        background: none;
        border: none;
        cursor: pointer;
    }

    .today {
        font-size: 1rem;
        align-self: center;
    }

    .arrow {
        cursor: pointer;
        padding: 0px 5px;
    }

    button:hover {
        color: ${(props: any) => props.theme.colors.accent};
    }

    .month {
        font-size: 1.5rem;
        font-weight: bold;
        padding-left: 3px;
    }

    .headers {
        font-weight: bold;
        align-items: center;
    }

    .days div,
    .headers div {
        margin: 3px;
    }

    .headers div {
        justify-content: center;
        width: ${DAY_SIZE}px;
    }

    .days {
        flex-wrap: wrap;
    }
`;
