function isBlank(r: number, g: number, b: number, a: number) {
    return (r === 255 && g === 255 && b === 255) || (r === 0 && g === 0 && b === 0) || a === 0;
}

/**
 * Find the average value of an array after
 * dropping some number of values from the edges.
 *
 * @param arr The array of numbers
 * @returns The average
 */
function average(arr: number[]) {
    let avg = arr[0];

    for (let i = 1; i < arr.length; i++) {
        // Rolling average formumal
        avg += (arr[i] - avg) / arr.length;
    }

    return avg;
}

export type OffsetBoundary = {
    /** The x offset */
    x: number;
    /** The y offset */
    y: number;
};

/**
 * This method will take an ImageBitmap and find the average x and y
 * offsets to apply so the image is mostly centered.
 *
 * @param raster The ImageBitmap to evaluate
 * @param thumbnailWidth The width of the intended thumbnail
 * @param thumbnailHeight The height of the intended thumbnail
 * @returns The x and y offsets to get the top-left roughly centered.
 */
export function findCenterOffset(raster: ImageBitmap, thumbnailWidth: number, thumbnailHeight: number): OffsetBoundary {
    // This code is meant to find where the first pixel is.
    const fake = new OffscreenCanvas(raster.width, raster.height);
    const fakeCtx = fake.getContext('2d');

    if (fakeCtx) {
        const xValues = [];
        let firstY = Number.MAX_VALUE,
            found = false;

        fakeCtx.drawImage(raster, 0, 0);
        const { data } = fakeCtx.getImageData(0, 0, raster.width, raster.height);
        const depth = 4;

        if (data) {
            for (let y = 0; y < raster.height; y++) {
                for (let x = 0; x < raster.width * depth; x += depth) {
                    const pos = y * (raster.width * depth) + x;
                    const r = data[pos];
                    const g = data[pos + 1];
                    const b = data[pos + 2];
                    const a = data[pos + 3];

                    if (!isBlank(r, g, b, a)) {
                        xValues.push(x / depth);
                        found = true;
                    }
                }

                if (found && firstY === Number.MAX_VALUE) {
                    firstY = y;
                } else if (y > firstY + thumbnailHeight) {
                    break;
                }
            }
        }

        return {
            x: -thumbnailWidth / 2 + average(xValues),
            y: firstY,
        };
    } else {
        return {
            x: 0,
            y: 0,
        };
    }
}
