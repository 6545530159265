import { Box, OSKIcon, OSKThemeType, Spinner, Typography } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { ImgTiff } from '~/atoms';
import { utc_date_format } from '~/utils';
import { InternalRasterCapture } from '..';
import { ArtifactAvailabilityChip } from '../ArtifactAvailabilityChip';
import { ARTIFACT_COLUMNS } from '~/constants';

type CapturesGridViewProps = {
    /** Children passthrough */
    children?: React.ReactNode | React.ReactNode[];
    /** The captures to render */
    captures?: InternalRasterCapture[];
    /** A method that's called when the image is clicked */
    onImageClick?: (capture: InternalRasterCapture) => void;
};

const CapturesGridView = ({ children, captures, onImageClick }: CapturesGridViewProps) => {
    const theme = useTheme() as OSKThemeType;

    if (!captures) {
        return (
            <Box p={14} grow center="horizontal">
                <Spinner variant="Box" size="Medium" />
            </Box>
        );
    } else if (captures.length === 0) {
        return (
            <Box
                style={{
                    padding: '14px',
                    borderRadius: '7px',
                    border: `1px solid ${theme.colors.black200}`,
                }}
                grow
                col
                center="horizontal"
            >
                <Typography variant="heading2">Error retrieving captures.</Typography>
            </Box>
        );
    }

    return (
        <Box
            style={{
                padding: '14px',
                borderRadius: '7px',
                border: `1px solid ${theme.colors.black200}`,
            }}
            col
        >
            {children}
            <Box>
                <Typography variant="heading2">Captures</Typography>
            </Box>
            <Box style={{ flexWrap: 'wrap', marginTop: '12px' }}>
                {captures.map((capture: InternalRasterCapture, idx) => (
                    <Box
                        key={`${capture.id}`}
                        style={{
                            width: '250px',
                            margin: '6px',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0px 2px 10.199999809265137px 0px #0E0E0E40',
                        }}
                        col
                    >
                        <Box col>
                            <Box m={8} w={150} col>
                                <Typography variant="caption1">
                                    <span
                                        style={{
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            width: '235px',
                                        }}
                                    >
                                        {capture.file_id}
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                        <ImgTiff
                            src={capture.preview_artifact ?? ''}
                            width={250}
                            height={250}
                            loading={'lazy'}
                            style={{ borderRadius: ' 0px' }}
                            onClick={() => {
                                onImageClick && onImageClick(capture);
                            }}
                        />
                        <Box style={{ margin: '13px 0px 11px 13px' }} col>
                            <Box style={{ alignItems: 'wrap' }} h={25}>
                                {ARTIFACT_COLUMNS.map((col) => {
                                    const raster =
                                        capture.rasters &&
                                        capture.rasters.find((raster) => raster.artifact_type === col.field);

                                    return (
                                        <ArtifactAvailabilityChip
                                            key={`${col.field}-${idx}`}
                                            text={col.label}
                                            available={raster !== undefined}
                                        />
                                    );
                                })}
                            </Box>
                        </Box>
                        <Box style={{ margin: '0px 0px 0px 13px' }} col>
                            <Box row>
                                <OSKIcon
                                    code="location-crosshairs"
                                    fill="black"
                                    w={14}
                                    style={{ placeSelf: 'flex-start', marginTop: '4px' }}
                                />
                                <Box col ml={4}>
                                    <Typography variant="caption1" m={2} strong>
                                        Acquired
                                    </Typography>
                                    <Typography variant="caption1" m={2}>
                                        {utc_date_format(capture.acquisition_time)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ margin: '4px 0px 10px 13px' }} col>
                            <Box row>
                                <OSKIcon code="clock" w={14} style={{ placeSelf: 'flex-start', marginTop: '4px' }} />
                                <Box col ml={4}>
                                    <Typography variant="caption1" m={2} strong>
                                        Processed
                                    </Typography>
                                    <Typography variant="caption1" m={2}>
                                        {utc_date_format(capture.processed_date)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default CapturesGridView;
