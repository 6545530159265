/**
 * This method will compile a shader based on the source code.
 */
export function createShader(gl: WebGLRenderingContext, type: number, source: string) {
    const shader = gl.createShader(type);
    if (shader) {
        gl.shaderSource(shader, source);
        gl.compileShader(shader);

        const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
        if (success) {
            return shader;
        } else {
            console.error('Failed to compile webgl shader');
            console.error(gl.getShaderInfoLog(shader));
            gl.deleteShader(shader);
        }
    } else {
        console.error('Failed to create webgl shader due to a compatibility issue');
    }

    return null;
}

/**
 * This method will compile a WebGL program based on two kinds of shaders.
 */
export function createProgram(gl: WebGLRenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader) {
    const program = gl.createProgram();
    if (program) {
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);

        const success = gl.getProgramParameter(program, gl.LINK_STATUS);
        if (success) {
            return program;
        } else {
            console.error('Failed to create the webgl program');
            gl.deleteProgram(program);
        }
    }

    return null;
}

/** This method will return true if wasm is supported and enabled */
export function wasmSupported(): boolean {
    try {
        if (typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function') {
            const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
            if (module instanceof WebAssembly.Module)
                return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
        }
    } catch (e) {}
    return false;
}

/**
 * This method will return true if webgl is supported and enabled.
 */
export function webglSupported(): boolean {
    try {
        const canvas = new OffscreenCanvas(1, 1);
        return !!window.WebGLRenderingContext && !!canvas.getContext('webgl');
    } catch (e) {
        return false;
    }
}
