import { Box, Typography } from 'oskcomponents';
import React from 'react';
import { BasicDialog } from '~/molecules/BasicDialog';

export type InviteUserAttributes = {
    [key: string]: any;
};

export type InviteUserDialogProps = {
    visible: boolean;
    email: string;
    roles: string[];
    onCancel?: () => void;
    onSubmit?: (data: InviteUserAttributes) => void;
};

export const InviteUserDialog = ({ email, roles, onCancel, onSubmit, visible }: InviteUserDialogProps) => {
    const emails = email.replace(' ', '').split(',');

    return (
        <BasicDialog
            cancel
            onCancel={onCancel}
            onSubmit={onSubmit}
            visible={visible}
            title="Invite User(s)"
            style={{ height: 'fit-content' }}
        >
            <Box center="all" col style={{ textAlign: 'center' }}>
                <Typography variant="body3" style={{ marginBottom: '8px' }}>
                    You are about to invite the following users:
                </Typography>
                <Box
                    style={{
                        marginBottom: '8px',
                        maxHeight: '300px',
                        overflowY: 'scroll',
                        width: '100%',
                    }}
                    center="horizontal"
                >
                    <Typography variant="body3">
                        {emails.map((email) => (
                            <>
                                {email}
                                <br />
                            </>
                        ))}
                    </Typography>
                </Box>
                <Typography variant="body3" style={{ marginBottom: '8px' }}>
                    with the following roles:
                </Typography>
                <Typography variant="body2">
                    {roles.map((role) => (
                        <>
                            <strong>{role}</strong>
                            <br />
                        </>
                    ))}
                </Typography>
            </Box>
        </BasicDialog>
    );
};
