import { Box, Heading, OSKThemeType, RatioLayout, RatioPanel, TabView, Text } from 'oskcomponents';
import { MapOf, noop } from 'oskcore';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Breadcrumb, OSKHR } from '~/atoms';
import { ActionBar } from '~/atoms/ActionBar';
import { useEnsureUrlParameters } from '~/hooks';
import { IconHeader } from '~/molecules/Monitor';
import { OSKContentView } from '~/molecules/OSKContentView';
import { ProgramReportsTable } from '~/organisms/tables';
import { MonitorResourceProvider } from '~/redux';
import { Asset } from '~/redux/modules/monitor/app';
import { getReportsWithNames, ProgramReport } from '~/redux/modules/monitor/reports';
import { RootState } from '~/redux/store';
import { date_format, getProgramId } from '~/utils';

const SUMMARY_REPORTS_INDEX = 0;
const FIELD_REPORTS_INDEX = 1;

export type MonitorReportsViewProps = {
    /** Whether the report list is actively being fetched */
    isLoading: boolean;
    /** List of reports to render in the ui */
    reports: Array<ProgramReport>;
    /** List of assets  */
    assets: MapOf<Asset>;
};

type HeaderType = {
    children: React.ReactNode;
};

const Header = ({ children }: HeaderType) => {
    return (
        <Box style={{ paddingBottom: '20px', paddingTop: '10px' }} col>
            <Box m={20} h={40}>
                {children}
            </Box>
            <OSKHR />
        </Box>
    );
};

const MonitorReportsView = ({ isLoading, reports, assets }: MonitorReportsViewProps) => {
    const ReportTypeByTabIndex = [SUMMARY_REPORTS_INDEX, FIELD_REPORTS_INDEX];

    const theme = useTheme() as OSKThemeType;
    const navigate = useNavigate();
    const urlParams = useParams();
    const { programId, reportId } = urlParams;
    const [selectedReportIdx, setSelectedReportIdx] = useState(0);
    const selectedTabIndex =
        reports.length > 0
            ? ReportTypeByTabIndex.findIndex((reportType) => reportType === reports[selectedReportIdx].report_type)
            : 0;

    const [selectedReportType, setSelectedReportType] = useState(ReportTypeByTabIndex[selectedTabIndex]);
    const filteredReports: ProgramReport[] = reports.filter((report) => report.report_type === selectedReportType);

    useEnsureUrlParameters('/program/:programId/monitor/reports');

    useEffect(() => {
        if (!isLoading && reports && reports.length > 0) {
            const defaultReportIdx = reports.findIndex((report) => report.external_id === reportId);

            // findIndex returns -1 if not found
            if (defaultReportIdx > -1) {
                setSelectedReport(defaultReportIdx);
            } else {
                setSelectedReport(0);
            }
        }
    }, [reports, isLoading]);

    const setSelectedReport = (newIdx: number) => {
        setSelectedReportIdx(newIdx);
        setSelectedReportType(reports[newIdx].report_type as number);
        navigate(`/program/${programId}/monitor/reports/${reports[newIdx].external_id}`, { replace: true });
    };

    const ReportTable = (
        <ProgramReportsTable
            selectedIndex={filteredReports.findIndex(
                (filteredReport) => filteredReport.external_id === reports[selectedReportIdx].external_id,
            )}
            isLoading={isLoading}
            data={filteredReports}
            onRowClick={(selectedReport) =>
                setSelectedReport(reports.findIndex((report) => report.external_id === selectedReport.external_id))
            }
        />
    );

    return (
        <MonitorResourceProvider programId={getProgramId()} features={['reports', 'assets']}>
            <OSKContentView>
                <Box grow col>
                    <ActionBar left={<Breadcrumb nodes={[{ title: 'Reports' }]} />} />
                    <Box style={{ padding: '14px 23px' }} grow>
                        <Box
                            style={{
                                backgroundColor: theme.colors.primary.transBg,
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                borderRadius: '30px',
                            }}
                            grow
                        >
                            <RatioLayout
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                grow
                            >
                                <RatioPanel
                                    p={24}
                                    weight={1}
                                    grow
                                    style={{
                                        borderRight: `1px solid ${theme.colors.primary.border}`,
                                    }}
                                >
                                    <IconHeader title="Reports" iconCode="report" />
                                    <Box m={14} />
                                    <TabView
                                        key={`report-type-tab-${selectedReportIdx}`}
                                        defaultTab={selectedTabIndex}
                                        onTabChange={(tabIdx) => {
                                            const newFilteredReports = reports.filter(
                                                (report) => report.report_type === ReportTypeByTabIndex[tabIdx],
                                            );

                                            // Short-circuit the "default row" logic if the report tab is not actually changing.
                                            if (selectedReportType === ReportTypeByTabIndex[tabIdx]) return;

                                            if (newFilteredReports.length > 0) {
                                                setSelectedReport(
                                                    newFilteredReports.length > 0
                                                        ? reports.findIndex(
                                                              (report) =>
                                                                  report.external_id ===
                                                                  newFilteredReports[0].external_id,
                                                          )
                                                        : 0,
                                                );
                                            } else {
                                                setSelectedReportType(ReportTypeByTabIndex[tabIdx]);
                                            }
                                        }}
                                        tabContainerStyle={{
                                            backgroundColor: 'transparent',
                                            borderTop: `1px solid ${theme.colors.primary.altTransBg}`,
                                            borderBottom: `1px solid ${theme.colors.primary.altTransBg}`,
                                            justifyContent: 'space-around',
                                            marginBottom: '20px',
                                        }}
                                        tabItems={[
                                            {
                                                label: 'Summary Reports',
                                                component: ReportTable,
                                            },
                                            {
                                                label: 'Field Reports',
                                                component: ReportTable,
                                            },
                                        ]}
                                    />
                                </RatioPanel>
                                <RatioPanel
                                    style={{ borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }}
                                    bg={theme.colors.primary.bg}
                                    weight={2}
                                    grow
                                >
                                    <Box
                                        style={{
                                            margin: '0px 18px',
                                        }}
                                        col
                                        grow
                                    >
                                        {filteredReports.length > 0 && (
                                            <React.Fragment>
                                                <Header>
                                                    <Heading>
                                                        {assets[reports[selectedReportIdx].assets[0]]?.name ?? 'Report'}{' '}
                                                        {date_format(reports[selectedReportIdx].created_at)}
                                                    </Heading>
                                                </Header>
                                                {reports && reports[selectedReportIdx].pdf_presigned_uri ? (
                                                    <object
                                                        data={reports[selectedReportIdx].pdf_presigned_uri ?? ''}
                                                        type="application/pdf"
                                                        width="100%"
                                                        height="100%"
                                                    >
                                                        <p>
                                                            <a
                                                                style={{ color: 'white', fontSize: '1.5rem' }}
                                                                href={
                                                                    reports[selectedReportIdx].pdf_presigned_uri ?? ''
                                                                }
                                                            >
                                                                Download the report here.
                                                            </a>
                                                        </p>
                                                    </object>
                                                ) : (
                                                    <Box center="all" col grow>
                                                        <Text variant="large" style={{ paddingBottom: '8px' }}>
                                                            No file generated for this report.
                                                        </Text>
                                                        <Text variant="small">(This can take a few minutes.)</Text>
                                                    </Box>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Box>
                                </RatioPanel>
                            </RatioLayout>
                        </Box>
                    </Box>
                </Box>
            </OSKContentView>
        </MonitorResourceProvider>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.monitor.reports.isFetching,
        reports: getReportsWithNames(state),
        assets: state.monitor.app.assets,
    };
};

export { MonitorReportsView };
export default connect(mapStateToProps, noop)(MonitorReportsView);
