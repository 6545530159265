import { ControlPosition, Map } from 'leaflet';
import 'leaflet-editable';
import { noop } from 'oskcore';
import React, { useEffect, useRef, useState } from 'react';
import { ReactLeafletEditable } from 'react-leaflet-editable';
import { connect } from 'react-redux';
import { Map as OSKMap, MapProps } from '~/atoms/Map';
import { useToggles } from '~/hooks/useToggles';
import { LeafletDrawUtil } from '~/leafletDrawUtil';
import { RootState } from '~/redux/store';
import { OSKMapLowbar } from '~/molecules/OSKMapLowbar';

type EditableMapProps = {
    /** Children components to pass to the map */
    children?: React.ReactNode;
    /** If true, the map will be in edit polygon mode which means
     * clicking on the map will begin drawing a polygon.
     */
    editPolygon?: boolean;
    /** Location of the zoom controls */
    zoomPosition?: ControlPosition;
    /** Optionally override some map displays to use our own
     * 'low-bar' component. */
    useOSKLowbar?: boolean;
} & MapProps;

type EditableMapContextType = {
    editRef: any;
    map: any;
    drawingUtils?: LeafletDrawUtil;
};

const EditableMapContext = React.createContext({
    editRef: null as any,
    drawingUtils: undefined,
    map: {} as any,
} as EditableMapContextType);

/**
 * This component is a react-wrapped map which supports the ability to enter
 * various edit modes through props..
 */
const EditableMap = ({ children, zoomPosition, useOSKLowbar = true, ...props }: EditableMapProps) => {
    const [map, setMap] = useState<Map | null>(null);
    const [drawingUtils, setDrawingUtils] = useState<LeafletDrawUtil | undefined>(undefined);
    const editRef = useRef<any>(null);
    const toggles = useToggles();

    useEffect(() => {
        if (map) {
            setDrawingUtils(new LeafletDrawUtil(map));
        }

        const fn = () => {
            // @ts-ignore
            map.invalidateSize(true);
        };

        // Hide "Leaflet" attribution
        map?.attributionControl.remove();

        // TODO: Something else. But this works ya know?
        const timerId = setInterval(fn, 1000);
        return () => {
            clearInterval(timerId);
        };
    }, [map]);

    // Hide maps if the relevant feature flags are set
    if (toggles.hideMaps()) {
        return <></>;
    }

    return (
        <React.Fragment>
            <EditableMapContext.Provider value={{ editRef, map, drawingUtils }}>
                <ReactLeafletEditable ref={editRef} map={map}>
                    <OSKMap
                        zoomPosition={zoomPosition}
                        editable={true}
                        doubleClickZoom={false}
                        /* @ts-ignore */
                        whenCreated={setMap}
                        {...props}
                    >
                        {children}
                        {useOSKLowbar && <OSKMapLowbar />}
                    </OSKMap>
                </ReactLeafletEditable>
            </EditableMapContext.Provider>
        </React.Fragment>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        editPolygon: state.data.map.editPolygon,
    };
};

export type { EditableMapProps, EditableMapContextType };
export { EditableMap, EditableMapContext };
export default connect(mapStateToProps, noop)(EditableMap);
