import { Box, OSKIcon, OSKThemeType, Spinner, Table, TableColumn, Text, Tooltip } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { MonitorResourceProvider } from '~/redux';
import { getReportsWithNames, ProgramReport } from '~/redux/modules/monitor/reports';
import { RootState } from '~/redux/store';
import { date_format, getProgramId } from '../../../utils';

type AssetReportsTableProps = {
    /** The currently selected asset */
    selectedAssetId?: number;
    /** List of all program reports */
    reports: Array<ProgramReport>;
    /** Flag to check if we're still loading */
    isLoading: boolean;
};

const AssetReportsTable = ({ reports, selectedAssetId, isLoading, ...props }: AssetReportsTableProps) => {
    const theme = useTheme() as OSKThemeType;
    const { programId } = useParams();

    const reportsForAsset = reports?.filter((report) => report.assets.includes(parseInt(`${selectedAssetId ?? 0}`)));

    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            {
                Header: 'Date',
                accessor: 'created_at',
                Cell: ({ value }: any) => {
                    const date = date_format(value);
                    return date ? date : '--';
                },
            },
            {
                Header: 'Type',
                accessor: 'report_type',
                Cell: ({ value }: any) => {
                    if (value === 0) {
                        return 'Summary Report';
                    } else {
                        return 'Field Report';
                    }
                },
            },
            {
                Header: 'File',
                accessor: 'pdf_presigned_uri',
                Cell: ({ row, value }: any) => {
                    const original = { ...row.original };
                    return (
                        <Box>
                            {value ? (
                                <Tooltip label="Download" position="top">
                                    <a
                                        style={{ color: theme.colors.primary.fg, paddingRight: '14px' }}
                                        href={value}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <OSKIcon code="download" />
                                    </a>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    label="Report is still being generated... this can take a few minutes. Check back later."
                                    position="top"
                                >
                                    <OSKIcon code="download" fill="grey" style={{ paddingRight: '14px' }} />
                                </Tooltip>
                            )}
                            <Tooltip label="Preview" position="top">
                                <Link to={`/program/${programId}/monitor/reports/${original.external_id}`}>
                                    <OSKIcon code="search" fill="white" />
                                </Link>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        ],
        [],
    );

    return (
        <MonitorResourceProvider programId={getProgramId()} features={['reports']}>
            {(function () {
                if (isLoading) {
                    return (
                        <Box center="all">
                            <Spinner />
                        </Box>
                    );
                }

                if (reportsForAsset.length < 1) {
                    return (
                        <Box grow center="all" style={{ alignSelf: 'center' }}>
                            <Text>No reports available</Text>
                        </Box>
                    );
                }

                return <Table {...props} columns={columns} data={reportsForAsset} />;
            })()}
        </MonitorResourceProvider>
    );
};

export { AssetReportsTable };
export type { AssetReportsTableProps };

const mapStateToProps = (state: RootState, ownProps: Partial<AssetReportsTableProps>) => {
    return {
        isLoading: state.monitor.reports.isFetching,
        reports: getReportsWithNames(state),
    };
};

export default connect(mapStateToProps, noop)(AssetReportsTable);
