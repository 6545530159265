import React from 'react';
import { Box, OSKIcon, Typography } from 'oskcomponents';
import CSS from 'csstype';
import { remap_to_range } from '~/utils';

export type CloudCoverageChipProps = {
    /** The value of cloud cover, as a 0-100 percentage.
     * If null, cloud cover isn't available for the capture. */
    value?: number;
};

export const CloudCoverageChip = ({ value, ...props }: CloudCoverageChipProps) => {
    let opacity = 0.5; // Neutral

    const chipStyle: CSS.Properties = {};
    if (value !== undefined && value !== null) {
        opacity = remap_to_range(value / 100, 0.05, 0.4);

        chipStyle.color = 'black';
        chipStyle.backgroundColor = `rgba(0,0,0,${opacity})`;
    } else {
        // Unavailable
        chipStyle.color = 'black';
        chipStyle.width = '114px';
        chipStyle.marginLeft = '-9px';
    }

    return (
        <Box {...props} style={{ borderRadius: '4px', width: '65px', height: '23px', ...chipStyle }} center="all">
            {value !== undefined && value !== null ? (
                <>
                    <OSKIcon code="cloud-outline" fill={chipStyle.color} style={{ paddingRight: '6px' }} />
                    <Typography variant="caption1" style={{ fontWeight: '700' }}>
                        {value}%
                    </Typography>
                </>
            ) : (
                <>
                    <OSKIcon code="no-cloud" fill={chipStyle.color} style={{ paddingRight: '6px' }} />
                    <Typography variant="caption1" strong>
                        Unavailable
                    </Typography>
                </>
            )}
        </Box>
    );
};
