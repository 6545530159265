import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, Button, Heading, OSKIcon, OSKThemeType, Spinner, Table, TableColumn, Text } from 'oskcomponents';
import { FormElement, OSKHR } from '~/atoms';
import * as yup from 'yup';
import { UserProfileIcon } from '~/molecules';
import { OSKProfile } from 'oskcore/src/templates/OSKAppProvider';
import { AdminAPI, Program } from 'oskcore';

export type ProfileFormProps = {
    data?: OSKProfile;
    className?: string;
    editMode?: boolean;
    onEditModeChange?: (value: boolean) => void;
};

export const ProfileSchema: yup.SchemaOf<any> = yup.object().shape({
    email: yup.string(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    organization: yup.string().required(),
    title: yup.string(),
    job_role: yup.string(),
    country: yup.string(),
    phone_number: yup.string(),
});

export const ProfileForm = styled(({ className, data, editMode, onEditModeChange }: ProfileFormProps) => {
    const [loading, setLoading] = useState(true);
    const [programRows, setProgramRows] = useState<Program[]>([]);
    const theme = useTheme() as OSKThemeType;

    useEffect(() => {
        AdminAPI.listPrograms()
            .then((resp) => {
                setProgramRows(resp.data.results ?? []);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // TODO: Replace this with a future API call to retrieve from the database
    const dataLevelAccessMap: { [id: number]: string } = {
        0: 'Level 0',
        10: 'Level 1a',
        20: 'Level 1b',
        30: 'Level 2',
        40: 'Level 3',
        50: 'Level 4',
    };

    const programColumns = React.useMemo<Array<TableColumn>>(
        () => [
            {
                Header: 'Program',
                accessor: 'name',
            },
        ],
        [],
    );

    if (loading) {
        return (
            <Box center="all">
                <Spinner />
            </Box>
        );
    }

    return (
        <Box style={{ justifyContent: 'center' }}>
            <Box className={className} col>
                <input type="hidden" name="id" value={data?.id} />
                <Box
                    h={75}
                    style={{
                        position: 'relative',
                        borderRadius: '5px',
                        background: "url('/images/spectral-bg.png')",
                    }}
                />
                <Box
                    style={{
                        position: 'relative',
                        width: '90px',
                        height: '90px',
                        borderRadius: '50%',
                        top: '-45px',
                        left: '45px',
                        border: 'solid white 2px',
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                        marginBottom: '-40px',
                        backgroundColor: theme.colors.primary.invertedFg,
                    }}
                    center="all"
                >
                    <UserProfileIcon iconSize={86} />
                </Box>
                <Box style={{ alignSelf: 'flex-end', margin: '-19px 0px' }}>
                    <Button inverted variant="action" onClick={() => onEditModeChange && onEditModeChange(!editMode)}>
                        Edit
                    </Button>
                </Box>
                {editMode ? (
                    <React.Fragment>
                        <FormElement
                            type="input"
                            name="first_name"
                            label="First Name"
                            defaultValue={data?.first_name}
                        />
                        <FormElement type="input" name="last_name" label="Last Name" defaultValue={data?.last_name} />
                    </React.Fragment>
                ) : (
                    <Heading style={{ width: 'fit-content' }}>
                        {data?.first_name} {data?.last_name}
                    </Heading>
                )}
                <OSKHR />
                <Box col>
                    <Heading>
                        <Box row center="vertical">
                            <OSKIcon code="email" style={{ paddingRight: '12px' }} /> Email:
                        </Box>
                    </Heading>
                    <Text style={{ paddingTop: '12px', paddingLeft: '2.5em' }}>
                        {data?.email && <a href={`mailto: ${data?.email}`}>{data?.email}</a>}
                    </Text>
                </Box>
                <OSKHR />
                <Box col>
                    <Heading>
                        <Box row center="vertical">
                            <OSKIcon code="company" style={{ paddingRight: '12px' }} /> Company:
                        </Box>
                    </Heading>
                    {editMode ? (
                        <FormElement
                            type="input"
                            name="organization"
                            label="Organization"
                            defaultValue={data?.organization}
                        />
                    ) : (
                        <Text style={{ paddingTop: '12px', paddingLeft: '2.5em' }}>{data?.organization}</Text>
                    )}
                </Box>
                <OSKHR />
                <Box col>
                    <Heading>
                        <Box row center="vertical">
                            <OSKIcon code="user" style={{ paddingRight: '12px' }} /> Job Title:
                        </Box>
                    </Heading>
                    {editMode ? (
                        <FormElement type="input" name="job_title" label="Job Title" defaultValue={data?.job_title} />
                    ) : (
                        <Text style={{ paddingTop: '12px', paddingLeft: '2.5em' }}>{data?.job_title}</Text>
                    )}
                </Box>
                <OSKHR />
                {(programRows ?? []).length > 0 && (
                    <Box col>
                        <Heading>
                            <Box row center="vertical">
                                <OSKIcon code="folder" style={{ paddingRight: '12px' }} /> Programs:
                            </Box>
                        </Heading>
                        <Box grow style={{ marginTop: '12px', paddingLeft: '2.5em', borderRadius: '10px' }}>
                            <Table
                                data={
                                    programRows ?? [
                                        {
                                            id: 0,
                                            name: 'None',
                                        } as Program,
                                    ]
                                }
                                columns={programColumns}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
})`
    & {
        width: 100%;
    }

    .oskhr {
        margin: 24px 0px;
    }

    a {
        color: ${(props: any) => props.theme.colors.primary.accent};
    }
`;
