import React from 'react';
import { useTheme } from 'styled-components';
import { Box } from '../Box';
import { ColorVariants, OSKThemeType } from '../DefaultThemeProvider';
import { OSKIcon, OSKIconType } from '../OSKIcon';
import { Tooltip } from '../Tooltip';

type IconSwitchOption = {
    /** Value of the option */
    value: string;
    /** The element to render for this option */
    icon: OSKIconType;
    /** A flag to prevent this option from being selected. */
    disabled?: boolean;
    /** Optional display label for tooltip */
    label?: string;
};

type IconSwitchProps = {
    /** Array of IconSwitchOptions to display */
    options: Array<IconSwitchOption>;
    /** Optional selected value, used to highlight an option*/
    selectedValue?: string;
    /** The theme variant to use. Default: `primary` */
    variant?: ColorVariants;
    /** Method that's called when an option is clicked */
    onSelect?: (value: any) => void;
};

const IconSwitch = ({ options, selectedValue, variant, onSelect, ...props }: IconSwitchProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            h={28}
            style={{
                borderRadius: '5px',
                backgroundColor: theme.colors[variant ?? 'primary'].transBg,
                backdropFilter: 'blur(8px)',
                alignItems: 'center',
                width: 'fit-content',
                padding: '17px 6px',
            }}
            {...props}
        >
            {options.map((option) => (
                <Box
                    p={8}
                    style={{
                        width: 'fit-content',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor:
                            selectedValue === option.value ? theme.colors[variant ?? 'primary'].accent : '',
                        borderRadius: '5px',
                        cursor: option.disabled || option.value === selectedValue ? 'default' : 'pointer',
                    }}
                    key={`option-${option.value}`}
                    onClick={() => {
                        if (!option.disabled && option.value !== selectedValue && onSelect) {
                            onSelect(option.value);
                        }
                    }}
                >
                    <div style={{ opacity: option.disabled ? 0.2 : 1 }}>
                        <Tooltip label={option.label} position="bottom">
                            <OSKIcon
                                code={option.icon}
                                fill={
                                    selectedValue === option.value
                                        ? theme.colors[variant ?? 'primary'].invertedFg
                                        : theme.colors[variant ?? 'primary'].fg
                                }
                                width={20}
                            />
                        </Tooltip>
                    </div>
                </Box>
            ))}
        </Box>
    );
};

export { IconSwitch };
export type { IconSwitchOption, IconSwitchProps };
