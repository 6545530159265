import { Box, Heading, OSKThemeType } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';

export type CartCustomizationProps = {
    title?: string;
    children?: React.ReactNode;
};

export const CartCustomization = ({ title, children }: CartCustomizationProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box pb={30} col>
            <Box
                col
                style={{
                    color: theme.colors.black900,
                    paddingLeft: '1px',
                    paddingRight: '1px',
                    paddingBottom: '30px',
                    marginBottom: '10px',
                    borderBottom: `1px solid ${theme.colors.gray80}`,
                }}
            >
                <Heading variant="small" style={{ paddingBottom: '10px' }}>
                    {title}
                </Heading>

                {children}
            </Box>
        </Box>
    );
};
