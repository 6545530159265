import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, OSKThemeType, Typography } from 'oskcomponents';
import { Capture } from 'oskcore';
import { GlobalZIndex } from 'oskcomponents/src/constants';
import { useTheme } from 'styled-components';
import { useDisableFeatureOnMouseOver } from '~/hooks';
import { date_format, date_format_short } from '~/utils';
import { TimelineSliderGrabber } from './TimelineSliderGrabber';
import { bucketByDateNoGaps, histogramGetClipPath, histogramGetSelectedClipPath } from './bucket';

type TimelineSliderProps = {
    /** From redux, the list of results from the search. */
    captures?: Capture[];

    /** A 0-1 percantage that caps the histogram to a certain minimum percentage. */
    minChartValue?: number;

    /** A 0-1 percantage that caps the histogram to a certain maximum percentage. */
    maxChartValue?: number;

    /** The minimum number of buckets required to display this component.
     * THIS WILL BE DEPRECATED IN THE FUTURE.
     */
    minBucketsForVisible?: number;

    /** The method that's called when the date range is updated. */
    onRangeUpdated?: (startDate?: Date, endDate?: Date) => void;
};

type TimelineBucket = {
    /** A percentage, showing the relative size of this bucket compared to other buckets. */
    value: number;

    /** The exact date that this bucket represents. */
    date: Date;
};

const TimelineSlider = ({
    captures,
    minChartValue = 0.15,
    maxChartValue = 0.95,
    minBucketsForVisible = 5,
    onRangeUpdated,
    ...props
}: TimelineSliderProps) => {
    const MIN_CHART_VALUE = 100 - minChartValue * 100;
    const MAX_CHART_VALUE = 100 - maxChartValue * 100;
    const CHART_SIDE_PADDING = 40;
    const CHART_TICK_HEIGHT = 10;
    const theme = useTheme() as OSKThemeType;
    const ref = useRef<any>(null);
    const boxRef = useRef<any>(null);
    const [startRange, setStartRange] = useState(0); // bucket index
    const [endRange, setEndRange] = useState(0); // bucket index
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const [, updateState] = React.useState({}); // Used for updating the page once
    const update = React.useCallback(() => updateState({}), []);

    const OnWindowResize = () => {
        update();
    };

    useEffect(() => {
        window.addEventListener('resize', OnWindowResize);

        return () => {
            window.removeEventListener('resize', OnWindowResize);
        };
    }, []);

    const buckets: TimelineBucket[] = useMemo(() => bucketByDateNoGaps(captures ?? []), [captures]);

    const tickSpacing = useMemo(
        () => (boxRef.current?.clientWidth ?? 1) / ((buckets.length ?? 1) - 1),
        [boxRef.current?.clientWidth, buckets],
    );

    useEffect(() => {
        if (buckets.length > 0) {
            setStartRange(0);
            setEndRange(buckets.length - 1);

            let minDate: Date = new Date();
            let maxDate: Date = new Date();

            buckets.forEach((bucket) => {
                if (bucket.date < minDate) minDate = bucket.date;
                if (bucket.date > maxDate) maxDate = bucket.date;
            });

            setStartDate(minDate);
            setEndDate(maxDate);
        }

        update();
    }, [buckets]);

    const isVisible = (captures && captures.length > minBucketsForVisible) ?? false;
    useDisableFeatureOnMouseOver(ref, 'Click', isVisible);
    useDisableFeatureOnMouseOver(ref, 'Drag', isVisible);
    useDisableFeatureOnMouseOver(ref, 'Zoom', isVisible);

    return (
        <Box
            id="timeline-slider"
            ref={ref}
            {...props}
            style={{
                display: isVisible ? 'inherit' : 'none',
                position: 'absolute',
                bottom: '28px',
                left: '350px',
                width: 'calc(100% - 350px - 40px)',
                zIndex: GlobalZIndex.Overlay,
                userSelect: 'none',
                pointerEvents: 'auto',
            }}
            center="all"
        >
            <Box
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    margin: '0px 40px',
                    borderRadius: '5px',
                    width: '100%',
                    height: '130px',
                }}
                draggable={false}
                center="horizontal"
                col
            >
                <Box
                    style={{
                        alignItems: 'flex-start',
                        width: '100%',
                        padding: '6px 20px',
                        borderBottom: `2px solid ${theme.colors.gray25}`,
                    }}
                >
                    <Typography variant="heading5">
                        {date_format(startDate)} - {date_format(endDate)}
                    </Typography>
                </Box>
                <Box
                    id="box-ref"
                    ref={boxRef}
                    style={{ width: `calc(100% - ${CHART_SIDE_PADDING}px)`, position: 'relative' }}
                >
                    <Box
                        style={{
                            backgroundColor: theme.colors.lightGray,
                            width: '100%',
                            height: '70px',
                            clipPath: histogramGetClipPath(buckets, MIN_CHART_VALUE, MAX_CHART_VALUE),
                            zIndex: 0,
                        }}
                    />
                    <Box
                        style={{
                            position: 'absolute',
                            opacity: '.2',
                            backgroundColor: theme.colors.accent,
                            width: '100%',
                            height: '70px',
                            clipPath: histogramGetSelectedClipPath(
                                buckets,
                                startRange,
                                endRange,
                                MIN_CHART_VALUE,
                                MAX_CHART_VALUE,
                            ),
                            zIndex: 0,
                        }}
                    />
                </Box>
                <Box
                    style={{
                        position: 'relative',
                        width: `calc(100% - ${CHART_SIDE_PADDING}px)`,
                        zIndex: 2,
                        justifyContent: 'space-between',
                    }}
                    center="horizontal"
                >
                    <Box
                        style={{
                            position: 'absolute',
                            left: `${startRange * tickSpacing}px`,
                            width: `${(endRange - startRange) * tickSpacing}px`,
                            border: `2px solid ${theme.colors.accent}`,
                            top: '-2px',
                            // @ts-ignore
                            userDrag: 'none',
                        }}
                    />
                    {boxRef.current && (
                        <Box
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: `70px`,
                                top: `-70px`,
                                // @ts-ignore
                                userDrag: 'none',
                            }}
                        >
                            <TimelineSliderGrabber
                                index={startRange}
                                tickSpacing={tickSpacing}
                                onDragChange={(delta: number, finished: boolean) => {
                                    const deltaTicks = Math.trunc(delta / tickSpacing);

                                    let newRange = startRange + deltaTicks;
                                    if (newRange < 0) newRange = 0;
                                    if (newRange > endRange - 1) newRange = endRange - 1;

                                    setStartRange(newRange);

                                    if (finished) {
                                        const newStartDate = buckets[newRange].date;
                                        setStartDate(newStartDate);
                                        onRangeUpdated && onRangeUpdated(newStartDate, endDate);
                                    }
                                }}
                            />
                            <TimelineSliderGrabber
                                index={endRange}
                                tickSpacing={tickSpacing}
                                onDragChange={(delta: number, finished: boolean) => {
                                    const deltaTicks = Math.trunc(delta / tickSpacing);

                                    let newRange = endRange + deltaTicks;
                                    if (newRange >= buckets.length - 1) newRange = buckets.length - 1;
                                    if (newRange < startRange + 1) newRange = startRange + 1;

                                    setEndRange(newRange);

                                    if (finished) {
                                        const newEndDate = buckets[newRange].date;
                                        setEndDate(newEndDate);
                                        onRangeUpdated && onRangeUpdated(startDate, newEndDate);
                                    }
                                }}
                            />
                        </Box>
                    )}
                    {buckets.map((value, idx) => {
                        return (
                            <Box
                                key={`bucket-${idx}`}
                                style={{
                                    borderLeft: `1px solid ${
                                        idx >= startRange - 1 && idx <= endRange - 1
                                            ? theme.colors.black600 // white
                                            : theme.colors.black600
                                    }`,
                                    height: `${CHART_TICK_HEIGHT}px`,
                                    marginTop: `-${CHART_TICK_HEIGHT}px`,
                                }}
                            />
                        );
                    })}
                </Box>
                <Box style={{ height: '1px', width: '100%', backgroundColor: theme.colors.black600 }} />
                <Box
                    style={{
                        width: `calc(100% - ${CHART_SIDE_PADDING}px)`,
                        justifyContent: 'space-between',
                    }}
                    center="vertical"
                    grow
                >
                    {buckets.map((bucket, idx) => {
                        return (
                            <Box key={`bucket-label-${idx}`} style={{ width: '1px', justifyContent: 'center' }}>
                                <Typography variant="caption3" style={{ width: '50px', textAlign: 'center' }}>
                                    {date_format_short(bucket.date)}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export { TimelineSlider };
export type { TimelineBucket, TimelineSliderProps };
