import { Capture, OSKGeoJson, noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { Footprint, MapCentroid } from '~/atoms';
import EditableMap from '~/organisms/map/EditableMap';
import { RootState } from '~/redux/store';

export type MapTabProps = {
    /** The list of captures to render on the map */
    captures?: Capture[];
};
export const MapTab = ({ captures }: MapTabProps) => {
    const captureList = (captures ?? []).filter((capture) => !!capture.footprint);
    const geometries = captureList.map((capture) => OSKGeoJson.fromAPIGeometry(capture.footprint));

    return (
        <EditableMap useOSKLowbar={false}>
            {geometries.map((geojson) => {
                return <Footprint key={`footprint_${geojson.getCentroid()}`} visible={true} shape={geojson} />;
            })}
            <MapCentroid area={geometries}></MapCentroid>
        </EditableMap>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        captures: state.data.orders.activeOrder?.captures,
    };
};
export default connect(mapStateToProps, noop)(MapTab);
