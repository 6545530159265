import React from 'react';
import { PageScaffold } from 'oskcomponents';
import { PortalContentView } from '~/atoms';
import { AssetsTable } from '../../../organisms/tables/AssetsTable';
import { useNavigate } from 'react-router-dom';
import { Program } from 'oskcore';

type AssetPanelProps = {
    data?: Program;
};

const AssetsPanel = ({ data }: AssetPanelProps) => {
    const id = data?.id;
    const navigate = useNavigate();

    const handleNavigation = (row: any) => {
        navigate(`/program/${id}/asset/${row.id}`);
    };

    return (
        <PortalContentView
            crumbs={[{ title: data?.name ?? 'Program', url: `/program/${data?.id}` }, { title: 'Assets' }]}
        >
            <PageScaffold title={<span>Assets</span>}>
                <AssetsTable onRowClick={handleNavigation} />
            </PageScaffold>
        </PortalContentView>
    );
};

export { AssetsPanel };
