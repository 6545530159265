import { Box, OSKIcon, OSKThemeType, Spinner, TabView, Typography } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import ActiveOrderTitle from './ActiveOrderTitle';
import { useTheme } from 'styled-components';
import SummaryTab from './SummaryTab';
import CollectsTab from './CollectsTab';
import MapTab from './MapTab';

export type PanelContentsProps = {
    /** The orderId of the active order */
    orderId?: string;
    /** True if the active order is being fetched */
    loadingSingleOrder?: boolean;
    /** True if archive orders are being fetched */
    loadingArchiveOrders?: boolean;
    /** Whether or not the last order placed was in an error state, which
     * causes this dashboard to become out of date. */
    isError?: boolean;
};
export const PanelContents = ({ orderId, loadingSingleOrder, loadingArchiveOrders, isError }: PanelContentsProps) => {
    const theme = useTheme() as OSKThemeType;

    if (loadingSingleOrder) {
        return (
            <Box center="all" grow>
                <Spinner size="Large" variant="Box" />
            </Box>
        );
    } else if (isError) {
        return (
            <Box center="all" grow col>
                <OSKIcon code="generic-error" width={250} scale={150} />
                <Typography variant="heading2" style={{ margin: '8px' }}>
                    Network Error
                </Typography>
                <Typography variant="body2" style={{ width: '350px', textAlign: 'center' }}>
                    Something went wrong fetching the latest data; please use the Refresh button to reload the order
                    data.
                </Typography>
            </Box>
        );
    } else if (orderId) {
        return (
            <Box style={{ overflowY: 'scroll', width: '100%' }} grow col>
                <Box p={20} grow col>
                    <ActiveOrderTitle />
                    <TabView
                        tabContainerStyle={{
                            backgroundColor: 'transparent',
                            borderBottom: `1px solid ${theme.colors.black300}`,
                        }}
                        tabStyle={{
                            color: theme.colors.black,
                            marginRight: '45px',
                        }}
                        contentStyle={{
                            paddingTop: '12px',
                        }}
                        tabItems={[
                            { label: 'Map', component: <MapTab /> },
                            { label: 'Collects', component: <CollectsTab /> },
                            { label: 'Details', component: <SummaryTab /> },
                        ]}
                    />
                </Box>
            </Box>
        );
    } else {
        return <React.Fragment />;
    }
};

const mapStateToProps = (state: RootState) => {
    const { activeOrderId, loadingSingleOrder, isOrderNetworkError, loadingArchiveOrders } = state.data.orders;

    return {
        orderId: activeOrderId,
        loadingSingleOrder,
        loadingArchiveOrders,
        isError: isOrderNetworkError,
    };
};

export default connect(mapStateToProps, noop)(PanelContents);
