import React from 'react';
import { ActiveApp, useCurrentApp } from '../../hooks/useCurrentApp';
import SigmaDataLogo from '../../assets/images/sigmadata.png';
import SigmaMonitorLogo from '../../assets/images/sigmamonitor.png';
import SigmaToolLogo from '../../assets/images/sigmatool.png';
import { Box, OSKThemeType } from 'oskcomponents';
import { useTheme } from 'styled-components';

type AppDetails = {
    /** The url of the image that represents this app.
     * Used in:
     * - Favicon
     * - Nav bar
     * - App tray
     */
    logo: string;

    /** The user-friendly name of the app.
     * Used in:
     * - Tab bar
     * - Nav bar
     * - Error messages
     */
    name: string;

    /** Which role a user has to have in order to access.
     * (No role defined means anyone can access.)
     */
    requiredRole?: string;

    /** Whether or not this is only accessible by OSK employees. */
    internalOnly?: boolean;

    /** Whether or not the app requires being part of a program to access. */
    programBased?: boolean;
};

export const AppData: Record<ActiveApp, AppDetails> = {
    data: {
        name: 'SIGMA: Data',
        logo: SigmaDataLogo,
        requiredRole: 'Tasking User',
        programBased: true,
    },
    monitor: {
        name: 'SIGMA: Monitor',
        logo: SigmaMonitorLogo,
        requiredRole: 'Monitor User',
        programBased: true,
    },
    sigma: {
        name: 'SIGMA',
        logo: SigmaDataLogo,
    },
    tasking: {
        name: 'SIGMA: Tasking',
        logo: SigmaDataLogo,
        requiredRole: 'Tasking User',
    },
    error: {
        name: 'SIGMA',
        logo: SigmaDataLogo,
    },
    tools: {
        name: 'SIGMA: Toolbox',
        logo: SigmaToolLogo,
        internalOnly: true,
    },
};

export const CurrentApp = () => {
    const app = useCurrentApp();
    const details = AppData[app];
    const theme = useTheme() as OSKThemeType;

    return (
        <Box center="vertical" style={{ fontFamily: theme.monospaceFont, fontSize: '0.75rem' }}>
            <img src={details.logo} width={30} style={{ marginRight: '10px' }} />
            {details.name}
        </Box>
    );
};
