import React from 'react';
import { useTheme } from 'styled-components';
import { Box, OSKThemeType, OneOrMore } from 'oskcomponents';

type SidePanelHeaderProps = {
    /** Child element passthrough */
    children: OneOrMore<React.ReactNode>;
};

const SidePanelHeader = ({ children }: SidePanelHeaderProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            col
            style={{
                backgroundColor: theme.colors.lightGray,
                border: `1px solid ${theme.colors.black600}`,
                borderWidth: '1px 0px 1px 0px',
                borderRadius: '0px',
                height: 'fit-content',
                top: '16px',
                padding: '12px 16px',
                margin: '12px -16px',
            }}
        >
            {children}
        </Box>
    );
};

export { SidePanelHeader };
export type { SidePanelHeaderProps };
