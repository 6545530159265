import React from 'react';
import { OSKError } from 'oskcore/src/templates/OSKError';

const NoProgramView = () => {
    document.title = 'Sigma';

    return (
        <OSKError
            actions={[
                {
                    label: 'Contact Support',
                    action: () => {
                        window.location.assign('mailto:contact@orbitalsidekick.com');
                    },
                },
            ]}
        >
            Your account has not been added to any programs yet. The admin for your program(s) will need to do this
            before you can access Sigma.
        </OSKError>
    );
};

export { NoProgramView };
