import { Box, OSKIcon, OSKThemeType, Typography, useClickAway } from 'oskcomponents';
import { Program, noop } from 'oskcore';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';
import { ProgramRowItem } from './ProgramRowItem';
import { SettingsButton } from './SettingsButton';
import { GlobalZIndex } from '~/constants';

export type ProgramSwitcherProps = {
    /** The currently active program */
    activeProgram?: Program;
    /** The list of programs that the user has access to */
    programs?: Program[];
};

export const ProgramSwitcher = ({ activeProgram, programs }: ProgramSwitcherProps) => {
    const theme = useTheme() as OSKThemeType;
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    useClickAway(
        ref,
        () => {
            setOpen(false);
        },
        open,
    );

    // If they aren't a member of a program then don't render the component
    if (!activeProgram || !programs || programs.length === 0) {
        return <></>;
    }

    const single = programs.length <= 1;

    return (
        <Box center="all" style={{ position: 'relative' }} ref={ref}>
            <Box
                style={{
                    border: `1px solid ${open ? theme.colors.accent : theme.colors.white}`,
                    borderRadius: '7px',
                    marginRight: '20px',
                    padding: '5px',
                    minWidth: '250px',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setOpen(!open);
                }}
                center="all"
            >
                <Typography variant="body4" style={{ cursor: 'pointer', paddingLeft: '0px' }}>
                    {activeProgram.name}
                </Typography>
                <Box grow />
                <OSKIcon code={open ? 'arrow-up' : 'arrow-down'} />
            </Box>

            {open && (
                <Box
                    style={{
                        position: 'absolute',
                        width: '300px',
                        backgroundColor: theme.colors.white,
                        border: `1px solid ${theme.colors.black200}`,
                        top: '60px',
                        borderRadius: '7px',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        zIndex: GlobalZIndex.HeaderPopup,
                    }}
                    col
                >
                    <Box
                        pt={20}
                        pb={single ? 0 : 20}
                        style={{
                            borderBottom: single ? '' : `2px solid ${theme.colors.black200}`,
                            marginBottom: single ? '' : '30px',
                            textAlign: 'center',
                        }}
                        center="all"
                    >
                        <Box col style={{ textAlign: 'center' }} center="all">
                            <Typography variant="body2" color={theme.colors.black} style={{ fontWeight: 'bold' }}>
                                {activeProgram.name}
                            </Typography>
                            <Typography variant="body3" color={theme.colors.black600}>
                                Active Program
                            </Typography>
                        </Box>
                    </Box>

                    {!single && programs.map((program) => <ProgramRowItem key={program.name} program={program} />)}

                    <SettingsButton activeProgram={activeProgram} />
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    const programs: Program[] = state.session.programs ?? [];
    const activeProgram = programs.find((program) => program.id === getProgramId());

    return {
        activeProgram,
        programs,
    };
};

export default connect(mapStateToProps, noop)(ProgramSwitcher);
