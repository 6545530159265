import { noop } from 'oskcore';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import ArchiveOrderList from '~/organisms/tables/ArchiveOrderList';
import { DataResourceProvider } from '~/redux';
import { selectArchiveOrdersByPage, setActiveOrderId, SigmaOrder } from '~/redux/modules/data/orders';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';

export type CatalogOrdersProps = {
    orders: SigmaOrder[];
    selectedIndex?: number;
    orderPage: number;
    loading: boolean;
};

export const CatalogOrders = ({ orders, selectedIndex, orderPage, loading }: CatalogOrdersProps) => {
    const dispatch = useDispatch();

    return (
        <DataResourceProvider programId={getProgramId()} features={['archive_orders']} orderPage={orderPage}>
            <ArchiveOrderList
                selectedIndex={selectedIndex}
                onRowClick={(row) => {
                    const id = row?.id ?? '';
                    dispatch(setActiveOrderId(id));
                }}
                loading={loading}
                data={orders}
            />
        </DataResourceProvider>
    );
};

const mapStateToProps = (state: RootState) => {
    const orders = selectArchiveOrdersByPage(state, state.data.orders.archiveOrderPage);
    const selectedIndex = (orders ?? []).findIndex((order) => order.id === state.data.orders.activeOrderId);

    return {
        orders,
        selectedIndex,
        loading: state.data.orders.loadingArchiveOrders,
        orderPage: state.data.orders.archiveOrderPage,
    };
};

export default connect(mapStateToProps, noop)(CatalogOrders);
