import React, { useEffect, useState } from 'react';
import { NavComponent, OSKGeoJson, OSKView, Sensor, noop } from 'oskcore';
import { Box, Button, Divider, OSKIcon, OSKIconType, OSKThemeType, Typography } from 'oskcomponents';
import AppShimmerBar from '~/organisms/AppShimmerBar';
import ActiveModeSwitcher from '~/organisms/ActiveModeSwitcher';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import EditableMap from '~/organisms/map/EditableMap';
import { ColorIndicator, MapSatellitePositionRealtime, MapSatelliteTrack } from '~/atoms';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { getLastAntemeridianCrossingTimeMS, getLatLngObj } from 'tle.js';
import { LatLng } from 'leaflet';
import { GlobalZIndex } from '~/constants';
import { useTheme } from 'styled-components';
import GhostSat from '../../../public/images/ghost.png';
import { useMap } from '~/hooks';
import { date_format_long } from '~/utils';

type SatelliteSpecs = {
    launchDate: Date;
};

/** A map for satellite specs that are either not present in the Sensor object
 * and aren't consistent across all satellites.
 */
const SatelliteSpecMap: Record<number, SatelliteSpecs> = {
    7: {
        launchDate: new Date('4/15/2023'),
    },
    8: {
        launchDate: new Date('4/15/2023'),
    },
    9: {
        launchDate: new Date('6/12/2023'),
    },
    10: {
        launchDate: new Date('3/4/2024'),
    },
    11: {
        launchDate: new Date('3/4/2024'),
    },
};

type SatelliteTrackerViewProps = {
    /** From redux, a map of the current TLEs for each sensor. */
    sensorTLEs?: Record<number, string>;
    /** From redux, a list of all available sensors. */
    sensors?: Array<Sensor>;
};

/** A MapCentroid variant that centers the map at our desired zoom level exactly once. */
const SingleUseCentroid = () => {
    const map = useMap();
    const c = { lat: 0, lng: 0 } as LatLng;
    const defaultCenter = OSKGeoJson.fromCoordinate(c);

    const [useOnce, setUseOnce] = useState(true);

    useEffect(() => {
        if (map.editableMap.map && useOnce) {
            map.fitCoordinates([defaultCenter], 27, false);
            setUseOnce(false);
        }
    }, []);

    return <React.Fragment />;
};

const SatelliteTrackerView = ({ sensorTLEs, sensors }: SatelliteTrackerViewProps) => {
    const [update, setUpdate] = useState(0);
    const [selectedSensor, setSelectedSensor] = useState<number | undefined>(undefined);
    const [showSensorPanel, setShowSensorPanel] = useState<boolean>(true);

    useEffect(() => {
        const timerId = setInterval(() => {
            if (sensorTLEs) {
                Object.values(sensorTLEs).forEach((tle) => {
                    const lastCrossing = getLastAntemeridianCrossingTimeMS(tle, new Date().getTime());
                    const delta = new Date().getTime() - lastCrossing;
                    if (delta < 1000) {
                        setUpdate(update + 1);
                    }
                });
            }
        }, 1000);

        if (!sensorTLEs) {
            clearInterval(timerId);
        }

        return () => {
            clearInterval(timerId);
        };
    }, [update, setUpdate]);

    return (
        <OSKView constrained nopadding nogradient>
            <AppShimmerBar />
            <Box style={{ flexGrow: 1 }}>
                <ActiveModeSwitcher />
                <NavComponent>
                    <SigmaNavItems />
                </NavComponent>
                <EditableMap scrollWheelZoom={false} zoomControl={false}>
                    {showSensorPanel && selectedSensor && (
                        <SatelliteDetailsPanel
                            sensor={sensors?.find((sensor) => sensor.osk_id === selectedSensor)}
                            tle={sensorTLEs ? sensorTLEs[selectedSensor] : undefined}
                            onClose={() => setShowSensorPanel(false)}
                        />
                    )}
                    <SingleUseCentroid />
                    {sensorTLEs &&
                        Object.entries(sensorTLEs).map((e) => {
                            const id = parseInt(e[0]),
                                tle = e[1],
                                sensor = sensors?.find((sensor) => sensor.osk_id === id);
                            return (
                                <React.Fragment key={`track-${id}-${update}`}>
                                    <MapSatelliteTrack
                                        key={`track-${id}-${update}`}
                                        startTime={new Date().getTime()}
                                        orbitalPeriods={1}
                                        tle={tle}
                                        activeOrbitColor={selectedSensor === id ? '#D8FF3B' : 'white'}
                                        dashPattern={selectedSensor === id ? '1' : '5 8'}
                                    />
                                    <MapSatellitePositionRealtime
                                        key={`sat-${id}`}
                                        tle={tle}
                                        selected={id === selectedSensor}
                                        label={sensor?.osk_sensor_name}
                                        onClick={() => {
                                            if (id === selectedSensor) {
                                                if (showSensorPanel) {
                                                    setSelectedSensor(undefined);
                                                    setShowSensorPanel(false);
                                                } else {
                                                    setShowSensorPanel(true);
                                                }
                                            } else {
                                                setSelectedSensor(id);
                                                setShowSensorPanel(true);
                                            }
                                            setUpdate(update + 1);
                                        }}
                                    />
                                </React.Fragment>
                            );
                        })}
                </EditableMap>
            </Box>
        </OSKView>
    );
};

const SatelliteDetailsPanel = ({ sensor, tle, onClose }: { sensor?: Sensor; tle?: string; onClose?: () => void }) => {
    const theme = useTheme() as OSKThemeType;
    const [update, setUpdate] = useState(0);
    const position = getLatLngObj(tle, new Date());
    const sensorSpecs = sensor ? SatelliteSpecMap[sensor?.osk_id] : undefined;

    useEffect(() => {
        const timerId = setInterval(() => setUpdate(update + 1), 1000);

        return () => {
            clearInterval(timerId);
        };
    }, [update, setUpdate]);

    if (!sensor) {
        return <React.Fragment />;
    }

    return (
        <Box style={{ position: 'absolute', top: '25px', right: '25px' }} col>
            <Box
                style={{
                    zIndex: GlobalZIndex.Timeline,
                    padding: '18px',
                    backgroundColor: theme.colors.gray1ab,
                    border: `1px solid ${theme.colors.gray80}`,
                    borderRadius: '7px',
                    boxShadow: '0px 4px 7px 3px rgba(0, 0, 0, 0.25)',
                    width: '290px',
                    overflow: 'hidden',
                    backdropFilter: 'blur(4px)',
                }}
                col
            >
                <Box style={{ position: 'absolute', right: '0px' }}>
                    <Button
                        style={{ width: '40px', padding: '8px' }}
                        variant="clear"
                        onClick={() => onClose && onClose()}
                    >
                        <OSKIcon code="times" style={{ justifyContent: 'center' }} />
                    </Button>
                </Box>
                <Typography variant="heading2">{sensor.osk_sensor_name}</Typography>
                <Typography variant="caption1" vm={4}>
                    Location:
                    <br />
                    {position.lat}, {position.lng}
                </Typography>
                <Divider bg={theme.colors.white} vm={12} />
                <Box center="horizontal">
                    <img src={GhostSat} width={133} />
                </Box>
                <Divider bg={theme.colors.white} vm={12} />
                <Box style={{ justifyContent: 'space-between' }}>
                    <PanelStatPair
                        top={<Typography variant="body4">{date_format_long(sensorSpecs?.launchDate)}</Typography>}
                        bottom={<Typography variant="caption3">Launch Date</Typography>}
                    />
                    <PanelStatPair
                        width={80}
                        top={<Typography variant="body4">Vandenberg</Typography>}
                        bottom={<Typography variant="caption3">Launch Site</Typography>}
                    />
                </Box>
                <Box style={{ justifyContent: 'space-between' }}>
                    <PanelStatPair
                        top={<Typography variant="body4">Earth Observation</Typography>}
                        bottom={<Typography variant="caption3">Spacecraft Type</Typography>}
                    />
                    <PanelStatPair
                        width={80}
                        top={
                            <Box>
                                <Typography variant="body4">Active</Typography>
                                <ColorIndicator color={theme.colors.green500} style={{ marginLeft: '12px' }} />
                            </Box>
                        }
                        bottom={<Typography variant="caption3">Status</Typography>}
                    />
                </Box>
                <Divider bg={theme.colors.white} vm={14} />
                <Box
                    style={{
                        border: `1px solid ${theme.colors.white}`,
                        borderRadius: '5px',
                        backgroundColor: theme.colors.gray1ab,
                    }}
                    col
                >
                    <PanelStatIcon icon="bands" stat="472 Bands" />
                    <PanelStatIcon icon="prism" stat="400-2500 NM" />
                    <PanelStatIcon icon="hsi" stat="8M HSI" />
                    <PanelStatIcon icon="grayscale" stat="3 Meter Grayscale" />
                </Box>
            </Box>
        </Box>
    );
};

const PanelStatPair = ({ top, bottom, width, ...props }: any) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box col style={{ margin: '8px 0px', width: width ? `${width}px` : 'auto' }} {...props}>
            {top}
            <Divider bg={theme.colors.white} vm={4} />
            {bottom}
        </Box>
    );
};

const PanelStatIcon = ({ icon, stat }: { icon: OSKIconType; stat: string }) => {
    return (
        <Box m={2} center="vertical">
            <OSKIcon code={icon} width={50} style={{ paddingRight: '12px' }} />
            <Typography variant="heading5">{stat}</Typography>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        sensorTLEs: state.osk.sensorTLEs,
        sensors: state.osk.sensors,
    };
};

export default connect(mapStateToProps, noop)(SatelliteTrackerView);
