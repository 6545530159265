import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { noop, Program, ProgramProductLevelAccessEnum, Sensor } from 'oskcore';
import { Box } from 'oskcomponents';
import { FormElement } from '~/atoms';
import * as yup from 'yup';
import { RootState } from '~/redux/store';

export type ProgramFormProps = {
    data?: Program;
    className?: string;
    sensors: Array<Sensor>;
    /** Readonly override */
    readonly?: boolean;
};

export const ProgramSchema: yup.SchemaOf<any> = yup.object().shape({
    name: yup.string().required('Program Title is a required field'),
    company_name: yup.string().required(),
    start_date: yup.date().required(),
    sensor_data_access: yup.string().required(),
    end_date: yup.date().min(yup.ref('start_date'), "Contract End Date can't be before start date").required(),
    max_users: yup.number().min(0).notRequired(),
    max_taskings: yup.number().min(0).notRequired(),
});

const Form = styled(({ className, data, sensors, readonly }: ProgramFormProps) => {
    return (
        <Box style={{ justifyContent: 'center', width: '100%' }} grow>
            <Box className={className} col>
                <FormElement
                    readonly={readonly}
                    type="input"
                    name="name"
                    label="Program Title"
                    defaultValue={data?.name}
                />
                <FormElement
                    readonly={readonly}
                    type="input"
                    name="company_name"
                    label="Company Name"
                    defaultValue={data?.company_name}
                />
                <FormElement
                    readonly={readonly}
                    type="date"
                    name="start_date"
                    label="Contract Start Date"
                    defaultValue={data?.start_date && new Date(data?.start_date)}
                />
                <FormElement
                    readonly={readonly}
                    type="date"
                    name="end_date"
                    label="Contract End Date"
                    defaultValue={data?.end_date && new Date(data?.end_date)}
                />
                <FormElement
                    readonly={readonly}
                    type="dropdown"
                    name="active"
                    label="Active"
                    defaultValue={data?.active ?? false}
                    options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                    ]}
                />
                <FormElement
                    readonly={readonly}
                    defaultValue={data?.max_users}
                    type="number"
                    name="max_users"
                    label="Max Number of Users"
                />
                <FormElement
                    readonly={readonly}
                    defaultValue={data?.max_taskings}
                    type="number"
                    name="max_taskings"
                    label="Max Number of Taskings"
                />
                <FormElement
                    readonly={readonly}
                    type="multiselect"
                    name="product_level_access"
                    label="Product Level Access"
                    defaultValues={data?.product_level_access ?? []}
                    options={Object.values(ProgramProductLevelAccessEnum).map((productLevel) => ({
                        label: productLevel.toString(),
                        value: productLevel.toString(),
                    }))}
                />
                <FormElement
                    readonly={readonly}
                    type="multiselect"
                    name="sensor_data_access"
                    label="Platforms"
                    defaultValues={data?.sensor_data_access ?? []}
                    options={sensors.map((sensor) => ({
                        label: sensor.osk_sensor_name,
                        value: sensor.osk_id,
                    }))}
                />
                <input
                    type="hidden"
                    value={(data?.subscribed_products ?? []).join(',')}
                    name="subscribed_products"
                ></input>
            </Box>
        </Box>
    );
})`
    & {
        width: 100%;
    }
`;

const mapStateToProps = (state: RootState) => {
    return {
        sensors: state.osk.sensors,
    };
};

export const ProgramForm = connect(mapStateToProps, noop)(Form);
