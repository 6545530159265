import { Box, Heading, OSKThemeType, Text } from 'oskcomponents';
import { OSKGeoJson, Opportunity, noop } from 'oskcore';
import React, { useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '~/redux/store';
import { CartWizardStep, CartWizardTemplate } from '~/templates';

import EditableMap from '~/organisms/map/EditableMap';
import OpportunityWindowTable from '~/organisms/tables/OpportunityWindowTable';
import { Footprint, MapCentroid } from '~/atoms';
import { useTheme } from 'styled-components';
import { useDisableFeatureOnMouseOver } from '~/hooks';
import { Marker } from 'react-leaflet';

export type CoveragePreviewProps = {
    /** From redux, the currently selected aoi on the map */
    selectedAoi?: OSKGeoJson;
    /** From redux, the currently selected Opportunity */
    selectedOpportunity?: Opportunity;
    /** From redux, a map of TLEs for the current sensors */
    sensorTLEs?: Record<number, string>;
} & CartWizardStep;

export const CoveragePreview = ({
    onWizardNext,
    onWizardClose,
    selectedAoi,
    selectedOpportunity,
    sensorTLEs,
}: CoveragePreviewProps) => {
    const theme = useTheme() as OSKThemeType;
    const centroid = selectedAoi?.getCentroid();
    const aoiPos = `@${centroid?.lat},${centroid?.lng}`;
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    useDisableFeatureOnMouseOver(ref, 'Zoom', true);

    return (
        <CartWizardTemplate
            buttons={
                [
                    // {
                    //     variant: 'primary',
                    //     inverted: true,
                    //     label: 'Close',
                    //     onClick: () => {
                    //         if (confirm('Are you sure you wish to cancel your order?')) {
                    //             onWizardClose && onWizardClose();
                    //         }
                    //     },
                    // },
                    // {
                    //     variant: 'action',
                    //     noborder: true,
                    //     label: 'Next',
                    //     onClick: onWizardNext ?? noop,
                    // },
                ]
            }
            style={{
                overflowY: 'hidden',
            }}
        >
            <Box style={{ width: '100%', height: '100%' }}>
                <Box grow p={8} style={{ height: '100%' }}>
                    <Box col grow style={{ height: '100%' }}>
                        <Heading variant="small">Coverage Summary</Heading>
                        <Text variant="small" style={{ padding: '12px 0px' }}>
                            Review the potential overpass opportunities for your selected AOI.
                        </Text>
                        <Box
                            bg="white"
                            style={{ borderRadius: '5px', padding: '16px 8px', height: '100%', overflowY: 'scroll' }}
                            id="opportunity-viewer-table"
                            ref={ref}
                        >
                            <OpportunityWindowTable />
                        </Box>
                    </Box>
                </Box>
                <Box style={{ width: '50%', height: '100%', borderRadius: '9px', overflow: 'hidden' }}>
                    <EditableMap position={aoiPos} useOSKLowbar={false}>
                        {selectedOpportunity && selectedAoi && (
                            <>
                                <MapCentroid area={OSKGeoJson.fromAPIGeometry(selectedOpportunity.max_fov)} />
                                <Footprint
                                    shape={OSKGeoJson.fromAPIGeometry(selectedOpportunity.max_fov)}
                                    color="#00d1ff80"
                                />
                                {selectedAoi.toLeafletCoordinates().length === 1 ? (
                                    <Marker position={selectedAoi.toLeafletCoordinates()[0]} />
                                ) : (
                                    <Footprint shape={selectedAoi} color={theme.colors.accent} />
                                )}
                            </>
                        )}
                    </EditableMap>
                </Box>
            </Box>
        </CartWizardTemplate>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        footprints: state.data.map.footprints,
        selectedAoi: state.data.search.roi,
        selectedOpportunity: state.tasking.app.selectedOpportunity,
        sensorTLEs: state.osk.sensorTLEs,
    };
};

export default connect(mapStateToProps, noop)(CoveragePreview);
