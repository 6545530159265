import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Button, Form, PageScaffold } from 'oskcomponents';
import { ProgramForm, ProgramSchema } from '~/organisms/forms/ProgramForm';
import { AdminAPI, mapToInt, Program, ProgramRequest } from 'oskcore';
import { decrementBusyCount, incrementBusyCount } from '~/redux/modules/data/app';
import { PortalContentView } from '~/atoms';

export type DetailsPanelProps = {
    /** The program to render. */
    data?: Program;
    /** The className to apply for styling. */
    className?: string;
    /** If true, you can't submit the form. */
    readOnly: boolean;
};
export type DetailsStatus = 'loading' | 'none' | 'success' | 'error';

export const DetailsPanel = styled(({ data, readOnly, className }: DetailsPanelProps) => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState<DetailsStatus>('none');

    const handleSubmit = (props: any) => {
        const programId: number = data?.id as number;
        setStatus('loading');
        dispatch(incrementBusyCount());
        const request: ProgramRequest = {
            ...(props as ProgramRequest),
        };

        // Convert multi-select fields into actual arrays.
        if (props.sensor_data_access) {
            request.sensor_data_access = mapToInt((props.sensor_data_access ?? '').split(','));
        }

        if (props.product_level_access) {
            request.product_level_access = (props.product_level_access ?? '').split(',');
        }

        if (props.subscribed_products) {
            request.subscribed_products = (props.subscribed_products ?? '').split(',');
        }

        AdminAPI.internalUpdateProgram({ id: programId ?? -1, programRequest: { ...request } }).then(() => {
            setStatus('none');
            dispatch(decrementBusyCount());
        });
    };

    return (
        <PortalContentView
            crumbs={[{ title: data?.name ?? 'Program', url: `/program/${data?.id}` }, { title: 'Details' }]}
        >
            <PageScaffold title={<span>Contract Details</span>}>
                <Form
                    className={className}
                    schema={ProgramSchema}
                    onSubmit={(formData) => {
                        handleSubmit(formData);
                    }}
                >
                    <Box grow col pb={25}>
                        <ProgramForm data={data} readonly={readOnly} />
                        {!readOnly ? (
                            <Box className="submit-container">
                                <Button
                                    disabled={status === 'loading'}
                                    className="button"
                                    variant="neutral"
                                    label="Update"
                                    style={{
                                        alignSelf: 'flex-end',
                                    }}
                                    inverted
                                    submit
                                />
                            </Box>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Form>
            </PageScaffold>
        </PortalContentView>
    );
})`
    & .submit-container {
        justify-content: flex-end;
    }

    & .form-container {
        width: 500px;
        padding-bottom: 150px;
    }
`;
