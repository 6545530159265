import { Box } from 'oskcomponents';
import { NavComponent, OSKView } from 'oskcore';
import React from 'react';
import ActiveModeSwitcher from '~/organisms/ActiveModeSwitcher';
import AppShimmerBar from '~/organisms/AppShimmerBar';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';

type OSKContentViewProps = {
    nogradient?: boolean;
    children?: Array<React.ReactNode> | React.ReactNode;
};

const OSKContentView = ({ children, nogradient = false, ...props }: OSKContentViewProps) => {
    return (
        <OSKView constrained nogradient={nogradient}>
            <AppShimmerBar />
            <NavComponent>
                <SigmaNavItems />
            </NavComponent>
            <Box style={{ flexGrow: 1, height: '100%' }}>
                <ActiveModeSwitcher />
                {children}
            </Box>
        </OSKView>
    );
};

export { OSKContentView };
export type { OSKContentViewProps };
