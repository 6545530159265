import React, { useRef } from 'react';
import L from 'leaflet';
import { useMap, useWaitForMap } from '~/hooks';
import { Box } from 'oskcomponents';

/**
 * A component to render the lat/lng of the mouse
 */
export const MapMouseCoords = () => {
    const map = useMap();
    const ref = useRef<HTMLDivElement>(null);

    useWaitForMap(() => {
        const center = map.getCenter();
        function handleMouseMove(evt: L.LeafletMouseEvent) {
            if (ref && ref.current) {
                ref.current.innerText = `${evt.latlng.lat.toFixed(3)}, ${evt.latlng.lng.toFixed(3)}`;
            }
        }

        // Initial gps value
        if (ref && ref.current && center && center.lat && center.lng) {
            ref.current.innerText = `${center.lat.toFixed(3)}, ${center.lng.toFixed(3)}`;
        }

        map.editableMap.map.on('mousemove', handleMouseMove);
        return () => {
            map.editableMap.map.off('mousemove', handleMouseMove);
        };
    });

    return (
        <Box
            style={{
                fontSize: '0.75rem',
                width: '100px',
                overflow: 'hidden',
                textAlign: 'end',
                marginLeft: '10px',
            }}
            ref={ref}
        ></Box>
    );
};
