import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { fetchOrderAsync, fetchOrdersAsync } from '../modules/data/orders';
import { AppDispatch } from '../store';
import { debounce } from 'lodash';

// Debounce this request. Since we don't cache it, this method
// could be invoked many times a second. We'd rather not do that.
const fetchOrderAsyncDebounced = debounce(
    (dispatch, programId, orderId) => {
        dispatch(fetchOrderAsync(programId, orderId));
    },
    50,
    // Leading doesn't work??
    { trailing: true },
);

const latch: Record<string, boolean | undefined> = {};
/**
 * This method will take a cache key. If the key has never been used before,
 * it will populate the cache and then fetch the data
 * by invoking the second parameter.
 */
function fetchDataOnce(key: string, doFetch: () => void) {
    if (latch[key] === undefined) {
        latch[key] = true;
        doFetch();
    }
}

export function clearDataResourceProvider() {
    for (const key in latch) {
        delete latch[key];
    }
}

/** The feature you would like to request from the backend */
export type DataResourceProviderFeatures = 'tasking_orders' | 'archive_orders' | 'order';
export type DataResourceProviderProps = {
    /** The children component to render. This should be your main content. */
    children: React.ReactNode;
    /** Optional programId, necessary for some features */
    programId?: number;
    /** The page of orders to fetch */
    orderPage?: number;
    /** The id of the order to fetch */
    orderId?: string;
    /** The list of features you are requested from the backend */
    features: DataResourceProviderFeatures[];
    /** Dispatch method */
    dispatch: AppDispatch;
};

/**
 * DataResourceProvider is a component which you can wrap any view or content with, specify some
 * optional parameters, and a list of features. It will take care of efficiently fetching
 * the necessary data (if it hasn't already been fetched). This is useful in case you want
 * access to information. It uses a layer of caching, so the data will only be fetched once.
 *
 * Example usage:
 * ```
 * <DataResourceProvider programId={6} features={['orders']}>
 *  <OrderView />
 * </DataResourceProvider>
 * ```
 */
class DataResourceProvider extends React.PureComponent<DataResourceProviderProps> {
    render() {
        const { children, features, dispatch, programId, orderId, orderPage } = this.props as DataResourceProviderProps;

        if (features.includes('tasking_orders')) {
            if (programId !== undefined && orderPage !== undefined) {
                fetchDataOnce(`fetch_tasking_orders_${programId}_page_${orderPage}`, () =>
                    dispatch<any>(fetchOrdersAsync('tasking', programId, orderPage)),
                );
            } else {
                !orderPage && console.warn('Tried to fetch tasking orders without supplying orderPage');
                !programId && console.warn('Tried to fetch tasking orders without supplying programId');
            }
        }

        if (features.includes('archive_orders')) {
            if (programId !== undefined && orderPage !== undefined) {
                fetchDataOnce(`fetch_archive_orders_${programId}_page_${orderPage}`, () =>
                    dispatch<any>(fetchOrdersAsync('archive', programId, orderPage)),
                );
            } else {
                !orderPage && console.warn('Tried to fetch archive orders without supplying orderPage');
                !programId && console.warn('Tried to fetch archive orders without supplying programId');
            }
        }

        if (features.includes('order')) {
            if (programId !== undefined && orderId !== undefined) {
                // NOTE: this is explicitly not cached because the reducer does not
                // accumulate the records. Instead, we should re-fetch each time
                // and it's better that way for performance and to catch changes to
                // the order.
                fetchOrderAsyncDebounced(dispatch, programId, orderId);
            } else {
                !programId && console.warn('Tried to fetch order without supplying programId');
                !orderId && console.warn('Tried to fetch order without supplying orderId');
            }
        }

        return children;
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        dispatch,
    };
};

const ConnectedProvider = connect(noop, mapDispatchToProps)(DataResourceProvider);
export { ConnectedProvider as DataResourceProvider };
