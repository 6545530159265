import React from 'react';
import { Box, BoxProps, Heading, OSKThemeType } from 'oskcomponents';
import { OSKIconType, OSKIcon } from 'oskcomponents/src/OSKIcon';
import { useTheme } from 'styled-components';

type IconHeaderProps = { iconCode?: OSKIconType; title: string } & Omit<BoxProps, 'ref'>;

const IconHeader = React.forwardRef<HTMLDivElement, IconHeaderProps>(
    ({ iconCode, title, ...props }: IconHeaderProps, ref) => {
        const theme = useTheme() as OSKThemeType;
        return (
            <Box style={{ alignItems: 'center' }} {...props} ref={ref}>
                {iconCode && (
                    <Box
                        style={{
                            borderRadius: '5px',
                            backgroundColor: `${theme.colors.primary.bg}`,
                            border: `2px solid ${theme.colors.primary.border}`,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '12px',
                        }}
                        w={36}
                        h={36}
                    >
                        <OSKIcon code={iconCode} fill={theme.colors.primary.fg} grow center="all" />
                    </Box>
                )}
                <Heading>{title}</Heading>
            </Box>
        );
    },
);

export { IconHeader };
export type { IconHeaderProps };
