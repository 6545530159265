import React, { useRef } from 'react';
import { Box, OSKThemeType } from 'oskcomponents';
import { useTheme } from 'styled-components';
import CSS from 'csstype';

type TimelineSliderGrabberProps = {
    /** An event that's called when the value has changed.
     * `delta` is the total amount that the slider was dragged during this
     * drag session.
     * `finished` is a flag indicating whether the value is done changing.
     */
    onDragChange?: (delta: number, finished: boolean) => void;

    /** Style passthrough */
    style?: CSS.Properties;

    /** The index of the bucket to display the slider at. */
    index: number;

    /** How far apart the ticks are, used for position calculation. */
    tickSpacing: number;
};

const TimelineSliderGrabber = ({ onDragChange, style, index, tickSpacing, ...props }: TimelineSliderGrabberProps) => {
    const theme = useTheme() as OSKThemeType;

    const mouseX = useRef<number>(0);

    const OnMouseDown = (e: any) => {
        mouseX.current = e.clientX;

        window.addEventListener('mouseup', OnMouseUp);
        window.addEventListener('mousemove', OnMouseMove);
    };

    const OnMouseUp = (e: any) => {
        const delta = e.clientX - mouseX.current;
        onDragChange && onDragChange(delta, true);

        window.removeEventListener('mouseup', OnMouseUp);
        window.removeEventListener('mousemove', OnMouseMove);
    };

    const OnMouseMove = (e: any) => {
        const delta = e.clientX - mouseX.current;
        onDragChange && onDragChange(delta, false);
    };

    return (
        <Box
            {...props}
            style={{
                ...style,
                position: 'absolute',
                // @ts-ignore
                userDrag: 'none',
                left: `${tickSpacing * index - 10}px`,
                width: '20px',
                height: '70px',
                cursor: 'col-resize',
            }}
            draggable={false}
            onMouseDown={(e) => {
                OnMouseDown(e);
            }}
        >
            <Box
                style={{
                    position: 'absolute',
                    width: '2px',
                    left: 'calc(10px - 1px)',
                    height: '70px',
                    backgroundColor: theme.colors.orange500,
                }}
            />
            <Box
                style={{
                    position: 'absolute',
                    bottom: '-10px',
                    height: '18px',
                    width: '18px',
                    backgroundColor: theme.colors.accent,
                    borderRadius: '20px',
                }}
            />
        </Box>
    );
};

export { TimelineSliderGrabber };
export type { TimelineSliderGrabberProps };
