import { theme, Box, Spinner } from 'oskcomponents';
import { OSKView, NavComponent, noop, MapOf } from 'oskcore';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppShimmerBar from '~/organisms/AppShimmerBar';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import { MonitorResourceProvider } from '~/redux';
import { RootState } from '~/redux/store';
import { ViewItem, PortalTemplate } from '~/templates';
import { getProgramId } from '~/utils';
import { AssetPanel, AssetDetailWithEmails } from '../AssetPanel';

type AssetsViewProps = {
    assets?: MapOf<AssetDetailWithEmails>;
};

const AssetsView = ({ assets, ...props }: AssetsViewProps) => {
    const { assetId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);

    const selectedAsset = assets && assets[assetId ?? -1];

    const programOptions: Array<ViewItem> = [
        {
            title: `${selectedAsset ? selectedAsset.name : 'undefined'}`,
            subItems: [{ title: 'Details', iconCode: 'list', component: <AssetPanel data={selectedAsset} /> }],
        },
    ];

    return (
        <MonitorResourceProvider programId={getProgramId()} features={['assets']}>
            <OSKView
                headerStyle={{
                    boxShadow: theme.boxShadow,
                }}
                nogradient
            >
                <NavComponent>
                    <SigmaNavItems />
                </NavComponent>
                <AppShimmerBar />
                {loading ? (
                    <Box style={{ justifyContent: 'center', paddingTop: '50px' }}>
                        <Spinner variant="Box" size="Large" />
                    </Box>
                ) : (
                    <PortalTemplate defaultViewItem="details" viewItems={programOptions} />
                )}
            </OSKView>
        </MonitorResourceProvider>
    );
};

const mapStateToProps = (state: RootState) => {
    const { assets } = state.monitor.app;

    return {
        assets: assets as MapOf<AssetDetailWithEmails>,
    };
};

export default connect(mapStateToProps, noop)(AssetsView);
