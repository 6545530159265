import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    FileUpload,
    IconProps,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    OSKIcon,
    OSKIconType,
    OSKThemeType,
    Spinner,
    Text,
    Typography,
    useClickAway,
} from 'oskcomponents';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { GlobalZIndex } from '~/constants';
import styled from 'styled-components';
import { OSKGeoJson, SearchArea, SigmaAPI } from 'oskcore';
import { MapModes, MapMode } from '~/atoms';
import { AOIFormAttributes, AOISaveDialog } from '~/molecules/AOISaveDialog';
import {
    getLibraryLoading,
    getCustomAreas,
    doFetchCustomAreasAsync,
    deleteCustomAreaAsync,
} from '~/redux/modules/data/library';
import { useTheme } from 'styled-components';
import { setIsSearchDirty, setRoi } from '~/redux/modules/data/search';
import { useMap } from '~/hooks';
import { format } from 'date-fns';

const MAP_FEATURE_MODE_HASH_KEY = 'drawing_feature';

/**
 * Given a file name candidate and a list of current file names,
 * create a distinct name that isn't in the list.
 */
function ensureDistinctName(fileName: string | undefined, currentNames: string[]) {
    const base = fileName ?? format(new Date(), `yyyy-MM-dd`);
    let name = base;
    let matches = 1;

    for (let i = 0; i < currentNames.length + 1; i++) {
        if (currentNames.includes(name)) {
            name = format(new Date(), `${base} (${matches++})`);
        }
    }

    return name;
}

type ToolIconProps = {
    /** The icon to display for this tool option */
    code: OSKIconType;
    /** The label to display for this tool option */
    label: string;
    /** A method to call whent this tool option is clicked */
    onClick?: () => void;
    /** A flag that disables the tool icon */
    disabled?: boolean;
    /** Styled-component class name pass-through */
    className?: string;
    /** Whether or not the button text / icon should be highlighted */
    highlighted?: boolean;
} & IconProps;

const ToolIconBase = ({ className, code, label, onClick, disabled, highlighted, ...props }: ToolIconProps) => {
    return (
        <Box
            className={className}
            center="all"
            col
            onClick={() => {
                if (!disabled && onClick) {
                    onClick();
                }
            }}
        >
            <OSKIcon
                code={code}
                className={` ${disabled ? 'tool-icon-disabled' : 'tool-icon-icon'}`}
                style={{ alignItems: 'center', justifyContent: 'center' }}
                {...props}
                w={14}
                h={20}
            />
            <Text variant="tiny" className={`tool-icon-label ${disabled ? 'tool-icon-disabled' : ''}`}>
                {label}
            </Text>
        </Box>
    );
};

const ToolIcon = styled(ToolIconBase)`
    & {
        width: 100%;
        aspect-ratio: 1;
        color: ${(props: any) => (props.highlighted ? props.theme.colors.accent : props.theme.colors.black900)};
        svg {
            rect,
            circle {
                stroke: ${(props: any) =>
                    props.highlighted ? props.theme.colors.accent : props.theme.colors.black900};
            }
            fill: ${(props: any) => (props.highlighted ? props.theme.colors.accent : props.theme.colors.black900)};
        }
    }

    &:hover {
        cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
        background-color: ${(props: any) => (props.disabled ? '' : props.theme.colors.black900)};
        color: white;

        .tool-icon-icon {
            svg {
                fill: white;
            }
            rect,
            circle {
                stroke: white;
            }
        }
    }

    .tool-icon-icon:hover {
        cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
        svg:hover {
            cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
        }
    }

    .tool-icon-label {
        text-align: center;
        margin-top: 4px;
        line-height: 1;
    }

    .tool-icon-disabled {
        color: ${(props: any) => props.theme.colors.black300} !important;
        svg {
            fill: ${(props: any) => props.theme.colors.black300} !important;
        }
    }
`;

type LibraryRowItemProps = {
    /** Icon to display for the library item */
    iconCode: OSKIconType;
    /** Text to display for the library item */
    label: string;
    /** Method to fire when the library item is clicked */
    onClick?: () => void;
    /** Method to fire when the library item's delete icon is clicked */
    onDelete?: () => void;
    /** Passthrough for styled components */
    className?: string;
};
const LibraryRowItemBase = ({ className, iconCode, label, onClick, onDelete, ...props }: LibraryRowItemProps) => {
    const TRUNC_LENGTH = 33;
    const truncLabel = label.length > TRUNC_LENGTH ? `${label.substring(0, TRUNC_LENGTH - 3)}...` : label;
    return (
        <Box center="vertical" p={8} row onClick={onClick} className={className} {...props}>
            <OSKIcon code={iconCode} style={{ marginRight: '8px' }} />
            <Text variant="small">{truncLabel}</Text>
            <OSKIcon
                className="tool-icon-delete"
                code="trash"
                onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();

                    onDelete && onDelete();
                }}
            />
        </Box>
    );
};

const LibraryRowItem = styled(LibraryRowItemBase)`
    .tool-icon-delete {
        display: none;
        position: absolute;
        right: 8px;
    }
    .tool-icon-delete:hover {
        svg {
            fill: ${(props: any) => props.theme.colors.accent};
        }
    }
    & {
        width: 100%;
    }

    &:hover {
        background-color: ${(props: any) => props.theme.colors.lightGray};
        cursor: pointer;

        .tool-icon-delete {
            display: block;
        }

        svg {
            cursor: pointer;
        }
    }
`;

const emptyKmlOutput = {
    succeeded: 0,
    lackingNames: 0,
    failed: 0,
    missingSections: 0,
    longNames: [] as string[],
    duplicates: [] as string[],
};

type AoiToolsProps = {
    /** The current roi from redux */
    activeRoi?: OSKGeoJson;
    /** A list of SearchArea elements to show in the library */
    libraryItems: Array<SearchArea>;
    /** Method to invoke when an item is deleted from the library */
    deleteLibraryItem: (id: number) => void;
    /** Method to invoke when we wish to re-fetch the library items */
    reloadLibrary: () => void;
    /** Method to invoke when a new aoi is drawn or selected from the library. */
    onAoiChanged?: (aoi: OSKGeoJson) => void;
    /** Checks Editable map for an AOI and sets to redux store */
    setMapRoi: (geoJson: OSKGeoJson, aoiName?: string) => void;
    /** Optional top override */
    top?: number;
};

export const AoiTools = ({
    activeRoi,
    libraryItems,
    deleteLibraryItem,
    reloadLibrary,
    onAoiChanged,
    setMapRoi,
    top,
}: AoiToolsProps) => {
    const [showLibrary, setShowLibrary] = useState(false);
    const [currentAoi, setCurrentAoi] = useState<OSKGeoJson>(activeRoi ?? new OSKGeoJson());
    const [currentNamedAoi, setCurrentNamedAoi] = useState<string | undefined>(undefined);
    const [isSavingAoi, setIsSavingAoi] = useState(false);
    const [saveModalVisible, setSaveModalVisible] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadResultsModalVisible, setUploadResultsModalVisible] = useState(false);
    const [saveModalError, setSaveModalError] = useState<string | undefined>(undefined);
    const [uploadModalError, setUploadModalError] = useState<string | undefined>(undefined);
    const [mode, setMode] = useState<MapModes>('None');
    const libraryPopupRef = useRef(null);
    const kmlOutput = useRef({ ...emptyKmlOutput });
    const theme = useTheme() as OSKThemeType;
    const map = useMap();
    const dispatch = useDispatch();
    const libraryItemNames = libraryItems.map((libraryItem) => libraryItem.name);

    useClickAway(
        libraryPopupRef,
        () => {
            setShowLibrary(false);
        },
        showLibrary,
    );

    useEffect(() => {
        reloadLibrary();
    }, []);

    const DisableMapMove = useCallback(() => {
        map.requestUpdateToFeatureEnabled('Drag', MAP_FEATURE_MODE_HASH_KEY, false);
        map.requestUpdateToFeatureEnabled('Zoom', MAP_FEATURE_MODE_HASH_KEY, false);
    }, []);

    const EnableMapMove = useCallback(() => {
        map.requestUpdateToFeatureEnabled('Drag', MAP_FEATURE_MODE_HASH_KEY, true);
        map.requestUpdateToFeatureEnabled('Zoom', MAP_FEATURE_MODE_HASH_KEY, true);
    }, []);

    useEffect(() => {
        // If they are drawing or something, disable zoom and scroll. Otherwise, enable it.
        if (['Polygon', 'Square', 'Point', 'Measure'].includes(mode)) {
            DisableMapMove();
        } else {
            EnableMapMove();
        }
    }, [mode]);

    useEffect(() => {
        if (currentAoi && !activeRoi?.sameAs(currentAoi)) {
            setMapRoi(currentAoi, currentNamedAoi);
        }
    }, [currentAoi, currentNamedAoi]);

    // Handle cancelling the save modal
    const hideSaveModal = () => {
        setSaveModalVisible(false);
        setSaveModalError(undefined);
    };

    // Handle showing the modal
    const showSaveModal = () => {
        setSaveModalVisible(true);
    };

    const UpdateCurrentAoi = (aoi: OSKGeoJson, name?: string) => {
        setCurrentAoi(aoi);
        setCurrentNamedAoi(name);
        dispatch(setIsSearchDirty(true));
        onAoiChanged && onAoiChanged(aoi);
    };

    const SaveAoi = (AreaName: string, aoi: OSKGeoJson) => {
        return SigmaAPI.createSearchArea({
            searchAreaRequest: {
                name: AreaName,
                area: JSON.stringify(aoi.toAPIGeometry()) as any,
            },
        }).then(() => {
            setMode('Clear');

            // setMode('clear') will cause taskingArea to be wiped out
            // so we need a bit of a delay before repopulating it and
            // becuase of react, there is no real way to guarantee
            // the operation has been completed or not.
            // So we use a setTimeout with reckless abandon.
            setTimeout(() => {
                setTimeout(() => {
                    UpdateCurrentAoi(aoi);
                    onAoiChanged && onAoiChanged(aoi);
                }, 150);
            });

            reloadLibrary();
        });
    };

    // Handle submitting the modal
    const handleModalSubmit = useCallback(
        ({ AreaName }: AOIFormAttributes) => {
            // Clear modal error
            setSaveModalError(undefined);
            setIsSavingAoi(true);

            // Compute the search parameters
            const searchArea = currentAoi && !currentAoi.isEmpty() ? currentAoi : activeRoi;

            // Basic validations
            if (AreaName === undefined) {
                setSaveModalError('Please specify an area name');
                setIsSavingAoi(false);
            } else if (searchArea) {
                SaveAoi(AreaName, searchArea)
                    .catch((err) => {
                        console.error(err);
                        setSaveModalError(err.toString());
                    })
                    .finally(() => {
                        setIsSavingAoi(false);
                        hideSaveModal();
                    });
            } else {
                setIsSavingAoi(false);
                setSaveModalVisible(false);
            }
        },
        [currentAoi, activeRoi, setIsSavingAoi, setSaveModalVisible, setSaveModalError],
    );

    const handleFileUpload = async (files: FileList | null) => {
        if (files && files.length > 0) {
            setIsUploading(true);
            kmlOutput.current = {
                succeeded: 0,
                lackingNames: 0,
                failed: 0,
                missingSections: 0,
                longNames: [] as string[],
                duplicates: [] as string[],
            };
            const fileName = files[0].name;
            const data = await files[0].text();
            const oskJson = OSKGeoJson.fromKML(data);
            const promises = [];

            try {
                // Check if it's empty
                if (oskJson.features.length === 0) {
                    kmlOutput.current.missingSections = 1;
                    kmlOutput.current.failed++;
                } else {
                    for (const feature of oskJson.features) {
                        const name = feature.name ?? ensureDistinctName(fileName, libraryItemNames);
    
                        if (name.length > 128) {
                            kmlOutput.current.failed++;
                            kmlOutput.current.longNames.push(name);
                        } else if (!name) {
                            kmlOutput.current.failed++;
                            kmlOutput.current.lackingNames++;
                        } else {
                            promises.push(
                                SaveAoi(name, OSKGeoJson.fromFeature(feature))
                                    .then(() => {
                                        kmlOutput.current.succeeded++;
                                    })
                                    .catch((err) => {
                                        kmlOutput.current.duplicates.push(name);
                                        kmlOutput.current.failed++;
                                    }),
                            );
                        }
                    }
                }

                await Promise.all(promises);
                setIsUploading(false);
                setUploadResultsModalVisible(true);
            }
            catch {
                // Error occurred, display message.
                setIsUploading(false);
                setUploadModalError("An unknown error occurred while uploading. Please reach out to your support contact for help.");
            }
        }
    };

    return (
        <React.Fragment>
            <Modal visible={uploadResultsModalVisible}>
                <ModalHeader variant="primary">
                    {kmlOutput.current.failed > 0 ? 'Upload Failed' : 'Upload Complete'}
                </ModalHeader>
                <ModalBody style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    <Box style={{ maxWidth: '400px' }} col>
                        {kmlOutput.current.missingSections > 0 && (
                            <Text>No geometries found in the uploaded KML file.</Text>
                        )}
                        {kmlOutput.current.succeeded > 0 && (
                            <Text style={{ paddingBottom: '12px' }}>{kmlOutput.current.succeeded} succeeded.</Text>
                        )}
                        {kmlOutput.current.lackingNames > 0 && (
                            <Text>{kmlOutput.current.lackingNames} failed because they needed a title.</Text>
                        )}
                        {kmlOutput.current.failed > 0 && (
                            <>
                                {kmlOutput.current.duplicates.length > 0 && (
                                    <>
                                        <Text>
                                            {kmlOutput.current.duplicates.length} section(s) failed because you already
                                            have items in your library with the same name:
                                        </Text>

                                        <Box pt={12} col>
                                            {kmlOutput.current.duplicates.map((name, idx) => (
                                                <Text key={`${name}_${idx}`}> - {name}</Text>
                                            ))}
                                        </Box>
                                    </>
                                )}

                                {kmlOutput.current.duplicates.length > 0 && kmlOutput.current.duplicates.length > 0 && (
                                    <>
                                        <Divider vm={18} />
                                    </>
                                )}

                                {kmlOutput.current.longNames.length > 0 && (
                                    <>
                                        <Text>
                                            {kmlOutput.current.longNames.length} section(s) failed because their names
                                            are over 128 characters long:
                                        </Text>

                                        <Box pt={12} col>
                                            {kmlOutput.current.longNames.map((name, idx) => (
                                                <Text key={`${name}_${idx}`}> - {name}</Text>
                                            ))}
                                        </Box>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button variant="action" label="Close" onClick={() => setUploadResultsModalVisible(false)} />
                </ModalFooter>
            </Modal>

            <Modal visible={isUploading}>
                <ModalHeader variant="primary">Uploading KML...</ModalHeader>
                <ModalBody>
                    <Box grow center="horizontal" p={42}>
                        <Spinner variant="Box" size="Medium" />
                    </Box>
                </ModalBody>
            </Modal>

            <Modal visible={!!uploadModalError}>
                <ModalHeader variant="primary">Upload Error</ModalHeader>
                <ModalBody>
                    <Box grow w={500}>
                        {uploadModalError}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button variant="action" label="Close" onClick={() => setUploadModalError(undefined)} />
                </ModalFooter>
            </Modal>

            <AOISaveDialog
                isLoading={isSavingAoi}
                errorMessage={saveModalError}
                onSubmit={handleModalSubmit}
                visible={saveModalVisible}
                onCancel={hideSaveModal}
            />

            <MapMode
                mode={mode}
                onBeforeEdit={() => {
                    if (!currentAoi.isEmpty() || !activeRoi || !activeRoi.isEmpty()) {
                        UpdateCurrentAoi(new OSKGeoJson());
                    }
                }}
                onSave={(geoJson) => {
                    setMode('None');
                    UpdateCurrentAoi(geoJson);
                }}
            />
            {showLibrary && (
                <Box
                    ref={libraryPopupRef}
                    onMouseEnter={() => {
                        DisableMapMove();
                    }}
                    onMouseLeave={() => {
                        EnableMapMove();
                    }}
                    style={{
                        position: 'absolute',
                        top: '450px',
                        right: 'calc(60px + 25px + 2px)',
                        maxHeight: '400px',
                        overflowY: 'scroll',
                        pointerEvents: 'auto',
                    }}
                    col
                >
                    <Box
                        style={{
                            zIndex: GlobalZIndex.Timeline,
                            alignItems: 'center',
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            width: '300px',
                            position: 'relative',
                            right: '0px',
                            borderRadius: '4px',
                        }}
                        col
                    >
                        <Box
                            bg={theme.colors.lightGray}
                            style={{
                                justifyContent: 'space-between',
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                            }}
                            center="vertical"
                            onClick={() => setShowLibrary(false)}
                        >
                            <Box center="vertical">
                                <OSKIcon code="report" fill={theme.colors.black900} style={{ marginRight: '8px' }} />
                                <Text variant="small" strong color={theme.colors.black900}>
                                    My Library
                                </Text>
                            </Box>
                            <OSKIcon code="arrow-right" />
                        </Box>
                        <Box col style={{ width: '100%' }}>
                            {libraryItems.length === 0 && (
                                <Box center="all" grow p={16} col>
                                    <Typography variant="heading5" style={{ textAlign: 'center' }}>
                                        Your library is empty.
                                    </Typography>
                                    <Typography variant="body3" style={{ textAlign: 'center' }} pt={6}>
                                        Upload your AOIs to store them here.
                                    </Typography>
                                </Box>
                            )}
                            {libraryItems.map((item, idx) => (
                                <React.Fragment key={`library-item-${idx}`}>
                                    {idx > 0 && (
                                        <Box
                                            style={{ borderTop: `1px solid ${theme.colors.lightGray}`, width: '100%' }}
                                        />
                                    )}
                                    <LibraryRowItem
                                        iconCode="draw-polygon"
                                        label={item.name}
                                        onClick={() => {
                                            const geo = OSKGeoJson.fromAPIGeometry(item.area);
                                            UpdateCurrentAoi(geo, item.name);
                                            map.fitCoordinates([geo], 0.01);
                                        }}
                                        onDelete={() => {
                                            deleteLibraryItem(item.id);
                                            setMode('Clear');
                                            reloadLibrary();
                                        }}
                                    />
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}

            <Box
                style={{ position: 'absolute', top: top ? `${top}px` : '25px', right: '25px', pointerEvents: 'auto' }}
                col
            >
                <Box
                    style={{
                        zIndex: GlobalZIndex.Timeline,
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        width: '60px',
                        overflow: 'hidden',
                    }}
                    col
                >
                    <ToolIcon
                        code="location-arrow"
                        label="Point"
                        highlighted={mode === 'Point'}
                        onClick={() => {
                            setMode('Point');
                        }}
                    />
                    <ToolIcon
                        code="draw-polygon"
                        label="Polygon"
                        highlighted={mode === 'Polygon'}
                        onClick={() => {
                            setMode('Polygon');
                        }}
                    />
                    <ToolIcon
                        code="draw-square"
                        label="Square"
                        highlighted={mode === 'Square'}
                        onClick={() => {
                            setMode('Square');
                        }}
                    />
                    <ToolIcon
                        code="undo"
                        label="Clear"
                        onClick={() => {
                            setMode('Clear');
                        }}
                    />
                    <Box style={{ borderTop: `1px solid ${theme.colors.black600}`, width: '80%' }} />

                    <FileUpload fileTypes=".kml" onFilesChosen={handleFileUpload}>
                        <ToolIcon code="upload" label="Upload" />
                    </FileUpload>

                    <ToolIcon
                        code="save"
                        label="Save"
                        onClick={() => {
                            showSaveModal();
                        }}
                    />
                    <ToolIcon
                        code="report"
                        label="Library"
                        w={12}
                        onClick={() => {
                            if (mode === 'Point' || mode === 'Polygon') {
                                setMode('Clear');
                            }
                            setShowLibrary(!showLibrary);
                        }}
                    />
                </Box>
            </Box>
            <Box style={{ position: 'absolute', top: '508px', right: '25px', pointerEvents: 'auto' }} col>
                <Box
                    style={{
                        zIndex: GlobalZIndex.Timeline,
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        width: '60px',
                        overflow: 'hidden',
                    }}
                    col
                >
                    <ToolIcon
                        scale={140}
                        code="measure"
                        label="Measure"
                        fill="white"
                        highlighted={mode === 'Measure'}
                        onClick={() => {
                            setMode('Measure');
                        }}
                    />
                </Box>
            </Box>
        </React.Fragment>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        activeRoi: state.data.search.roi,
        libraryLoading: getLibraryLoading(state),
        libraryItems: getCustomAreas(state),
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        reloadLibrary: () => {
            dispatch<any>(
                doFetchCustomAreasAsync({
                    offset: 0,
                    limit: 1000,
                    silent: false,
                }),
            );
        },

        deleteLibraryItem: (id: number) => {
            dispatch<any>(deleteCustomAreaAsync(id, { silent: true }));
        },

        setMapRoi: (geoJson: OSKGeoJson, aoiName?: string) => {
            dispatch(setRoi(geoJson, aoiName, 'manual'));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AoiTools);
