import { Box, BoxProps, OSKThemeType } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import CSS from 'csstype';

export type SummarySectionProps = {
    /** Children to render with some box styling around */
    children: React.ReactNode;
    /** Override of box styling */
    style?: CSS.Properties;
} & Omit<BoxProps, 'ref'>;

export const SummaryTabSection = ({ children, style, ...props }: SummarySectionProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                lineHeight: '2.5rem',
                paddingTop: '20px',
                paddingBottom: '20px',
                borderBottom: `1px solid ${theme.colors.gray50}`,
                ...style,
            }}
            col
            {...props}
        >
            {children}
        </Box>
    );
};
