import { interpolate } from './math';

export type Color = [number, number, number];

export const color_viridis: Array<Color> = [
    [253, 231, 37],
    [94, 201, 98],
    [94, 201, 98],
    [33, 145, 140],
    [33, 145, 140],
    [59, 82, 139],
    [59, 82, 139],
    [68, 1, 84],
];

export const color_magma: Array<Color> = [
    [252, 253, 191],
    [252, 137, 97],
    [252, 137, 97],
    [183, 55, 121],
    [183, 55, 121],
    [81, 18, 124],
    [81, 18, 124],
    [0, 0, 4],
];

export const color_inferno: Array<Color> = [
    [252, 255, 164],
    [249, 142, 9],
    [249, 142, 9],
    [188, 55, 84],
    [188, 55, 84],
    [87, 16, 110],
    [87, 16, 110],
    [0, 0, 4],
];

export const color_plasma: Array<Color> = [
    [240, 249, 33],
    [248, 149, 64],
    [248, 149, 64],
    [204, 71, 120],
    [204, 71, 120],
    [126, 3, 168],
    [126, 3, 168],
    [13, 8, 135],
];

export const color_rainbow: Array<Color> = [
    [255, 0, 0],
    [253, 174, 97],
    [255, 255, 191],
    [171, 221, 164],
    [43, 131, 186],
];

/**
 * This method will take a set of colors and a blending depth and generate a bitmap
 * with discreet color transitions between each band.
 *
 * @param colors The baseline colors you want in your gradient
 * @param blending_depth How many interpolation steps between each color
 * @returns A bitmap
 */
export function generateGradient(colors: Array<Color>, blending_depth: number): Uint8Array {
    const values = [];
    // Clamp the blending depth at 1
    blending_depth = Math.max(blending_depth, 1);

    // If the blending depth is 1, don't interpolate. Just add all the colors
    if (blending_depth === 1) {
        for (let colorIndex = 0; colorIndex < colors.length; colorIndex++) {
            for (let channel = 0; channel < 3; channel++) {
                values.push(colors[colorIndex][channel]);
            }
        }
    } else {
        // We can do the interpolation here.
        for (let colorIndex = 0; colorIndex < colors.length - 1; colorIndex++) {
            const cur = colors[colorIndex];
            const peek = colors[colorIndex + 1];
            const interpolation_lower_bound = 0.0;
            const interpolation_upper_bound = colorIndex === colors.length - 2 ? blending_depth - 1 : blending_depth;

            for (let colorStep = 0; colorStep < blending_depth; colorStep++) {
                for (let channel = 0; channel < 3; channel++) {
                    values.push(
                        interpolate(
                            cur[channel],
                            peek[channel],
                            interpolation_lower_bound,
                            colorStep,
                            interpolation_upper_bound,
                        ),
                    );
                }
            }
        }
    }

    return new Uint8Array(values);
}
