import { useEffect } from 'react';
import { useMap } from './map';

type Fn = () => void;

/**
 * This function takes a method and it will invoke that method
 * when the map is finished loading. Optionally, the method
 * can return a destructor function just like useEffect()
 * which will be called when the hook is unloaded.
 *
 * NOTE: Your method parameter may get called a lot so
 * make sure to implement the destructor properly if
 * binding any methods.
 *
 * @param method A method to invoke when the map is loaded.
 */
export function useWaitForMap(method?: () => Fn) {
    const map = useMap();

    useEffect(() => {
        let destructor = function () {};

        const timerId = setInterval(() => {
            if (map && map.editableMap && map.editableMap.map) {
                clearInterval(timerId);
                if (method) {
                    destructor = method();
                }
            }
        }, 10);

        return () => {
            clearInterval(timerId);
            destructor && destructor();
        };
    });
}
