import { Box, IconSwitch } from 'oskcomponents';
import { Geometry, GeometryCollection, noop, OSKGeoJson, AssetDetail } from 'oskcore';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Footprint, MapCentroid, MapRefresher } from '~/atoms';
import { ActionBar } from '~/atoms/ActionBar';
import { GlobalZIndex } from '~/constants';
import DetectionMarkers from '~/organisms/map/DetectionMarkers';
import EditableMap from '~/organisms/map/EditableMap';
import { decrementBusyCount, incrementBusyCount } from '~/redux/modules/data/app';
import { Asset, AssetDetection, selectAlertsByAsset, selectDetectionsByAsset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import AssetDetailsSection from '../../AssetDetailsSection';
import AlertDetailsSection from '../../AlertDetailsSection';
import AssetDetailsSidePanel from './AssetDetailsSidePanel';
import DateRangePicker from '~/molecules/DateRangePicker';
import { isEmpty } from 'lodash';

const STAGE = {
    SHOW_SHROUD: 0,
    SHOW_MAP: 1,
    SHOW_MAP_PINS: 2,
    RENDER_FOOTPRINTS: 3,
};

type ValidAssetDetailMapViewProps = {
    /** The assetId that is selected */
    selectedAssetId?: number;
    /** The optional detection (including alert) that is selected */
    selectedDetectionId?: string;
    /** The geometry to render */
    geometry?: Geometry | GeometryCollection;
    /** The asset object from redux */
    asset: Asset;
    /** The loading state of the current asset */
    fetchingAsset: boolean;
    /** The detections to render */
    detections?: AssetDetection[];
    /** The alerts to render */
    alerts?: AssetDetection[];
};

const ValidAssetDetailMapView = ({
    geometry,
    selectedAssetId,
    selectedDetectionId,
    asset,
    fetchingAsset,
    detections,
    alerts,
}: ValidAssetDetailMapViewProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { programId } = useParams();
    const [stage, setStage] = useState(0);
    const [center, setCenter] = useState(false);
    const [showOverlay, setShowOverlay] = useState(true);
    const selectedDetection =
        alerts && detections && [...alerts, ...detections].find((detection) => detection.id === selectedDetectionId);

    let crumbs = [{ title: asset.name, url: `/program/${programId}/monitor/${asset.id}/grid` }, { title: 'Map' }];

    // Link back to the Alerts grid if we're deep-linking to a specific alert
    if (selectedDetectionId) {
        crumbs = [{ title: 'All Alerts', url: `/program/${programId}/monitor/${asset.id}/map` }, { title: 'Map' }];
    }

    const aoi = useMemo(() => {
        if (geometry) {
            return OSKGeoJson.fromAPIGeometry(geometry);
        } else {
            return new OSKGeoJson();
        }
    }, [geometry]);

    useEffect(() => {
        if (showOverlay) {
            dispatch(incrementBusyCount());
        } else {
            dispatch(decrementBusyCount());
        }
    }, [showOverlay, dispatch]);

    useEffect(() => {
        if (stage == STAGE.SHOW_SHROUD) {
            const timerId = setTimeout(() => setStage(STAGE.SHOW_MAP), 100);
            return () => {
                clearTimeout(timerId);
            };
        }
    });

    if (fetchingAsset) return <Box>...</Box>;

    return (
        <Box style={{ position: 'relative', width: '100%', zIndex: 0 }}>
            {
                <Box
                    style={{
                        position: 'absolute',
                        opacity: showOverlay ? 1.0 : 0.0,
                        visibility: showOverlay ? 'visible' : 'hidden',
                        top: '0px',
                        left: '0px',
                        width: '100vw',
                        height: '100vh',
                        filter: 'blur(.25rem)',
                        transition: 'opacity 1.5s, visibility 1.5s',
                        backgroundImage: 'url(/images/spaaace.jpg)',
                        backgroundSize: 'cover',
                        zIndex: GlobalZIndex.MapSection + 1000,
                    }}
                />
            }
            {stage > STAGE.SHOW_SHROUD && aoi.features.length > 0 && (
                <EditableMap fadeAnimation={false} center={aoi?.getCentroid()} zoomPosition="bottomright">
                    {aoi && stage > STAGE.SHOW_MAP_PINS ? (
                        <Fragment>
                            {stage === STAGE.RENDER_FOOTPRINTS && (
                                <MapCentroid animated={false} area={aoi} padding={0.001} />
                            )}
                            <Footprint shape={aoi} color="#00d1ff" />
                            <MapRefresher
                                onLoad={(map) => {
                                    // If we are on stage 2, increment stage and then wait 100ms before unveiling
                                    // the page and forcing a final re-render of the map.
                                    if (stage === STAGE.SHOW_MAP_PINS) {
                                        map.invalidate();
                                    } else if (stage === STAGE.RENDER_FOOTPRINTS) {
                                        setStage(stage + 1);
                                        setTimeout(() => {
                                            setShowOverlay(false);
                                        }, 1000);
                                    }
                                }}
                            />
                        </Fragment>
                    ) : (
                        <Fragment></Fragment>
                    )}

                    <ActionBar
                        left={<Breadcrumb nodes={crumbs} />}
                        right={
                            <React.Fragment>
                                <DateRangePicker />
                                <IconSwitch
                                    options={[
                                        {
                                            value: 'grid',
                                            icon: 'list',
                                            label: 'List View',
                                        },
                                        {
                                            value: 'map',
                                            icon: 'map',
                                            label: 'Map View',
                                        },
                                    ]}
                                    selectedValue="map"
                                    onSelect={(value: any) => {
                                        if (value === 'grid') {
                                            navigate(`/program/${programId}/monitor/${selectedAssetId}/grid`);
                                        }
                                    }}
                                />
                            </React.Fragment>
                        }
                    />

                    {center && (
                        <AssetDetailsSidePanel
                            title={selectedDetectionId ? selectedDetection?.id : asset.name}
                            showBackButton={!isEmpty(selectedDetectionId)}
                            onBackClick={() => {
                                navigate(`/program/${programId}/monitor/${asset.id}/map`);
                            }}
                        >
                            {selectedDetectionId ? (
                                <AlertDetailsSection
                                    selectedAssetId={asset.id}
                                    selectedDetectionId={selectedDetectionId}
                                />
                            ) : (
                                <AssetDetailsSection
                                    selectedAssetId={asset.id}
                                    showMap={false}
                                    showMapLinks={true}
                                    showVisibilityFilters={true}
                                />
                            )}
                        </AssetDetailsSidePanel>
                    )}
                    {center && stage > STAGE.SHOW_MAP && (
                        <>
                            {selectedAssetId && (
                                <DetectionMarkers
                                    selectedDetectionId={selectedDetectionId}
                                    selectedAssetId={selectedAssetId}
                                />
                            )}

                            {stage == STAGE.SHOW_MAP_PINS && (
                                <MapRefresher
                                    onLoad={() => {
                                        setStage(STAGE.RENDER_FOOTPRINTS);
                                    }}
                                />
                            )}
                        </>
                    )}
                    {aoi && stage === STAGE.SHOW_MAP && (
                        <MapRefresher
                            onLoad={(map) => {
                                if (
                                    aoi &&
                                    aoi.features &&
                                    aoi.features.length > 0 &&
                                    !(aoi.getCentroid().lat === 0 && aoi.getCentroid().lng === 0)
                                ) {
                                    setStage(STAGE.SHOW_MAP_PINS);
                                    setCenter(true);
                                    map.invalidate();
                                }
                            }}
                        />
                    )}
                </EditableMap>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState, props: Partial<ValidAssetDetailMapViewProps>) => {
    const { selectedAssetId } = props;
    const asset = state.monitor.app.assets[selectedAssetId ?? -1] as AssetDetail;

    return {
        asset,
        geometry: (asset && asset.aoi) ?? undefined,
        fetchingAsset: state.monitor.app.fetchingAsset,
        detections: selectDetectionsByAsset(state, selectedAssetId ?? -1),
        alerts: selectAlertsByAsset(state, selectedAssetId ?? -1),
    };
};

const ConnectedValidAssetDetailMapView = connect(mapStateToProps, noop)(ValidAssetDetailMapView);
export default ConnectedValidAssetDetailMapView;
export { ConnectedValidAssetDetailMapView as ValidAssetDetailMapView };
