import React from 'react';
import { Box, Typography } from 'oskcomponents';
import { Capture, noop } from 'oskcore';
import { GlobalZIndex } from 'oskcomponents/src/constants';
import { RootState } from '~/redux/store';
import { connect } from 'react-redux';
import { FootprintOverlayMode } from '~/redux/modules/data/search';

type OverlayKeyProps = {
    /** From redux, a map of overlay modes by task id */
    taskOverlayModes: Record<string, FootprintOverlayMode>;
    /** From redux, search results */
    searchResults?: any;
};

const OverlayKey = ({ taskOverlayModes, searchResults, ...props }: OverlayKeyProps) => {
    const cloudCoverActive =
        searchResults && (searchResults as Capture[]).some((capture) => taskOverlayModes[capture.task_id] === 'clouds');

    if (cloudCoverActive) {
        return (
            <Box
                style={{
                    zIndex: GlobalZIndex.Overlay,
                    backgroundColor: 'white',
                    color: 'black',
                    position: 'absolute',
                    right: '24px',
                    bottom: '100px',
                    borderRadius: '4px',
                    padding: '14px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
                col
            >
                <Typography variant="caption2" strong>
                    Cloud Model
                </Typography>
                <KeyElement color="yellow" label="Clouds" />
                <KeyElement color="green" label="Shadows" />
            </Box>
        );
    }

    return <></>;
};

type KeyElementProps = {
    /** The color of the key element. Must be valid CSS value. */
    color: string;
    /** The label of the key element. */
    label: string;
};

const KeyElement = ({ color, label }: KeyElementProps) => {
    return (
        <Box center="vertical" style={{ marginTop: '14px' }}>
            <Box
                style={{
                    width: '10px',
                    height: '10px',
                    marginRight: '8px',
                    backgroundColor: color,
                    border: '1px solid #D4D2CF',
                }}
            />
            <Typography variant="caption1">{label}</Typography>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        ...state,
        searchResults: state.data.search.results,
        taskOverlayModes: state.data.search.taskOverlayModes,
    };
};

export type { OverlayKeyProps };
export default connect(mapStateToProps, noop)(OverlayKey);
