import { Box, OSKThemeType, Typography } from 'oskcomponents';
import React from 'react';
import styled, { useTheme } from 'styled-components';

export type AppIconProps = {
    /** The image source */
    imgSrc: string;
    /** An optional label to render */
    label?: string;
    /** The size (WxH) to render the image as */
    imgSize?: number;
    /** The CSS class provided by styled-components */
    className?: string;
};

export const AppIcon = styled(({ className, imgSrc, label, imgSize }: AppIconProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box cursor={'pointer'} col center="all" className={className}>
            <img src={imgSrc} alt={label} width={imgSize} />
            <Typography color={theme.colors.black} pt={5} variant="body4">
                {label}
            </Typography>
        </Box>
    );
})`
    & {
        padding: 10px;
        width: 100px;
        height: 100px;
    }

    &:hover {
        background-color: ${(props) => props.theme.colors.lightGray};
        border-radius: 7px;
    }
`;
