import { Box, Button, Heading, OSKIcon, Spinner, Typography } from 'oskcomponents';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SigmaOrder } from '~/redux/modules/data/orders';
import { OrderCard } from '~/atoms/OrderCard';
import { format } from 'date-fns-tz';

type ArchiveOrderListProps = {
    /** The data that will go in the table. */
    data?: SigmaOrder[];
    /** Whether to show the spinner or not */
    loading?: boolean;
    /** The row to highlight (if applicable) */
    selectedIndex?: number;
    /** Method to invoke when a row is clicked */
    onRowClick?: (order?: SigmaOrder) => void;
    /** Children nodes to render underneath the table */
    children?: React.ReactNode | JSX.Element | Array<React.ReactNode | JSX.Element>;
};

const ArchiveOrderList = ({ data, onRowClick, selectedIndex, children, loading }: ArchiveOrderListProps) => {
    const navigate = useNavigate();

    if (!loading) {
        if (!data) {
            return <></>;
        }

        if (data.length === 0) {
            return (
                <Box col center="all" p={30} grow>
                    <OSKIcon code="no-orders" width={300} m={-25} />
                    <Heading>No orders yet.</Heading>
                    <Button
                        m={26}
                        variant="action"
                        onClick={() => {
                            navigate('/map');
                        }}
                    >
                        Place Order
                    </Button>
                </Box>
            );
        }
    }

    const dateMap: Record<string, Array<SigmaOrder>> = {};
    let idx = 0;
    data?.forEach((order) => {
        const orderDate = new Date(order.orderTimeStamp);
        const key = format(orderDate, 'MM-dd-yyyy');

        if (!(key in dateMap)) {
            dateMap[key] = [];
        }

        dateMap[key].push(order);
    });

    return (
        <Box col style={{ height: '100%', overflow: 'hidden' }} grow>
            <Box grow style={{ overflow: 'auto' }} col>
                {Object.values(dateMap).map((bucket) => {
                    const firstOrder = bucket[0];
                    if (!firstOrder) return <></>;
                    const orderDate = format(new Date(firstOrder.orderTimeStamp), 'MMMM d, yyyy');

                    return (
                        <Box key={`order-${orderDate}`} col>
                            <Typography variant="heading2" style={{ padding: '12px 12px' }}>
                                {orderDate}
                            </Typography>
                            {bucket?.map((order) => (
                                <OrderCard
                                    key={`ordercard-${order.id}`}
                                    order={order}
                                    selected={idx++ === selectedIndex}
                                    onClick={() => onRowClick && onRowClick(order)}
                                />
                            ))}
                        </Box>
                    );
                })}
                {loading && (
                    <Box grow center="all">
                        <Spinner variant="Box" size="Large" />
                    </Box>
                )}
            </Box>
            {children}
        </Box>
    );
};

export default ArchiveOrderList;
