import { ListEntry, Select, SelectProps } from 'oskcomponents';
import { PublicDataProduct, noop } from 'oskcore';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';

export type FileTypePickerProps = {
    /** The list of supported data products */
    dataProducts: Array<PublicDataProduct>;
    /** The currently selected product */
    selectedProduct?: string;
    /** The method to invoke when the selection changes */
    onChange: (option: ListEntry | null) => void;
    /** The default entry to pick */
    defaultValue?: ListEntry;
} & Omit<Omit<Omit<Omit<Omit<SelectProps, 'name'>, 'items'>, 'variant'>, 'inverted'>, 'onChange'>;

export const FileTypePicker = ({
    onChange,
    selectedProduct,
    dataProducts,
    defaultValue,
    ...props
}: FileTypePickerProps) => {
    // Compute the fileTypeOptions
    const candidates = dataProducts.filter((x) => x.product_level === (selectedProduct ?? 'Basic'));

    // Get the intersection of possibilities
    // Pare down the final options
    const fileTypeOptions: Array<ListEntry> = candidates.map((dataProduct) => ({
        label: dataProduct.file_format.toUpperCase(),
        value: dataProduct.file_format,
    }));

    // Derive the default option
    let defaultFileType = defaultValue ?? fileTypeOptions[0];

    // Validate the default, just in case something
    // old has been stuck in localStorage.
    let valid = false;
    for (const option of fileTypeOptions) {
        if (option.label === defaultFileType.label && option.value === defaultFileType.value) {
            valid = true;
        }
    }

    // If the option is not valid, default to the first valid thing.
    if (!valid) {
        defaultFileType = fileTypeOptions[0];
    }

    const [selectedFileType, setSelectedFileType] = useState<ListEntry>(defaultFileType);

    useEffect(() => {
        // If the selectedProduct is supported, keep it
        if (!fileTypeOptions.some((option) => option.value === selectedFileType?.value)) {
            setSelectedFileType(fileTypeOptions[0]);
        }
    }, [selectedProduct]);

    useEffect(() => {
        onChange && onChange(selectedFileType);
    }, [selectedFileType, onChange]);

    return (
        <Select
            name="file_type"
            inverted
            variant="primary"
            items={fileTypeOptions}
            value={selectedFileType?.value}
            defaultValue={selectedFileType?.label}
            onSelect={(option) => {
                if (option) {
                    setSelectedFileType(option);
                }
            }}
            {...props}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        dataProducts: state.osk.dataProducts,
    };
};

export default connect(mapStateToProps, noop)(FileTypePicker);
