import React, { useEffect, useState } from 'react';
import { Box, Text, Table } from 'oskcomponents';
import { NoPermission } from '~/atoms';
import { Asset, SigmaAPI } from 'oskcore';
import { getProgramId } from '~/utils';

export type AssetsTableProps = {
    onRowClick?: (asset: Record<string, any>) => void;
};

export const AssetsTable = ({ onRowClick }: AssetsTableProps) => {
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState(true);
    const [rows, setRows] = useState<Asset[]>([]);

    useEffect(() => {
        SigmaAPI.listAssets({ program: getProgramId() })
            .then((resp) => {
                setAllowed(true);
                setRows(resp.data.results ?? []);
            })
            .catch((err) => {
                const { response } = err;
                if (response && response.status === 403) {
                    setAllowed(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const columns = React.useMemo(
        () => [
            { Header: 'Id', accessor: 'id', maxWidth: 100, minWidth: 100 },
            { Header: 'Name', accessor: 'name' },
            { Header: 'Monitoring Start Date', accessor: 'monitoring_start_date' },
            { Header: 'Monitoring End Date', accessor: 'monitoring_end_date' },
            { Header: 'Monitoring Frequency', accessor: 'monitoring_frequency' },
        ],
        [],
    );

    if (loading) {
        return (
            <Box>
                <Text style={{ fontSize: '1.85rem' }}>Loading...</Text>
            </Box>
        );
    } else if (rows.length > 0) {
        return <Table onRowClick={onRowClick} columns={columns} data={rows} />;
    } else {
        return (
            <Box style={{ justifyContent: 'center', paddingTop: '50px' }}>
                {allowed && <Text style={{ fontSize: '1.5rem' }}>There are no assets in the system.</Text>}
                {!allowed && <NoPermission />}
            </Box>
        );
    }
};
