import React, { useRef } from 'react';
import { NavItem } from 'oskcore';
import { AppIcon } from '~/atoms';
import { RiGridFill } from 'react-icons/ri';
import { Box, OSKThemeType, Overlay, Typography, useClickAway } from 'oskcomponents';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { NavItemEntry, showNavItem } from '~/redux/modules/data/nav';
import { GlobalZIndex } from '~/constants';
import { useToggles } from '~/hooks/useToggles';

import SigmaDataIconUri from '~/assets/sigmadata.png';
import SigmaMonitorIconUri from '~/assets/sigmamonitor.png';
import SigmaToolIconUri from '~/assets/sigmatool.png';

export type AppListProps = {
    show: boolean;
    setShow: (show: boolean) => void;
    onNavigate?: () => void;
};

/**
 * AppNavItem is a button that pops-up an overlay
 * listing all the applications supported within
 * Sigma.
 */
export const AppList = ({ show, setShow, onNavigate }: AppListProps) => {
    const ref = useRef(null);
    const theme = useTheme() as OSKThemeType;
    useClickAway(ref, () => setShow(false), show);
    const toggles = useToggles();

    // Count how many apps they have access to
    const appCount = [toggles.showSigmaData(), toggles.showSigmaMonitor(), toggles.showSigmaTools()]
        .map((entry) => (entry ? 1 : 0) as number)
        .reduce((acc, cur) => cur + acc, 0);

    // If they have 1 or fewer apps, do not render the component
    if (appCount <= 1) {
        return <></>;
    }

    return (
        <Box ref={ref} style={{ zIndex: GlobalZIndex.HeaderPopup }}>
            <NavItem>
                <Box
                    center="all"
                    onClick={() => {
                        setShow(!show);
                    }}
                    style={{
                        padding: '10px',
                        borderRadius: '7px',
                        backgroundColor: show ? theme.colors.gray80 : '',
                    }}
                >
                    <RiGridFill style={{ fontSize: '25px' }} />
                </Box>
                <Box style={{ position: 'absolute', top: '55px', right: 0 }}>
                    <Overlay
                        show={show}
                        style={{ height: 'fit-content', top: '10px', right: toggles.hasApps() ? '10px' : '65px' }}
                        col
                    >
                        <Box
                            style={{
                                border: `1px solid ${theme.colors.black200}`,
                                borderRadius: '10px',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                            }}
                            bg={theme.colors.white}
                            grow
                            col
                        >
                            <Box
                                grow
                                style={{
                                    justifyContent: 'flex-start',
                                    flexWrap: 'wrap',
                                    padding: '10px',
                                }}
                                center="horizontal"
                            >
                                {toggles.hasApps() ? (
                                    <Box>
                                        {/* If you add more apps, update the appCount logic above! */}

                                        {toggles.showSigmaData() && (
                                            <Link to="/map" style={{ textDecoration: 'none' }} onClick={onNavigate}>
                                                <AppIcon imgSrc={SigmaDataIconUri} imgSize={40} label={'Data'} />
                                            </Link>
                                        )}

                                        {toggles.showSigmaMonitor() && (
                                            <Link to="/monitor" style={{ textDecoration: 'none' }} onClick={onNavigate}>
                                                <AppIcon imgSrc={SigmaMonitorIconUri} imgSize={40} label={'Monitor'} />
                                            </Link>
                                        )}

                                        {toggles.showSigmaTools() && (
                                            <Link
                                                to="/tools/tasking"
                                                style={{ textDecoration: 'none' }}
                                                onClick={onNavigate}
                                            >
                                                <AppIcon imgSrc={SigmaToolIconUri} imgSize={40} label={'Tools'} />
                                            </Link>
                                        )}

                                        {/* If you add more apps, update the appCount logic above! */}
                                    </Box>
                                ) : (
                                    <Box grow center="all">
                                        <Typography variant="body3" color={theme.colors.black}>
                                            No apps available.
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Overlay>
                </Box>
            </NavItem>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        show: state.data.nav.showAppList,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setShow: (show: boolean) => {
            dispatch(showNavItem(show ? NavItemEntry.AppList : NavItemEntry.None));
        },
        onNavigate: () => {
            // Close all nav items
            dispatch(showNavItem(NavItemEntry.None));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppList);
