import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, NullableDate, Pagination, Spinner, Table, Text, Typography } from 'oskcomponents';
import { EclipsedMode, setSelectedOpportunity } from '~/redux/modules/tasking/app';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '~/redux/store';
import { noop, Opportunity, OSKGeoJson, Sensor, SigmaAPI } from 'oskcore';
import styled from 'styled-components';
import { utc_date_format } from '~/utils';
import { calculateRollAngle } from '~/utils/rollAngleUtils';
import toast from 'react-hot-toast';

export type OpportunityWindowTableProps = {
    /** From redux, data for all current sensors. */
    sensors: Array<Sensor>;
    /** From redux, the currently selected AOI */
    selectedAoi?: OSKGeoJson;
    /** From redux, the start date for tasking search */
    taskingSearchStartDate: NullableDate;
    /** From redux, the end date for tasking search */
    taskingSearchEndDate: NullableDate;
    /** The "eclipsed" mode for the tasking request */
    taskingSearchEclipsedMode: EclipsedMode;
};

type DisplayedOpportunity = Opportunity & {
    /** The calculated roll angle estimate to display for this opportunity */
    rollAngle?: string;
    /** The friendly name of the sensor (GHOSt 1 etc) */
    sourceName?: string;
};

const StyledTable = styled<any>(Table)`
    tbody tr {
        border-bottom: 1px solid ${(props) => props.theme.colors.black200} !important;
    }

    tbody tr:hover {
        td {
            background-color: ${(props: any) => props.theme.colors[props.variant ?? 'primary'].highlight} !important;
        }
    }
`;

const OPPORTUNITY_PAGE_SIZE = 20;

export const OpportunityWindowTable = ({
    sensors,
    selectedAoi,
    taskingSearchStartDate,
    taskingSearchEndDate,
    taskingSearchEclipsedMode,
}: OpportunityWindowTableProps) => {
    const dispatch = useDispatch();
    const [loadingOps, setLoadingOps] = useState<'' | 'loading' | 'success' | 'failed'>('');
    const [opportunities, setOpportunities] = useState<DisplayedOpportunity[]>([]);
    const [totalOpportunities, setTotalOpportunities] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [error, setError] = useState<string | undefined>(undefined);
    const [selectedRow, setSelectedRow] = useState<number>(0);
    const center_point = selectedAoi?.getCentroid();
    const [selectedOpportunities, setSelectedOpportunities] = useState<Array<DisplayedOpportunity>>([]);

    const SelectOpportunity = useCallback(
        (opportunity: DisplayedOpportunity) => {
            if (!selectedOpportunities.includes(opportunity)) {
                const newv = [...selectedOpportunities, opportunity];
                setSelectedOpportunities(newv);
            }
        },
        [selectedOpportunities],
    );

    const UnselectOpportunity = useCallback(
        (opportunity: DisplayedOpportunity) => {
            if (selectedOpportunities.includes(opportunity)) {
                setSelectedOpportunities(
                    selectedOpportunities.filter((opp) => opp.start_time !== opportunity.start_time),
                );
            }
        },
        [selectedOpportunities],
    );

    useEffect(() => {
        if (selectedAoi) {
            setLoadingOps('loading');
            setError(undefined);
            SigmaAPI.searchOrbits({
                limit: OPPORTUNITY_PAGE_SIZE,
                searchTimeStart: taskingSearchStartDate?.toISOString(),
                searchTimeEnd: taskingSearchEndDate?.toISOString(),
                aoi: JSON.stringify(selectedAoi.toAPIGeometry()) as any,
                offset: OPPORTUNITY_PAGE_SIZE * currentPage,
                ...(taskingSearchEclipsedMode !== 'all' ? { eclipsed: taskingSearchEclipsedMode === 'night' } : {}),
            })
                .then((response) => {
                    setTotalOpportunities(response.data.count ?? 0);
                    setLoadingOps('success');
                    const processedResults = response.data.results?.map((opp) => {
                        const opportunity: DisplayedOpportunity = { ...opp };

                        const { sensor, max_fov } = opportunity;
                        const db_sensor = sensors.find((platform) => platform.osk_id == sensor);

                        if (db_sensor && center_point && db_sensor.max_slew_angle) {
                            const roll_angle = calculateRollAngle(
                                -db_sensor.max_slew_angle,
                                db_sensor.max_slew_angle,
                                max_fov,
                                center_point,
                            );

                            if (roll_angle) {
                                opportunity.rollAngle = `${roll_angle.toFixed(1)}°`;
                            } else {
                                opportunity.rollAngle = 'N/A';
                            }
                        } else {
                            opportunity.rollAngle = 'N/A';
                        }

                        opportunity.sourceName =
                            sensors.find((sensor) => sensor.osk_id === opportunity.sensor)?.osk_sensor_name ?? '--';

                        return opportunity;
                    });
                    setOpportunities(processedResults ?? []);
                    if (response.data.results) {
                        dispatch(setSelectedOpportunity(response.data.results[0]));
                    }
                })
                .catch((err) => {
                    setLoadingOps('failed');
                    console.info(err);
                    setError(err);
                });
        }
    }, [selectedAoi, currentPage, taskingSearchEclipsedMode]);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                Cell: (o: any) => {
                    const { start_time } = o.row.original;
                    return (
                        <Checkbox
                            id="selected"
                            onChange={(checked) => {
                                if (checked) {
                                    SelectOpportunity(o.row.original);
                                } else {
                                    UnselectOpportunity(o.row.original);
                                }
                            }}
                            defaultChecked={
                                selectedOpportunities.find((opp) => opp.start_time === start_time) ? true : false
                            }
                        />
                    );
                },
            },
            {
                Header: 'Estimated Start',
                accessor: 'start_time',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    return <Text variant="small">{utc_date_format(value)}</Text>;
                },
            },
            {
                Header: 'Estimated End',
                accessor: 'end_time',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    return <Text variant="small">{utc_date_format(value)}</Text>;
                },
            },
            {
                Header: 'Roll Angle',
                accessor: 'rollAngle',
                Cell: ({ value }: any) => {
                    return <Text variant="small">{value}</Text>;
                },
            },
            {
                Header: 'Source',
                accessor: 'sourceName',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    return <Text variant="small">{value}</Text>;
                },
            },
        ],
        [selectedOpportunities],
    );
    switch (loadingOps) {
        case 'success':
            if (opportunities.length > 0) {
                return (
                    <Box style={{ width: '100%' }} col center="horizontal">
                        <Box style={{ width: '100%', overflowY: 'scroll' }} col>
                            <Box
                                style={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    marginBottom: '12px',
                                }}
                                center="vertical"
                            >
                                <Typography variant="body3" style={{ marginLeft: '4px' }}>
                                    {selectedOpportunities.length} row(s) selected
                                </Typography>
                                <Button
                                    variant="action"
                                    disabled={selectedOpportunities.length === 0}
                                    onClick={() => {
                                        const blob = selectedOpportunities
                                            .map((opp) => {
                                                return `${opp?.start_time}\t${opp?.end_time}\t${opp?.rollAngle}\t${opp?.sourceName}\t`;
                                            })
                                            .join('\n');

                                        navigator.clipboard
                                            .writeText(blob)
                                            .then(() =>
                                                toast.success(
                                                    `${selectedOpportunities.length} row(s) copied to clipboard!`,
                                                ),
                                            );
                                    }}
                                >
                                    Copy Selected Row(s)
                                </Button>
                            </Box>
                            <StyledTable
                                onRowClick={(row: any, idx: number) => {
                                    setSelectedRow(idx);
                                    dispatch(setSelectedOpportunity(row));
                                }}
                                selectedIndex={selectedRow}
                                sensors={sensors}
                                columns={columns}
                                data={opportunities ?? []}
                                inverted
                            />
                        </Box>
                        <Pagination
                            pageSize={OPPORTUNITY_PAGE_SIZE}
                            offset={currentPage * OPPORTUNITY_PAGE_SIZE}
                            count={totalOpportunities}
                            onChange={(page: number) => setCurrentPage(page)}
                        />
                    </Box>
                );
            } else {
                return (
                    <Box center="all" col grow>
                        <Text style={{ fontSize: '1.75rem' }}>No opportunities found.</Text>
                        <Text variant="small">Please adjust your search criteria.</Text>
                    </Box>
                );
            }

        case 'loading':
            return (
                <Box style={{ width: '100%' }} center="all">
                    <Spinner variant="Box" size="Large" />
                </Box>
            );
        case 'failed':
            return (
                <Box center="all" col grow>
                    <Text style={{ fontSize: '1.75rem' }}>Error retrieving opportunites.</Text>
                    <Text variant="small">{error?.toString()}</Text>
                </Box>
            );
        default:
            return <></>;
    }
};

const mapStateToProps = (state: RootState) => {
    const { taskingSearchStartDate, taskingSearchEndDate, taskingSearchEclipsedMode } = state.tasking.app;

    return {
        sensors: state.osk.sensors,
        selectedAoi: state.data.search.roi,
        taskingSearchStartDate,
        taskingSearchEndDate,
        taskingSearchEclipsedMode,
    };
};
export default connect(mapStateToProps, noop)(OpportunityWindowTable);
