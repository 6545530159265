import React from 'react';
import { useTheme } from 'styled-components';
import { Box, BoxProps } from '../Box';
import { Text } from '../Typography';
import { ColorVariants, OSKThemeType } from '../DefaultThemeProvider';

export type ModalHeaderProps = {
    /** Children elements to render in the header */
    children?: React.ReactNode;
    /** Which color theme to use for the header */
    variant: ColorVariants;
} & Omit<BoxProps, 'ref'>;

/**
 * The ModalHeader component is styled according to the color variant prop
 * and represents the top section of a modal dialog box. Use it to enclose
 * header title text.
 */
const ModalHeader = ({ children, variant, style, ...props }: ModalHeaderProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                backgroundColor: theme.colors[variant].bg,
                padding: '15px',
                borderRadius: '5px 5px 0px 0px',
                ...style,
            }}
            {...props}
        >
            <Text color={theme.colors[variant].fg}>{children}</Text>
        </Box>
    );
};

export { ModalHeader };
