import { useProfileMetadata } from 'oskcore/src/hooks/useProfileMetadata';
import React, { useState } from 'react';
import { AbbreviationIcon, AbbreviationIconProps, PictureIcon } from '~/atoms';
import { user_fullname } from '~/utils';

export type UserProfileIconProps = Omit<AbbreviationIconProps, 'label'>;

export const UserProfileIcon = ({ ...props }: UserProfileIconProps) => {
    const [profile] = useProfileMetadata();
    const [error, setError] = useState(undefined);

    if (profile?.picture && !error) {
        return (
            <PictureIcon
                onImageError={(e: any) => {
                    console.error('Image failed to load.', e);
                    setError(e);
                }}
                title={user_fullname(profile)}
                url={profile.picture}
                {...props}
            />
        );
    } else {
        return <AbbreviationIcon label={`${profile?.first_name} ${profile?.last_name}`} {...props} />;
    }
};
