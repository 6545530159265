import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';
import { noop } from 'oskcore';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from 'oskcomponents';

type HiddenFootprintWarningProps = {
    /** From redux, the results of an archive search */
    results?: any;
    /** From redux, the total count of the api results. (Unpaginated, the total number available.) */
    apiResultsLength?: number;
};

const HiddenFootprintWarning = ({ results, apiResultsLength }: HiddenFootprintWarningProps) => {
    const MAX_RESULTS = 1000;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (Array.isArray(results) && (apiResultsLength ?? 0) > MAX_RESULTS) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [results, apiResultsLength]);

    if (!results) return <></>;

    return (
        <Modal visible={visible}>
            <ModalHeader variant="primary">
                <Typography variant="body3">Some footprints are hidden.</Typography>
            </ModalHeader>
            <ModalBody>
                {((n: number) => {
                    const plural = n - MAX_RESULTS > 1 ? 'were' : 'was';
                    const message = (
                        <>
                            Your search returned {n} results, however we can only show {MAX_RESULTS} at a time.{' '}
                            {n - MAX_RESULTS} {plural} hidden. <br /> <br /> Please adjust the search parameters to
                            narrow your selection.
                        </>
                    );
                    return message;
                })(apiResultsLength ?? 0)}
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="action"
                    label="Okay"
                    onClick={() => {
                        setVisible(false);
                    }}
                />
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    const { results, apiResultsLength } = state.data.search;
    return { results, apiResultsLength };
};

export default connect(mapStateToProps, noop)(HiddenFootprintWarning);
