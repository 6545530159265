import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, OSKIcon, OSKThemeType } from 'oskcomponents';

type FilterTagData = {
    /** Value to display in the label */
    value: any;
    /** Method to call when clicked */
    onClick?: () => void;
    /** The color to use for this tag */
    color?: string;
    /** The color to use for the foreground */
    fg?: string;
    /** The color to use for the border */
    border?: string;
};

type FilterTagElementProps = {
    /** Data to use for rendering the tag element */
    data: FilterTagData;
    /** Class name for styled-component */
    className?: string;
};
const FilterTagElement = styled(({ data, className, ...props }: FilterTagElementProps) => {
    const theme = useTheme() as OSKThemeType;
    const { value, fg = theme.colors.white, onClick } = data;
    return (
        <Box center="all" h={40} className={className} {...props} onClick={() => onClick && onClick()}>
            <OSKIcon code="assets" fill={fg} w={24} />
            <Box className="tag-value">{value}</Box>
            <OSKIcon code="times" scale={70} />
        </Box>
    );
})`
    & {
        position: relative;
        background-color: ${(props: any) => props.data.color ?? props.theme.colors.primary.accent};
        margin: 0px 8px 8px 0px;
        border-radius: 5px;
        padding: 8px 4px;
        border: ${(props: any) => (props.data.border ? `1px solid ${props.data.border}` : 'none')};
        color: ${(props: any) => (props.data.fg ? props.data.fg : 'black')};
    }
    &:hover {
        opacity: 0.5;
    }

    .tag-value {
        padding: 0px 8px;
    }
`;

type FilterTagListProps = {
    /** A list of tag data to render */
    tags?: Array<FilterTagData>;
};

/** This List element takes a list of FilterTagData items and displays them as clickable
 * tag elements that represent active filters. */
const FilterTagList = ({ tags }: FilterTagListProps) => {
    return (
        <Box style={{ margin: '8px 0px 8px 0px', flexWrap: 'wrap' }}>
            {tags?.map((tag) => (
                <FilterTagElement key={`tag-${tag.value}`} data={tag} />
            ))}
        </Box>
    );
};

export { FilterTagElement, FilterTagList };
export type { FilterTagData, FilterTagElementProps, FilterTagListProps };
