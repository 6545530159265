/**
 * This class represents a basic object that can be rendered to the canvas.
 */
export class Object2D {
    /** A 1d array of vertexes to render to the canvas. This engine only supports a 2d coordinate system. */
    vertexes: number[] = [];
    /** The 1d array of texture coordinates to apply to the underlying geometry. This engine only supports a 2d coordinate system. The texcoords should be 1:1 with the vertexes. */
    texcoords: number[] = [];
    /** The texture to render (as a bitmap) */
    textureRaster?: ArrayBuffer;
    /** The overlay texture to render (as a bitmap) */
    overlayRaster?: ArrayBuffer;
    /** How many samples or channels are represented in the bitmap. This engine only supports 1 and 3 channels. */
    textureChannels?: number;
    /** The width of the texture bitmap (in pixels) */
    textureWidth = 0;
    /** The height of the texture bitmap (in pixels) */
    textureHeight = 0;
    /** The 1d bitmap of colors to apply as a gradient based on intensity of each pixel. */
    textureGradient?: Uint8Array;

    /**
     * A helper method to set the vertexes.
     *
     * @param vertexes The vertexes to apply to this object
     * @returns The resulting Object2D
     */
    withVertexes(vertexes: number[]): Object2D {
        this.vertexes = vertexes;
        return this;
    }

    /**
     * A helper method to set the texture coordinates.
     *
     * @param texcoords The texcoords to apply to this object
     * @returns The resulting Object2D
     */
    withTexcoords(texcoords: number[]): Object2D {
        this.texcoords = texcoords;
        return this;
    }

    /**
     * A helper method to set the active gradient
     *
     * @param gradient The gradient to apply to this object
     * @returns The resulting Object2D
     */
    withGradient(gradient: Uint8Array): Object2D {
        this.textureGradient = gradient;
        return this;
    }

    /**
     * A helper method to set the overlay raster.
     *
     * @param gradient The overlay raster byte array to apply to this object
     * @returns The resulting Object2D
     */
    withOverlay(raster: Uint8Array): Object2D {
        this.overlayRaster = raster;
        return this;
    }

    /**
     * A helper method to set the active texture
     *
     * @param channels How many channels or smaples are present in the raster byte array
     * @param width The width of the texture
     * @param height The height of the texture
     * @param texture The byte array containing the texture bitmap
     * @returns The resulting Object2D
     */
    withRaster(channels: number, width: number, height: number, texture: ArrayBuffer | SharedArrayBuffer): Object2D {
        this.textureChannels = channels;
        this.textureWidth = width;
        this.textureHeight = height;
        this.textureRaster = texture;
        return this;
    }
}

/**
 * This will create a full-screen rectangle and return the associated
 * object.
 *
 * @returns An Object2D with the vertexes for a full-screen rectangle object
 */
export function Rect2D() {
    const texcoord_x1 = 0;
    const texcoord_x2 = 1;
    const texcoord_y1 = 1;
    const texcoord_y2 = 0;

    const vx1 = -1;
    const vx2 = 1;
    const vy1 = -1;
    const vy2 = 1;

    return new Object2D()
        .withVertexes([
            // First triangle:
            vx1,
            vy1,

            vx2,
            vy1,

            vx2,
            vy2,

            // Second triangle:
            vx2,
            vy2,

            vx1,
            vy2,

            vx1,
            vy1,
        ])
        .withTexcoords([
            // First triangle:
            texcoord_x1,
            texcoord_y1,

            texcoord_x2,
            texcoord_y1,

            texcoord_x2,
            texcoord_y2,

            // Second triangle:
            texcoord_x2,
            texcoord_y2,

            texcoord_x1,
            texcoord_y2,

            texcoord_x1,
            texcoord_y1,
        ]);
}
