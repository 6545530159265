import { Box, OSKIcon, Table, TableColumn, Text } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { AssetDetection, selectAlertsByAsset } from '~/redux/modules/monitor/app';
import { date_format, getProgramId } from '~/utils';
import { useNavigate } from 'react-router-dom';

type AssetAlertsTableProps = {
    /** The currently selected assetId */
    selectedAssetId?: number;
    /** The alert to populate the table with */
    data: Array<AssetDetection>;
    /** Whether or not to display the visibility checkbox column */
    showVisibilityFilters: boolean;
    /** Whether or not to show the map deep link button */
    showMapLinkButton: boolean;
};

const AssetAlertsTable = ({ data, showMapLinkButton, ...props }: AssetAlertsTableProps) => {
    const navigate = useNavigate();
    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            { Header: 'Id', accessor: 'id' },
            {
                Header: 'Type',
                accessor: 'call_type',
            },
            {
                Header: 'Detection Type',
                accessor: 'detection_type',
            },
            {
                Header: 'Date',
                accessor: 'detected_at',
                Cell: ({ value }: any) => {
                    const date = date_format(value);
                    return <>{date ? date : '--'}</>;
                },
            },
            ...(showMapLinkButton
                ? [
                      {
                          Header: '',
                          accessor: 'deeplink',
                          disableSortBy: true,
                          Cell: ({ row }: any) => {
                              const { asset, id } = row.original;
                              const mapLink = `/program/${getProgramId()}/monitor/${asset}/map/alert/${id}`;
                              return <OSKIcon code="map" onClick={() => navigate(mapLink)} />;
                          },
                      },
                  ]
                : []),
        ],
        [],
    );

    if (data.length < 1) {
        return (
            <Box grow center="all" style={{ alignSelf: 'center' }}>
                <Text>No alerts available</Text>
            </Box>
        );
    }

    return (
        <Box style={{ flexShrink: 1 }} col grow>
            <Table {...props} columns={columns} data={data} />
        </Box>
    );
};

const mapStateToProps = (state: RootState, ownProps: Partial<AssetAlertsTableProps>) => {
    const { selectedAssetId } = ownProps;

    return {
        data: selectAlertsByAsset(state, selectedAssetId ?? -1),
    };
};

const ConnectedAssetAlertsTable = connect(mapStateToProps, noop)(AssetAlertsTable);
export default ConnectedAssetAlertsTable;
export { ConnectedAssetAlertsTable as AssetAlertsTable };
export type { AssetAlertsTableProps };
