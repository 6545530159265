import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Text, TextElementProps, VariantTypes } from '../Typography';

import { FaRegSquare, FaCheckSquare } from 'react-icons/fa';
import { OSKThemeType } from '../DefaultThemeProvider';

export type CheckboxProps = {
    /** The class used to the style the wrapping div */
    className?: string;
    /** Children elements to render alongside the checkbxo icon */
    children?: React.ReactNode;
    /** Initial checkbox state */
    defaultChecked?: boolean;
    /** Id of the checkbox component (should be unique) */
    id?: string;
    /** Name of the checkbox component (this will be passed to forms) */
    name?: string;
    /** Optional label to render next to the checkbox */
    label?: string;
    /** Method to invoke when the value is changed  */
    onChange?: (checked: boolean) => void;
    /** Size variant, which gets proxied to the underlying Text component */
    variant?: VariantTypes;
    /** Optional scale to change the size of the check icon itself */
    scale?: number;
} & Omit<Omit<TextElementProps, 'variant'>, 'onChange'>;

export const Checkbox = styled(
    ({
        className,
        children,
        defaultChecked,
        variant = 'medium',
        id,
        name,
        label,
        onChange,
        scale = 100,
        ...props
    }: CheckboxProps) => {
        const [checked, setChecked] = useState(defaultChecked ?? false);
        const theme = useTheme() as OSKThemeType;

        /** This method will toggle the checkbox and invoke the onChange handler if applicable */
        const handleChange = () => {
            const nextValue = !checked;
            setChecked(nextValue);
            onChange && onChange(nextValue);
        };

        useEffect(() => {
            setChecked(defaultChecked ?? false);
        }, [defaultChecked]);

        return (
            <Text as="div" variant={variant} className={className} onClick={handleChange} {...props}>
                <input type="hidden" name={name} value={`${checked}`} />
                {checked && <FaCheckSquare />}
                {!checked && <FaRegSquare />}
                {label && (
                    <label style={{ paddingLeft: theme.padding.sm }} className={className}>
                        {label}
                    </label>
                )}
                {children}
            </Text>
        );
    },
)`
    align-items: center;
    align-content: center;
    cursor: pointer;
    vertical-align: middle;
    display: flex;

    svg {
        height: ${(props: any) => (props.scale ? props.scale / 100 : 1)}em;
        width: ${(props: any) => (props.scale ? props.scale / 100 : 1)}em;
    }
`;
