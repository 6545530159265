/**
 * This module asynchronously loads the tiff_parser wasm module and handles the scenario
 * where wasm is disabled or unsupported. In that case, it will fail with a warning.
 */

import { GeoTIFFImage } from 'geotiff';
import { initSync, parse_tiff } from 'tiff-parser';

let failed = false;
let loaded = false;
let warning = false;

/** 
    Asynchronously load the wasm module.
*/
async function initAsync() {
    return new Promise<void>((resolve) => {
        if (failed || loaded) {
            resolve();
        } else {
            try {
                WebAssembly.compileStreaming(fetch('/wasm/tiff_parser_bg.wasm'))
                    .then((module) => {
                        initSync(module);
                        loaded = true;
                    })
                    .catch(() => {
                        failed = true;
                        console.warn('Failed to load wasm module');
                    })
                    .finally(() => {
                        resolve();
                    });
            } catch (ex) {
                failed = true;
                console.warn(ex);
                resolve();
            }
        }
    });
}

/**
 * Parse a tiff file into raster bytes.
 * @param buffer A Uint8Array of the raw file.
 * @param image A GeoTIFFImage to use as a fallback if wasm is disabled
 * @returns A raster byte array.
 */
export async function parseTiff(buffer: Uint8Array, image: GeoTIFFImage): Promise<Uint8Array> {
    await initAsync();
    if (!failed && loaded && image.getSamplesPerPixel() > 1) {
        // Do the thing, but only for 3 band images
        return parse_tiff(buffer);
    } else {
        if (!warning && image.getSamplesPerPixel() > 1) {
            warning = true;
            console.warn('Tiff parser failed to load');
        }

        // Use the fallback method
        return (await image.readRasters({ interleave: true })) as Uint8Array;
    }
}
